import {
  Rive,
  Layout,
  Fit,
  Alignment,
  useRive,
  useStateMachineInput,
  FileAsset,
  decodeImage,
  ImageAsset,
} from '@rive-app/react-canvas';
import { useEffect } from 'react';

export const BossDungeonBtnAnimation = ({ triggerHit }: { triggerHit: boolean }) => {
  const { rive, RiveComponent } = useRive({
    src: '/animation/bossback.riv',
    stateMachines: 'State Machine 1',
    autoplay: false,
  });

  useEffect(() => {
    if (rive) {
      if (triggerHit) {
        rive.play();
      }
    }
  }, [rive, triggerHit]);

  return <RiveComponent />;
};
