import { useTranslation } from "react-i18next";
import { useUtils } from "../../../../utils/navigateTo";



export const BossButton = () => {
  const { navigateTo } = useUtils();
  const { t } = useTranslation();
  return (
    <div className="select-none cursor-pointer w-14 h-10 pb-1 ml-2 bg-[#1e0400] border border-black rounded-lg shadow-lg  relative transform transition-transform duration-150 active:scale-9">
      <div
        className="w-full h-full  rounded-lg  flex justify-center items-center bg-gradient-to-b from-[#FEE7BA] to-[#B6765A] p-1 relative"
        onClick={() => {
          navigateTo('/boss')
        }}
      >
        <div className="relative flex flex-col items-center ">
          <div className="relative w-full flex justify-center items-center overflow-visible">
            <img
              src={require("../../../../assets/images/bossDungeon/boss-button.png?type=base64")}
              className="absolute -top-6 w-[48px] h-[34px] max-w-none"
            />
          </div>
          <div
            className="absolute -top-[23px] text-center text-white text-sm font-bold leading-3 mt-8 text-nowrap"
            style={{
              filter:
                "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black) ",
            }}>
            {t("bossFight.title")}
          </div>
        </div>
      </div>
    </div>
  )
}
