import React, { useEffect, useState } from 'react';
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { WalletButton } from './components';
import { PopupButton } from '../../../../components/PopupButton';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { useSoundService } from '../../../../utils/soundService';
import { getProfileInfo, updateProfileWallet } from '../../../../endpoints/getProfileInfo';
import { useTelegram } from '../../../../hooks/useTelegram';

import ConnectWalletPopup from '../../../../components/ConnectWalletPopup';
import { useSessionStorage } from '@uidotdev/usehooks';

export const ConnectWallet: React.FC = () => {
  const [openConnectWalletPopup, setOpenConnectWalletPopup] = useSessionStorage('openConnectWalletPopup', false);
  const { t } = useTranslation();
  const address = useTonAddress();
  const { playSound } = useSoundService();
  const [firstConnectWallet, setFirstConnectWallet] = useState(true);

  const { userId } = useTelegram();

  const handleUpdateProfileWallet = async (address: string) => {
    await updateProfileWallet({
      clientId: userId,
      wallet: address,
    });
  };

  const handleFirstWallet = async () => {
    const data = await getProfileInfo({
      clientId: userId,
    });

    if (data.wallet) {
      setFirstConnectWallet(false);
    }
  };

  useEffect(() => {
    if (userId) {
      handleFirstWallet();
    }
  }, [userId]);

  useEffect(() => {
    if (address) {
      handleUpdateProfileWallet(address);
    }
  }, [address]);

  return (
    <>
      <div className="flex mt-4 justify-center items-center text-nowrap">
        {address ? (
          <WalletButton />
        ) : (
          <div className="flex flex-col items-center">
            {firstConnectWallet && <div className="font-bold text-sm text-[#FFC923]">{t('profile.earnRewards')}</div>}
            <PopupButton
              type={'blue'}
              size={'medium'}
              width="200px"
              onClick={() => {
                playSound('notification');
                setOpenConnectWalletPopup(true);
              }}
              className="mt-1"
            >
              {t('profile.connect')}
            </PopupButton>
          </div>
        )}
      </div>
      {openConnectWalletPopup ? <ConnectWalletPopup firstConnectWallet={firstConnectWallet} /> : null}
      <ToastContainer className={'fixed top-[70px] z-40'} />
    </>
  );
};
