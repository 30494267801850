import { PanOnScrollMode, ReactFlow, ReactFlowProvider, useReactFlow, Viewport } from '@xyflow/react';
import { PopupButton } from '../../../../../../components/PopupButton';
import { SkillNode } from '../SkillNode';
import { useTranslation } from 'react-i18next';
import CustomEdge from '../CustomEdge';
import { HeroRarity, TierLevel, Tree } from '../../../../../../interfaces/hero';
import { getHeroesShards, getHeroTierUpgradePrice, updateTierLevel } from '../../../../../../endpoints/heroEndpoints';
import { useTelegram } from '../../../../../../hooks/useTelegram';
import React, { memo, useEffect, useRef, useState } from 'react';
import { getBalance } from '../../../../../../endpoints/farmMock';
import { HeroRating } from '../../../HeroRating';
import { heroShardMap, universalShardMap } from '../../../../../../utils/mapping';
import { HeroShard, ItemType, UniversalShard } from '../../../../../../interfaces/lootBotx';
import { HandleBackButton } from '../../../../../../layout/components/HeaderCave/components';
import { Offer } from '../../../../../../pages/Shop/components';
import { usePurchase } from '../../../../../../hooks/usePurchase';
import { OfferProps } from '../../../../../../pages/Shop/components/Offer';
import { PurchaseModal } from '../../../../../../components/PurchaseModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../app/store';
import { TutorialFarmSave, TutorialFarmStage } from '../../../../../../interfaces/tutorial';
import { saveFarmTutorialProgress } from '../../../../../../app/features/farmTutoralSlice';
import { PointerHand } from '../../../../../../components/PointerHand';
import { SuccessAnimation } from '../../../../../../pages/Quests';
import { SuccessOverlay } from '../../../../../../components/SuccessOverlay';
import { useSoundService } from '../../../../../../utils/soundService';
import { defineCountString } from '../../../../../../utils/farmConfigParser';
import { CategoryItem, HeroesItem } from '../../../../../Inventory';
import { SkillData, transformTreeToSkillsData } from '../transformTreeToSkillsData';
import { GenerateNodesAndEdges } from '../GenerateNodesAndEdges';

export const HeroTierModal = memo(
  ({
    getHeroesList,
    upgradeCards,
    treesData,
    // nodes,
    // edges,
    onClose,
    rating,
    rarity,
    // onNodeClick,
    // selectedSkill,
    heroId,
    offer,
    universalShards,
  }: {
    getHeroesList: () => void;
    upgradeCards: number;
    treesData: Tree[];
    // nodes: any;
    // edges: any;
    onClose?: () => void;
    rating: { claimedLevels: number; totalLevels: number };
    rarity: string;
    // onNodeClick: any;
    // selectedSkill: any;
    heroId: number;
    offer: OfferProps;
    universalShards: HeroesItem[];
  }) => {
    const nodeTypes = {
      customSkillNode: SkillNode,
    };
    const edgeTypes = {
      custom: CustomEdge,
    };
    const { t } = useTranslation();
    const { playSound } = useSoundService();

    const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);
    const [safeAreaTop, setSafeAreaTop] = useState(() => {
      return sessionStorage.getItem('safeAreaTop') || 0;
    });

    const [univarsalShards, setUniversalShards] = useState<HeroesItem[]>(universalShards);

    const dispatch = useDispatch<AppDispatch>();

    const updateSave = (save: TutorialFarmSave) => {
      dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
    };

    const skillsData: SkillData[] = transformTreeToSkillsData(treesData);

    const [selectedSkillId, setSelectedSkillId] = useState(null);

    const handleNodeClick = (event: any, node: any) => {
      //console.log(`Ви натиснули на вузол з ID: ${node.id} та назвою: ${node.alt}`);
      setSelectedSkillId(node.id);
    };

    const [nodes, setNodes] = useState<any>([]);
    const [edges, setEdges] = useState<any>([]);

    const selectedSkill = nodes.find((node: any) => node.id === selectedSkillId) || null;

    useEffect(() => {
      const { nodes, edges } = GenerateNodesAndEdges(skillsData, selectedSkillId);
      setNodes(nodes);
      setEdges(edges);
    }, [selectedSkillId, treesData]);

    // Auto select of nearest available skill
    // useEffect(() => {
    //   if (nodes.length > 0 && !selectedSkillId) {
    //     const availableSkill = nodes.find((node: any) => node.data.available && !node.data.claimed);

    //     if (availableSkill) {
    //       setSelectedSkillId(availableSkill.id);
    //     }
    //   }
    // }, [nodes]);

    useEffect(() => {
      if (farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade) {
        updateSave({
          stage: TutorialFarmStage.upgradeTier,
        });
      }
    }, [selectedSkill]);

    const [upgradePrice, setUpgradePrice] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { userId } = useTelegram();

    const updateTierHero = async (auto: boolean = false) => {
      try {
        setIsLoading(true);
        playSound('heroTypeUp');
        const result = await updateTierLevel({
          clientId: userId,
          heroId: heroId,
          tier: Number(selectedSkill.id),
          auto,
        });

        if (result) {
          getHeroesList();
          fetchHeroTierUpgradePrice();
          fetchUnivarsalShards();
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchHeroTierUpgradePrice();
      getHeroesList();
      if (univarsalShards.length === 0) {
        fetchUnivarsalShards();
      }
    }, []);

    const fetchHeroTierUpgradePrice = async () => {
      try {
        const price = await getHeroTierUpgradePrice({
          clientId: userId,
          heroId: heroId,
        });
        setUpgradePrice(price);
      } catch (error) {}
    };

    const fetchUnivarsalShards = async () => {
      try {
        const responseHeroesShards = await getHeroesShards({
          clientId: userId,
        });
        const mappedUniversalShards: HeroesItem[] = responseHeroesShards.universalShards.map((item, index) => {
          return {
            id: `universalShards-${item.shardId}-${index}`,
            type: ItemType.universalShard,
            name: universalShardMap[item.shardId as UniversalShard].name,
            description: universalShardMap[item.shardId as UniversalShard].description,
            category: CategoryItem.Heroes,
            amount: item.amount,
            icon: universalShardMap[item.shardId as UniversalShard].image,
            rarity: item.shardId,
          };
        });

        setUniversalShards(mappedUniversalShards);
      } catch (e) {
        console.log(e);
      }
    };

    const allLevelsClaimed = selectedSkill?.data?.levels?.every((level: TierLevel) => level.isClaimed) || false;

    const YScroll = selectedSkill ? (window.innerHeight < 800 ? 1060 : 950) : window.innerHeight < 800 ? 980 : 920;

    const [showOffer, setShowOffer] = useState(false);

    const handleNoTiersPromoteButPack = () => {
      setShowOffer(true);
    };

    const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();

    const calculateVisibleLevels = () => {
      const { levels, claimed } = selectedSkill.data;
      if (!claimed) {
        return levels.slice(0, 1);
      }
      const nextLevelIndex = levels.findIndex((level: TierLevel) => !level.isClaimed);
      if (nextLevelIndex === -1) {
        return levels.slice(-1);
      }
      return levels.slice(nextLevelIndex - 1, nextLevelIndex + 1);
    };

    return (
      <>
        <div
          className={`fixed inset-0 flex items-center justify-center 
    ${
      farmTutorialSave?.stage === TutorialFarmStage.clickOnRankUpgrade ||
      farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade ||
      farmTutorialSave?.stage === TutorialFarmStage.upgradeTier
        ? 'z-[11]'
        : 'z-1'
    }`}
        >
          <div
            className={`absolute w-full max-w-4xl overflow-hidden 
          ${
            farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade && !selectedSkill
              ? 'z-100 animate-highlight'
              : ''
          } `}
            style={{ height: '75vh', top: safeAreaTop }}
          >
            <div className={`absolute left-[20px] top-0`}>
              <HandleBackButton onClick={onClose}></HandleBackButton>
            </div>
            <ReactFlowProvider>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodesDraggable={false}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodesConnectable={false}
                onNodeClick={handleNodeClick}
                elementsSelectable={true}
                style={{ backgroundColor: 'transparent', height: '100%' }}
                proOptions={{ hideAttribution: true }}
                fitView
                zoomOnScroll={false}
                zoomOnPinch={false}
                panOnScroll={false}
                panOnDrag={false}
                zoomOnDoubleClick={false}
                //panOnScrollMode={PanOnScrollMode.Vertical}
                // translateExtent={[
                //   [124, 0],
                //   [455, YScroll],
                // ]}
              />
            </ReactFlowProvider>
          </div>
          <div
            className={`w-full absolute h-auto left-0 bottom-0  flex flex-col  z-[11] bg-gradient-to-t from-[#201B18] via-[#201B18]/100 via-80% to-transparent to-100%
          ${
            farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade ? 'brightness-50 pointer-events-none' : ''
          }`}
          >
            <div className="relative w-full h-fit top-[15%] flex flex-col">
              <div className="py-2">
                <HeroRating
                  claimedLevels={rating.claimedLevels}
                  totalLevels={rating.totalLevels}
                  starsWidth={'w-[32px]'}
                />
              </div>

              {/* {selectedSkill &&
              (
                <div className="w-full text-center text-white">
                    {selectedSkill.data.levels.filter((s: any) => s.isClaimed)[0] ?'HERE ':'THERE.' }
                </div>
              ))} */}

              {selectedSkill && (
                <div className="flex flex-col gap-1 px-4 max-h-28 ">
                  {calculateVisibleLevels().map((level: TierLevel, index: number) => (
                    <div
                      key={level.id}
                      className="flex justify-center items-center relative flex-shrink-0 min-h-7"
                      style={{
                        background: 'radial-gradient(circle, rgba(153, 153, 153, 0.2) 0%, rgba(255, 255, 255, 0) 80%)',
                      }}
                    >
                      <div
                        className="absolute top-0 left-0 w-full h-[1px] pointer-events-none"
                        style={{
                          background:
                            'radial-gradient(circle at center, rgba(220, 134, 3, 0.5), rgba(220, 134, 3, 0) 70%)',
                        }}
                      ></div>

                      <div
                        className="absolute bottom-0 left-0 w-full h-[1px] pointer-events-none"
                        style={{
                          background:
                            'radial-gradient(circle at center, rgba(220, 134, 3, 0.5), rgba(220, 134, 3, 0) 70%)',
                        }}
                      ></div>
                      <div className="grid grid-cols-4 justify-items-center">
                        {!level.isClaimed ? (
                          <div className="w-[24px] h-[24px]">
                            <img
                              className="w-full h-full"
                              src={require('../../../../../../assets/images/heroes/icons/closed.png')}
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}

                        <div
                          className={`${
                            level.isClaimed
                              ? 'flex flex-col items-center justify-center text-center text-[#ffefcb] text-sm font-bold leading-3 col-span-2'
                              : 'flex flex-col items-center justify-center text-center text-[#6a6565] text-sm font-bold leading-3 col-span-2'
                          }  `}
                          style={
                            level.isClaimed
                              ? {
                                  filter:
                                    'drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)',
                                }
                              : {}
                          }
                        >
                          {level.rewards.map((reward, index) => {
                            return (
                              <div key={index} className="flex justify-center items-center">
                                {t(`bonusTypes.${reward.bonusType}`)}: {reward.value}
                              </div>
                            );
                          })}
                        </div>
                        <div />
                      </div>
                      {calculateVisibleLevels().length > 1 && calculateVisibleLevels().length - 1 !== index && (
                        <svg
                          className="absolute -bottom-3 z-50"
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.700543 8.89567C0.798645 8.6295 1.0439 8.34018 1.4265 8.34018L4.25185 8.34018L4.25185 1.74381C4.25185 1.23462 4.60502 0.818004 5.03667 0.818004L8.96076 0.818004C9.39241 0.818004 9.74558 1.23462 9.74558 1.74381L9.74557 8.34018L12.5807 8.34018C12.9633 8.34018 13.1988 8.6295 13.2969 8.89567C13.395 9.16184 13.4146 9.56688 13.1497 9.90248L7.57751 16.8923C7.42055 17.0775 7.22434 17.1816 6.99871 17.1816C6.77307 17.1816 6.57687 17.0775 6.42972 16.8923L0.847696 9.90248C0.592631 9.56688 0.602441 9.16184 0.700543 8.89567Z"
                            fill="#47C77A"
                            stroke="#19191B"
                            stroke-width="0.7"
                          />
                        </svg>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div className="flex gap-2 justify-center items-center pt-2 pb-5 flex-grow h-[15vh] ">
                <div className="flex flex-col justify-between gap- h-full">
                  {allLevelsClaimed ? (
                    <div
                      className="flex justify-center items-center relative flex-shrink-0 min-h-7 w-[100vw]"
                      style={{
                        background: 'radial-gradient(circle, rgba(153, 153, 153, 0.2) 0%, rgba(255, 255, 255, 0) 80%)',
                      }}
                    >
                      <div
                        className="absolute top-0 left-0 w-full h-[1px] pointer-events-none"
                        style={{
                          background:
                            'radial-gradient(circle at center, rgba(220, 134, 3, 0.5), rgba(220, 134, 3, 0) 70%)',
                        }}
                      ></div>

                      <div
                        className="absolute bottom-0 left-0 w-full h-[1px] pointer-events-none"
                        style={{
                          background:
                            'radial-gradient(circle at center, rgba(220, 134, 3, 0.5), rgba(220, 134, 3, 0) 70%)',
                        }}
                      ></div>
                      <div
                        className="flex flex-col items-center justify-center text-center text-[#DC8603] font-black leading-3 col-span-2"
                        style={{
                          filter:
                            'drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)',
                        }}
                      >
                        <div className="text-center">{t('heroes.maxLevel')}</div>
                      </div>
                    </div>
                  ) : (
                    // <div className="text-center text-[#ffefcb] text-lg font-normal leading-[18px] pt-4">
                    // </div>
                    <div className="flex items-center justify-center gap-[22px] mb-[25px]">
                      {!(upgradeCards >= upgradePrice!) &&
                      univarsalShards.find((shard) => shard.rarity === 0)?.amount ? (
                        <div className="flex flex-col items-center justify-center">
                          <div className="flex gap-2 justify-center items-center">
                            <div className="flex gap-1 justify-center items-center">
                              <img
                                src={univarsalShards?.find((shard) => shard.rarity === 0)?.icon}
                                className="w-6 h-6"
                              />
                              <div
                                className={`text-right ${
                                  univarsalShards &&
                                  upgradePrice! - upgradeCards >
                                    univarsalShards?.find((shard) => shard.rarity === 0)!.amount
                                    ? 'text-[#dd5444]'
                                    : 'text-[#3adc96]'
                                } text-sm font-black uppercase leading-[14px]`}
                              >
                                {univarsalShards?.find((shard) => shard.rarity === 0)?.amount}
                              </div>
                              <div className="text-right text-white text-sm font-black uppercase leading-[14px]">/</div>
                              <div className="text-right text-white text-sm font-black uppercase leading-[14px]">
                                {upgradePrice && upgradePrice - upgradeCards}
                              </div>
                            </div>
                            {upgradeCards > 0 && (
                              <div className="flex gap-1 justify-center items-center">
                                <img src={heroShardMap[heroId as HeroShard].image} className="w-6 h-6" />
                                <div
                                  className={`text-right text-[#3adc96] text-sm font-black uppercase leading-[14px]`}
                                >
                                  {upgradeCards}
                                </div>
                                <div className="text-right text-white text-sm font-black uppercase leading-[14px]">
                                  /
                                </div>
                                <div className="text-right text-white text-sm font-black uppercase leading-[14px]">
                                  {upgradeCards}
                                </div>
                              </div>
                            )}
                          </div>
                          <PopupButton
                            type={selectedSkill?.data?.available && !isLoading ? 'blue' : 'gray'}
                            disabled={!selectedSkill?.data?.available || isLoading}
                            onClick={() => {
                              updateSave({
                                stage: TutorialFarmStage.finishTierTutorial,
                              });
                              if (
                                upgradePrice !== undefined &&
                                univarsalShards?.find((shard) => shard.rarity === 0)!.amount >=
                                  upgradePrice - upgradeCards
                              ) {
                                updateTierHero(true);
                              } else {
                                handleNoTiersPromoteButPack();
                              }
                            }}
                          >
                            <div className="text-center text-[#ffefcb] text-lg font-normal leading-[18px] p-[5px]">
                              {t('heroes.upgradeTier')}
                            </div>
                            {selectedSkill?.data?.available &&
                              upgradePrice !== undefined &&
                              univarsalShards &&
                              univarsalShards?.find((shard) => shard.rarity === 0)!.amount >= upgradePrice && (
                                <div className="w-3 min-w-3 h-3 min-h-3 bg-[#ff3a3a] border border-[black] rounded-full absolute top-0 right-0 z-50"></div>
                              )}
                          </PopupButton>
                        </div>
                      ) : null}
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex gap-2 justify-center items-center">
                          <div className="flex gap-1 justify-center items-center">
                            <img src={heroShardMap[heroId as HeroShard].image} className="w-6 h-6" />
                            <div
                              className={`text-right ${
                                upgradePrice! > upgradeCards ? 'text-[#dd5444]' : 'text-[#3adc96]'
                              } text-sm font-black uppercase leading-[14px]`}
                            >
                              {upgradeCards}
                            </div>
                            <div className="text-right text-white text-sm font-black uppercase leading-[14px]">/</div>
                            <div className="text-right text-white text-sm font-black uppercase leading-[14px]">
                              {upgradePrice}
                            </div>
                          </div>
                        </div>
                        <PopupButton
                          type={selectedSkill?.data?.available && !isLoading ? 'green' : 'gray'}
                          disabled={!selectedSkill?.data?.available || isLoading}
                          onClick={() => {
                            updateSave({
                              stage: TutorialFarmStage.finishTierTutorial,
                            });
                            if (upgradePrice !== undefined && upgradeCards >= upgradePrice) {
                              updateTierHero();
                            } else {
                              handleNoTiersPromoteButPack();
                            }
                          }}
                        >
                          <div className="text-center text-[#ffefcb] text-lg font-normal leading-[18px] p-[5px]">
                            {t('heroes.upgradeTier')}
                          </div>
                          {farmTutorialSave?.stage === TutorialFarmStage.upgradeTier && (
                            <div className={`absolute z-[9999] right-[20px] top-[20px]`}>
                              <PointerHand />
                            </div>
                          )}
                          {selectedSkill?.data?.available &&
                            upgradePrice !== undefined &&
                            upgradeCards >= upgradePrice && (
                              <div className="w-3 min-w-3 h-3 min-h-3 bg-[#ff3a3a] border border-[black] rounded-full absolute top-0 right-0 z-50"></div>
                            )}
                        </PopupButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute -z-[9] inset-0 bg-gradient-to-t from-[#201B18] via-[#201B18]/100 via-40% to-transparent to-100% backdrop-blur-sm"></div>
          <div className="absolute -z-[8] inset-0 top-0">
            <img
              className="relative w-full h-full object-cover"
              src={require('../../../../../../assets/images/hero-tier-bg-stars.png')}
              alt=""
            />
          </div>
          <div className="absolute -z-10 inset-0 top-0">
            <img
              className="relative w-full h-full object-cover object-top"
              src={require('../../../../../../assets/images/hero-tier-bg.png')}
              alt=""
            />
          </div>
        </div>
        {showOffer && (
          <div className="fixed inset-0 min-w-full min-h-full z-20 backdrop-blur-sm">
            {/* Чорний фоновий шар */}

            <div className="absolute inset-0 bg-black bg-opacity-50 z-20 pointer-events-none"></div>

            {/* Компонент Offer, який має бути поверх чорного фону */}
            <div className="absolute inset-0 z-30 flex items-center justify-center">
              <Offer
                onClose={() => setShowOffer(false)}
                onClick={() => {
                  confirmPurchase({ offerId: offer.id });
                }}
                rankPoints={defineCountString(Math.floor(Number(offer.price) / 10))}
                offerType={offer.offerType}
                id={offer.id}
                resourceList={offer.resourceList}
                layout={offer.layout}
                type={offer.type}
                label={offer.label}
                kitsu={offer.kitsu}
                price={offer.price}
                deadline={offer.deadline}
              />
            </div>
          </div>
        )}
        <SuccessOverlay isSuccess={isSuccessPurchase} />
      </>
    );
  }
);
