import { useTranslation } from 'react-i18next';
import { HandleBackButton } from '../../layout/components/HeaderCave/components';
import { useUtils } from '../../utils/navigateTo';
import PageTransition from '../../containers/Router/components/PageTransition';
import { useEffect, useMemo, useState } from 'react';
import { clearUserRank, fetchUserRank } from '../../app/features/userRankSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { useTelegram } from '../../hooks/useTelegram';
import { PopupButton } from '../../components/PopupButton';
import { BonusType } from '../../interfaces/hero';
import { ConfigBonusType, RankBonuses, RankConfig } from '../../interfaces/rank';
import { BonusRankType, claimOneTime, claimRank } from '../../endpoints/rankEndpoints';
import { Offer } from '../Shop/components';
import { OfferResourceProps } from '../Shop/components/OfferResources';
import { defineCountString } from '../../utils/farmConfigParser';
import { ConfigOffer, OfferType } from '../../mock/offers';
import { OfferProps } from '../Shop/components/Offer';
import { usePurchase } from '../../hooks/usePurchase';
import { SuccessOverlay } from '../../components/SuccessOverlay';
import { useSessionStorage } from '@uidotdev/usehooks';
import * as Sentry from '@sentry/react';
import { ErrorType, RuntimeErrorData } from '../../interfaces/error';
import { ErrorReportingService } from '../../services/errorReportingService';
import { ErrorComponent } from '../../components';
import { getIsSelected } from '../Shop';
import { handleRewardResult } from '../../utils/rewardParse';
import { UniversalShard } from '../../interfaces/lootBotx';
import { universalShardMap } from '../../utils/mapping';
import { getUserOffers } from '../../endpoints/getProfileInfo';
import { Link } from 'react-router-dom';

const ProgressBar = ({ current, max }: any) => {
  const percentage = Math.min((current / max) * 100, 100);

  return (
    <div className="w-full bg-[#312d2a]  h-5 relative rounded-[3px] shadow-inner-sm-black border border-[#18191a]">
      <div
        className="bg-gradient-to-b from-[#fffe2e] to-[#fe8315] h-full p rounded-[3px] transition-all duration-500 flex items-center justify-center"
        style={{ width: `${percentage}%` }}
      />
      <div className="absolute text-center top-0 text-white text-[15px] font-extrabold leading-[15px] w-full h-full flex justify-center items-center">
        {`${current} / ${max}`}
      </div>
    </div>
  );
};

export const RankPage = () => {
  const { t } = useTranslation();
  const { goBack, navigateTo } = useUtils();
  const dispatch = useDispatch<AppDispatch>();
  const { tg, userId } = useTelegram();
  const [isShopOpen, setShopOpen] = useSessionStorage('isShopOpen', false);
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);
  const [purchasedOfferIds, setPurchasedOfferIds] = useState<Set<number> | null>(null);

  useEffect(() => {
    const getUserOffersData = async () => {
      const userOffersData = await getUserOffers({
        clientId: userId,
      });

      if (Array.isArray(userOffersData)) {
        setPurchasedOfferIds(
          new Set(userOffersData.map((o: { offerId: number; offerType: number; quantity: number }) => o.offerId))
        );
      }
    };
    getUserOffersData();
  }, []);

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  useEffect(() => {
    dispatch(fetchUserRank({ clientId: userId }));

    return () => {
      dispatch(clearUserRank());
    };
  }, [dispatch]);

  const { userRank, loading, error } = useSelector((state: RootState) => state.userRank);

  const sortedRanks = useMemo(() => {
    if (!appConfig) return [];
    return [...appConfig.ranks.variables].sort((a, b) => a.lvl.value - b.lvl.value);
  }, [appConfig]);

  const currentRankIndex = useMemo(() => {
    if (!userRank) return undefined;
    return sortedRanks.findIndex((rank) => rank.lvl.value === userRank.myRankLvl);
  }, [sortedRanks, userRank]);

  const [selectedRankIndex, setSelectedRankIndex] = useState(currentRankIndex ?? 0);

  // const configRankData = useMemo(() => {
  //   if (!appConfig || !userRank) return undefined;

  //   const configRanks: RankConfig[] = appConfig.ranks.variables;

  //   // Створюємо копію масиву перед сортуванням
  //   const sortedRanks = [...configRanks].sort(
  //     (a, b) => a.lvl.value - b.lvl.value
  //   );

  //   // Знаходимо індекс поточного рівня
  //   const currentRankIndex = sortedRanks.findIndex(
  //     (rank: RankConfig) => rank.lvl.value === userRank.myRankLvl
  //   );

  //   // Якщо поточного рівня немає в конфігурації, повертаємо undefined
  //   if (currentRankIndex === -1) return undefined;

  //   // Поточний ранг
  //   const currentRank = sortedRanks[currentRankIndex];

  //   // Наступний ранг (якщо існує)
  //   const nextRank = sortedRanks[currentRankIndex + 1] || null;

  //   return {
  //     currentRank,
  //     nextRank,
  //   };
  // }, [appConfig, userRank]);

  const currentRank: RankConfig = sortedRanks[userRank?.myRankLvl! + 1]
    ? sortedRanks[userRank?.myRankLvl! + 1]
    : sortedRanks[userRank?.myRankLvl!];
  const selectedRank: RankConfig = sortedRanks[selectedRankIndex!];
  const nextRank: RankConfig = sortedRanks[selectedRankIndex! + 1] || null;
  const prevRank: RankConfig = sortedRanks[selectedRankIndex! - 1] || null;
  const [safeAreaTop, setSafeAreaTop] = useState(() => {
    return sessionStorage.getItem('safeAreaTop') || 0;
  });

  type RankBonuses = {
    [key: string]: number;
  };

  type ActiveBonus = {
    type: string;
    previousAmount: number;
    newAmount: number;
    isOpen: boolean;
    isNew: boolean;
  };

  const activeBonuses = useMemo<ActiveBonus[] | undefined>(() => {
    if (!selectedRank || !userRank || sortedRanks.length === 0) {
      return undefined;
    }

    // Мапа для накопичення бонусів за типом
    const bonusMap: {
      [type: string]: {
        previousAmount: number;
        newAmount: number;
        isNew: boolean;
      };
    } = {};

    // Ітеруємося по всіх рангам до обраного включно
    for (let i = 0; i <= selectedRankIndex; i++) {
      const rank: RankConfig = sortedRanks[i];

      // Обробка звичайних бонусів
      const bonuses = rank.bonuses.value;
      bonuses.forEach((bonus: ConfigBonusType) => {
        const type = bonus.type.value;
        const amount = bonus.amount.value;

        if (i === selectedRankIndex) {
          // Бонуси з поточного рівня
          if (bonusMap[type]) {
            // Додаємо до існуючого бонусу
            bonusMap[type].newAmount = bonusMap[type].previousAmount + amount;
            bonusMap[type].isNew = true; // Позначаємо як новий або оновлений
          } else {
            // Ініціалізуємо новий бонус
            bonusMap[type] = {
              previousAmount: 0,
              newAmount: amount,
              isNew: true, // Позначаємо як новий тип бонусу
            };
          }
        } else {
          // Бонуси з попередніх рівнів
          if (bonusMap[type]) {
            // Додаємо до існуючого бонусу
            bonusMap[type].previousAmount += amount;
            // isNew залишається без змін
          } else {
            // Ініціалізуємо бонус без позначки новизни
            bonusMap[type] = {
              previousAmount: amount,
              newAmount: 0,
              isNew: false,
            };
          }
        }
      });

      // Обробка одноразових нагород
      if (rank.oneTimeRewards && rank.oneTimeRewards.value) {
        rank.oneTimeRewards.value.forEach((reward) => {
          const { rewardType, rewardId, amount } = reward;
          const result = handleRewardResult({
            rewardType: rewardType.value,
            rewardId: rewardId.value,
          });
          if (!result) {
            return;
          }

          if (i === selectedRankIndex) {
            // Нагороди з поточного рівня
            if (bonusMap[result.name]) {
              // Додаємо до існуючого бонусу
              bonusMap[result.name].newAmount = bonusMap[result.name].previousAmount + amount.value;
              bonusMap[result.name].isNew = true; // Позначаємо як новий або оновлений
            } else {
              // Ініціалізуємо новий бонус
              bonusMap[result.name] = {
                previousAmount: 0,
                newAmount: amount.value,
                isNew: true, // Позначаємо як новий тип бонусу
              };
            }
          } else {
            // Нагороди з попередніх рівнів
            if (bonusMap[result.name]) {
              // Додаємо до існуючого бонусу
              bonusMap[result.name].previousAmount += amount.value;
              // isNew залишається без змін
            } else {
              // Ініціалізуємо бонус без позначки новизни
              bonusMap[result.name] = {
                previousAmount: amount.value,
                newAmount: 0,
                isNew: false,
              };
            }
          }
        });
      }
    }

    // Конвертуємо мапу в масив
    const accumulatedBonuses: ActiveBonus[] = Object.entries(bonusMap).map(
      ([type, { previousAmount, newAmount, isNew }]) => ({
        type,
        previousAmount,
        newAmount,
        isNew,
        isOpen: false, // Ініціалізуємо як false, буде оновлено далі
      })
    );

    // Отримуємо значення бонусів користувача
    const values = Object.values(userRank.bonuses) as Array<RankBonuses[keyof RankBonuses]>;

    // Функція для отримання значення бонусу за типом
    function getValueByType(type: string): number | undefined {
      const typeIndex = parseInt(type, 10);
      if (isNaN(typeIndex)) return undefined;
      return values[typeIndex];
    }

    // Мапінг бонусів з встановленням isOpen
    const mappedBonuses: ActiveBonus[] = accumulatedBonuses.map((bonus) => ({
      ...bonus,
      isOpen: getValueByType(bonus.type) !== undefined && getValueByType(bonus.type)! > 0,
    }));

    // Сортуємо бонуси, щоб нові були на верху
    const sortedMappedBonuses = mappedBonuses.sort((a, b) => {
      if (a.isNew === b.isNew) return 0;
      return a.isNew ? -1 : 1;
    });

    return sortedMappedBonuses;
  }, [selectedRank, userRank, sortedRanks, selectedRankIndex]);

  const rankOffer = useMemo(() => {
    if (!appConfig || !userRank || !purchasedOfferIds) return undefined;

    const {
      offers: { variables: allOffers },
    } = appConfig;
    const rankOffers = allOffers.filter((offer: ConfigOffer) => offer.offerType.value === OfferType.rankOffer);
    const notPurchasedOffers = rankOffers.filter((offer: ConfigOffer) => !purchasedOfferIds.has(offer.offerId.value));
    const chosenOffer = notPurchasedOffers.find(
      (offer: ConfigOffer) => offer.offerRank.value === selectedRank.lvl.value
    );

    if (!chosenOffer) return undefined;

    const mapOffer = (offer: ConfigOffer): OfferProps => {
      const sortedItems = [...offer.items.value].sort((a, b) => {
        const aSelected = getIsSelected(a);
        const bSelected = getIsSelected(b);

        if (aSelected === bSelected) return 0;
        return aSelected ? -1 : 1;
      });
      let kitsu: number = 0; // або let kitsu: number = 0;

      const resourceList: OfferResourceProps[] = sortedItems
        .filter((item) => {
          if (item.rewardType.value === 2 && item.rewardId.value === 0) {
            kitsu = item.amount.value;
            return false;
          }
          return true;
        })
        .map((item, index: number): OfferResourceProps => {
          const isEnabled = getIsSelected(item);

          return {
            resourceType: item.rewardType.value,
            resourceId: item.rewardId.value,
            isEnabled: isEnabled,
            layout: 'vertical',
            amount: defineCountString(item.amount.value),
          };
        });

      return {
        id: offer.offerId.value,
        resourceList: resourceList,
        offerType: offer.offerType.value,
        type: 'gold',
        label: 'top',
        layout: 'rank',
        kitsu: kitsu ? defineCountString(kitsu) : '0',
        price: offer.stars.value.toString(),
        deadline: 'January 31, 2025',
      };
    };

    return mapOffer(chosenOffer);
  }, [appConfig, selectedRank, userRank, purchasedOfferIds]);

  const currentOffer = useMemo(() => {
    if (!userRank || !selectedRank) return undefined;

    const filteredRanks = Object.values(userRank.rewardsToClaim)
      .filter((reward) => (reward.status === 1 || reward.status === 2) && reward.lvl <= userRank.myRankLvl)
      .sort((a, b) => a.lvl - b.lvl);
    // Знайдемо ранг з потрібним lvl
    const foundRank = filteredRanks.find((reward) => reward.lvl === selectedRank.lvl.value);

    return foundRank;
  }, [userRank, selectedRank]);

  const handleLevelChange = (delta: any) => {
    setSelectedRankIndex((prevIndex) => {
      const newIndex = prevIndex + delta;
      if (newIndex < 0) return 0;
      if (newIndex >= sortedRanks.length) return sortedRanks.length - 1;
      return newIndex;
    });
  };

  if (loading || !appConfig || !userRank) {
    return <div>Loading...</div>;
  }
  if (currentRankIndex === -1) return <div>Loading...</div>;

  if (error) return <div>Error: {error}</div>;

  if (!selectedRank) {
    return <div>No data for the next rank.</div>;
  }

  if (!activeBonuses) {
    return <div>No data for the activeBonuses.</div>;
  }

  const handleClaimFreePack = async (lvl: number) => {
    const data = await claimOneTime({
      clientId: userId,
      lvl: lvl,
    });
    if (data) dispatch(fetchUserRank({ clientId: userId }));
  };

  const handleClaimUserRank = async () => {
    const data = await claimRank({
      clientId: userId,
    });
    if (data) dispatch(fetchUserRank({ clientId: userId }));
  };

  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: '' };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return <ErrorComponent jsError={{ error, resetError, componentStack, eventId }} />;
      }}
    >
      <PageTransition>
        {userRank ? (
          <div
            className="w-full h-full fixed top-0 max-w-[550px] mx-auto z-[56] shadow-inner-sm-black overflow-y-auto max-h-[100vh]"
            style={{
              background: 'radial-gradient(circle at center, #332B26 20%, rgba(0,0,0,0.5) 100%)',
              marginTop: `${safeAreaTop}px`,
            }}
          >
            <div className="relative flex justify-start items-center  w-full bg-[#312e2b] border-y-2 border-y-[#574E48] shadow-inner-sm-black">
              {/* Заголовок Inventory */}
              <div className="absolute top-[1px] pl-3">
                <HandleBackButton onClick={goBack} />
              </div>
              <div className="text-white text-2xl font-black leading-normal pl-16 text-shadow-black-sm">
                {t('rank')}
              </div>
            </div>
            <div className="pt-[15px] pb-4 flex flex-col bg-gradient-to-b from-[#372C25] to-[#534038] shadow-inner-sm-black border-b-2 border-black">
              <div className="flex justify-between items-center p-4">
                <div className="relative w-[84px] h-[84px]">
                  {userRank?.myRankLvl ? (
                    <img
                      className="w-full h-full"
                      src={require(`../../assets/images/ranks/${userRank.myRankLvl}.png`)}
                    />
                  ) : null}
                  <div className="flex justify-center items-center absolute w-full bottom-0">
                    <div className="w-16 h-6">
                      <img className="w-full h-full" src={require('../../assets/images/ranks/titlePlace.png')} />
                    </div>
                    <div className="absolute text-center text-white text-[15px] font-black  leading-[15px] text-shadow-black-sm">
                      {userRank?.myRankLvl} {` ${t('rank')}`}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-white text-xl font-black leading-tight text-shadow-black-sm">
                    {userRank?.myRankLvl} {` ${t('rank')}`}
                  </div>
                  <div className="w-[160px] text-white text-xs font-bold">
                    {t('ranks.get')}{' '}
                    <img
                      className="inline-block mb-[2px] w-[13px] h-[13px]"
                      src={require('../../assets/images/ranks/rankProgress.png')}
                    />{' '}
                    {t('ranks.for')}{' '}
                    <span
                      className="text-[#82C3FF] underline"
                      onClick={() => {
                        navigateTo('/island');
                        setOpenedDepositModal(true);
                      }}
                    >
                      {t('ranks.deposit')}
                    </span>{' '}
                    {t('ranks.or')}{' '}
                    <img
                      className="inline-block mb-[2px] w-[13px] h-[13px]"
                      src={require('../../assets/images/ranks/star.png')}
                    />{' '}
                    STARS{' '}
                    <span
                      className="text-[#82C3FF] underline"
                      onClick={() => {
                        navigateTo('/island');
                        setShopOpen(true);
                      }}
                    >
                      {' '}
                      {t('ranks.purchases')}
                    </span>{' '}
                    {t('ranks.upgradeLvl2')}
                  </div>
                </div>
                <div
                  className="relative w-14 h-14"
                  onClick={
                    currentOffer && currentOffer.status === 1 ? () => handleClaimFreePack(currentOffer.lvl) : () => {}
                  }
                >
                  <img className="w-full h-full" src={require(`../../assets/images/ranks/chest.png`)} />
                  {currentOffer && currentOffer.status === 1 ? (
                    <div className="absolute w-3 h-3 top-2 right-0">
                      <div className="w-3 min-w-3 h-3 min-h-3 bg-[#ff3a3a] border border-[black] rounded-full"></div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="flex justify-between items-center p-4 gap-2 ">
                <div className="flex justify-center items-center w-full">
                  <div className="w-full relative ml-5">
                    <div className="ml-5">
                      <ProgressBar
                        current={userRank.currentRankpoints}
                        max={
                          currentRank
                            ? currentRank.rankPoints
                              ? currentRank.rankPoints!.value
                              : userRank.currentRankpoints
                            : userRank.currentRankpoints
                        }
                      />
                    </div>
                    <div className="absolute flex justify-center items-center -left-6 -top-[65%]">
                      <div className="flex flex-col items-center ">
                        {/* Стрілка */}
                        <div className="w-5 h-5 mr-[-30px] mb-[-8px] z-10">
                          <img
                            className="w-full h-full"
                            src={require('../../assets/images/ranks/arrow.png')}
                            alt="Arrow"
                          />
                        </div>

                        {/* Зірка */}
                        <div className="w-7 h-7">
                          <img
                            className="w-full h-full"
                            src={require('../../assets/images/ranks/star.png')}
                            alt="Star"
                          />
                        </div>
                      </div>

                      <div className="w-12 h-12 ml-2">
                        <img className="w-full h-full" src={require('../../assets/images/ranks/rankProgress.png')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative z-10 w-full flex justify-between items-center space-x-2 p-4">
              {prevRank ? (
                <PopupButton type={'blue'} size="small" onClick={() => handleLevelChange(-1)} disabled={!prevRank}>
                  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.0823 1.07009L1.04416 12L21.0823 22.9299L17.1997 12.1697L17.1385 12L17.1997 11.8303L21.0823 1.07009Z"
                      fill="white"
                      stroke="#452B31"
                    />
                  </svg>
                </PopupButton>
              ) : (
                <div className="w-12 h-12"></div>
              )}
              {
                <div className="absolute left-[37%] flex justify-center items-center p-4  ml-10">
                  <div className="relative">
                    {userRank.myRankLvl < selectedRank.lvl.value ? (
                      <>
                        <div className="w-20 h-5">
                          <img src={require('../../assets/images/ranks/lockedRank.png')} alt="Locked Rank" />
                        </div>
                        <div className="absolute top-[5px] left-4 text-shadow-black-sm text-white text-[12px] font-normal  uppercase leading-[13px] tracking-wide">
                          {t(`ranks.locked`)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-14 h-4">
                          <img src={require('../../assets/images/ranks/newRank.png')} alt="Open Rank" />
                        </div>
                        <div className="absolute top-[5px] left-4 text-shadow-black-sm text-white text-[13px] font-normal  uppercase leading-[13px] tracking-wide">
                          {t(`ranks.open`)}
                        </div>
                      </>
                    )}

                    <div className="absolute -top-3 -left-9">
                      <div className="relative w-12 h-12 ">
                        <img
                          className="w-full h-full"
                          src={require(`../../assets/images/ranks/${selectedRank.lvl.value}.png`)}
                        />
                        <div className="flex justify-center items-center absolute w-full bottom-0">
                          <div className="w-9 h-3">
                            <img className="w-full h-full" src={require('../../assets/images/ranks/titlePlace.png')} />
                          </div>
                          <div className="absolute text-center text-white text-[8.57px] font-black  leading-[8.57px] text-shadow-black-sm">
                            {selectedRank.lvl.value} Rank
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {nextRank ? (
                <PopupButton type={'blue'} size="small" onClick={() => handleLevelChange(1)} disabled={!nextRank}>
                  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.917673 1.07009L20.9558 12L0.917672 22.9299L4.80026 12.1697L4.86149 12L4.80026 11.8303L0.917673 1.07009Z"
                      fill="white"
                      stroke="#452B31"
                    />
                  </svg>
                </PopupButton>
              ) : (
                <div className="w-12 h-12"></div>
              )}
            </div>

            <div className="py-4 px-6">
              <div className="flex flex-col gap-4 overflow-y-auto max-h-[40vh]">
                {activeBonuses.length ? (
                  activeBonuses.map((bonus) => (
                    <div className="flex justify-between items-center">
                      {/* Внутрішній контейнер для зображення та тексту */}
                      <div className="flex items-center">
                        {/* Умовне відображення зображення */}

                        {bonus.isNew ? (
                          <div className="relative">
                            <div className="w-12 h-5">
                              <img src={require('../../assets/images/ranks/newRank.png')} alt="New Rank" />
                            </div>
                            <div className="absolute top-[4px] left-2 text-shadow-black-sm text-white text-[13px] font-normal  uppercase leading-[13px] tracking-wide">
                              {t(`ranks.new`)}
                            </div>
                          </div>
                        ) : null}

                        {/* Текст з умовним відступом */}
                        <div className={`text-white text-sm font-black leading-[14px] pl-1`}>
                          {bonus.type === universalShardMap[3].name
                            ? universalShardMap[3].name
                            : t(`ranks.${bonus.type}`)}
                        </div>
                      </div>

                      {/* Відображення суми */}
                      <div className={`text-[#47c77a] text-sm font-black leading-[14px] pl-2 whitespace-nowrap`}>
                        {`${bonus.previousAmount as number}  
                        ${bonus.newAmount ? `> ${bonus.newAmount}` : ''}
                        `}
                      </div>
                    </div>
                  ))
                ) : (
                  // <div className="opacity-20 text-center text-white text-2xl font-black leading-normal">
                  //   Purchase offers to level up your rank.
                  //   <img src={require('../../assets/images/ranks/rank-icon-grey.png')} alt="" />
                  //   <br />
                  //   OR
                  //   <br />
                  //   Deposit $KITSU to level up your rank.
                  // </div>

                  <div className="flex flex-col items-center font-bold text-[#646464] text-[22px] ">
                    <div className="flex items-center justify-center flex-col">
                      <div className="flex items-center">
                        {t('ranks.get')}
                        <img
                          src={require('../../assets/images/ranks/rank-icon-grey.png')}
                          alt=""
                          className="w-6 h-6 mx-1"
                        />
                        {t('ranks.for2')}
                        <img
                          src={require('../../assets/images/ranks/rank-icon-star.png')}
                          alt=""
                          className="w-5 h-5 mx-1"
                        />
                        STARS
                      </div>
                      <div className="flex items-center mt-[-8px]">
                        <div
                          className="w-fit h-fit underline mx-1 text-[#9B9B9B] cursor-pointer "
                          onClick={() => {
                            navigateTo('/island');
                            setShopOpen(true);
                          }}
                        >
                          {t('ranks.purchases2')}
                        </div>
                        {t('ranks.upgradeLvl')}
                      </div>
                      {/* <div>{t('rank')} lvl</div> */}
                    </div>
                    <p className="uppercase -my-2">{t('other.or')}</p>
                    <div className="flex items-center justify-center flex-col">
                      <div className="flex items-center mb-[-8px]">
                        {t('ranks.get2')}
                        <img
                          src={require('../../assets/images/ranks/rank-icon-grey.png')}
                          alt=""
                          className="w-6 h-6 mx-2"
                        />
                        {t('ranks.for3')}
                        <div
                          className="w-fit h-fit underline mx-2 text-[#9B9B9B] cursor-pointer"
                          onClick={() => {
                            navigateTo('/island');
                            setOpenedDepositModal(true);
                          }}
                        >
                          {t('ranks.deposit2')}
                        </div>
                        {/* {t('ranks.upgradeLvl2')} */}
                      </div>
                      <div>{t('ranks.upgradeLvl2')}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col w-full p-4 gap-4 z-20 fixed bottom-0">
              {rankOffer ? (
                <Offer
                  onClick={() => {
                    confirmPurchase({ offerId: rankOffer.id });
                  }}
                  rankPoints={defineCountString(Math.floor(Number(rankOffer.price) / 10))}
                  offerType={rankOffer.offerType}
                  key={0}
                  id={rankOffer.id}
                  resourceList={rankOffer.resourceList}
                  layout={rankOffer.layout}
                  type={rankOffer.type}
                  label={rankOffer.label}
                  kitsu={rankOffer.kitsu}
                  price={rankOffer.price}
                  deadline={rankOffer.deadline}
                />
              ) : null}

              {userRank.myRankLvl < userRank.availableToClaimRankLvl ? (
                <div className="flex justify-center items-center">
                  <PopupButton type={'green'} size="big" onClick={() => handleClaimUserRank()} className="relative">
                    <div className="text-center text-[#ffefcb] text-xl font-black leading-tight px-6">Activate</div>

                    <div className="absolute w-3 h-3 top-0 right-0">
                      <div className="w-3 min-w-3 h-3 min-h-3 bg-[#ff3a3a] border border-[black] rounded-full"></div>
                    </div>
                  </PopupButton>
                </div>
              ) : (
                <div className="flex justify-center items-center gap-4 ">
                  <PopupButton
                    type={'blue'}
                    size="big"
                    onClick={() => {
                      navigateTo('/island');
                      setTimeout(() => {
                        setOpenedDepositModal(true);
                      }, 1000);
                    }}
                  >
                    <div className="text-center text-[#ffefcb] text-lg font-black leading-[18px]">
                      {t('ranks.depositBtn')}
                    </div>
                  </PopupButton>
                  <PopupButton
                    type={'blue'}
                    size="big"
                    onClick={() => {
                      navigateTo('/island');
                      setTimeout(() => {
                        setShopOpen(true);
                      }, 1000);
                    }}
                  >
                    <div className="text-center text-[#ffefcb] text-lg font-black leading-[18px]">
                      {t('ranks.shopBtn')}
                    </div>
                  </PopupButton>
                </div>
              )}
            </div>
            <SuccessOverlay isSuccess={isSuccessPurchase} />
          </div>
        ) : (
          <div>No data</div>
        )}
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
