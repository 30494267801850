import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RippleState {
  x: number | null;
  y: number | null;
  key: number;
}

const initialState: RippleState = {
  x: null,
  y: null,
  key: 0,
};

const rippleSlice = createSlice({
  name: 'ripple',
  initialState,
  reducers: {
    // Устанавливаем позицию анимации (при клике)
    setRipple: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.x = action.payload.x;
      state.y = action.payload.y;
      state.key += 1; // Обновляем ключ, чтобы анимация рендерилась заново
    },
    // Очищаем состояние анимации
    clearRipple: (state) => {
      state.x = null;
      state.y = null;
    },
  },
});

export const { setRipple, clearRipple } = rippleSlice.actions;

export default rippleSlice.reducer;
