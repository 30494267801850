import { useEffect, useState } from 'react';
import { Address, beginCell, Cell, fromNano, JettonMaster, JettonWallet, Slice, toNano, TonClient } from '@ton/ton';
import { useTonClient } from './useTonClient';
import { useTonConnect } from './useTonConnect';
import { useAsyncInitialize } from './useAsyncInitialize';

const JETTON_MASTER_ADDRESS = 'EQAmLDfD9kc5nrqCfrCRQ1pK3KL6wB7Ya4Nud5HjNbwnbiWF';

export const RECIPIENT_WALLET = 'UQASBVuWIC96wQzTDu2L-LAjfnlYgUOEkNIlqEkYg45yh7BS';

async function initJettonContract(client: TonClient | undefined, wallet: string | null) {
  if (!client || !wallet) return null;

  const masterAddress = Address.parse(JETTON_MASTER_ADDRESS);
  const userWallet = Address.parse(wallet);

  const jettonMaster = client.open(JettonMaster.create(masterAddress));
  const jettonWalletAddress = await jettonMaster.getWalletAddress(userWallet);

  const contract = client.open(JettonWallet.create(jettonWalletAddress));
  return contract;
}

export function useJettonContract() {
  const { client } = useTonClient();
  const { wallet, sender, transactionStatus } = useTonConnect();

  // Ініціалізація JettonWallet контракту асинхронно
  const jettonContract = useAsyncInitialize(() => initJettonContract(client, wallet), [client, wallet]);

  const [jettonWalletAddress, setJettonWalletAddress] = useState<string | null>(null);
  const [balance, setBalance] = useState<string | null>(null);

  useEffect(() => {
    if (jettonContract) {
      setJettonWalletAddress(jettonContract.address.toString());
    }
  }, [jettonContract]);

  useEffect(() => {
    const fetchJettonBalance = async () => {
      if (!jettonContract) return;

      try {
        const bal = await jettonContract.getBalance();
        setBalance(fromNano(bal));
      } catch (error) {
        console.error('Помилка отримання балансу Jetton:', error);
      }
    };

    fetchJettonBalance();

    const interval = setInterval(fetchJettonBalance, 5000);
    return () => clearInterval(interval);
  }, [jettonContract]);

  async function transferJettons(amount: string, toAddress: string = RECIPIENT_WALLET, comment: string) {
    if (!jettonContract || !sender) return;

    try {
      const destination = Address.parse(toAddress);
      const nanoAmount = toNano(amount);
      const forwardTonAmount = toNano('0.01'); // Сума TON за потреби

      // Створюємо forward_payload з коментарем як рядок
      const forwardPayload = beginCell().storeStringTail(comment).endCell();

      const transferBody = beginCell()
        .storeUint(0xf8a7ea5, 32) // operation code for transfer
        .storeUint(0, 64) // query_id = 0 (для простоти)
        .storeCoins(nanoAmount)
        .storeAddress(destination)
        .storeAddress(sender.address) // response_address
        .storeBit(0) // custom_payload = none
        .storeCoins(forwardTonAmount)
        .storeBit(1) // Індикація присутності forward_payload
        .storeRef(forwardPayload) // forward_payload = cell з коментарем
        .endCell();
      // Надсилаємо транзакцію через sender
      await sender.send({
        to: jettonContract.address,
        value: toNano('0.06'), // газ (налаштуйте при потребі)
        body: transferBody,
      });

      // console.log(`Відправлено ${amount} jetton'ів на адресу ${toAddress} з коментарем: "${comment}"`);

      return true;
    } catch (error) {
      console.error("Помилка відправлення jetton'ів:", error);
    }
  }

  return {
    transactionStatus,
    transferJettons,
    jettonWalletAddress,
    balance,
  };
}
