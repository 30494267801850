import { useLocation, Routes, Route } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Home, Friends, Tutorial, Heroes, Rewards, RoomPage, Profile, NotFound } from '../../../../pages';
import { Battle } from '../../../../pages/Battle/Battle';
import { Farm } from '../../../../pages/Farm';
import { LanguageSettings } from '../../../LanguageSettings';
import { Layout } from '../../../../layout';
import { ExchangeSettings } from '../../../ExchangeSettings';
import { IslandWindow } from '../../../Farm/IslandWindow';
import { Dialogue, TutorialDungeon } from '../../../../components/Tutorial';
import { Dungeon } from '../../../../pages/Dungeon';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { getTutorialProgress } from '../../../../endpoints/tutorialProgress';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useUtils } from '../../../../utils/navigateTo';
import { TutorialSave, TutorialStage } from '../../../../interfaces/tutorial';
import { Quests } from '../../../../pages/Quests';
// import { getCurrentHero } from "../../../../endpoints/heroEndpoints";
import { useDispatch, useSelector } from 'react-redux';
import { setHero } from '../../../../app/features/heroSlice';
import { setSave } from '../../../../app/features/tutorialSaveSlice';
import { getUserSettings } from '../../../../endpoints/saveSettings';
import { RootState, store } from '../../../../app/store';
import { setUserSettings } from '../../../../app/features/userSettings';
import { AppDispatch } from '../../../../app/store';
import { fetchAppConfig } from '../../../../app/features/appConfigSlice';
import { useUserInteraction } from '../../../../utils/hasInteracted';
import { useSoundService } from '../../../../utils/soundService';
import { createProfile } from '../../../../endpoints/getProfileInfo';
import { openWebSocket } from '../../../../Websocket/websocketInit';
import AllCards from '../../../../components/Card/AllCards';
import { RankPage } from '../../../../pages/Rank';
import { useSessionStorage } from '@uidotdev/usehooks';
import { BossDungeon } from '../../../../pages/BossDungeon';
import { LeaderboardPage } from '../../../../pages/Leaderboard';
import { JoinAlliance } from '../../../../pages/Alliance/Join';

export const AnimatedRoutes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { navigateTo, activeDiv } = useUtils();
  const location = useLocation();
  const { userId, user, startParam, tg } = useTelegram();
  const { playMusic, pausedMusic } = useSoundService();
  const hasInteracted = useUserInteraction();
  const settings = useSelector((state: RootState) => state.settings.settings);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  const [userCreateOrUpdateUser, setCreateOrUpdateUser] = useSessionStorage('createOrUpdateUser', false);
  const webSocketStatus = useSelector((state: RootState) => state.webSocket.status);

  document.documentElement.lang = settings.language.toLowerCase();

  useEffect(() => {
    if (tg) {
      tg.setHeaderColor('#1f1c1a');
    }
  }, []);
  const [webSocketError, setWebSocketError] = useState(false);

  // useEffect(() => {
  //   dispatch(openWebSocket())
  //     .unwrap()
  //     .catch((error) => {
  //       console.error("Failed to open WebSocket:", error);
  //       setWebSocketError(true);
  //     });
  // }, [dispatch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeDiv === '/island' || activeDiv === '/tutorial/dialogue' || activeDiv === '/boss') {
        playMusic('farm');
      }
      if (activeDiv === '/dungeon' || activeDiv === '/tutorial/dungeon' || activeDiv === '/tutorial') {
        playMusic('dungeon');
      }
    }, 0)

    return () => {
      clearTimeout(timeout);
    }
  }, [activeDiv, hasInteracted]);

  // useEffect(() => {
  //   const getHero = async () => {
  //     const result = await getCurrentHero({ clientId: userId });
  //     if (result?.heroId) {
  //       dispatch(setHero(result));
  //     }
  //   };
  //   getHero();
  // }, []);

  useEffect(() => {
    const getSettings = async () => {
      const result = await getUserSettings({ clientId: userId });
      if (result) {
        store.dispatch(setUserSettings(result));
      }
    };

    const CreateProfile = async () => {
      const generatePlayerName = (): string => {
        return Math.floor(1000 + Math.random() * 9000).toString();
      };

      const fullName = (): string => {
        if (user.first_name && user.last_name) {
          return `${user.first_name} ${user.last_name}`;
        }

        if (user.first_name) {
          return user.first_name;
        }

        if (window.platform === 'android') {
          return `AndroidPlayer#${generatePlayerName()}`;
        }

        if (window.platform === 'ios') {
          return `IOSPlayer#${generatePlayerName()}`;
        }

        return '';
      };

      const result = await createProfile({
        clientId: userId,
        isPremium: user?.is_premium ? user.is_premium : false,
        referrer: startParam ? extractUserId(startParam) : undefined,
        username: user?.username ? user.username : undefined,
        fullName: fullName(),
        avatarId: user?.photo_url ? user.photo_url : undefined,
        supportId: startParam ? extractSupportId(startParam) : undefined,
      });

      if (result) {
        setCreateOrUpdateUser(true);
      }
    };

    CreateProfile();
    getSettings();
  }, [userId]);

  const extractUserId = (startParam: string) => {
    const regex = /^ref_([A-Za-z0-9_-]+)$/;
    const match = startParam.match(regex);

    if (match) {
      return match[1];
    } else {
      return undefined;
    }
  };

  const extractSupportId = (startParam: string) => {
    const regex = /^merg_([A-Za-z0-9_-]+)$/;
    const match = startParam.match(regex);

    if (match) {
      return match[1];
    } else {
      return undefined;
    }
  };

  // useEffect(() => {
  //   const CreateProfile = async () => {
  //     const fullName = user.last_name
  //       ? `${user.first_name} ${user.last_name}`
  //       : user.first_name;

  //     const result = await createProfile({
  //       clientId: userId,
  //       isPremium: user.is_premium ? user.is_premium : false,
  //       referrer: startParam ? extractUserId(startParam) : undefined,
  //       username: user.username ? user.username : undefined,
  //       fullName: fullName,
  //       avatarId: user.photo_url ? user.photo_url : undefined,
  //     });
  //   };

  //   CreateProfile();
  // }, [userId]);

  const retryCheckTutorialProgress = async (retries = 3) => {
    let attempt = 0;
    while (attempt < retries) {
      try {
        const save = await getTutorialProgress({ clientId: userId });

        if (!save) throw new Error('No tutorial save data available');

        // Зберігаємо прогрес у Redux
        dispatch(setSave(save));

        // Логіка перенаправлення
        handleTutorialNavigation(save, activeDiv);

        return; // Успішне виконання
      } catch (error) {
        attempt++;
        console.warn(`Retrying tutorial progress check (${attempt}/${retries})`, error);
        if (attempt >= retries) {
          console.error('Failed to fetch tutorial progress after multiple attempts');
          // window.location.reload();
          return; // Якщо всі спроби провалились
        }

        // Затримка перед повторною спробою
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  };

  const handleTutorialNavigation = (save: TutorialSave, activeDiv: string) => {
    if (location.pathname.startsWith('/allcards')) return;

    if (save.completed && sessionStorage.getItem('firstVisit') && !location.pathname.startsWith('/tutorial')) {
      navigateTo(`${activeDiv}`);
      return;
    }
    if (!save.completed) {
      switch (save.stage) {
        case TutorialStage.start:
          navigateTo('/battle');
          break;
        case TutorialStage.battle:
          navigateTo('/battle');
          break;
        case TutorialStage.finish:
          navigateTo(`${activeDiv}`);
          break;
        default:
          navigateTo('/island');
          break;
      }
      return;
    }

    if (save.completed) {
      if (!sessionStorage.getItem('firstVisit')) {
        sessionStorage.setItem('firstVisit', 'true');
      }
      navigateTo('/island');
    }
  };

  useEffect(() => {
    if (webSocketStatus === 'connected' && userCreateOrUpdateUser) {
      retryCheckTutorialProgress();
    }
  }, [webSocketStatus, userCreateOrUpdateUser]);

  return webSocketStatus !== 'connected' && !userCreateOrUpdateUser ? (
    <div className="loading-container">
      <p className="text-white p-2">Connect to server...</p>
    </div>
  ) : appConfig ? (
    <AnimatePresence mode="wait">
      <Routes location={location}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="allcards" element={<AllCards />} />
          <Route path="tutorial" element={<Tutorial />}>
            <Route path="dialogue" element={<Dialogue />} />
            <Route path="dungeon" element={<TutorialDungeon />} />
          </Route>
          <Route path="quests" element={<Quests />} />
          <Route path="boss" element={<BossDungeon />} />
          <Route path="island" element={<IslandWindow />} />
          <Route path="battle" element={<Battle />} />
          <Route path="dungeon" element={<Dungeon />} />
          <Route path="room" element={<RoomPage />} />
          <Route path="friends" element={<Friends />} />
          <Route path="heroes" element={<Heroes />} />
          <Route path="profile" element={<Profile />}>
            <Route path="language" element={<LanguageSettings />} />
            <Route path="exchange" element={<ExchangeSettings />} />
          </Route>
          <Route path="rank" element={<RankPage />} />
          <Route path="leaderboard" element={<LeaderboardPage />}>
            <Route path="alliance-join" element={<JoinAlliance />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  ) : (
    <div className="loading-container">
      <p className="text-white">Error get config</p>
    </div>
  );
};
