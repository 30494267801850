import { useState, useEffect, memo } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Claim } from "../../../../interface";

interface MyCircularProgressProps {
    isClaim: Claim;
    onComplete?: () => void;
    onStartAnimation?: () => void;
  }
  
  export const MyCircularProgress: React.FC<MyCircularProgressProps> = memo(({ isClaim, onComplete, onStartAnimation }) => {
    const [progress, setProgress] = useState(0);
    const [disableTransition, setDisableTransition] = useState(false);
  
    useEffect(() => {
      onStartAnimation?.();
    }, [onStartAnimation]);
  
    useEffect(() => {
      if (!isClaim?.perTick) return;
  
      let isCancelled = false;
      let timeout: NodeJS.Timeout;
      let timeout1: NodeJS.Timeout;
      // Головна функція, яка ініціює цикл анімації
      const runAnimation = () => {
        // Крок 1. Вимикаємо анімацію, скидаємо прогрес у 0
        setDisableTransition(true);
        setProgress(0);
  
        // Крок 2. Даємо браузеру (через double rAF) промалювати 0,
        // потім вмикаємо анімацію 0 -> 100
        requestAnimationFrame(() => {
          if (isCancelled) return;
          requestAnimationFrame(() => {
            if (isCancelled) return;
            // Тепер точно 0 промальовано на екрані
            setDisableTransition(false);
            setProgress(100);
  
            // Крок 3. Чекаємо, поки CSS-транзішен (perTick) завершиться.
            timeout = setTimeout(() => {
              if (isCancelled) return;
              // (Необов'язково) Коротка пауза перед новим циклом
              timeout1 = setTimeout(() => {
                if (isCancelled) return;
                onComplete?.();
                runAnimation();
              }, 0);
            }, isClaim.perTick);
          });
        });
      };
  
      runAnimation();
  
      return () => {
        isCancelled = true;
        clearTimeout(timeout);
        clearTimeout(timeout1);
      }
    }, [isClaim?.perTick, onComplete]);
  
    return (
      <CircularProgressbar
        value={progress}
        strokeWidth={50}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: '#45b684',
          // Якщо disableTransition=true — без transition,
          // інакше — плавно від 0 до 100 за isClaim.perTick мс
          pathTransition: disableTransition ? 'none' : `stroke-dashoffset ${isClaim?.perTick}ms linear`,
        })}
      />
    );
  });