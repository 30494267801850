import { useTranslation } from 'react-i18next';
import { Resources } from '../../enums/resources';
import { resourcesMap } from '../../utils/mapping';

export const CheckInIcon = ({ onClick, showNotification }: { onClick: () => void, showNotification: boolean }) => {
  console.log('rendered Share to Earn');
  const { t } = useTranslation();

  return (
    <div onClick={onClick} className={`flex justify-center items-center direction-column relative w-[52px] h-[52px]`}>
      <img
        className={`relative w-[52px]  bottom-0`}
        src={require('../../assets/images/check-in-icon.png?type=base64')}
        alt=""
      /> 
      {showNotification && (
        <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full absolute top-0 right-0"></div>
      )}

      <div
        className="absolute  bottom-[-3px] text-[14px] text-white
        text-stroke-small text-center font-black whitespace-pre-line leading-[12px] w-[70px] mb-1"
      >
        {t('farm.icons.shareToEarn')}
      </div>
    </div>
  );
};
