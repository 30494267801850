import {
  Rive,
  Layout,
  Fit,
  Alignment,
  useRive,
  useStateMachineInput,
  RiveFile,
} from "@rive-app/react-canvas";
import { useEffect, memo } from "react";
import { useUnmount } from "usehooks-ts";


export const UnitAnimation = memo(({
  className,
  triggerHit,
  triggerAttack,
  riveFile,
}: {
  className?: string;
  triggerHit?: boolean;
  triggerAttack?: boolean;
  riveFile: RiveFile;
}) => {
  // Завантаження файлу Rive
  const { rive, RiveComponent } = useRive({

    riveFile: riveFile,
    stateMachines: "State Machine 1",
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
  });



  const hitInput = useStateMachineInput(rive, "State Machine 1", "OnHit");
  
  const attackInput = useStateMachineInput(rive, "State Machine 1", "Attack");

  useEffect(() => {
    if (triggerHit && hitInput) {
      hitInput.fire();
    }
  }, [triggerHit, hitInput]);

  useEffect(() => {
    if (triggerAttack && attackInput) {
      attackInput.fire();
    }
  }, [triggerAttack, attackInput]);

  useUnmount(() => {
    rive?.cleanup();
  })


  return <RiveComponent className={className} />;
});
