import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { DungeonCompletErays } from '../../../../components/animation/DungeonCompletErays';
import { LevelUpBanner } from '../../../../components/LevelUp';
import { XPIco, KitsuIco, StoneIco } from '../../../../layout/components/HeaderFarm/components';
import { Resource } from '../../../../mock/resources';
import { ItemType, LootBoxOpenResult } from '../../../../interfaces/lootBotx';
import { DisplayData, handleLootBoxResult } from '../../../../utils/lootBoxHandler';
import { useState } from 'react';
import { AppDispatch, RootState } from '../../../../app/store';
import { TutorialFarmSave, TutorialFarmStage } from '../../../../interfaces/tutorial';
import { saveFarmTutorialProgress } from '../../../../app/features/farmTutoralSlice';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useTranslation } from 'react-i18next';
import { TutorialDungeon } from '../../../../components';
import { defineCountString } from '../../../../utils/farmConfigParser';
import { useSoundService } from '../../../../utils/soundService';
import { getBalance } from '../../../../endpoints/farmMock';

export const OpenLootBox = ({
  slot,
  rewards,
  onClose,
  openBoxName,
}: {
  slot?: boolean;
  rewards: DisplayData[];
  onClose: () => void;
  openBoxName: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [key, setKey] = useState(0);
  const [skipAnimation, setSkipAnimation] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const { userId } = useTelegram();
  const dispatchSave = useDispatch<AppDispatch>();
  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);
  const { playSound } = useSoundService();
  const updateSave = (save: TutorialFarmSave) => {
    dispatchSave(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  // playSound('questsReward');
  // console.log('questsReward Sound');

  // Функція для агрегації нагород
  const aggregateRewards = (rewards: DisplayData[]): DisplayData[] => {
    const rewardMap = new Map<string, DisplayData>();

    rewards
      .filter((reward) => reward.amount > 0)
      .forEach((reward) => {
        const key = `${reward.name}-${reward.type}`;
        if (rewardMap.has(key)) {
          const existing = rewardMap.get(key)!;
          existing.amount += reward.amount;
        } else {
          rewardMap.set(key, { ...reward });
        }
      });

    return Array.from(rewardMap.values());
  };

  const aggregatedRewards = aggregateRewards(rewards);

  // Оновлені варіанти з використанням функції для visible
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: (skipAnimation: boolean) => ({
      opacity: 1,
      transition: {
        staggerChildren: skipAnimation ? 0 : 0.2,
      },
    }),
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const handleOverlayClick = async () => {
    if (!isAnimationComplete) {
      setSkipAnimation(true);
      setKey((prev) => prev + 1);
    } else {
      if (farmTutorialSave?.stage === TutorialFarmStage.endbattle) {
        updateSave({
          stage: TutorialFarmStage.clickOnRewards,
        });
      } else if (
        farmTutorialSave?.stage === TutorialFarmStage.finishFirstBuilding &&
        openBoxName === 'Dungeon Completed'
      ) {
        updateSave({
          stage: TutorialFarmStage.clickOnRewards2,
        });
      }
      onClose();
    }
    await getBalance({ clientId: userId });
  };

  const handleAnimationComplete = () => {
    setIsAnimationComplete(true);
  };

  return (
    <div className="fixed inset-0 z-40" onClick={handleOverlayClick}>
      {/* Overlay Background */}
      <div className="w-full h-full absolute bg-gradient-to-b from-black via-black/70 to-black opacity-80"></div>

      <div className="w-full h-full absolute">
        <DungeonCompletErays />
      </div>
      {/* Modal Container */}
      <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-md`}>
        {slot ? (
          <div className="flex justify-center items-center">
            <div className="w-72 h-48">
              <img className=" w-full h-full" src={require('../../../../assets/images/kitsuSlot.png')} />
            </div>
          </div>
        ) : null}
        <LevelUpBanner text={openBoxName} />
        {slot ? (
          <div className="absolute top-[60%] w-full">
            <div className="text-center text-white text-lg font-black  leading-[18px] text-stroke-small">
              Your awards:
            </div>
          </div>
        ) : null}
        <motion.div
          key={key}
          className="flex flex-wrap justify-center gap-4"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          custom={skipAnimation}
          onAnimationComplete={handleAnimationComplete}
        >
          {aggregatedRewards.map((item) => (
            <motion.div
              key={`${item.name}-${item.type}`}
              variants={itemVariants}
              className="flex flex-col items-center p-4 w-20 relative"
            >
              <div
                className={`w-20 h-20 mb-2 relative border border-black rounded-md ${
                  item?.backgroundColor ? item?.backgroundColor : 'bg-gradient-to-b from-[#33a8cc] to-[#0d81b6]'
                }`}
              >
                {item.type === ItemType.timeBoosts && (
                  <div className="absolute flex justify-center text-center w-full rounded-md">
                    <div className="absolute w-full h-full bg-black opacity-30 rounded-t-md"></div>
                    <div className=" text-white text-lg font-semibold z-10 leading-none">{item.name}</div>
                  </div>
                )}
                <div className="flex justify-center items-center p-2">
                  <div className="w-16 h-16">
                    <img src={item.image} alt={item.name} className="w-full h-full object-contain" />
                  </div>
                </div>
              </div>
              <div className="absolute bottom-5 right-2 text-right text-white text-base font-black leading-none text-shadow-black-sm">
                <div className="text-center text-white text-lg font-semibold text-stroke-small">
                  {defineCountString(item.amount)}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
        {farmTutorialSave?.stage === TutorialFarmStage.endbattle && (
          <div className="flex justify-center items-center mt-[25%] flex-col">
            <div className="w-full flex items-center justify-center mb-[5%]">
              <svg width="88" height="2" viewBox="0 0 88 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="88" height="2" fill="url(#paint0_linear_5389_79693)" />
                <defs>
                  <linearGradient
                    id="paint0_linear_5389_79693"
                    x1="0"
                    y1="1"
                    x2="88"
                    y2="1"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                  </linearGradient>
                </defs>
              </svg>

              <div className="text-white mx-2 text-sm sm:text-base md:text-lg lg:text-xl whitespace-nowrap">
                New section available
              </div>

              <svg width="88" height="2" viewBox="0 0 88 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="88" height="2" transform="matrix(-1 0 0 1 88 0)" fill="url(#paint0_linear_5389_79694)" />
                <defs>
                  <linearGradient
                    id="paint0_linear_5389_79694"
                    x1="0"
                    y1="1"
                    x2="88"
                    y2="1"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className="flex items-center justify-center -mt-2 w-[35%] h-[60px] pb-1 border-t border-r border-b border-black bg-transparent bg-gradient-to-b from-[#1E0400] to-[#452B31] rounded-lg">
              <button className="relative flex items-center justify-center w-full h-full  bg-transparent bg-gradient-to-b from-[#FEE7BA] to-[#B6765A] rounded-lg">
                <div className="absolute top-1 right-1 w-[5px] h-[5px] bg-[#864C39] rounded-full z-20"></div>
                <div className="absolute bottom-1 left-1 w-[11px] h-[11px] bg-[#5B2400] rounded-full opacity-25"></div>
                <div className="absolute top-2  left-1 w-[3px] h-[3px] bg-[#E4B98F] rounded-full "></div>
                <div className="relative flex justify-center items-center w-14 h-14 -mt-5">
                  <img src={require('../../../../assets/images/heroes.png')} className="w-full h-full" />
                  <div className="absolute -bottom-3 flex justify-center items-end z-10 text-white text-2xl font-normal uppercase leading-normal tracking-[3.5px] text-shadow">
                    {t('footer.heroes')}
                  </div>
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
