import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { PopupButton } from '../../../../components/PopupButton';
import { GrowthFundOffer } from './GrowthFundOffer/GrowthFundOffer';
import { useEffect, useState } from 'react';
import { useTelegram } from '../../../../hooks/useTelegram';
import { checkGrowthFund, claimGrowthFundTask, getGrowthFundTasks } from '../../../../endpoints/growthFundEndpoints';
import { usePurchase } from '../../../../hooks/usePurchase';
import { SuccessOverlay } from '../../../../components/SuccessOverlay';
import { SuccessAnimation } from '../../../Quests';
import { useSoundService } from '../../../../utils/soundService';
import { PopupWindow } from '../../../../components/PopupWindow';
import { useTranslation } from 'react-i18next';
export enum GrowthFundOfferStatus {
  none,
  available,
  claimed,
}

export const GrowthFound = () => {
  const { userId } = useTelegram();
  const { playSound } = useSoundService();

  const kitsuShrine = useSelector((state: RootState) => state.islands.islands[0].buildings[0]);
  const quests = useSelector((state: RootState) => state.appConfig.configs.growthfundquests.variables);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const {
    offers: { variables: allOffers },
  } = appConfig;
  const growthFundOffer = allOffers.filter((o: any) => o.offerId.value === 31);

  const [tasks, setTasks] = useState<any>();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [purchasePopup, setPurchasePopup] = useState<boolean>(false);
  const { t } = useTranslation();
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  useEffect(() => {
    // console.log('TASKSSSSSSS', tasks);
  }, [tasks]);

  useEffect(() => {
    const checkGrowthFundStatus = async () => {
      const result = await checkGrowthFund({ clientId: userId });

      setIsEnabled(result);
    };

    checkGrowthFundStatus();
  }, []);

  useEffect(() => {
    if (isEnabled) {
      getGrowthFundData();
    }
  }, [isEnabled]);

  const getGrowthFundData = async () => {
    const result = await getGrowthFundTasks({ clientId: userId });
    setTasks(result);
  };

  const revalidateTasks = () => {
    if (isEnabled) {
      getGrowthFundData();
    }
  };

  const onClaim = () => {
    playSound('dungeonReward');
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
  };

  const growthFundTable: Record<number, number> = {
    3: 25200,
    5: 25200,
    7: 25200,
    8: 26775,
    10: 26775,
    12: 26775,
    14: 26775,
    16: 26775,
    18: 29925,
    20: 31500,
  };
  // console.log('tasks before rendering', quests);
  return (
    <>
      {showSuccess ? (
        <>
          <div className="absolute inset-0 bg-black opacity-60 z-20"></div>
          <div className="absolute w-full top-0 left-[50%] translate-x-[-50%] h-full z-30 ">
            <SuccessAnimation
              text="The award has been successfully received!"
              onClose={() => {
                setShowSuccess(false);
              }}
            />
          </div>
        </>
      ) : null}
      {purchasePopup && (
        <>
          <div
            className={`absolute w-full h-full bg-[rgba(0,0,0,0.5)] ml-[-12px] mt-[-12px] z-[98]`}
            onClick={() => setPurchasePopup(false)}
          ></div>
          <div
            className={`absolute w-[90%] h-[200px] top-[38%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[99]`}
          >
            <PopupWindow
              onClickClose={() => setPurchasePopup(false)}
              headerElement={
                <div className="relative w-fit text-center font-[900] text-[20px] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white p-2 bg-[#331d1c] border border-[#847a70]">
                  BUY TO ACCURE
                </div>
              }
            >
              <div className="text-[#ffefca] text-center">
                Upon purchase, instantly recieve $KITSU based on reached level
              </div>
              <img src={require('../../../../assets/images/kitsu-icon.png')} alt="" />
              <span className="text-[yellow]">
                {quests?.reduce(
                  (ac: any, cv: any) =>
                    cv.buildings.value[0].lvl.value <= kitsuShrine.lvl!
                      ? ac + cv.rewards.value[0].rewardAmount.value
                      : ac + 0,
                  0
                )}
              </span>
              <PopupButton
                type="gold"
                onClick={() => confirmPurchase({ offerId: growthFundOffer[0].offerId.value })}
                className="m-2 "
              >
                {!isEnabled && (
                  <div
                    className={` absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[80%] -translate-y-[20%] `}
                  >
                    <div
                      className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                    >
                      <img
                        src={require('../../../../assets/images/shop/offer/notify.png')}
                        className={`w-[3em] aspect-square -translate-y-[5%] `}
                        alt=""
                      />
                      {(growthFundOffer[0].stars.value / 10).toFixed(0)}
                    </div>
                  </div>
                )}
                <div className="flex items-center gap-1">
                  <img
                    className="w-[16px] h-[16px]"
                    src={require('../../../../assets/images/shop/offer/offer-star.png')}
                    alt=""
                  />
                  {growthFundOffer[0].stars.value}
                </div>
              </PopupButton>
            </PopupWindow>
          </div>
        </>
      )}
      <div className={`relative flex items-center relatve w-full h-[160px]`}>
        <div className={`flex flex-col`}>
          <div className={`text-[24px] text-white text-stroke-small font-[900]`}>{t('shop.tabs.growthFund')}</div>
          <div className="w-full h-[2px] bg-gradient-to-r from-[#f28a2f]"></div>
          <div className={`text-[#ffefcb] text-[16px] text-stroke-small leading-tight font-[900] mr-[15px] mt-2 `}>
            {t('shop.growthFund.description')}{' '}
            <span className={`text-[#ffff2f]`}>
              <span className="text-nowrap">270 900</span> $KITSU
            </span>{' '}
            {t('shop.growthFund.description2')}
          </div>
        </div>
        <div className={`relative flex justify-end items-end min-w-[127px] h-full`}>
          <img
            src={require('../../../../assets/images/shop/offer/growthFund-kitsu.png')}
            className={`absolute w-full aspect-square top-[50%] translate-y-[-50%]`}
            alt=""
          />
          <div className={`absolute ${isEnabled ? 'hidden' : ''} bottom-[15px] right-[15px]`}>
            <PopupButton
              type={isEnabled ? 'gray' : 'gold'}
              width={'80px'}
              size={'medium'}
              onClick={() => confirmPurchase({ offerId: growthFundOffer[0].offerId.value })}
            >
              {/* Rank points notif button */}
              <>
                {!isEnabled && (
                  <div
                    className={` absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[80%] -translate-y-[20%] `}
                  >
                    <div
                      className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                    >
                      <img
                        src={require('../../../../assets/images/shop/offer/notify.png')}
                        className={`w-[3em] aspect-square -translate-y-[5%] `}
                        alt=""
                      />
                      {Math.floor(growthFundOffer[0].stars.value / 10)}
                    </div>
                  </div>
                )}
                <SuccessOverlay isSuccess={isSuccessPurchase} />
                <div className="flex justify-center items-center w-full gap-1">
                  {!isEnabled && (
                    <img
                      className="w-[16px] h-[16px]"
                      src={require('../../../../assets/images/shop/offer/offer-star.png')}
                      alt=""
                    />
                  )}
                  <span className={`text-white text-stroke-regular leading-[16px] font-[800]`}>
                    {isEnabled ? 'Claimed' : growthFundOffer[0].stars.value}
                  </span>
                </div>
              </>
            </PopupButton>
          </div>
        </div>
      </div>
      <div className={'relative mb-[20px]'}>
        <div className={`w-full h-[16px] bg-[#f28a2f] rounded-lg`}></div>
        <div
          className={`bg-[#f28a2f] absolute w-[30px] h-[15px] bottom-[-50%] left-[50%] translate-x-[-50%]  `}
          style={{ clipPath: 'polygon(50% 100%, 0 0, 100% 0)' }}
        ></div>
      </div>
      <div className={`flex flex-col w-full gap-1 overflow-scroll h-full pb-[200px]`}>
        {tasks
          ? tasks?.map((task: any) => (
              <GrowthFundOffer
                key={task.id}
                isAvailable={task.building.lvl <= kitsuShrine.lvl! ? true : false}
                lvl={task.building.lvl}
                kitsu={task.rewards[0].rewardAmount}
                isEnabled={isEnabled}
                onClaim={onClaim}
                id={task.id}
                status={task.status}
                onRevalidate={revalidateTasks}
                onPopupOpen={() => setPurchasePopup(true)}
              />
            ))
          : quests?.map((task: any) => (
              <GrowthFundOffer
                key={task.id.value}
                isAvailable={task.buildings.value[0].lvl.value <= kitsuShrine.lvl! ? true : false}
                lvl={task.buildings.value[0].lvl.value}
                kitsu={task.rewards.value[0].rewardAmount.value}
                isEnabled={false}
                id={task.id.value}
                status={1}
                onPopupOpen={() => setPurchasePopup(true)}
              />
            ))}
      </div>
      <div
        className={`w-full h-[120px] bg-gradient-to-t from-[#FFCB50] absolute bottom-0 left-0 pointer-events-none`}
      ></div>
    </>
  );
};
