import { memo, useCallback, useRef, useState, useEffect } from 'react';
import { RoomDisplay } from '../../useRoom';
import { BuildingConfig, FarmRoom, IslandSchemeI } from '../../../../../interfaces/farm';
import { useRoomTick } from '../../useRoomTick';
import { useSessionStorage } from '@uidotdev/usehooks';
import { MyCircularProgress } from '../RenderRoom/components/MyCirculaProgress';
import { Resources } from '../../../../../enums/resources';
import { resourcesMap } from '../../../../../utils/mapping';
import { AnimatedResource } from '../RenderRoom/components';
import { AnimatePresence, motion } from 'framer-motion';
import { defineCountString } from '../../../../../utils/farmConfigParser';
import { useSelector } from 'react-redux';
import { isStepLoaded, markStepAsLoaded } from '../../../../../app/features/loadingManagerSlice';
import { RootState } from '../../../../../app/store';
import ReImg from '../../../../../components/ReloadableImage';

interface RoomActionProps {
  room: FarmRoom | undefined;
  roomDisplay: RoomDisplay;
  scheme: IslandSchemeI;
  building?: BuildingConfig;
}

const animationVariants = {
  initial: {
    opacity: 0,
    scale: 0.75,
    y: 30,
  },
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.4,
      ease: 'easeIn',
    },
  },
};

export const RoomTickAction = memo((props: RoomActionProps) => {
  const { roomDisplay, room, scheme, building } = props;
  const sessionKey = room?.buildingId ? `buildingLevelUp_${room?.buildingId}` : 'buildingLevelUp_default';

  const buildingsIsLoaded = useSelector((state: RootState) => isStepLoaded(state, 'buildings'));
  const balancePositions = useSelector((state: RootState) => state.resources.positions);

  const [showClaimAnimation, setShowClaimAnimation] = useState(false);
  const [animatedResources, setAnimatedResources] = useState<any>([]);

  const previousClaimValueRef = useRef<number | null>(null);
  const containerRef = useRef<HTMLInputElement>(null);
  const { Claim, isClaimAvailable, onClickBuild } = useRoomTick({ scheme, room });

  const [displayValue, setDisplayValue] = useSessionStorage<number | null>(`claimValue-${building?.id}`, null);

  const handleProgressComplete = useCallback(() => {
    setDisplayValue(previousClaimValueRef.current);
  }, []);

  const handleAnimationComplete = (id: any) => {
    setAnimatedResources((prev: any[]) => prev.filter((res) => res.id !== id));
  };

  const getRelativePosition = (absolutePosition: { x: number; y: number }) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      return {
        x: absolutePosition.x - containerRect.left,
        y: absolutePosition.y - containerRect.top,
      };
    }
    return { x: 0, y: 0 };
  };

  console.log('room action claim', Claim);

  useEffect(() => {
    if (!Claim.isClaim && Claim.value === 0 && previousClaimValueRef.current !== null) {
      setShowClaimAnimation(true);
      // Приховати анімацію після 3 секунд
      const timer = setTimeout(() => {
        setShowClaimAnimation(false);
        previousClaimValueRef.current = null; // Скинути попереднє значення після анімації
        setDisplayValue(0);
      }, 3000);
      return () => clearTimeout(timer);
    } else if (Claim.isClaim) {
      // Зберегти попереднє значення перед новим клеймом

      previousClaimValueRef.current = Claim.value;
    }
  }, [Claim.isClaim, Claim.value]);

  useEffect(() => {
    if (!Claim.isClaim && Claim.value === 0 && previousClaimValueRef.current !== null) {
      setShowClaimAnimation(true);
      // Запуск анімації ресурсів

      const value = previousClaimValueRef.current;
      let iconCount;

      if (building?.resourceType === Resources.kitsu) {
        iconCount = 1;
      } else if (value >= 3000) {
        iconCount = 12;
      } else if (value >= 1500 && value < 3000) {
        iconCount = 9;
      } else if (value > 800 && value < 1500) {
        iconCount = 6;
      } else if (value > 100 && value < 800) {
        iconCount = 3;
      } else {
        iconCount = 1;
      }

      // Припустимо, у вас є тип ресурсу, який анімується
      // Наприклад, building.resourceType
      const resourceType = building?.resourceType;

      const newResources = Array.from({ length: iconCount }, (_, index) => ({
        id: `${Date.now()}-${index}`,
        type: resourceType,
        startPosition: {
          x: Math.random() * 200 - 100, // Випадкове розташування навколо центру
          y: Math.random() * 200 - 100,
        },
        spawnPosition: {
          x: Math.random() * 2 - 1,
          y: Math.random() * 2 - 1,
        },
      }));

      setAnimatedResources(newResources);

      // Приховати анімацію після 4 секунд (відповідно до тривалості анімації)
      const timer = setTimeout(() => {
        setShowClaimAnimation(false);
        setAnimatedResources([]); // Видалити анімовані ресурси
        previousClaimValueRef.current = null; // Скинути попереднє значення після анімації
      }, 5000);

      return () => clearTimeout(timer);
    } else if (Claim.isClaim) {
      // Зберегти попереднє значення перед новим клеймом
      previousClaimValueRef.current = Claim.value;
    }
  }, [Claim.isClaim, Claim.value, building?.resourceType]);

  console.log('buildings claim', buildingsIsLoaded && Claim.isClaim && !Claim.isLimit);

  return (
    <button
      id={'room_claim_' + room?.id}
      onClick={onClickBuild}
      className={`overflow-visible w-full h-full flex items-center justify-center relative z-[55]`}
      style={{
        position: 'absolute',
        top: roomDisplay.y,
        left: roomDisplay.x,
        width: roomDisplay.width,
        maxHeight: roomDisplay.height,
        height: roomDisplay.height,
      }}
    >
      <div ref={containerRef} className="w-full h-full flex items-center justify-center relative">
        {buildingsIsLoaded && Claim.isClaim && !Claim.isLimit && (
          <div className="flex absolute top-14 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[54] w-14 h-14 items-center justify-center">
            <div className="absolute z-[54] w-14 h-14">
              <svg
                width="36"
                height="40"
                viewBox="0 0 36 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full"
              >
                <g filter="url(#filter0_dii_2993_11801)">
                  <mask
                    id="path-1-outside-1_2993_11801"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="0"
                    width="34"
                    height="38"
                    fill="black"
                  >
                    <rect fill="white" x="1" width="34" height="38" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.0139 32.5758C22.0787 32.5043 22.1632 32.4533 22.2562 32.4277C29.0267 30.564 34 24.3628 34 17C34 8.16344 26.8366 1 18 1C9.16344 1 2 8.16344 2 17C2 24.3628 6.97328 30.564 13.7438 32.4277C13.8368 32.4533 13.9213 32.5043 13.9861 32.5758L17.6297 36.5918C17.8281 36.8106 18.1719 36.8106 18.3703 36.5918L22.0139 32.5758Z"
                    />
                  </mask>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.0139 32.5758C22.0787 32.5043 22.1632 32.4533 22.2562 32.4277C29.0267 30.564 34 24.3628 34 17C34 8.16344 26.8366 1 18 1C9.16344 1 2 8.16344 2 17C2 24.3628 6.97328 30.564 13.7438 32.4277C13.8368 32.4533 13.9213 32.5043 13.9861 32.5758L17.6297 36.5918C17.8281 36.8106 18.1719 36.8106 18.3703 36.5918L22.0139 32.5758Z"
                    fill="white"
                  />
                  <path
                    d="M17.6297 36.5918L16.8891 37.2638L17.6297 36.5918ZM18.3703 36.5918L19.1109 37.2638L18.3703 36.5918ZM13.7438 32.4277L14.0092 31.4636L13.7438 32.4277ZM13.9861 32.5758L14.7267 31.9038L13.9861 32.5758ZM22.2562 32.4277L22.5216 33.3919L22.2562 32.4277ZM22.0139 32.5758L21.2733 31.9038L22.0139 32.5758ZM33 17C33 23.9012 28.3386 29.7163 21.9908 31.4636L22.5216 33.3919C29.7148 31.4118 35 24.8244 35 17H33ZM18 2C26.2843 2 33 8.71573 33 17H35C35 7.61116 27.3888 0 18 0V2ZM3 17C3 8.71573 9.71573 2 18 2V0C8.61116 0 1 7.61116 1 17H3ZM14.0092 31.4636C7.66139 29.7163 3 23.9012 3 17H1C1 24.8244 6.28516 31.4118 13.4784 33.3919L14.0092 31.4636ZM18.3703 35.9199L14.7267 31.9038L13.2454 33.2477L16.8891 37.2638L18.3703 35.9199ZM21.2733 31.9038L17.6297 35.9199L19.1109 37.2638L22.7546 33.2477L21.2733 31.9038ZM16.8891 37.2638C17.4844 37.9199 18.5156 37.9199 19.1109 37.2638L17.6297 35.9199C17.8281 35.7012 18.1719 35.7012 18.3703 35.9199L16.8891 37.2638ZM13.4784 33.3919C13.3938 33.3685 13.3117 33.3208 13.2454 33.2477L14.7267 31.9038C14.5308 31.6879 14.2799 31.5381 14.0092 31.4636L13.4784 33.3919ZM21.9908 31.4636C21.7201 31.5381 21.4692 31.6879 21.2733 31.9038L22.7546 33.2477C22.6883 33.3208 22.6062 33.3685 22.5216 33.3919L21.9908 31.4636Z"
                    fill="black"
                    mask="url(#path-1-outside-1_2993_11801)"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_dii_2993_11801"
                    x="0"
                    y="-2"
                    width="36"
                    height="41.7559"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2993_11801" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2993_11801" result="shape" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.36 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2993_11801" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="-2" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                    <feBlend
                      mode="normal"
                      in2="effect2_innerShadow_2993_11801"
                      result="effect3_innerShadow_2993_11801"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div className="absolute z-[54] w-10 h-10 top-1">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-[30px] h-[30px]">
                  <ReImg src={resourcesMap[building?.resourceType as Resources].image} alt="" />
                </div>
              </div>
              <MyCircularProgress
                isClaim={Claim}
                onComplete={handleProgressComplete}
                onStartAnimation={() => setDisplayValue(Claim.value)}
              />
              {displayValue !== null && displayValue > 0 && (
                <div className="absolute -bottom-1 flex justify-center items-center w-full text-center text-white text-xs font-black  leading-3 text-shadow-black-sm">
                  {building?.resourceType === Resources.kitsu
                    ? `${Number(displayValue.toFixed(2))} `
                    : `${Math.floor(Number(displayValue))}`}
                </div>
              )}
            </div>
          </div>
        )}
        {buildingsIsLoaded && Claim.isClaim && Claim.isLimit && (
          <div className="animate-bounce flex absolute top-10 transform -translate-x-1/2 -translate-y-1/2 z-[54] w-14 h-14 items-center justify-center">
            <div className="absolute z-[54] w-14 h-14">
              <svg
                width="36"
                height="40"
                viewBox="0 0 36 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full"
              >
                <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_dii_2993_11069)">
                    <mask
                      id="path-1-outside-1_2993_11069"
                      maskUnits="userSpaceOnUse"
                      x="1"
                      y="0"
                      width="34"
                      height="38"
                      fill="black"
                    >
                      <rect fill="white" x="1" width="34" height="38" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.1132 32.4663C28.9566 30.6511 34 24.4145 34 17C34 8.16344 26.8366 1 18 1C9.16344 1 2 8.16344 2 17C2 24.4145 7.04339 30.6511 13.8868 32.4663L17.6297 36.5918C17.8281 36.8106 18.1719 36.8106 18.3703 36.5918L22.1132 32.4663Z"
                      />
                    </mask>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.1132 32.4663C28.9566 30.6511 34 24.4145 34 17C34 8.16344 26.8366 1 18 1C9.16344 1 2 8.16344 2 17C2 24.4145 7.04339 30.6511 13.8868 32.4663L17.6297 36.5918C17.8281 36.8106 18.1719 36.8106 18.3703 36.5918L22.1132 32.4663Z"
                      fill="url(#paint0_linear_2993_11069)"
                    />
                    <path
                      d="M22.1132 32.4663L21.8568 31.4998L21.5712 31.5755L21.3726 31.7944L22.1132 32.4663ZM13.8868 32.4663L14.6274 31.7944L14.4288 31.5755L14.1432 31.4998L13.8868 32.4663ZM17.6297 36.5918L18.3703 35.9199L17.6297 36.5918ZM18.3703 36.5918L17.6297 35.9199H17.6297L18.3703 36.5918ZM33 17C33 23.9497 28.2728 29.7979 21.8568 31.4998L22.3696 33.4329C29.6404 31.5043 35 24.8793 35 17H33ZM18 2C26.2843 2 33 8.71573 33 17H35C35 7.61116 27.3888 0 18 0V2ZM3 17C3 8.71573 9.71573 2 18 2V0C8.61116 0 1 7.61116 1 17H3ZM14.1432 31.4998C7.72718 29.7979 3 23.9497 3 17H1C1 24.8793 6.3596 31.5043 13.6304 33.4329L14.1432 31.4998ZM18.3703 35.9199L14.6274 31.7944L13.1462 33.1383L16.8891 37.2638L18.3703 35.9199ZM17.6297 35.9199C17.8281 35.7012 18.1719 35.7012 18.3703 35.9199L16.8891 37.2638C17.4844 37.9199 18.5156 37.9199 19.1109 37.2638L17.6297 35.9199ZM21.3726 31.7944L17.6297 35.9199L19.1109 37.2638L22.8538 33.1383L21.3726 31.7944Z"
                      fill="black"
                      mask="url(#path-1-outside-1_2993_11069)"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_dii_2993_11069"
                      x="0"
                      y="-2"
                      width="36"
                      height="41.7559"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="0.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2993_11069" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2993_11069" result="shape" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="0.5" />
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.36 0" />
                      <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2993_11069" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="-2" />
                      <feGaussianBlur stdDeviation="1.5" />
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                      <feBlend
                        mode="normal"
                        in2="effect2_innerShadow_2993_11069"
                        result="effect3_innerShadow_2993_11069"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_2993_11069"
                      x1="18"
                      y1="1"
                      x2="18"
                      y2="36.7559"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFFE2E" />
                      <stop offset="1" stopColor="#FE8315" />
                    </linearGradient>
                  </defs>
                </svg>
              </svg>
            </div>
            <div className="absolute z-[54] w-8 h-8 top-2">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-9 h-9">
                  <ReImg src={resourcesMap[building?.resourceType as Resources].image} />
                </div>
              </div>
              {previousClaimValueRef.current !== null && (
                <div className="absolute -bottom-1 flex justify-center items-center w-full text-center text-white text-xs font-black  leading-3 text-shadow-black-sm">
                  {building?.resourceType === Resources.kitsu
                    ? `${Number(previousClaimValueRef.current.toFixed(2))} `
                    : `${Math.floor(Number(previousClaimValueRef.current))}`}
                </div>
              )}
            </div>
          </div>
        )}

        {showClaimAnimation &&
          previousClaimValueRef.current !== null &&
          animatedResources.map((res: { type: string | number; id: any; startPosition: any; spawnPosition: any }) => {
            const targetPosition = balancePositions[res.type];

            if (!targetPosition) return null;

            const relativeEndPosition = getRelativePosition(targetPosition);

            return (
              <AnimatedResource
                key={res.id}
                iconType={res.type}
                spawnPosition={res.spawnPosition}
                startPosition={res.startPosition}
                endPosition={relativeEndPosition}
                onAnimationComplete={() => handleAnimationComplete(res.id)}
              />
            );
          })}

        <AnimatePresence>
          {showClaimAnimation && previousClaimValueRef.current !== null && (
            <motion.div
              key=""
              variants={animationVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              className="
                  flex
                  justify-center
                  items-center
                  gap-1
                  absolute
                  top-[10px] z-[55]
                  px-3 py-2 rounded-md
                  text-center
                  text-white
                  text-2xl
                  text-shadow-black-sm
                  font-extrabold
                  leading-3
                  flex-nowrap
                  tracking-wide"
            >
              <div className="text-nowrap text-center text-white text-shadow-black-sm text-2xl">
                {
                  building?.resourceType === Resources.kitsu
                    ? `+ ${defineCountString(Number(previousClaimValueRef.current), true)}` /* для KITSU */
                    : `+ ${defineCountString(Math.floor(Number(previousClaimValueRef.current)))}` /*для остальных ресурсов*/
                }
              </div>
              <div className="w-7 h-7">
                <ReImg className="w-full h-full" src={resourcesMap[building?.resourceType as Resources].image} />{' '}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </button>
  );
});
