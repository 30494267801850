export const stageOfDailyQuests = [
  {
    id: 1,
    count: 1,
    fontSize: '14px',
    marginLeft: '18%',
    marginBottom: '-16px',
    gap: '5px',
    imageSize: '18px',
  },
  {
    id: 2,
    count: 3,
    fontSize: '16px',
    marginLeft: '33%',
    marginBottom: '-18px',
    gap: '4px',
    imageSize: '22px',
  },
  {
    id: 3,
    count: 5,
    fontSize: '20px',
    marginLeft: '50%',
    marginBottom: '-20px',
    gap: '1px',
    imageSize: '26px',
  },
  {
    id: 4,
    count: 7,
    fontSize: '24px',
    marginLeft: '70%',
    marginBottom: '-23px',
    gap: '0',
    imageSize: '30px',
  },
];
export const resoursesOfDailyQuests = [
  {
    id: 1,
    count: '1',
    resourse: 'Spin Ticket',
    image: require('../assets/images/keys.png'),
    bgGradient: 'linear-gradient(196deg, #CF0000 -5.43%, #800B0B 146.19%)',
  },
  {
    id: 2,
    count: '30K',
    resourse: 'Stone',
    image: require('../assets/images/resource/3.png'),
    bgGradient: 'linear-gradient(180deg, #33A8CC 0%, #0D81B6 97.5%)',
  },
  {
    id: 3,
    count: '500',
    resourse: 'EXP',
    image: require('../assets/images/resource/4.webp'),
    bgGradient: 'linear-gradient(180deg, #33A8CC 0%, #0D81B6 97.5%)',
  },
];
