import React, { useEffect, useState } from 'react';
import { CoinsBalance, HandleBackButton, KeysBalance, PageName } from './components';
import { useUtils } from '../../../utils/navigateTo';
import { useSoundService } from '../../../utils/soundService';
import { CardProps } from '../../../interfaces/card';
import { CardsModal } from '../../../pages/Battle/CardsModal';
import { ItemCard } from '../FooterCave/components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { TutorialFarmSave, TutorialFarmStage } from '../../../interfaces/tutorial';
import { saveFarmTutorialProgress } from '../../../app/features/farmTutoralSlice';
import { useTelegram } from '../../../hooks/useTelegram';
import { PointerHand } from '../../../components/PointerHand';

export let tutorial: boolean;
export let pageName: string;
export let remainingPages: number;

export const HeaderCave: React.FC<{
  tutorial?: boolean;
  pageName?: string;
  remainingPages?: number;
  gold?: number;
  onClick?: () => void;
  cards?: CardProps[];
  keys?: number;
}> = ({ tutorial = false, pageName = 'I: Border Village', remainingPages = 0, gold = 0, onClick, cards, keys }) => {
  const { navigateTo } = useUtils();
  const { t } = useTranslation();
  const { playSound } = useSoundService();
  const [isDeckOpen, setIsDeckOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const transformCards = (cards: any[]): CardProps[] => {
    return cards
      .filter((card) => card.deckCardType !== 2)
      .map((card) => ({
        id: card.cardId,
        lvl: card.stars,
        uid: card.uid,
      }));
  };

  return (
    <header
      id="header-cave"
      className="w-full h-14 border border-black border-y-2 border-t-[#534038] border-b-[#372C25]"
      style={{
        background: 'linear-gradient(to top, #22201e 0%, #22201e 25%, #312e2b 100%)',
      }}
    >
      <div className={`h-full flex items-center justify-between px-4`}>
        {!tutorial ? (
          <div className={``}>
            <HandleBackButton
              onClick={() => {
                if (onClick) {
                  onClick();
                } else {
                  navigateTo('/island');
                }
              }}
            />
          </div>
        ) : (
          <div></div>
        )}
        <PageName pageName={pageName} remainingPages={remainingPages} />

        {cards ? (
          <ItemCard
            img={undefined}
            name={t('deck')}
            onClick={() => {
              if (farmTutorialSave?.stage !== TutorialFarmStage.clickOnHero) {
                setIsDeckOpen(true);
                playSound('button');
              }
            }}
          />
        ) : keys !== undefined ? (
          <KeysBalance keys={keys} />
        ) : (
          <CoinsBalance goldAmount={gold} />
        )}
      </div>

      {isDeckOpen && cards && (
        <CardsModal
          noblessing={true}
          cardsAtDeck={transformCards(cards)}
          closeDeck={() => {
            setIsDeckOpen(false);
          }}
        />
      )}
    </header>
  );
};
