import React, { useState, useEffect, useRef } from 'react';
import { BattleEffects } from './Effects';
import { useTranslation } from 'react-i18next';
import { HpBarAnimation } from '../../components';
import { ManaBarAnimation } from '../../components/animation/ManaBarAnimation';
import { BuffAnimation } from '../../components';
import { useUtils } from '../../utils/navigateTo';
import { motion } from 'framer-motion';
import { EffectType } from '../../endpoints/mock';
import { useRiveFile } from '@rive-app/react-canvas';

//TODO согласовать этот енам с беком, узнать у гд об эффектах
export enum BattleEffectsLink {
  stun = '../../assets/images/effect-stun.png',
  dmg = '../../assets/images/effect-dmg.png',
}

// CUSTOM EFFECTS for Boss Fights
enum customEffectsNames {
  instantDeath = 1098,
  healthSwap = 1107,
}
// 1000 + bossId
const customEffects = [1098, 1107];

//TODO согласовать с беком
export interface BattleEffectsData {
  effectId: EffectType;
  effectUid: string;
  duration?: number;
  amount?: number;
}

export interface MappedEffect {
  data: BattleEffectsData | null;
}

function getEffectImage(effect: EffectType): string {
  switch (effect) {
    //@ts-ignore
    case 1098:
      return require(`../../assets/images/effects/effect-doubleDamage.png`);
    //@ts-ignore
    case 1107:
      return require(`../../assets/images/effects/effect-returnDamage.png`);
    default:
      return require(`../../assets/images/effects/effect-${EffectType[effect]}.png`);
  }

  //return require(`../../assets/images/effect-stun.png`);
}

interface EnemyBattleDataProps {
  id: number;
  name: string;
  lvl: number;
  currentHp: number;
  maxHp: number;
  maxMana: number;
  currentMana: number;
  currentDefence: number;
  effects: BattleEffectsData[];
}

interface DisplayAmount {
  [key: string]: number;
}
const scaleAndRed = {
  visible: {
    scale: [1, 2, 1],
    transition: { duration: 0.5 },
  },
  initial: {
    scale: 1,
  },
};

const bounceAndFlash = {
  visible: {
    y: [0, -10, 0],
    opacity: [1, 0.8, 1],
    boxShadow: ['0px 0px 5px 2px #fff', '0px 0px 15px 5px #fff', '0px 0px 5px 2px #fff'],
  },
  initial: {
    y: 0,
    opacity: 1,
    boxShadow: '0px 0px 0px 0px #fff',
  },
};

export const EnemyBattleData = (props: EnemyBattleDataProps) => {
  const { t } = useTranslation();
  const [effectTooltip, setEffectTooltip] = useState<{
    effectId: EffectType;
    amount: number;
  } | null>(null);
  const { activeDiv } = useUtils();
  const [animationType, setAnimationType] = useState<'bounce' | 'scale'>('bounce');
  // Стан для збереження останнього доданого ефекту для анімації
  const [triggeredEffect, setTriggeredEffect] = useState<string | null>(null);
  const [displayAmount, setDisplayAmount] = useState<DisplayAmount>({});
  const [effectList, setEffectList] = useState<MappedEffect[]>([]);
  const [currentBossDefence, setCurrentBossDefence] = useState<BattleEffectsData>({
    effectId: 0,
    effectUid: 'someStringForDefence',
    amount: 0,
    duration: 0,
  });

  const { riveFile: BuffAnimationFile, status: BuffAnimationStatus } = useRiveFile({
    src: 'animation/statusvfx.riv',
  });

  const prevAmounts = useRef<DisplayAmount>({});
  const handleMouseDown = (effect: BattleEffectsData) => {
    setEffectTooltip({ effectId: effect.effectId, amount: effect.amount ? effect.amount : 0 });
  };

  const handleMouseUp = () => {
    setEffectTooltip(null);
  };

  const handleMouseLeave = () => {
    setEffectTooltip(null);
  };

  const handleTouchStart = (effect: BattleEffectsData) => {
    setEffectTooltip({ effectId: effect.effectId, amount: effect.amount ? effect.amount : 0 });
  };

  const handleTouchEnd = () => {
    setEffectTooltip(null);
  };

  const { lvl, name, id, effects, maxHp, maxMana, currentMana, currentHp, currentDefence } = props;

  useEffect(() => {
    setCurrentBossDefence({ effectId: 0, effectUid: 'someStringForDefence', amount: currentDefence, duration: 0 });
  }, [currentDefence]);

  useEffect(() => {
    if (effects) {
      let newEffectList = effects.map((e) => getEffectDetails(e));
      switch (id) {
        case 98:
          newEffectList = [
            ...newEffectList,
            getEffectDetails({ effectId: 1000 + id, effectUid: 'instantDeath', duration: 6, amount: -1 }),
          ];
          break;
        case 107:
          newEffectList = [
            ...newEffectList,
            getEffectDetails({ effectId: 1000 + id, effectUid: 'healthSwap', duration: 6, amount: -1 }),
          ];
          break;
        default:
          break;
      }

      if (currentBossDefence?.amount! > 0) {
        newEffectList.push(getEffectDetails(currentBossDefence));
      }
      //@ts-ignore
      setEffectList(newEffectList);
    }
  }, [effects, currentBossDefence]);

  // Ефект для анімації додавання ефектів
  useEffect(() => {
    if (effectList.length > 0) {
      const lastEffect = effectList[effectList.length - 1];
      const effectUid = lastEffect?.data?.effectUid;
      const newAmount = lastEffect?.data?.amount ? lastEffect?.data?.amount : 0;
      const prevAmount = prevAmounts.current[effectUid!] || 0;

      if (newAmount > prevAmount) {
        setAnimationType('bounce');
      } else if (newAmount < prevAmount) {
        setAnimationType('scale');
      }

      setTriggeredEffect(effectUid!);
      prevAmounts.current[effectUid!] = newAmount;
      let timer1: NodeJS.Timeout;
      // Анімоване оновлення числа
      if (newAmount < prevAmount) {
        setDisplayAmount((prev) => ({
          ...prev,
          [effectUid!]: prevAmount,
        }));

        timer1 = setTimeout(() => {
          setDisplayAmount((prev) => ({
            ...prev,
            [effectUid!]: newAmount,
          }));
        }, 500); // Затримка відповідає тривалості анімації
      } else {
        setDisplayAmount((prev) => ({
          ...prev,
          [effectUid!]: newAmount,
        }));
      }

      // Скидаємо тригер через деякий час, щоб анімація могла відображатися знову
      const timer = setTimeout(() => {
        setTriggeredEffect(null);
      }, 1500); // Тривалість анімації

      return () => (timer1 ? [timer, timer1].forEach((t) => clearTimeout(t)) : clearTimeout(timer));
    }
  }, [effectList]);

  // useEffect(() => {
  //   setDisplayAmount((prev) => ({
  //     ...prev,
  //     [effectUid]: newAmount,
  //   }));
  // }, [currentDefence])

  const getEffectDetails = (effect: BattleEffectsData) => {
    let effectDetails: MappedEffect | null;
    switch (effect.effectId) {
      // For Pepe in Boss Fights
      //@ts-ignore
      case 1098: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      // For Trump in Boss Fights
      //@ts-ignore
      case 1107: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.defense: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.doubleDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.lessCardPerRound: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.returnDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.negateAttack: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.lessDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.additionalDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.additionalDefense: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.arcaneEcho: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.mindBreak: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.restoreHealthByDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      default: {
        effectDetails = null;
      }
    }
    return effectDetails;
  };

  // useEffect(() => {
  //   console.log("boss display amount", displayAmount);
  // }, [displayAmount]);
  // useEffect(() => {
  //   console.log("boss effectTooltip", effectTooltip);
  // }, [effectTooltip]);
  // useEffect(() => {
  //   console.log("effectList boss", effectList);
  // }, [effectList]);

  return (
    <>
      <div className="absolute top-[30px] z-[2]">
        <div className="flex justify-between">
          <div className="text-white leading-none text-stroke-regular">{name}</div>
          <div className="text-white leading-none text-stroke-regular">{t('level', { lvl })}</div>
        </div>
        <div className="relative text-white h-[16px] w-[234px] border-l border-r border-[#47403A] text-center text-xl rounded-[3px] leading-3">
          {activeDiv === '/battle' && <HpBarAnimation currentHp={currentHp} maxHp={maxHp} />}
        </div>
        <div className="flex absolute left-full top-2.5 text-white bg-[rgba(25,25,27,0.6)] text-center text-xs rounded-[3px] leading-4 border border-black py-[3px] px-[5px] ml-[4px]">
          <img src={require('../../assets/images/mana-icon.png')} className="w-[14px] min-w-[14px] h-[14px] mr-[5px]" />
          {currentMana}
        </div>
        {/*<div className="flex w-[234px] mt-[3px] justify-center">*/}
        {/*  <div className="w-[120px] relative text-white h-[12px] text-center text-xl rounded-[3px] leading-3">*/}
        {/*    <div className="absolute h-full w-full">*/}
        {/*      {activeDiv === "/battle" && (*/}
        {/*        <ManaBarAnimation currentMana={currentMana} maxMana={maxMana} />*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*    <div className=" absolute text-white text-[14px] leading-none text-stroke-small top-[-3px] w-full text-center">*/}
        {/*      {currentMana}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="absolute flex flex-wrap justify-center items-center top-[50px] z-0 w-full">
          {effectList.map(
            (effectData: MappedEffect) =>
              typeof effectData.data?.effectId === 'number' && (
                <div
                  className="flex w-[34px] min-w-[34px] h-[34px] mr-1 mb-1 justify-center items-center text-center relative"
                  key={effectData.data?.effectUid}
                  onMouseDown={() => handleMouseDown(effectData.data!)}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={() => handleTouchStart(effectData.data!)}
                  onTouchEnd={handleTouchEnd}
                  onClick={() => setTriggeredEffect('')}
                >
                  {triggeredEffect === effectData.data?.effectUid ? (
                    <motion.div
                      className={`${
                        triggeredEffect === effectData.data?.effectUid ? 'flex' : 'hidden'
                      }  justify-center items-center absolute w-[34px] h-[34px] z-10`}
                      variants={animationType === 'bounce' ? bounceAndFlash : scaleAndRed}
                      initial="initial"
                      animate="visible"
                      transition={{ duration: 0.5 }}
                    >
                      {BuffAnimationFile && (
                        <div className="flex justify-center items-center absolute w-20 h-20 z-10">
                          {/* BuffAnimation повинен бути визначений або імпортований */}
                          <BuffAnimation riveFile={BuffAnimationFile} />
                        </div>
                      )}

                      <motion.img
                        src={getEffectImage(effectData.data?.effectId)}
                        className="w-full h-full object-contain"
                        alt=""
                      />
                      <div
                        className={`w-full h-full text-[18px] absolute text-stroke-regular z-10 flex justify-center items-center ${
                          animationType === 'scale' ? 'text-red-500' : 'text-white'
                        }`}
                      >
                        {effectData.data.amount! > 0 ? effectData.data.amount : ''}
                      </div>
                    </motion.div>
                  ) : (
                    <>
                      <img
                        src={getEffectImage(effectData.data?.effectId)}
                        className="w-full h-full object-contain"
                        alt=""
                      />
                      <div
                        className={`w-full h-full text-[18px] absolute text-stroke-regular z-10 flex justify-center items-center ${
                          animationType === 'scale' ? 'text-red-500' : 'text-white'
                        }`}
                      >
                        {effectData.data.amount! > 0 ? effectData.data.amount : ''}
                      </div>
                    </>
                  )}
                </div>
              )
          )}
        </div>
      </div>
      {effectTooltip && (
        <div
          className={`${
            effectTooltip ? 'flex' : 'hidden'
          } absolute bottom-[150px] left-[8px] bg-[rgba(25,25,27,0.9)] text-white py-2.5 px-4 z-10 rounded  items-center`}
        >
          <img src={getEffectImage(effectTooltip.effectId)} className="w-[28px] h-[28px] rounded-[6px] mr-2" alt="" />
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                `effects.descriptions.${
                  customEffects.some((e) => e === effectTooltip.effectId)
                    ? customEffectsNames[effectTooltip.effectId]
                    : EffectType[effectTooltip.effectId]
                }`,
                {
                  amount: '<span class="text-[#ff3a3a]">' + effectTooltip.amount + '</span>',
                }
              ),
            }}
          />
        </div>
      )}
    </>
  );
};
