import { useEffect, useState } from "react"


export const PointerHand = ({ state, scale }: { state?: 'press' | 'point', scale?: string }) => {
    const [isPressing, setIsPressing] = useState(false)

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!state) {
            timeout = setTimeout(() => {
                setIsPressing(!isPressing)
            }, 500)
        }
        return () => clearTimeout(timeout);
    }, [isPressing])
    
    return (
        <>
            {state === 'point' &&
                <div className={`absolute w-[3em] h-[3em] scale-${scale?scale:''} `} >
                    <img src={require('../../assets/images/tutorFinger.png')} alt="" />
                </div>
            }
            {state === 'press' &&
                <div className={`absolute w-[3em] h-[3em] scale-${scale?scale:''} `} >
                    <img src={require('../../assets/images/tutorFinger2.png')} alt="" />
                </div>
            }
            {!state &&
                <>
                    <div className={`absolute w-[3em] h-[3em] scale-${scale?scale:''} ${isPressing?'hidden':'static'} `} >
                        <img src={require('../../assets/images/tutorFinger.png')} alt="" />
                    </div>
                    <div className={`absolute w-[3em] h-[3em] scale-${scale?scale:''} ${isPressing?'static':'hidden'} `} >
                        <img src={require('../../assets/images/tutorFinger2.png')} alt="" />
                    </div>
                </>}

        </>
    )
} 