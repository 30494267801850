import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { RoomStatus } from '../../../enums/buildingStatus';
import { claimResourse, finishBuilding } from '../../../endpoints/farmMock';
import selectedRoom, { setSelectedRoom } from '../../../app/features/selectedRoom';
import { calculateTimer } from '../../../utils/timer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { FarmRoom, IslandSchemeI } from '../../../interfaces/farm';
import { useTelegram } from '../../../hooks/useTelegram';
import { useSessionStorage } from '@uidotdev/usehooks';
import { fetchIslands } from '../../../app/features/islandsSlice';
import { saveFarmTutorialProgress } from '../../../app/features/farmTutoralSlice';
import { APP_ENV } from '../../../config';
import { TutorialFarmStage } from '../../../interfaces/tutorial';
import ReactGA from 'react-ga4';

interface useRoomTimerProps {
  room: FarmRoom | undefined;
  scheme: IslandSchemeI;
}

export const useRoomTimer = (props: useRoomTimerProps) => {
  const { scheme, room } = props;
  const sessionKey = room?.buildingId ? `buildingLevelUp_${room?.buildingId}` : 'buildingLevelUp_default';
  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);
  const { buildingConfigs } = useSelector((state: RootState) => state.config);
  const selectedIsland = useSelector((state: RootState) => state.selectedIsland.selectedIsland);
  const isFarmTutorialCompleted = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save?.completed
  );
  const selectedRoom = useSelector((state: RootState) => state.selectedRoom);
  //   const gTimer = useSelector((state: RootState) => state.globalTimer);
  const gTimer = useSelector((state: RootState) => state.globalTimer);

  const [triggerEndBuild, setTriggerEndBuild] = useState(false);
  const [isConfirmationRequired, setIsConfirmationRequired] = useState(false);
  const [openSpeed, setOpenSpeed] = useSessionStorage<any>('openSpeed', false);
  const [timer, setTimer] = useSessionStorage<any>(`timer-${room?.id}`, '');
  const [isBuilderBusy, setIsBuilderBusy] = useSessionStorage<any>('isBuilderBusy', false);
  const [triggerShowLevelUp, setTriggerShowLevelUp] = useSessionStorage(sessionKey, false);
  const [triggerClose, setTriggerCloseLevelUp] = useSessionStorage('buildingLevelUp', false);
  const [buildTime, setBuildTime] = useSessionStorage<number>(`buildTime-${selectedRoom.id}`, 0);

  const hasBuildingCompletedRef = useRef(false);
  const dialogueId: number = farmTutorialSave?.dialogueId ?? 1;

  const { userId } = useTelegram();
  const dispatch = useDispatch<AppDispatch>();

  const building = useMemo(() => {
    if (!buildingConfigs) return undefined; // Return undefined if buildingConfigs is null

    return scheme.buildingId
      ? buildingConfigs.find((v) => v.id === scheme.buildingId)
      : buildingConfigs.find((v) => v.id === room?.buildingId);
  }, [scheme.buildingId, room?.buildingId, buildingConfigs]);

  const buildingLvlConfig = useMemo(
    () => building?.lvlInfo.find((lvl) => lvl.lvl === room?.lvl),
    [building, room?.lvl]
  );



  const handleBuildingCompletion = useCallback(async () => {
    if (!building || hasBuildingCompletedRef.current) return;
    let timeout: NodeJS.Timeout;
    hasBuildingCompletedRef.current = true;

    if (building) {
      setTriggerEndBuild(true);

      timeout = setTimeout(() => {
        setIsConfirmationRequired(true);
      }, 500);

      setTimer(undefined);

      if (room!.id === 2 && !isFarmTutorialCompleted) {
        dispatch(
          saveFarmTutorialProgress({
            clientId: userId,
            save: {
              dialogueId: dialogueId + 3,
              stage: TutorialFarmStage.finishFirstBuilding,
            },
          })
        );
        if (APP_ENV === 'production') {
          ReactGA.event({ category: 'Farm', action: 'Repair 1 building' });
          //amplitude.track('Repair 1 building', {group: 'Farm'});
        }
      }

      if (room!.id === 3 && !isFarmTutorialCompleted) {
        dispatch(
          saveFarmTutorialProgress({
            clientId: userId,
            save: {
              dialogueId: dialogueId + 4,
              completed: true,
              stage: TutorialFarmStage.finishSecondBuilding,
            },
          })
        );
        if (APP_ENV === 'production') {
          ReactGA.event({ category: 'Farm', action: 'Repair 2 building' });
          //amplitude.track('Repair 2 building', {group: 'Farm'});
        }
      }
    }
    return () => clearTimeout(timeout);
  }, [building]);

  const confirmBuildingCompletion = useCallback(async () => {
    if (building) {

      setTriggerEndBuild(false);

      const isFarming = !!building.lvlInfo[0].gatherDelayMs;

      const result = await finishBuilding({
        clientId: userId,
        buildingId: room!.id,
        islandId: selectedIsland?.id!,
        isFarming,
      });

      if (result) {
        setIsBuilderBusy(false);

        await new Promise((resolve) => setTimeout(resolve, 200));

        setTriggerShowLevelUp(true);
        // console.log("Level up animation triggered");

        await new Promise((resolve) => setTimeout(resolve, 2000));

        setTriggerShowLevelUp(false);
        setTriggerCloseLevelUp(false);
      }

      dispatch(fetchIslands(`${userId}`));

      hasBuildingCompletedRef.current = false;

      // Скидаємо прапор після підтвердження
      setIsConfirmationRequired(false);
    }
  }, [building, selectedIsland, room, dispatch]);


  const onClickOpenSpeedUpMenu = () => {
    if (selectedRoom.id === -1 || selectedRoom.id !== room?.id) {
      setTimeout(() => {
        dispatch(
          setSelectedRoom({
            id: scheme.id ,
            buildingId: room?.buildingId,
            lvl: room?.lvl,
            type: 1,
            statusUpdateDate: room?.statusUpdateDate,
          })
        );
      }, 0);
      setOpenSpeed(true);
    }
  };


  const onClickBuild = useCallback(() => {
    console.log('calim 1', room?.status);
    
    switch (room?.status) {

      case RoomStatus.building: {
        console.log('buildTime', buildTime);
        return buildTime > 0 && !isConfirmationRequired ? onClickOpenSpeedUpMenu() : null;
      }

    }
  }, [onClickOpenSpeedUpMenu]);

    const getTimer = useCallback(() => {
      console.log('get timer');
      //TODO CLEAR TIMEOUTS
      if (!building || !room || hasBuildingCompletedRef.current) return;
      let timeout: NodeJS.Timeout;
      if (room && (room.status === RoomStatus.building || room.status === RoomStatus.repairing)) {
        switch (room.status) {
          case RoomStatus.building:
            if (room.instabuild) {
              timeout = setTimeout(() => {
                handleBuildingCompletion();
              }, 1000);

              break;
            }
            const preventTimeBug = 15000;
            let updatedStartTime =
              new Date(room.statusUpdateDate).getTime() + (buildingLvlConfig?.buildingTimeMs || 0) + preventTimeBug;

            const timerStr = calculateTimer(updatedStartTime, handleBuildingCompletion);
            setBuildTime(updatedStartTime - Date.now());

            setTimer(timerStr);

            break;

          case RoomStatus.repairing:
            const isFarming = !!building?.lvlInfo[0].gatherDelayMs;
            break;

          default:
            break;
        }
      }
      return () => clearTimeout(timeout);
    }, [room, selectedIsland, selectedRoom, handleBuildingCompletion]);

    useEffect(() => {
      getTimer();
    }, [selectedRoom]);

    useEffect(() => {
      if (!building) return; // Add this line
       getTimer();

    }, [selectedIsland, timer, building, gTimer]);



  return { timer, isConfirmationRequired, confirmBuildingCompletion, triggerEndBuild, onClickBuild };
};
