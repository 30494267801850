import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useScroll } from '../../../../utils/ScrollContext';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { ArtefactCard } from '../../../../components';
import { Card } from '../../../../components/Card';
import CoinImage from '../../../../components/CoinImage';

import { PopupButton } from '../../../../components/PopupButton';
import { Artefacts, CardType } from '../../../../endpoints/mock';
import { usePurchase } from '../../../../hooks/usePurchase';
import { useTelegram } from '../../../../hooks/useTelegram';
import { CardProps } from '../../../../interfaces/card';
import { CoinsBalance } from '../../../../layout/components/HeaderCave/components';
import { mapToFullArtefacts } from '../../../../utils/artefactsParser';
import { useSoundService } from '../../../../utils/soundService';
import { getShopItems } from '../../../../endpoints/dungeonEndpoints';
import { DungeonShopBuyArtefact, DungeonShopBuyCard } from './components';

interface ShopCards {}
export const DungeonShop = ({
  gold,
  close,
  updateGoldAmount,
  updateSomeStates,
}: {
  gold: number;
  close: () => void;
  updateGoldAmount: (totalAmount: number, amount: number) => void;
  updateSomeStates: any;
}) => {
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { t } = useTranslation();
  const { userId } = useTelegram();
  const actualSaves = useSelector((state: RootState) => state.battleSave.battleSave.save);
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const { setScrollable } = useScroll();
  const [isCard, setCard] = useState<any>(null);
  const [isArtefact, setArtefact] = useState<any>(null);
  const scrollableElRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { playSound } = useSoundService();
  const [cards, setCards] = useState<any[]>([] as CardProps[]);
  const [artefacts, setArtefacts] = useState<any[]>([] as Artefacts[]);
  const [updates, setUpdates] = useState(2);
  const refreshOfferId = 32;

  useEffect(() => {
    setScrollable(true, scrollableElRef);
    return () => {
      setScrollable(false);
    };
  }, [setScrollable]);

  useEffect(() => {
    getCardsForShop();
  }, []);

  useEffect(() => {
    if (isSuccessPurchase) {
      playSound('questsReward');
      setTimeout(() => {
        setTimeout(() => {
          getCardsForShop();
        }, 0);
      }, 1500);
      setUpdates(updates - 1);
    }
  }, [isSuccessPurchase]);

  const getCardsForShop = async () => {
    const getCards = await getShopItems({
      //@ts-ignore
      dungeonId: actualSaves?.dungeonId,
      //@ts-ignore
      stageId: actualSaves?.currentStage,
      clientId: userId,
    });

    const newCards = getCards.cards
      .filter((card) => card.cardType !== CardType.equip)
      .map((card, index) => {
        return {
          id: card.cardId,
          lvl: card.stars,
          uid: `${index}`,
          glow: false,
          cost: card.cost,
        };
      });

    const newArtefacts = getCards.cards
      .filter((card) => card.cardType === CardType.equip)
      .map((card, index) => {
        return {
          id: card.cardId,
          uid: `${index}`,
          dungeonCost: card.cost,
        };
      });
    if (getCards.cards.length) {
      setCards(() => [...newCards]);
      setArtefacts(() => [...newArtefacts]);
      setIsLoading(true);
    }
  };

  const mappedArtefacts = useMemo(() => {
    if (!artefacts || !appConfig) return [];

    if (!appConfig.skills) return [];

    const configArtefacts = appConfig.skills.variables;

    return mapToFullArtefacts(artefacts, configArtefacts);
  }, [artefacts, appConfig]);

  const refreshCards = () => {
    playSound('button');
    if (updates > 0) {
      confirmPurchase({
        offerId: refreshOfferId,
        stageId: actualSaves?.currentStage,
      });
    }
  };

  return (
    <>
      {isLoading && (
        <div className="z-40 absolute top-1/2 left-1/2 ">
          <div className="w-full fixed top-0 left-0 bottom-0 right-0 z-5 flex items-center justify-center">
            <div className="absolute top-[8px] right-[17px] rounded-[6px] z-[31]">
              <CoinsBalance goldAmount={gold} />
            </div>
            <div className="w-full h-full absolute bg-black opacity-60"></div>
            <div className="relative w-[90%] bg-[#554837] border border-[#18191a] rounded-[2px] p-[2px]">
              <img
                src={require('../../../../assets/images/shop-person.png')}
                className="w-[120px] absolute left-[11px] top-[-121px]"
                alt=""
              />
              <img
                src={require('../../../../assets/images/cards-modal-border.png')}
                className="absolute w-[17.5px] h-[21px] -top-[6px] -left-[5px]"
                alt=""
              />
              <img
                src={require('../../../../assets/images/cards-modal-border.png')}
                className="absolute w-[17.5px] h-[21px] -bottom-[6.5px] -left-[4.5px] -rotate-90"
                alt=""
              />
              <img
                src={require('../../../../assets/images/cards-modal-border.png')}
                className="absolute w-[17.5px] h-[21px] -bottom-[5.5px] -right-[4.5px] -rotate-180"
                alt=""
              />
              <button
                className="absolute z-10 w-11 h-11 p-1 -top-4 -right-4 flex items-center justify-center"
                onClick={close}
              >
                <img src={require('../../../../assets/images/shop-modal-close.png')} className="w-8 h-8" alt="" />
              </button>
              <div className=" w-full h-full bg-[#242520] border border-[#18191a] rounded-[2px] p-3 shadow-inner-sm-white">
                <div className="relative w-full h-full flex flex-col items-center bg-[#201b18] rounded-[1px] p-3 pt-7 shadow-inner-sm-black">
                  <div className="absolute top-[-28px] left-0 right-0 flex justify-self-center">
                    <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
                      <div className="bg-[#351d1e] text-center text-white text-xl p-[7px] leading-none rounded-[1px] border border-[#18191a] shadow-inner-sm-black">
                        {t('dungeon.shop.title')}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center text-white text-xl items-center w-full">
                    <div className="w-full pr-4 pl-1">
                      <div className="w-full h-[3px] bg-[#181615] "></div>
                      <div className="w-full h-[3px] bg-[#3b332e]"></div>
                    </div>
                    {t('dungeon.shop.header')}
                    <div className="w-full pl-4 pr-1">
                      <div className="w-full h-[3px] bg-[#181615] "></div>
                      <div className="w-full h-[3px] bg-[#3b332e]"></div>
                    </div>
                  </div>
                  {cards.length ? (
                    <div className="flex  gap-4 mb-3">
                      {cards.map((card, index) => {
                        return (
                          <div
                            className="w-1/3"
                            key={index}
                            onClick={() => {
                              setCard({ ...card, cardIndex: index });
                            }}
                          >
                            <div className="mb-2">
                              <Card id={card.id} lvl={card.lvl} uid={card.uid} hidden={false} />
                            </div>
                            <div className="flex justify-center items-center text-center gap-1">
                              <CoinImage width={24} height={24} />
                              <div className="text-right text-white uppercase leading-[14px] font-[900]">
                                {card?.cost}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-full w-full text-2xl text-white pt-10 pb-12">
                      Empty
                    </div>
                  )}
                  {isCard ? (
                    <DungeonShopBuyCard
                      card={isCard}
                      setCard={setCard}
                      gold={gold}
                      cards={cards}
                      setCards={setCards}
                      updateGoldAmount={updateGoldAmount}
                      updateSomeStates={updateSomeStates}
                    />
                  ) : null}
                  {isArtefact ? (
                    <DungeonShopBuyArtefact
                      card={isArtefact}
                      setCard={setArtefact}
                      gold={gold}
                      cards={artefacts}
                      setCards={setArtefacts}
                      updateGoldAmount={updateGoldAmount}
                      updateSomeStates={updateSomeStates}
                    />
                  ) : null}
                  {mappedArtefacts.length ? (
                    <div className="flex justify-center text-white text-xl items-center w-full py-2">
                      <div className="w-full pr-4 pl-1">
                        <div className="w-full h-[3px] bg-[#181615] "></div>
                        <div className="w-full h-[3px] bg-[#3b332e]"></div>
                      </div>
                      {t('artefacts.name')}
                      <div className="w-full pl-4 pr-1">
                        <div className="w-full h-[3px] bg-[#181615] "></div>
                        <div className="w-full h-[3px] bg-[#3b332e]"></div>
                      </div>
                    </div>
                  ) : null}
                  {mappedArtefacts.length ? (
                    <div className="flex justify-center items-stretch gap-4 mb-3">
                      {mappedArtefacts.map((mappedArtefact, index) => {
                        return (
                          <div
                            className="flex flex-col h-full flex-1"
                            key={index}
                            onClick={() => {
                              setArtefact({ ...mappedArtefact, cardIndex: index });
                            }}
                          >
                            <div className="mb-2 ">
                              <ArtefactCard artefact={mappedArtefact} inShop={true} />
                            </div>
                            <div className="flex justify-center items-center text-center gap-1">
                              <CoinImage width={24} height={24} />
                              <div className="text-right text-white uppercase leading-[14px] font-[900]">
                                {mappedArtefact?.cost}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <div className="absolute bottom-[12px] w-full flex items-center justify-center gap-2 text-warmGray-300">
                    <img src={require('../../../../assets/images/shop/refresh.png')} className="w-5 h-5" alt="" />
                    <div>{`${updates}/2`}</div>
                  </div>
                  <PopupButton
                    type={updates ? 'blue' : 'gray'}
                    onClick={refreshCards}
                    className="absolute bottom-[-50px] flex justify-self-center"
                    disabled={updates ? false : true}
                  >
                    <div
                      className={` absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[30%] -translate-y-[10%] `}
                    >
                      <div
                        className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                      >
                        <img
                          src={require('../../../../assets/images/shop/offer/notify.png')}
                          className={`w-[3em] aspect-square -translate-y-[5%] `}
                          alt=""
                        />
                        <p>4</p>
                      </div>
                    </div>
                    <div className=" flex justify-center min-w-[160px] items-center h-[24px] ">
                      {t('btnStatus.refresh')}
                      <img
                        className="w-[16px] h-[16px] mx-1"
                        src={require('../../../../assets/images/shop/offer/offer-star.png')}
                        alt=""
                      />
                      40
                    </div>
                  </PopupButton>
                </div>
              </div>
            </div>
            {/* <SuccessOverlay isSuccess={isSuccessPurchase} /> */}
          </div>
        </div>
      )}
    </>
  );
};
