import { PopupButton } from "../../components/PopupButton";

export const FallbackComponent = ({
  error,
  componentStack,
  resetError,
}: any) => {
  return (
    <div className="absolute w-full h-full flex flex-col gap-2 justify-center items-center text-white text-5xl bg-[#1f1c1a] ">
      <img src={require("../../assets/images/bugImages/smthWentWrong.png")} />
      <div className="pb-4">
        <div className="text-center text-[#ffefcb] text-2xl font-black  leading-normal">
          {error}
        </div>
        <div className="w-60 text-center text-[#ffefcb] text-base font-semibold leading-none">
          Please reload the app and this should fix the problem
        </div>
      </div>

      <PopupButton type={"green"} onClick={resetError}>
        <div className="text-center text-[#ffefcb] text-lg font-black  leading-[18px]">
          Reload App
        </div>
      </PopupButton>
    </div>
  );
};
