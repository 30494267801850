import React from 'react';
import { ArtefactRarity, FullArtefacts } from '../../endpoints/mock';
import { renderDescription } from '../Card';
import { useTranslation } from 'react-i18next';

interface IArtefactCard {
  artefact: FullArtefacts;
  selected?: boolean;
  inShop?: boolean;
}

interface ParsedPart {
  type: 'text' | 'colored';
  color?: string;
  content: string;
}
const ArtefactRarityColor: Record<ArtefactRarity, { color: string }> = {
  [ArtefactRarity.common]: {
    color: 'text-[#4181b8]',
  },
  [ArtefactRarity.rare]: {
    color: 'text-[#9E5BD9]',
  },
  [ArtefactRarity.legendary]: {
    color: 'text-[#ffbf37]',
  },
};
/**
 * Розпарсити рядок формату:
 * "Deal |red:10 dmg|. |white:Repeats 2 times|\nNext line"
 * у масив об’єктів ParsedPart, де кожен об'єкт вказує,
 * чи це кольоровий текст, чи звичайний.
 */
function tokenizeDescription(description: string): ParsedPart[] {
  const regex = /\|([^:]+):(.*?)\|/g;
  // Група 1 ( color ) = [^:]+ (все що завгодно, крім ":")
  // Група 2 ( content ) = .*? (текст усередині)

  let lastIndex = 0;
  let match: RegExpExecArray | null;
  const result: ParsedPart[] = [];

  while ((match = regex.exec(description)) !== null) {
    const textBeforeToken = description.slice(lastIndex, match.index);
    if (textBeforeToken) {
      // Додаємо звичайний текст, якщо він є
      result.push({
        type: 'text',
        content: textBeforeToken,
      });
    }

    const color = match[1]; // те, що у групі 1
    const content = match[2]; // те, що у групі 2

    result.push({
      type: 'colored',
      color: color,
      content: content,
    });

    lastIndex = regex.lastIndex;
  }

  // Якщо в кінці рядка ще залишився текст
  const remainingText = description.slice(lastIndex);
  if (remainingText) {
    result.push({
      type: 'text',
      content: remainingText,
    });
  }

  return result;
}
/**
 * Перетворити ParsedPart[] у React-елементи з урахуванням переносів рядка (\n).
 */
function renderParsedParts(parts: ParsedPart[]): React.ReactNode {
  return parts.map((part, index) => {
    // Розбиваємо контент по \n, аби вставити <br/>.
    const splittedByNewLine = part.content.split('\\n');
    // console.log('part', part)
    // Якщо це кольоровий текст
    if (part.type === 'colored' && part.color) {
      return (
        <React.Fragment key={index}>
          {splittedByNewLine.map((subText, i) => (
            <React.Fragment key={i}>
              <span style={{ color: part.color }}>{subText}</span>
              {/* Якщо це не останній шматок — додаємо <br/> */}
              {i < splittedByNewLine.length - 1 && <br />}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }

    // Якщо це звичайний текст
    return (
      <React.Fragment key={index}>
        {splittedByNewLine.map((subText, i) => (
          <React.Fragment key={i}>
            {subText}
            {i < splittedByNewLine.length - 1 && <br />}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  });
}

/**
 * Головна функція, яку можна викликати:
 * 1) бере ваш фінальний опис (description)
 * 2) токенізує (розбиває на кольорові/звичайні частини)
 * 3) повертає React-елементи для відображення
 */
export function parseColoredDescription(description: string): React.ReactNode {
  const tokens = tokenizeDescription(description);
  return renderParsedParts(tokens);
}

export const ArtefactCard: React.FC<IArtefactCard> = ({
  artefact,
  selected,
  inShop,
}: {
  artefact: FullArtefacts;
  selected?: boolean;
  inShop?: boolean;
}) => {
  const description = renderDescription({ id: artefact.id, levelData: artefact.stars });
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col gap-[8px] ${selected ? '' : 'h-[120px]'}`}>
      <div
        className={`flex items-center w-full justify-start gap-[5px] px-[8px] ${
          inShop ? '' : 'py-2'
        } bg-[#342a21] rounded border-2 border-[#422E24] shadow-inner-sm-lightbrown flex-1`}
        style={{
          background: 'radial-gradient(circle, rgb(51, 43, 38) 0%, rgb(32, 27, 24) 60%)',
        }}
      >
        <div className={`flex ${selected ? 'max-w-[70px] max-h-[70px]' : 'max-w-[45px] max-h-[45px]'} items-center`}>
          <img
            src={require(`../../assets/images/artefacts/${artefact.id}.png`)}
            alt="Artifact"
            className="w-full h-full object-cover scale-150"
          />
        </div>

        <div className="flex flex-col flex-1 min-w-[50px]">
          <div className="flex flex-col gap-[5px]">
            <div>
              <p className={`text-white ${selected ? 'text-[15px]' : 'text-[10px]'} font-bold leading-none`}>
                {t(`cardNames.${artefact.id}`)}
              </p>
              <p className={`${ArtefactRarityColor[artefact.rarity].color} ${selected ? 'text-[12px]' : 'text-[8px]'}`}>
                {t(`artefacts.rarity.${artefact.rarity}`)}
              </p>
            </div>
            {/* Обрізання тексту за допомогою line-clamp-3 */}
            <p
              className={`text-white 
                ${selected ? 'text-[15px]' : 'text-[10px] '} font-semibold leading-none  `}
            >
              {parseColoredDescription(description)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
