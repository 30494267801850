import React from 'react';
import { PopupButton } from '../../../../components/PopupButton';
import { useTranslation } from 'react-i18next';

interface Props {
  boughtLives: number;
  offerCost: number;
  onClose: () => void;
  handleShare: () => void;
  handleBuy: () => void;
}

export const HerzModal: React.FC<Props> = ({ boughtLives, offerCost, onClose, handleShare, handleBuy }) => {
  const { t } = useTranslation();
  return (
    <div className="absolute inset-0 flex items-center justify-center z-50">
      <div className="relative flex flex-col z-10 gap-24">
        <div className="relative flex items-center justify-center">
          <img className="w-[132px]" src={require('../../../../assets/images/Herz_Big.png')} alt={`Additional live`} />
          <div
            className="py-[5px] absolute -bottom-[10px] overflow-hidden flex-shrink-0 w-[180px]"
            style={{
              background: 'radial-gradient(circle, rgba(250, 186, 60, 1) 20%, rgba(250, 186, 60, 0) 100%)',
            }}
          >
            {/* Верхня Рамка */}
            <div
              className="absolute top-0 left-0 w-full h-[2px] pointer-events-none"
              style={{
                background: 'radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)',
              }}
            ></div>

            {/* Нижня Рамка */}
            <div
              className="absolute bottom-0 left-0 w-full h-[2px] pointer-events-none"
              style={{
                background: 'radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)',
              }}
            ></div>

            {/* Вміст Елемента */}
            <div className="flex justify-center items-center text-[#FFEFCB] text-stroke-regular">{t('heart')} + 1</div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center mb-10 gap-4">
          <div>
            <PopupButton type="red" size="medium" onClick={onClose}>
              <div className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]">
                {t('btnStatus.close')}
              </div>
            </PopupButton>
          </div>
          <div className="relative flex justify-center">
            {boughtLives === 0 ? (
              <PopupButton size="medium" type="gold" onClick={handleShare}>
                <div className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]">
                  <div className="flex justify-center items-center gap-[2px]">
                    <div className="flex justify-center items-center gap-[3px]">
                      {t('btnStatus.share')} +{' '}
                      <img className="inline w-4 h-4" src={require('../../../../assets/images/Herz.png')} />
                    </div>
                  </div>
                </div>
              </PopupButton>
            ) : (
              <PopupButton size="medium" type="gold" onClick={handleBuy}>
                <div
                  className={`absolute top-0 left-4 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[80%] -translate-y-[20%] `}
                >
                  <div
                    className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                  >
                    <img
                      src={require('../../../../assets/images/shop/offer/notify.png')}
                      className={`w-[3em] aspect-square -translate-y-[5%] `}
                      alt=""
                    />
                    {(offerCost / 10).toFixed(0)}
                  </div>
                </div>
                <div className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]">
                  <div className="flex justify-center items-center gap-[2px]">
                    <div className="text-white flex justify-center items-center gap-[3px]">
                      {t('buyFor')}
                      <img
                        className="inline w-4 h-4"
                        src={require('../../../../assets/images/telegram-stars-icon.png')}
                      />
                      {offerCost}
                    </div>
                  </div>
                </div>
              </PopupButton>
            )}
            <div className="absolute -top-7 font-bold text-[#949494]">
              <span className="text-[18px] text-white">{3 - boughtLives}</span>
              /3
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-black opacity-80" onClick={onClose}></div>
    </div>
  );
};
