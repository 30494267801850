import { Card } from '../../../../components/Card';
import { PopupButton } from '../../../../components/PopupButton';
import { useTranslation } from 'react-i18next';

export const DeleteSecretCards = ({
  cards,
  onClose,
}: {
  cards: { cardId: number; cardLvl: number; uid: string }[];
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="absolute bg-[black]/[0.7] w-full h-full z-[100] left-0 top-0 flex items-center justify-center">
      <div className="relative">
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto"
        ></div>
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-gray-400/[0.2] to-traansparent mx-auto
          text-[#FFEFCB] text-center text-[14px] p-2"
        >
          {cards.length === 1 ? t('roomsModal.7.title') : t('roomsModal.4.title')}
          {/* {t('roomsModal.7.title')} */}
        </div>
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto mb-[30px]"
        ></div>
        <div className="flex justify-center">
          {cards.length > 0 &&
            cards.slice(0, 3).map((card, index) => (
              <div key={index} className={'relative  w-[30%]'}>
                <Card id={card.cardId} lvl={card.cardLvl ?? 1} uid={card.uid} />
              </div>
            ))}
        </div>
        <div className="flex justify-center">
          <PopupButton type={'blue'} size="medium" className="mt-[30px] mx-auto" disabled={false} onClick={onClose}>
            <div className="px-6">{t('roomsModal.6.title')}</div>
          </PopupButton>
        </div>
      </div>
    </div>
  );
};
