import { useTranslation } from 'react-i18next';
import { AdditionalCondition, AdditionalConditionType, defineCountString } from '../../../../../utils/farmConfigParser';
import { useUtils } from '../../../../../utils/navigateTo';
import { PopupButton } from '../../../../../components/PopupButton';
import { InviteButton } from '../../../../../components/InvitesButtons/components';
import { Tooltip } from 'react-tooltip';
import React from 'react';
import { useSessionStorage } from '@uidotdev/usehooks';

interface LvlGateButtonProps {
  stats: {
    referrals: number;
    premiumReferrals: number;
    donated: number;
    rankLvl: number;
  };
  starsValue: number;
  condition: AdditionalCondition;
  onClick: () => void;
  closePopup?: () => void;
}

export const LvlGateButton = ({ stats, condition, starsValue, onClick, closePopup }: LvlGateButtonProps) => {
  const { t } = useTranslation();

  const [isToolTip, setIsToolTip] = React.useState(false);
  const rankTooltip = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClick = (e: Event) => {
      if (!rankTooltip.current || !rankTooltip.current.contains(e.target as Node)) {
        setIsToolTip(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    // document.addEventListener("wheel", handleClick);
    // document.addEventListener("touchmove", handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
      // document.removeEventListener("wheel", handleClick);
      // document.removeEventListener("touchmove", handleClick);
    };
  }, []);

  switch (condition.conditionType) {
    case AdditionalConditionType.Friends: {
      return (
        <InviteButton className="flex-1 relative">
          <div className="flex items-center justify-center h-[30px]">
            <button className="absolute text-sm tracking-wide py-1 text-stroke-small">
              {`${t('friends.invite')} [${stats.referrals}/${condition.value}]`}
            </button>
            <div className="absolute w-8 h-8 flex justify-center items-center -top-5">
              <img src={require('../../../../../assets/images/notify-kitsu.png')} />
              <p className="text-white absolute bottom-[2px] text-[8px]">+800</p>
            </div>
          </div>
        </InviteButton>
      );
    }

    case AdditionalConditionType.DonateStars: {
      return (
        <PopupButton className="flex-1" type="gold" onClick={onClick}>
          <div
            className={` absolute -top-4 left-3 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[80%] -translate-y-[20%] `}
          >
            <div
              className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
            >
              <img
                src={require('../../../../../assets/images/shop/offer/notify.png')}
                className={`w-[3em] aspect-square -translate-y-[5%] `}
                alt=""
              />
              {defineCountString(Math.floor(starsValue / 10))}
            </div>
          </div>
          <div className="w-full items-center p-1 h-full gap-1">
            <div className="flex justify-center items-center gap-[2px]">
              <div className="w-5 h-5 flex justify-center items-center">
                <img src={require('../../../../../assets/images/telegram-stars-icon.png')} />
              </div>
              <div className="text-white text-[18px] flex justify-center items-center pt-1">{starsValue}</div>
            </div>
          </div>
        </PopupButton>
      );
    }

    case AdditionalConditionType.PremiumFriends: {
      return (
        <InviteButton className="flex-1 relative">
          <div className="flex items-center justify-center h-[30px]">
            <button className="absolute text-[11px] tracking-wide py-1 text-stroke-small">
              {`Invite prem friend [${stats.premiumReferrals}/${condition.value}]`}
            </button>
            <div className="absolute w-8 h-8 flex justify-center items-center -top-5">
              <img src={require('../../../../../assets/images/notify-kitsu.png')} />
              <p className="text-white absolute bottom-[2px] text-[8px]">+800</p>
            </div>
          </div>
        </InviteButton>
      );
    }

    case AdditionalConditionType.RankLvl: {
      return (
        <div
          className="flex-1 mb-8"
          onClick={() => {
            setIsToolTip(true);
          }}
          ref={rankTooltip}
        >
          <div className="flex items-center justify-center">
            <div ref={rankTooltip} className="relative w-14 h-14">
              {condition && (
                <img
                  className="w-full h-full"
                  data-tooltip-id="rank-tooltip-1"
                  src={require(`../../../../../assets/images/ranks/${condition.value}.png`)}
                />
              )}
              <div className="flex justify-center items-center absolute w-full bottom-0">
                <div className="w-9 h-3">
                  <img className="w-full h-full" src={require('../../../../../assets/images/ranks/titlePlace.png')} />
                </div>
                <div className="absolute text-center text-white text-[8.57px] font-black  leading-[8.57px] text-shadow-black-sm">
                  {condition.value} Rank
                </div>
              </div>
              {isToolTip && <RankTooltip isOpen={true} closePopup={closePopup} id={1} />}
            </div>
          </div>
        </div>
      );
    }
    default:
      return null;
  }
};

interface RankToolTipProps {
  isOpen: boolean;
  closePopup?: () => void;
  id: number;
  classNameArrow?: string;
  margin?: number;
}

export const RankTooltip: React.FC<RankToolTipProps> = ({ isOpen, closePopup, id, margin, classNameArrow }) => {
  const { navigateTo } = useUtils();
  const [isShopOpen, setShopOpen] = useSessionStorage('isShopOpen', false);
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);
  const { t } = useTranslation();

  return isOpen ? (
    <Tooltip
      isOpen={true}
      id={`rank-tooltip-${id}`}
      place="top"
      style={{
        backgroundColor: '#ffffff',
        color: '#000000',
        border: '1px solid #000000',
        borderRadius: '8px',
        maxWidth: '300',
        padding: '8px',
        wordWrap: 'break-word',
        marginLeft: margin ? `${margin}px` : 'unset',
        zIndex: '999',
      }}
      clickable={true}
      classNameArrow={classNameArrow ? classNameArrow : ''}
      opacity={1}
    >
      <div className="flex items-center gap-2">
        <div className="w-[138px] text-black text-[10px] font-bold">
          {t('other.get')}{' '}
          <img
            className="inline-block mb-[2px] w-[13px] h-[13px]"
            src={require('../../../../../assets/images/ranks/rankProgress.png')}
          />{' '}
          {t('ranks.for')}{' '}
          <span
            className="text-[#4D52E2] underline"
            onClick={() => {
              navigateTo('/island');
              setOpenedDepositModal(true);
              closePopup?.();
            }}
          >
            {t('ranks.deposit')}
          </span>{' '}
          {t('other.or')}{' '}
          <img
            className="inline-block mb-[2px] w-[13px] h-[13px]"
            src={require('../../../../../assets/images/ranks/star.png')}
          />{' '}
          STARS{' '}
          <span
            className="text-[#4D52E2] underline"
            onClick={() => {
              navigateTo('/island');
              setShopOpen(true);
              closePopup?.();
            }}
          >
            {' '}
            {t('ranks.purchases')}
          </span>{' '}
          {t('ranks.upgradeLvl2')}
        </div>
        <PopupButton
          width="72px"
          type={'blue'}
          onClick={() => {
            closePopup?.();
            navigateTo('/rank');
          }}
          className="text-white"
        >
          {t('rank')}
        </PopupButton>
      </div>
    </Tooltip>
  ) : null;
};
