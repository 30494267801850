import { CSSProperties, useEffect, useState } from 'react';
import PageTransition from '../../containers/Router/components/PageTransition';
import { HandleBackButton } from '../../layout/components/HeaderCave/components';
import { BossEnemyBtn } from './components/BossEnemyBtn';
import { OfferResource, OfferResourceProps } from '../Shop/components/OfferResources';
import { PopupButton } from '../../components/PopupButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { HeroShard } from '../../interfaces/lootBotx';
import { heroesMap } from '../../utils/mapping';
import { TitleField } from '../../containers/Room';
import { HeroRating } from '../../containers/Heroes/components/HeroRating';
import { AnimatePresence, motion, stagger } from 'framer-motion';
import { setHeroesList } from '../../app/features/heroesSlice';
import { setSelectedHero } from '../../app/features/selectedHero';
import { getHeroes } from '../../endpoints/heroEndpoints';
import { Hero, FullHero, ConfigHero, HeroRarity } from '../../interfaces/hero';
import { parseFullHero } from '../../utils/heroParser';
import { useTelegram } from '../../hooks/useTelegram';
import { useTranslation } from 'react-i18next';
import { updateBattleSave } from '../../utils/updateBattleSave';
import * as Sentry from '@sentry/react';
import { useUtils } from '../../utils/navigateTo';
import { completedBosses } from '../../endpoints/bossEndpoint';
import {
  getDungeonProgressByDungeonId,
  getDungeonProgressSafe,
  resetCurrentDungeon,
} from '../../endpoints/dungeonEndpoints';
import { FooterSelectHero } from '../../layout/components/FooterSelectHero';
import { useSessionStorage } from '@uidotdev/usehooks';
import { BattleSaves } from '../../endpoints/mock';
import { OpenLootBox } from '../../containers/Inventory/components/OpenLootBox';
import { resetDungeon } from '../../app/features/dungeonCompleted';
import { ErrorComponent } from '../../components';
import { RuntimeErrorData, ErrorType } from '../../interfaces/error';
import { ErrorReportingService } from '../../services/errorReportingService';
import { TutorialTooltip } from '../../components/TutorialTooltip';
import { defineCountString } from '../../utils/farmConfigParser';
import { useSoundService } from '../../utils/soundService';

interface Enemy {
  enemyId?: number;
  dungeonId: number;
  status?: number;
  title: string;
  stars: number;
}
interface BossDungeon {
  dungeonId: {
    value: number;
  };
  title: {
    value: string;
  };
  stars: {
    value: number;
  };
}

export enum BossStatus {
  locked,
  active,
  completed,
}

// const mockEnemies = [
//   {
//     enemyId: 1,
//     status: 2,
//     title: 'Pepe Assasin',
//     stars: 1,
//   },
//   {
//     enemyId: 2,
//     status: 2,
//     title: 'Trumpfall',
//     stars: 2,
//   },
//   {
//     enemyId: 3,
//     status: 0,
//     title: 'Doggy Paladin',
//     stars: 3,
//   },
//   {
//     enemyId: 4,
//     status: 2,
//     title: 'Pepe Assasin',
//     stars: 4,
//   },
//   {
//     enemyId: 5,
//     status: 2,
//     title: 'Starforged Elon',
//     stars: 5,
//   },
// ];

const HeroIcon = ({ isActive, heroId, heroLevel }: { isActive: boolean; heroId: number; heroLevel?: number }) => {
  const heroesList = useSelector((state: RootState) => state.heroes.heroesList);
  const selectedHero = useSelector((state: RootState) => state.selectedHero);
  const heroImg = require(`../../assets/images/heroes/cards/hero-icon-${heroId}.png`);
  const totalParts = 25;
  const filledParts =
    (heroesList[heroId]?.rating?.claimedLevels / heroesList[heroId]?.rating?.totalLevels) * totalParts;
  const fullStars = Math.floor(filledParts / 5);
  const { t } = useTranslation();

  return (
    <div
      className={`relative w-[64px] h-[64px] ${
        isActive ? 'border-solid border-[5px] border-[#3ADC96]' : 'border-solid border-[5px] border-[#1a1819]'
      } `}
    >
      {!isActive && (
        <div className={`absolute w-full h-full top-0  text-stroke-small  `}>
          <div className={`relative z-[1] left-[-5px] w-[calc(100%+10px)] translate-y-[-40%] `}>
            <TitleField title={t('heroes.comingSoon')} fontSize="8px" />
          </div>
          <div
            className={`absolute w-[calc(100%+10px)] h-[calc(100%+10px)] top-[-5px] left-[-5px] bg-[rgba(0,0,0,0.5)] z-[2]`}
          ></div>
        </div>
      )}
      <div className={`w-full h-full border-solid border-[1px] border-black bg-[#595ffd]`}>
        <img src={heroImg} className={``} alt="" />
        <div className={`absolute flex flex-col justify-end bottom-0 bg-gradient-to-t from-black w-full h-[50%] `}>
          <p className="w-full text-center text-[8px] leading-[1]">Lv.{heroLevel}</p>
          <HeroRating
            starsWidth="w-[8px]"
            totalLevels={heroesList[heroId]?.rating?.totalLevels}
            claimedLevels={heroesList[heroId]?.rating?.claimedLevels}
          />
        </div>
      </div>
    </div>
  );
};

const Star = () => {
  return (
    <div className={` w-[2em] h-[2em]`}>
      <img src={require('../../assets/images/stars/full-star.png')} className="w-full h-full" alt="" />
    </div>
  );
};

export const BossDungeon = () => {
  const { userId } = useTelegram();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { navigateTo } = useUtils();

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [currentEnemy, setCurrentEnemy] = useState<number>(0);
  const [parsedEnemies, setParsedEnemies] = useState<Enemy[]>();
  const [parsedRewards, setParsedRewards] = useState<any>();
  const [bossId, setBossId] = useState<number>();
  const [completedBossList, setCompletedBossList] = useState<{ bossId: number }[]>([]);
  const [alertTooltip, setAlertTooltip] = useState<boolean>(false);
  const [inBossDungeon, setInBossDungeon] = useState<boolean>(false);

  const [isDungeonStart, setIsDungeonStart] = useSessionStorage('isDungeonStart', false);
  const [currentDungeonId, setCurrentDungeonId] = useSessionStorage('currentDungeonId', 0);
  const [startSelectHero, setStartSelectHero] = useSessionStorage('startSelectHero', false);
  const [sessionHeroId, setSessionHeroId] = useSessionStorage('sessionHeroId', '');
  const [openHeroTierModalSession, setOpenHeroTierModalSession] = useSessionStorage('openHeroTierModal', false);

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const allHeroes: ConfigHero[] = appConfig.heroes.variables;
  const heroesList = useSelector((state: RootState) => state.heroes.heroesList);
  const selectedHero = useSelector((state: RootState) => state.selectedHero);
  const enemyList = useSelector((state: RootState) => state.appConfig.configs.bossfights.variables);
  const dungeonList = useSelector((state: RootState) => state.appConfig.configs.dungeonCards.variables);
  const actualSaves = useSelector((state: RootState) => state.battleSave.battleSave.save) as BattleSaves | null;
  const dungeonIsCompleted = useSelector((state: RootState) => state.dungeonCompleted.dungeonCompleted);

  const dungeonResources = useSelector((state: RootState) => state.dungeonCompleted.resources);
  const [isResoursesShown, setResoursesShown] = useState(false);
  const { playSound } = useSoundService();

  // console.log('actualSave', actualSaves?.bossId && actualSaves?.stages.filter((stage) => stage.stageId > 3).length > 0);
  // here must fix

  useEffect(() => {
    if (dungeonIsCompleted) {
      setTimeout(() => {
        setResoursesShown(true);
        playSound('questsReward');
      }, 1000);
    }
  }, [dungeonIsCompleted]);

  const fetchDungeonProgress = async () => {
    try {
      const progress = await getDungeonProgressSafe({ clientId: userId });
      if (progress) {
        setInBossDungeon(progress.inBossDungeon && progress.inDungeon);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchDungeonProgress();
  }, []);

  useEffect(() => {
    if (actualSaves?.buildingId) {
      setAlertTooltip(true);
    }
  }, [actualSaves]);

  const heroId = Number(selectedHero.uid);
  const heroName = heroesMap[Number(heroId) as HeroShard].name;
  const heroLevel = heroesList[heroId]?.level;
  const heroImg = heroesList[heroId]?.img;

  const getCompletedBosses = async () => {
    const bossIds = await completedBosses({ clientId: userId });
    if (bossIds.length >= 1) {
      setBossId(bossIds[bossIds?.length - 1]?.bossId + 1000);
      setCompletedBossList(bossIds);
    } else {
      setBossId(0);
    }
  };

  useEffect(() => {
    getCompletedBosses();
  }, []);

  useEffect(() => {
    if (dungeonList && currentEnemy > 0) {
      const rewards = dungeonList
        .filter((dungeon: any) => dungeon.dungeonId.value === currentEnemy)[0]
        ?.rewards?.value.map((r: any, index: number) => {
          return {
            resourceType: r.rewardType.value,
            resourceId: r.rewardId.value,
            amount: r.rewardAmount.value,
            isEnabled: index === 0 ? true : false,
            layout: 'wide',
          };
        });
      setParsedRewards(rewards);
    }
  }, [dungeonList, currentEnemy]);

  const getDungeonSaveOrStartDungeon = async () => {
    const progress = await getDungeonProgressByDungeonId({ clientId: userId, bossId: bossId! + 1, heroId: heroId });

    if (progress?.stages?.length) {
      // setVisibleItems(progress.stages);
      // setLastStageId(progress.stages[progress.stages.length - 1].cardId);
      // setCurrentIndex(progress.stages[progress.stages.length - 1].cardId - 1);
      // setNextIndex(progress.stages[progress.stages.length - 1].cardId + 1);
      // setCurrentHp(progress.currentHp);
      // setCurrentMana(progress.currentMana);
      // setGoldAmount(progress.gold);
      if (progress.currentDungeonId) {
        updateBattleSave({
          save: {
            gold: progress.gold,
            currentHp: progress.currentHp,
            currentMana: progress.currentMana,
            stages: progress.stages,
            dungeonId: progress.currentDungeonId,
            bossId: bossId! + 1,
          },
          clientId: userId,
        });
        navigateTo('/dungeon');
      }
    }
  };

  const getHeroesList = async () => {
    try {
      // Отримання даних з API
      const result = await getHeroes({ clientId: userId });

      if (result?.heroes?.length) {
        // Створюємо мапу для швидкого пошуку Hero за heroId
        const heroMap: Record<number, Hero> = {};
        result.heroes.forEach((hero: Hero) => {
          heroMap[hero.heroId] = hero;
        });

        // Перетворюємо доступних героїв у FullHero[]
        const heroes: FullHero[] = allHeroes.map((configHero: ConfigHero) => {
          const heroData: Hero | undefined = heroMap[configHero.id.value];

          if (heroData) {
            const hero = parseFullHero(configHero, heroData);

            return {
              ...hero,
              name: `${t(`heroes.${configHero.id.value}`)}`,
            };
          } else {
            return {
              heroId: configHero.id.value,
              boosts: { hp: 0, mana: 0 },
              level: 0,
              inDungeon: false,
              upgradeCards: 0,
              expToNextLevel: 0,
              upgrades: [{ id: 1, level: 1 }],
              upgradesCount: 0,
              id: configHero.id.value,
              isAvaillable: false,
              tiers: [],
              levels: [],
              img: require(`../../assets/images/heroes/cards/hero-${configHero.id.value}.webp`),
              name: '',
              rarity: HeroRarity.Rare,
              rating: { claimedLevels: 1, totalLevels: 30 },
              energyType: 0,
              energyAmount: 0,
              health: 0,
              nextLevel: null,
              cardsAmount: 0,
              cards: [],
              inDungeonId: 0,
              maxLevel: 1,
              usedLives: 0,
              boughtLives: 0,
            };
          }
        });

        dispatch(setHeroesList(heroes));

        dispatch(
          setSelectedHero({
            uid: heroes.filter((hero) => hero.isAvaillable)[0].id.toString(),
            lvl: heroes.filter((hero) => hero.isAvaillable)[0].level,
          })
        );
      }
    } catch (error) {
      console.error('Error fetching heroes:', error);
    }
  };

  useEffect(() => {
    getHeroesList();
  }, [userId]);

  useEffect(() => {
    const newParsedEnemies = dungeonList
      .filter((En: BossDungeon) => En.dungeonId.value > 1000)
      .map((En: Enemy) => {
        return parseEnemies(En);
      })
      .sort((a: Enemy, b: Enemy) => a.dungeonId - b.dungeonId);
    setParsedEnemies(newParsedEnemies);
  }, [enemyList, bossId]);

  const totalParts = 25; // 5 зірок по 5 частин
  const filledParts =
    (heroesList[heroId]?.rating?.claimedLevels / heroesList[heroId]?.rating?.totalLevels) * totalParts;

  const fullStars = Math.floor(filledParts / 5);

  const handleClick = (enemyId: number) => {
    setCurrentEnemy(enemyId);
    setTimeout(() => {
      setShowPopup(true);
    }, 500);
  };

  const handleGoToTiers = () => {
    setSessionHeroId('0');
    navigateTo('/heroes');
    setOpenHeroTierModalSession(true);
  };

  const handleRetry = async () => {
    await resetCurrentDungeon({ clientId: userId, heroId: 1 });
    const progress = await getDungeonProgressSafe({
      clientId: userId,
    });
    setInBossDungeon(progress.inBossDungeon && progress.inDungeon);
    setCurrentDungeonId(progress.currentDungeonId);
  };

  const handleClaimResourcesForCompletedDungeon = () => {
    dispatch(resetDungeon());
    getCompletedBosses();
  };

  const handleCloseTooltip = () => {
    setAlertTooltip(false);
  };

  const parseEnemies = (enemy: any) => {
    //If bossId != 0, add 1001 to it
    const status =
      bossId !== 0
        ? enemy?.dungeonId?.value <= bossId!
          ? BossStatus.completed
          : enemy?.dungeonId?.value === bossId! + 1
            ? BossStatus.active
            : BossStatus.locked
        : enemy?.dungeonId?.value <= bossId!
          ? BossStatus.completed
          : enemy?.dungeonId?.value === bossId! + 1001
            ? BossStatus.active
            : BossStatus.locked;

    const parsedEnemy = {
      enemyId: enemy?.enemyId?.value,
      dungeonId: enemy?.dungeonId?.value,
      status: status,
      title: enemy?.description?.value,
      stars: enemy?.stars?.value,
    };
    return parsedEnemy;
  };

  const mockResources: OfferResourceProps[] = [
    {
      resourceType: 2,
      resourceId: 0,
      isEnabled: true,
      amount: '7500',
      layout: 'wide',
    },
    {
      resourceType: 2,
      resourceId: 4,
      isEnabled: false,
      amount: '20',
      layout: 'wide',
    },
    {
      resourceType: 2,
      resourceId: 1,
      isEnabled: false,
      amount: '25k',
      layout: 'wide',
    },
    {
      resourceType: 2,
      resourceId: 2,
      isEnabled: false,
      amount: '7500',
      layout: 'wide',
    },
    {
      resourceType: 0,
      resourceId: 1,
      isEnabled: false,
      amount: '5',
      layout: 'wide',
    },
  ];

  const layout: Record<number, Record<number, any>> = {
    0: {
      0: {
        hidden: { opacity: 1, transform: 'translateY(200%)' },
        visible: { opacity: 1, transform: `translateY(75%) translateX(-65%)`, transition: { delay: '0.1' } },
      },
      1: {
        hidden: { opacity: 0, transform: 'translateY(400%)' },
        visible: { opacity: 1, transform: `translateY(-15%) translateX(20%)`, transition: { delay: '0.2' } },
      },
      2: {
        hidden: { opacity: 0, transform: 'translateY(600%)' },
        visible: { opacity: 1, transform: `translateY(-30%) translateX(-90%)`, transition: { delay: '0.3' } },
      },
      3: {
        hidden: { opacity: 0, transform: 'translateY(800%)' },
        visible: { opacity: 1, transform: `translateY(-120%) translateX(50%)`, transition: { delay: '0.4' } },
      },
      4: {
        hidden: { opacity: 0, transform: 'translateY(1000%)' },
        visible: { opacity: 1, transform: `translateY(-180%) translateX(-120%)`, transition: { delay: '0.5' } },
      },
    },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: (skipAnimation: boolean) => ({
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    }),
  };

  const headerVariants = {
    hidden: {
      transform: 'translateY(-110%)',
    },
    visible: {
      transform: 'translateY(0%)',
    },
  };

  const nextPageVariants = {
    hidden: {
      transform: 'translateY(200%)',
    },
    visible: {
      transform: 'translateY(0%)',
    },
  };

  return (
    <>
      <Sentry.ErrorBoundary
        onError={(error, componentStack, eventId) => {
          const errorData: RuntimeErrorData = { message: '' };

          if (error instanceof Error) {
            errorData.message = error.message;
            errorData.stack = componentStack;
          }

          if (componentStack) {
            errorData.stack = componentStack;
          }

          return ErrorReportingService.reportError({
            type: ErrorType.runtime,
            errorData,
            clientId: userId,
          });
        }}
        fallback={({ error, resetError, componentStack, eventId }) => {
          return <ErrorComponent jsError={{ error, resetError, componentStack, eventId }} />;
        }}
      >
        <PageTransition>
          <AnimatePresence mode="wait">
            <div
              id="boss-dungeon"
              className={`w-full h-full bg-center bg-cover bg-no-repeat bg-[url('./assets/images/bossDungeon/boss-dungeon-bg.webp')] text-white`}
            >
              {dungeonIsCompleted && (
                <>
                  {isResoursesShown && (
                    <div className="z-[9999]">
                      <OpenLootBox
                        rewards={dungeonResources}
                        openBoxName={'Dungeon Completed'}
                        onClose={handleClaimResourcesForCompletedDungeon}
                      />
                    </div>
                  )}
                </>
              )}
              {/* Header */}
              <motion.div
                variants={headerVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: 0.9 }}
                className={`absolute flex top-0 w-full h-[30px] bg-[#3a302b]`}
              >
                {/* Header borders */}
                <motion.div
                  initial={{ transform: 'translateY(-100%)' }}
                  animate={{ transform: 'translateY(0)' }}
                  className="absolute top-0 left-[20px] z-[2]"
                >
                  <HandleBackButton />
                </motion.div>
                <div className="absolute w-full top-0 h-[2px] bg-[#574137]"></div>
                <div className="absolute w-full bottom-[-2px] h-[2px] bg-[#423024]"></div>
                <div className="absolute w-full bottom-[-4px] h-[2px] bg-[black] "></div>
                <p className=" absolute left-[70px] text-[24px] text-stroke-small">{t('bossFight.title')}</p>
              </motion.div>
              {parsedEnemies && (
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  className={`w-full h-full bg-cover bg-no-repeat bg-right-top`}
                >
                  {parsedEnemies.map((En: any, index: number) => (
                    <motion.div
                      key={En.enemyId}
                      variants={layout[0][index]}
                      initial="hidden"
                      animate="visible"
                      className={`absolute top-[50%] left-[50%]`}
                    >
                      <BossEnemyBtn enemy={En} onClick={handleClick} activeEnemy={currentEnemy} />
                    </motion.div>
                  ))}
                </motion.div>
              )}

              <motion.div
                variants={nextPageVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: 0.9 }}
                className={`absolute w-[2em] h-[2em] left-[30px] bottom-[50px] `}
              >
                <HeroIcon isActive={true} heroId={heroId} heroLevel={heroLevel} />
              </motion.div>

              {/* Buttom at the bottom of the screen? */}
              <motion.div
                variants={nextPageVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: 0.9 }}
                className={`absolute w-[2em] h-[2em] right-[30px] bottom-[30px] `}
              >
                <PopupButton height="35px" width="35px" type="gray">
                  <img src={require('../../assets/images/bossDungeon/boss-btn-arrow.png')} className={``} alt="" />
                </PopupButton>
              </motion.div>

              {/* POPUP FOR ENEMY */}
              {showPopup && (
                <motion.div
                  key={currentEnemy}
                  initial={{ transform: 'translateY(150%)', opacity: '0,7' }}
                  animate={{ transform: 'translateY(0%)', opacity: '1' }}
                  exit={{ transform: 'translateY(100%)' }}
                  className={`absolute flex flex-col items-center bottom-0 w-[calc(100%-38px)] h-[70%] inset-x-[19px] bg-[#201b18] z-20`}
                >
                  {/* POPUP CORNER */}
                  <img
                    src={require('../../assets/images/cards-modal-border.png')}
                    className="absolute w-[17.5px] h-[21px] -top-[6px] -left-[5px]"
                    alt=""
                  />

                  {/* POPUP CLOSE BUTTON */}
                  <div
                    className={`absolute -right-6 -top-6 w-14 h-14 p-2 flex items-center justify-center`}
                    onClick={() => {
                      setShowPopup(false);
                    }}
                  >
                    <img src={require('../../assets/images/shop-modal-close.png')} className="w-9 h-9" alt="" />
                  </div>
                  <div
                    className={`flex flex-col justify-end items-center w-full h-[50%] border-solid border-2 border-[#18191b] shadow-[inset_0_0_1px_rgba(255,255,255,0.5)]`}
                  >
                    <div className={`absolute w-[60%] translate-y-[-70%] scale-125 top-0`}>
                      {/* BORDER FOR ENEMY */}
                      <img
                        className={`relative z-[10] top-[30px]`}
                        src={require('../../assets/images/bossDungeon/boss-dungeon-border-1.webp')}
                        alt=""
                      />

                      {/* ENEMY IMG AND BG */}
                      <div
                        className={`absolute w-full aspect-square top-[14px] bg-[#584d3a] scale-[0.7] z-[9]`}
                        style={{ clipPath: 'circle(50.0% at 50% 50%)' }}
                      >
                        <img
                          className={`w-full h-full`}
                          src={require(`../../assets/images/bossDungeon/boss-dungeon-enemy${currentEnemy}.webp`)}
                          alt=""
                        />
                      </div>

                      {/* BOSS START RATING */}
                      <div
                        className={`flex justify-center items-center -bottom-[35px] translate-y-[-100%] absolute w-full h-[35px] z-30`}
                      >
                        {Array(Number(parsedEnemies?.filter((e) => e.dungeonId === currentEnemy)[0].stars)).fill(
                          <Star />
                        )}
                      </div>
                    </div>
                    {/* ENEMY NAME AND BORDERS FOR IT */}
                    <div className=" relative w-[273px] h-[38px] text-center mb-[8px]">
                      <div className="absolute top-0  w-full h-[2px] bg-gradient-to-r from-[rgba(255,199,90,0.2)] via-[rgba(110,78,28,1)] to-[rgba(255,199,90,0.2)] "></div>
                      <div className="absolute bottom-0  w-full h-[2px] bg-gradient-to-r from-[rgba(255,199,90,0.2)] via-[rgba(110,78,28,1)] to-[rgba(255,199,90,0.2)] "></div>
                      <div
                        className={`text-[24px] bg-cover`}
                        style={{
                          background:
                            'linear-gradient(to right, rgba(111, 8, 34, 0), rgba(111, 8, 34, 0.5), rgba(111, 8, 34, 0.6), rgba(111, 8, 34, 0.5), rgba(111, 8, 34, 0))',
                        }}
                      >
                        {parsedEnemies?.filter((e) => e.dungeonId === currentEnemy)[0].title}
                      </div>
                    </div>
                    <p>{t('bossFight.nextRewards')}</p>
                    <div
                      className={`flex items-end relative w-[200px] min-w-[200px] bg-[#312c26] p-1 mb-[27px] rounded-md overflow-scroll gap-1 scale-[1.1] my-1 ml-2 z-[2]`}
                    >
                      {parsedRewards?.map((r: any) => (
                        <OfferResource
                          resourceType={r.resourceType}
                          resourceId={r.resourceId}
                          isEnabled={r.isEnabled}
                          amount={defineCountString(r.amount)}
                          layout={r.layout}
                        />
                      ))}
                    </div>
                  </div>

                  <div
                    className={`w-[calc(100%+2px)] h-[3px] bg-[#574b3d] border-solid border-[1px] border-[black]`}
                  ></div>

                  <div className={`flex flex-col w-full items-center h-[50%] bg-[#242520]`}>
                    {/* HERO LIST */}
                    <div
                      className={`flex justify-center items-center gap-[10px] w-[calc(100%-18px)] mb-[14px] inset-x-[9px] h-[92px] bg-[#221d19] rounded-[5px] border-solid border-2 border-[#18191b] shadow-[inset_0_0_1px_rgba(255,255,255,0.3)]`}
                    >
                      <HeroIcon isActive={true} heroId={Number(selectedHero.uid)} heroLevel={heroLevel} />
                      <HeroIcon isActive={false} heroId={1} />
                      <HeroIcon isActive={false} heroId={2} />
                    </div>
                    {fullStars < Number(parsedEnemies?.filter((e) => e.dungeonId === currentEnemy)[0].stars)! && (
                      <div className="flex items-center">
                        <p className={`flex items-center text-[red]`}>
                          {t('bossFight.levelRequired')}:{' '}
                          {Array(Number(parsedEnemies?.filter((e) => e.dungeonId === currentEnemy)[0].stars)).fill(
                            <span className="scale-[0.8]">
                              <Star />
                            </span>
                          )}{' '}
                        </p>
                        <PopupButton type="blue" onClick={handleGoToTiers}>
                          {t('btnStatus.go')}
                        </PopupButton>
                      </div>
                    )}

                    <div className="relative">
                      {inBossDungeon && (
                        <PopupButton
                          type={'red'}
                          size="medium"
                          width="140px"
                          onClick={() => {
                            handleRetry();
                          }}
                        >
                          {t('btnStatus.retreat')}
                        </PopupButton>
                      )}
                      <PopupButton
                        type={
                          Number(parsedEnemies?.filter((e) => e.dungeonId === currentEnemy)[0]?.stars) > fullStars
                            ? 'gray'
                            : inBossDungeon
                              ? 'green'
                              : 'blue'
                        }
                        size="medium"
                        width="140px"
                        className={`z-[10] ${Number(parsedEnemies?.filter((e) => e.dungeonId === currentEnemy)[0]?.stars) > fullStars ? 'pointer-events-none' : ''} `}
                        onClick={() => {
                          // @ts-ignore
                          getDungeonSaveOrStartDungeon({ clientId: userId, bossId: bossId + 1, heroId: heroId });
                        }}
                      >
                        {inBossDungeon ? t('btnStatus.continue') : t('btnStatus.play')}
                      </PopupButton>

                      {alertTooltip && currentDungeonId ? (
                        <div className="absolute top-[-100%] translate-x-[-15%] z-[50]">
                          <TutorialTooltip
                            onClose={handleCloseTooltip}
                            messageType="alert"
                            value={`You have saved progress in dungeon ${
                              currentDungeonId
                            }, entering Boss Dungeon will clear it.`}
                            type="bottom"
                            fontSize="12px"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </motion.div>
              )}

              {/* DARK BG FOR POPUP */}
              {showPopup && <div className={`absolute bottom-0 w-full h-full bg-[rgba(0,0,0,0.5)] z-[19]`}></div>}
            </div>
          </AnimatePresence>
        </PageTransition>
      </Sentry.ErrorBoundary>
    </>
  );
};
