import { CardType, DungeonStage, DungeonStagesResponse, StageType } from './mock';
import { RequestWebsocket } from '../interfaces/wsInterfaces';
import { generateRequestId } from '../utils/generateRequestId';
import { sendRequestAndGetResponse } from '../Websocket/websocketInit';

// export interface GetTutorialProgressResponse {
//   save: string;
// }

export interface CardsAtDeck {
  cardId: number;
  stars: number;
  cardUid: string;
}

export interface getDungeonProgressByDungeonIdResponse {
  stages: DungeonStagesResponse[];
  currentHp: number;
  currentMana: number;
  gold: number;
  cardsAtDeck: CardsAtDeck[];
  hp: number;
  currentDungeonId: number;
  usedLives: number;
  boughtLives: number;
  boosts: {
    additionalCards: number;
  };
}

export interface getDungeonProgressSafe {
  inDungeon: boolean;
  inBossDungeon: boolean;
  currentDungeonId: number;
}

export interface Stage {
  randomEventId: number;
  id: number;
  type: StageType;
  stageId: number;
  amount: number;
  currentAmount: number;
}
export interface useDungeonCardResponse {
  stages: Stage[];
  currentHp: number;
  currentMana: number;
  maxHp: number;
  gold: number;
  cardsAtDeck: CardsAtDeck[];
  randomEventId?: number;
  usedLives: number;
  boughtLives: number;
}
export interface responseForManyEndpoints {
  currentHp: number;
  currentMana: number;
  gold: number;
  cardsAtDeck: CardsAtDeck[];
}
export interface cancelDungeonCardResponse {
  stages: DungeonStagesResponse[];
  currentHp: number;
  hp: number;
  gold: number;
  mana: number;
  currentMana: number;
  cardsAtDeck: CardsAtDeck[];
  usedLives: number;
  boughtLives: number;
}

interface updateCardParams {
  clientId: string;
  cardId: number;
  lvl: number;
}

interface getShopItems {
  clientId: string;
  stageId: number; // stageId (id) from config dung
  dungeonId: number;
}

interface upgradeCardParams {
  clientId: string;
  cardUid: string;
  dungeonId: number;
  stageId: number; // stageId
}

interface getUpgradeCostParams {
  clientId: string;
  stageId: number; // stageId (id) from config dung
  dungeonId: number;
}

interface getShopItemsResponse {
  cards: cardsResponse[];
}

interface cardsResponse {
  cardId: number;
  stars: number;
  cost: number;
  cardType: CardType;
}

interface updateCardResponse {
  id: string;
  lvl: number;
  uid: string;
}

interface buyCard {
  cardId: number;
  clientId: string;
  stageId: number;
  withStars?: boolean;
}

interface resetProgressIF {
  clientId: string;
}

interface chestRewardsResponse {
  cardId: number;
  stars: number;
}

export enum EffectAction {
  modifyGold, // value = amount (+ and - supported)
  modifyHpInRange,
  modifyHp, // value = amount (+ and - supported)
  modifyMaxHp, // value = amount (+ and - supported)
  getCard,
  upgradeCards, // value = amount
  deleteCards, // value = amount
  modifyHpPercent, // value = percent in format 0.9 = 90% (+ and - supported)
  deleteRandomAtkCards, // value = amount
  modifyMaxHpPercent, // value = percent in format 0.9 = 90% (+ and - supported)
  heal, // No value needed
  nothing,
}

export interface GenericEffect {
  // Generic case
  value: { description: string; value: number };
  action: { description: string; value: EffectAction };
}

export interface HealEffect {
  action: EffectAction.heal; // Discriminant
}

export interface GetCardEffect {
  action: EffectAction.getCard; // Discriminant
  cardId: number;
  cardStars: number;
  amount: number;
}

export interface ModifyHpInRangeEffect {
  action: EffectAction.modifyHpInRange; // Discriminant
  min: number;
  max: number;
}

export type Effect = ModifyHpInRangeEffect | GetCardEffect | HealEffect | GenericEffect;

export interface Option {
  id: number;
  title: string;
  orEffects: {
    // Select 1 random group of effects
    groupId: number;
    effects: Effect[]; // And group, the all effects in this array are executed
  }[];
}

export interface RandomEvent {
  id: number;
  options: Option[];
}

interface getOverallStats {
  clientId: string;
}

export const getDungeonProgressByDungeonId = async (params: {
  clientId: string;
  buildingId?: number;
  bossId?: number;
  heroId: number;
}): Promise<getDungeonProgressByDungeonIdResponse> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO узнать у бека
    lang: 'en',
    method: 'lobbyEndpoints_getDungeonProgress',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('getDungeonProgressByDungeonId ERROR');
    }
    return result?.value as getDungeonProgressByDungeonIdResponse;
    // return {
    //   currentMana: 2,
    //   currentHp: 20,
    //   gold: 33,
    //   stages: [{cardId: 1, amount: 1, type: StageType.elixir, currentAmount: 0}, {cardId: 2, amount: 1, type: StageType.enemy, currentAmount: 0}, {cardId: 3, amount: 1, type: StageType.shop, currentAmount: 0}],
    // }
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as getDungeonProgressByDungeonIdResponse;
  }
};
export const getDungeonProgressSafe = async (params: { clientId: string }): Promise<getDungeonProgressSafe> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby',
    lang: 'en',
    method: 'lobbyEndpoints_getDungeonStateSafe',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('getDungeonProgressByDungeonId ERROR');
    }
    return result?.value as getDungeonProgressSafe;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as getDungeonProgressSafe;
  }
};

export const startDungeon = async (params: { clientId: string; dungeonId: number; heroId: number }): Promise<void> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO узнать у бека
    lang: 'en',
    method: 'lobbyEndpoints_startDungeon',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === 'Left') {
      throw new Error('startDungeon ERROR');
    }
    return result?.value as void;
    // return {
    //   currentMana: 2,
    //   currentHp: 20,
    //   gold: 33,
    //   stages: [{cardId: 1, amount: 1, type: StageType.elixir, currentAmount: 0}, {cardId: 2, amount: 1, type: StageType.enemy, currentAmount: 0}, {cardId: 3, amount: 1, type: StageType.shop, currentAmount: 0}],
    // }
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};

export const usingDungeonCard = async (params: {
  clientId: string;
  dungeonId: number;
  stageId: number;
}): Promise<useDungeonCardResponse> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO узнать у бека
    lang: 'en',
    method: 'lobbyEndpoints_useCard',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('useDungeonCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as useDungeonCardResponse;
  }
};

export const cancelDungeonCard = async (params: {
  clientId: string;
  dungeonId: number;
  stageId: number;
}): Promise<cancelDungeonCardResponse> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO узнать у бека
    lang: 'en',
    method: 'lobbyEndpoints_cancelCard',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('cancelDungeonCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as cancelDungeonCardResponse;
  }
};

export const updateCard = async (params: updateCardParams): Promise<updateCardResponse> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_updateCard',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('updateCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as updateCardResponse;
  }
};

export const getShopItems = async (params: getShopItems): Promise<getShopItemsResponse> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_getShopItems',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('updateCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as getShopItemsResponse;
  }
};

export const buyCard = async (params: buyCard): Promise<responseForManyEndpoints> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_buyCard',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('updateCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as responseForManyEndpoints;
  }
};

export const resetProgress = async (params: resetProgressIF): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_removeAllByClientId',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('reset progress ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as void;
  }
};

export const getUpgradePrice = async (params: getUpgradeCostParams): Promise<{ price: number }> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_getUpgradePrice',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === 'Left') {
      throw new Error('getUpgradePrice ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as { price: number };
  }
};

export const upgradeCard = async (params: upgradeCardParams): Promise<responseForManyEndpoints> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_upgradeCard',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === 'Left') {
      throw new Error('upgradeCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as responseForManyEndpoints;
  }
};

export const getRemovePrice = async (params: getUpgradeCostParams): Promise<{ price: number }> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_getRemovePrice',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === 'Left') {
      throw new Error('getRemovePrice ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as { price: number };
  }
};

export const removeCard = async (params: upgradeCardParams): Promise<responseForManyEndpoints> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_removeCard',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.type === 'Left') {
      throw new Error('removeCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as responseForManyEndpoints;
  }
};

export const getChestRewards = async (params: {
  clientId: string;
  stageId: number;
}): Promise<chestRewardsResponse[]> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_openChest',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('getChestRewards ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as chestRewardsResponse[];
  }
};
//getRewardCard

export const takeChestReward = async (params: buyCard): Promise<responseForManyEndpoints> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_takeChestReward',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('getRewardCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as responseForManyEndpoints;
  }
};

export const resetCurrentDungeon = async (params: {
  clientId: string;
  heroId: number;
}): Promise<responseForManyEndpoints> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_resetCurrentDungeon',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('resetCurrentDungeon ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as responseForManyEndpoints;
  }
};

export const getOverallStats = async (params: getOverallStats): Promise<responseForManyEndpoints> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'lobbyEndpoints_getOverallStats',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('updateCard ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as responseForManyEndpoints;
  }
};

export const buyLifeWithShare = async (params: { clientId: string }): Promise<Object> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby',
    lang: 'en',
    method: 'lobbyEndpoints_buyLifeWithShare',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('resetCurrentDungeon ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as responseForManyEndpoints;
  }
};

export const getRandomEvent = async (params: {
  clientId: string;
  stageId: number;
  optionId: number;
}): Promise<{ orGroupId: number; randomEventId: number }> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby',
    lang: 'en',
    method: 'randomEvent_getEvent',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('resetCurrentDungeon ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as { orGroupId: number; randomEventId: number };
  }
};

export const getExecuteEvent = async (params: {
  clientId: string;
  stageId: number;
  chooses: { value: number; action: number }[];
}): Promise<{}> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby',
    lang: 'en',
    method: 'randomEvent_executeEvent',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('resetCurrentDungeon ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as {};
  }
};

export const skipEvent = async (params: {
  clientId: string;
  stageId: number;
  dungeonId: number;
}): Promise<{
  stages: {
    id: number;
    stageId: number;
    amount: number;
    type: number;
    currentAmount: number;
  }[];
}> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby',
    lang: 'en',
    method: 'randomEvent_skipEvent',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('skipEvent ERROR');
    }
    return result.value;
  } catch (e) {
    console.error((e as Error).message);
    return { stages: [] };
  }
};
