export const ItemCard = ({ img, name, cardsAmount, onClick }: 
  { img: any; name: string, cardsAmount?: number, onClick?: () => void }) => {
  // Обчислюємо ширину прогрес бару в процентах

  return (
    <div className="relative w-14 min-w-14 h-14 bg-[url('./assets/images/background_btn.png')] bg-cover bg-center" onClick={onClick}>
      <div className="text-shadow-sm flex w-full h-full justify-center items-center">
          <img src={require('../../../../../assets/images/deck.webp')} alt="" className="w-[42px] h-[42px]"/>
      </div>
      <div className="text-center text-violet-100 text-sm font-bold leading-[14px] -m-2 text-stroke-small">
        {name}
      </div>
      <div className="absolute top-[2px] right-[6px] text-[#D7D7D7] text-sm font-light">
        {cardsAmount}
      </div>
    </div>
  );
};
