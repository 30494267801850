import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../../../../app/store';
import { PopupButton } from '../../../../../components/PopupButton';
import { PopupWindow } from '../../../../../components/PopupWindow';
import { FarmRoom } from '../../../../../interfaces/farm';
import { RoomStatus } from '../../../../../enums/buildingStatus';
import { completedBosses } from '../../../../../endpoints/bossEndpoint';
import { useTelegram } from '../../../../../hooks/useTelegram';
import { useEffect, useRef, useState } from 'react';
import { useUtils } from '../../../../../utils/navigateTo';
import { useSessionStorage } from '@uidotdev/usehooks';
import { setSelectedRoom } from '../../../../../app/features/selectedRoom';
import { DisplayData, handleLootBoxResult } from '../../../../../utils/lootBoxHandler';
import { OpenLootBox } from '../../../../Inventory/components';
import { OfferResource } from '../../../../../pages/Shop/components/OfferResources';
import { Resources } from '../../../../../enums/resources';
import { claimTaskReward, getMyTasks, ITasks } from '../../../../../endpoints/sbtEndpoints';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import { handleRewardResult } from '../../../../../utils/rewardParse';
import { defineCountString } from '../../../../../utils/farmConfigParser';
import { getBalance } from '../../../../../endpoints/farmMock';
import { useSoundService } from '../../../../../utils/soundService';
import { useTranslation } from 'react-i18next';

interface ISbtPopup {
  handleToggleSbtModal: () => void;
}

const SbtPopup = ({ handleToggleSbtModal }: ISbtPopup) => {
  const { userId } = useTelegram();
  const { navigateTo } = useUtils();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sbtModal, setSbtModal] = useSessionStorage<boolean>('sbtModal', false);
  const [startSelectHero, setStartSelectHero] = useSessionStorage('startSelectHero', false);
  const [currentBattleBuildingId, setCurrentBattleBuildingId] = useSessionStorage('currentBattleBuildingId', 0);
  const islandsFromRedux = store.getState().islands;
  const heroesList = useSelector((state: RootState) => state.heroes.heroesList);
  const selectedHero = heroesList.find((hero) => hero.id === 0);
  // const [completedBoss, setCompletedBoss] = useState(false);
  const [showRewardForTaskId, setShowRewardForTaskId] = useState<number | null>(null);
  const [tasks, setTasks] = useState<ITasks[] | null>(null);
  const [openToolTip, setOpenToolTip] = useState(false);
  const sbtTooltipRef = useRef<HTMLDivElement>(null);
  const { playSound } = useSoundService();
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);

  const items = [
    {
      id: 1,
      title: t('sbt.sbt1'),
      imageUrl: require('../../../../../assets/images/sbtModal/sbt-popup-modal-image1.webp'),
      btnType: 'hero',
    },
    {
      id: 2,
      title: t('sbt.sbt2'),
      imageUrl: require('../../../../../assets/images/sbtModal/sbt-popup-modal-image2.webp'),
      btnType: 'build',
    },
    {
      id: 3,
      title: t('sbt.sbt3'),
      imageUrl: require('../../../../../assets/images/sbtModal/sbt-popup-modal-image3.webp'),
      btnType: 'boss',
    },
    {
      id: 4,
      title: t('sbt.sbt4'),
      imageUrl: require('../../../../../assets/images/sbtModal/sbt-popup-modal-image4.webp'),
      btnType: 'deposit',
    },
  ];

  useEffect(() => {
    const getTasks = async () => {
      const response = await getMyTasks({ clientId: userId });

      setTasks(response);
    };
    getTasks();
  }, []);

  useEffect(() => {
    // console.log('reload');
  }, [tasks]);

  useEffect(() => {
    const handleCloseTooltip = (e: Event) => {
      if (!sbtTooltipRef.current || !sbtTooltipRef.current.contains(e.target as Node)) {
        setOpenToolTip(false);
      }
    };
    document.addEventListener('mousedown', handleCloseTooltip);
    document.addEventListener('wheel', handleCloseTooltip);
    document.addEventListener('touchmove', handleCloseTooltip);
    return () => {
      document.removeEventListener('mousedown', handleCloseTooltip);
      document.removeEventListener('wheel', handleCloseTooltip);
      document.removeEventListener('touchmove', handleCloseTooltip);
    };
  }, []);

  const handleHeroNavigation = (link: string) => {
    setSbtModal(false);
    if (selectedHero && selectedHero.level < 5) {
      navigateTo(link);
    }
  };

  const handleBuildNavigation = (link: string) => {
    setSbtModal(false);
    if (currentBattleBuildingId <= 4) {
      dispatch(setSelectedRoom({ id: currentBattleBuildingId }));
      setStartSelectHero(true);
    } else {
      navigateTo(link);
    }
  };

  const handleBossNavigation = (link: string) => {
    setSbtModal(false);
    if (currentBattleBuildingId <= 3) {
      dispatch(setSelectedRoom({ id: currentBattleBuildingId }));
      setStartSelectHero(true);
    } else {
      navigateTo(link);
    }
  };

  const handleDepositNavigation = () => {
    setSbtModal(false);
    setOpenedDepositModal(true);
  };

  const handleClaimRewards = async (taskId: number) => {
    await claimTaskReward({ clientId: userId, taskId });
    await getBalance({ clientId: `${userId}` });
    setShowRewardForTaskId(taskId);
    playSound('questsReward');

    setTasks((prevTasks) => (prevTasks ?? []).map((task) => (task.id === taskId ? { ...task, status: 2 } : task)));
  };

  const handleClaimSbt = async (taskId: number) => {
    switch (taskId) {
      case 1:
        window.location.href =
          'https://t.me/tbook_incentive_bot/tbook?startapp=WyIxIiwia2l0c3UiLCI2ODk3NDE5MTI0NTg4MTMiXQ';
        break;
      case 2:
        window.location.href =
          'https://t.me/tbook_incentive_bot/tbook?startapp=WyIxIiwia2l0c3UiLCI2ODk3NDc0MTI0NTg4MjMiXQ';
        break;
      case 3:
        window.location.href =
          'https://t.me/tbook_incentive_bot/tbook?startapp=WyIxIiwia2l0c3UiLCI2ODk3NTA3OTI0NTg4MzEiXQ';
        break;
      case 4:
        window.location.href =
          'https://t.me/tbook_incentive_bot/tbook?startapp=WyIxIiwia2l0c3UiLCI2OTY1ODc4ODI0ODYyMTQiXQ';
        break;
      default:
        break;
    }
  };

  const returnTasks = (tasks: ITasks[]) => {
    return items.map((task) => {
      const item = tasks.find((i) => i.id === task.id);

      const updatedTask = item
        ? {
            ...item,
            rewards:
              item.id === 3 || item.id === 4
                ? [...item.rewards, { rewardId: 8, rewardType: 2, amount: item.id === 3 ? 10 : 50 }]
                : item.rewards,
          }
        : null;

      return (
        <li
          key={task.id}
          className={`flex justify-between items-center h-[84px] bg-[#473D37] p-2 shadow-[0px_2px_0px_0px_#1D0206] rounded`}
          style={{
            background: task.id !== 1 ? 'linear-gradient(86deg, #F6DF77 -6.97%, #F5C411 46.66%, #F0A833 100%)' : '',
          }}
        >
          {updatedTask ? (
            <>
              {showRewardForTaskId === task.id ? (
                <div className="z-[9999]">
                  <OpenLootBox
                    rewards={updatedTask.rewards
                      .map(handleLootBoxResult)
                      .filter((reward): reward is DisplayData => reward !== null)}
                    openBoxName={'Reward'}
                    onClose={() => setShowRewardForTaskId(null)}
                  />
                </div>
              ) : null}
              <img src={task.imageUrl} alt="" className="w-[68px] h-[68px]" />
              <div className="flex flex-col w-full ml-2 h-full justify-between">
                <h4 className="text-stroke-small w-full text-start text-[16px] leading-[14px]">{task.title}</h4>
                <div className="flex justify-between items-end">
                  <ul className="flex gap-[1px] -ml-[2px]">
                    {updatedTask.rewards.map((reward, index) => (
                      <li key={index} style={{ transform: 'scale(0.9)' }}>
                        <OfferResource
                          resourceId={reward.rewardId}
                          resourceType={reward.rewardType}
                          amount={
                            reward.rewardId === 8 && reward.rewardType === 2
                              ? `+${reward.amount}%`
                              : defineCountString(reward.amount)
                          }
                          isEnabled={false}
                          layout="wide"
                        />
                      </li>
                    ))}
                  </ul>
                  <div className="w-full flex justify-end">{returnButtons(task.btnType, task.id)}</div>
                </div>
              </div>
            </>
          ) : null}
        </li>
      );
    });
  };

  const returnButtons = (type: string, taskId: number) => {
    const task = tasks?.[taskId - 1];
    const isCompleted = task?.status === 2;

    if (isCompleted) {
      return (
        <PopupButton
          type={'green'}
          className="w-full max-w-[103px]"
          classNameChild="!px-[2px] !py-[3px]"
          size="small"
          onClick={() => handleClaimSbt(taskId)}
        >
          <p>{t('sbt.claimSbt')}</p>
        </PopupButton>
      );
    }

    switch (type) {
      case 'hero':
        return !(tasks?.[0].status === 0) ? (
          <PopupButton
            type={'green'}
            classNameChild="!px-[0] !py-[3px]"
            size="small"
            onClick={() => handleClaimRewards(taskId)}
          >
            <p>{t('sbt.claimReward')}</p>
          </PopupButton>
        ) : (
          <PopupButton
            className="w-full max-w-[103px]"
            type={'blue'}
            classNameChild="!px-[5px] !py-[2px]"
            size="small"
            onClick={() => handleHeroNavigation('/heroes')}
          >
            <p className={'px-3'}>{t('other.go')}</p>
          </PopupButton>
        );

      case 'build':
        return !(tasks?.[1].status === 0) ? (
          <PopupButton
            type={'green'}
            classNameChild="!px-[0] !py-[3px]"
            size="small"
            onClick={() => handleClaimRewards(taskId)}
          >
            <p>{t('sbt.claimReward')}</p>
          </PopupButton>
        ) : (
          <PopupButton
            className="w-full max-w-[103px]"
            type={'blue'}
            classNameChild="!px-[5px] !py-[2px]"
            size="small"
            onClick={() => handleBuildNavigation('/island')}
          >
            <p className={'px-2'}>{t('other.go')}</p>
          </PopupButton>
        );

      case 'boss':
        return !(tasks?.[2].status === 0) ? (
          <PopupButton
            type={'green'}
            classNameChild="!px-[0] !py-[3px]"
            size="small"
            onClick={() => handleClaimRewards(taskId)}
          >
            <p>{t('sbt.claimReward')}</p>
          </PopupButton>
        ) : (
          <PopupButton
            className="w-full max-w-[103px]"
            type={'blue'}
            classNameChild="!px-[5px] !py-[2px]"
            size="small"
            onClick={() => handleBossNavigation('/boss')}
          >
            <p className={'px-2'}>{t('other.go')}</p>
          </PopupButton>
        );

      case 'deposit':
        return !(tasks?.[3].status === 0) ? (
          <PopupButton
            type={'green'}
            classNameChild="!px-[0] !py-[3px]"
            size="small"
            onClick={() => handleClaimRewards(taskId)}
          >
            <p>{t('sbt.claimReward')}</p>
          </PopupButton>
        ) : (
          <PopupButton
            className="w-full max-w-[103px]"
            type={'blue'}
            classNameChild="!px-[5px] !py-[2px]"
            size="small"
            onClick={handleDepositNavigation}
          >
            <p className={'px-2'}>{t('other.go')}</p>
          </PopupButton>
        );

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-[999] bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        initial={{ transform: 'translateY(200%)', opacity: '0' }}
        animate={{ transform: 'translateY(0%)', opacity: '1' }}
        exit={{ transform: 'translateY(100%)' }}
        transition={{ delay: 0.3 }}
        className={`w-full h-full flex flex-col items-center justify-center`}
      >
        <PopupWindow onClickClose={handleToggleSbtModal}>
          <div className="max-h-[70vh] w-[80vw] ">
            <div className="flex justify-end w-full h-14">
              <img
                src={require('../../../../../assets/images/sbtModal/sbt-icon-big.webp')}
                alt=""
                className="absolute w-[94px] h-[94px] -top-10 left-2 z-30"
              />
              <div className="mt-2 mr-5 flex items-center gap-1">
                <p className=" font-black text-[24px] text-[#FFEFCB] text-stroke-small">{t('sbt.title')}</p>
                <div
                  className="ml-1 inline-flex items-center justify-center w-6 h-6 rounded-full bg-[#4181b7] border border-[#043c6f]"
                  onClick={() => setOpenToolTip((prev) => !prev)}
                  ref={sbtTooltipRef}
                >
                  <span className="text-white text-md" data-tooltip-id="info-sbt">
                    i
                  </span>
                </div>
                <Tooltip
                  isOpen={openToolTip}
                  id={'info-sbt'}
                  place="bottom-end"
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    border: '1px solid #000000',
                    borderRadius: '8px',
                    maxWidth: '350',
                    padding: '8px',
                    wordWrap: 'break-word',
                    zIndex: 100,
                  }}
                  clickable={true}
                  opacity={1}
                >
                  <div className="flex flex-col gap-2" onClick={() => setOpenToolTip(false)}>
                    <h3 className="w-full text-center font-black ">SBT</h3>
                    <h4 className="w-full text-center text-black text-xs leading-3">
                      {t('sbt.tooltip1')}
                      <br />
                      {t('sbt.tooltip2')}
                    </h4>
                    <div className="flex items-center justify-between gap-1">
                      <div style={{ width: 60, height: 60 }} className="overflow-hidden rounded-[3px]">
                        <img
                          src={require('../../../../../assets/images/sbtModal/sbt-1.webp')}
                          style={{ transform: 'scale(1.4)' }}
                          className=""
                          alt=""
                        />
                      </div>
                      <div style={{ width: 60, height: 60 }} className="overflow-hidden rounded-[3px]">
                        <img
                          src={require('../../../../../assets/images/sbtModal/sbt-2.webp')}
                          style={{ transform: 'scale(1.4)' }}
                          className=""
                          alt=""
                        />
                      </div>
                      <div style={{ width: 60, height: 60 }} className="overflow-hidden rounded-[3px]">
                        <img
                          src={require('../../../../../assets/images/sbtModal/sbt-3.webp')}
                          style={{ transform: 'scale(1.4)' }}
                          className=""
                          alt=""
                        />
                      </div>
                      <div style={{ width: 60, height: 60 }} className="overflow-hidden rounded-[3px]">
                        <img
                          src={require('../../../../../assets/images/sbtModal/sbt-4.webp')}
                          style={{ transform: 'scale(1.4)' }}
                          className=""
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>
            </div>
            <ul className="flex flex-col flex-wrap w-full gap-[10px] mb-5 text-white pt-3">
              {tasks ? returnTasks(tasks) : null}
            </ul>
          </div>
        </PopupWindow>
      </motion.div>
    </div>
  );
};

export default SbtPopup;
