import React, { useEffect, useMemo, useState } from 'react';
import { Offer } from '../../pages/Shop/components';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import { OfferResource, OfferResourceProps } from '../../pages/Shop/components/OfferResources';
import { defineCountString } from '../../utils/farmConfigParser';
import { getIsSelected } from '../../pages/Shop';
import { ConfigOffer, OfferType } from '../../mock/offers';
import { OfferProps } from '../../pages/Shop/components/Offer';
import { AnimatePresence, motion } from 'framer-motion';
import { usePurchase } from '../../hooks/usePurchase';
import { getUserOffers } from '../../endpoints/getProfileInfo';
import { useTelegram } from '../../hooks/useTelegram';
import { SuccessOverlay } from '../SuccessOverlay';
import { useSessionStorage } from '@uidotdev/usehooks';
import { PopupButton } from '../PopupButton';
import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';

export const ValentinesDayOffer = ({ offer }: { offer: any }) => {
  // const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const [purchasedOfferIds, setPurchasedOfferIds] = useState<Set<number> | null>(null);
  const { userId } = useTelegram();
  console.log('offer', offer);
  useEffect(() => {
    const getUserOffersData = async () => {
      const userOffersData = await getUserOffers({
        clientId: userId,
      });

      if (Array.isArray(userOffersData)) {
        setPurchasedOfferIds(
          new Set(userOffersData.map((o: { offerId: number; offerType: number; quantity: number }) => o.offerId))
        );
      }
    };
    getUserOffersData();
  }, []);

  const [isValentinesDayOpenOffer, setIsValentinesDayOpenOffer] = useSessionStorage<boolean | null>(
    'showValentinesDayOffer',
    null
  );

  const handleClose = () => {
    setIsValentinesDayOpenOffer(false);

  };

  return (
    <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-[57]">
      <div className="absolute w-full h-full top-0 left-0 bg-black opacity-80" onClick={handleClose}></div>
      <motion.div
        initial={{ transform: 'translateY(200%)', opacity: '0' }}
        animate={{ transform: 'translateY(0%)', opacity: '1' }}
        exit={{ transform: 'translateY(100%)' }}
        transition={{ delay: 0.3 }}
        className={`min-w-[362px] min-h-[163px] relative flex items-center justify-end gap-6 bottom-4`}
        style={{
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="absolute z-10 p-2 -top-4 -right-4 flex items-center justify-center gap-2">
          <h2 className="text-[#FF8098] text-2xl text-stroke-small">Valentine's day!!!</h2>
          <button onClick={handleClose}>
            <img src={require('../../assets/images/shop-modal-close.png')} className="w-8 h-8" alt="" />
          </button>
        </div>
        <div className="z-20 flex flex-col gap-2 items-center relative top-4">
          <div
            className="flex items-center relative pt-2 scale-90"
            style={{
              background: 'radial-gradient(circle, rgba(250, 186, 60, 1) 20%, rgba(250, 186, 60, 0) 90%)',
            }}
          >
            {/* Верхня Рамка */}
            <div
              className="absolute top-[2px] left-0 w-full h-[2px] pointer-events-none"
              style={{
                background: 'radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)',
              }}
            ></div>

            {/* Нижня Рамка */}
            <div
              className="absolute bottom-[2px] left-0 w-full h-[2px] pointer-events-none"
              style={{
                background: 'radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)',
              }}
            ></div>
            <div className="flex gap-1 items-end mb-2">
              {offer.resourceList.map((r: any, index: any) => (
                <OfferResource
                  key={index}
                  resourceType={r.resourceType}
                  resourceId={r.resourceId}
                  isEnabled={r.isEnabled}
                  amount={r.amount}
                  layout={'wide'}
                />
              ))}
            </div>
            <p className="text-white text-2xl ml-2 text-stroke-small">+</p>
            <div
              className="w-[50px] h-[50px]"
              style={{ background: 'radial-gradient(circle, rgba(0, 0, 0, 1) 10%, rgba(255, 255, 255, 0) 70%)' }}
            >
              <img src={require(`../../assets/images/buildings/build1Valentine.png`)} alt="kitsu" />
            </div>
          </div>
          <PopupButton
            width="160px"
            size="small"
            type="gold"
            className="self-center"
            onClick={() => {
              confirmPurchase({
                offerId: offer.id,
              });
            }}
          >
            <div className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]">
              <div className="flex justify-center items-center gap-[2px]">
                <div className="text-white flex justify-center items-center gap-[3px]">
                  <img
                    className="inline w-4 h-4 mb-[2px]"
                    src={require('../../assets/images/telegram-stars-icon.png')}
                  />
                  {offer.price}
                </div>
              </div>
            </div>
          </PopupButton>
        </div>
        <img
          className="absolute left-0 bottom-[2px] z-10"
          src={require('../../assets/images/offers/valentinesDay/valentines-day-kitsu.png')}
          alt="kitsu"
        />
        <img
          className="absolute w-full h-[163px] bottom-0"
          src={require('../../assets/images/offers/valentinesDay/valentines-day-bg.png')}
          alt="valentines-day-bg"
        />
      </motion.div>

      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </div>
  );
};

export const ValentinesDayOfferNotification = () => {
  const [show, setShow] = useState(false);
  const [showKitsu, setShowKitsu] = useState(false);
  const [isValentinesDayOpenOffer, setIsValentinesDayOpenOffer] = useSessionStorage<boolean | null>(
    'showValentinesDayOffer',
    null
  );

  useEffect(() => {
    if (!isValentinesDayOpenOffer) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isValentinesDayOpenOffer]);

  // Rive
  const { rive, RiveComponent } = useRive({
    src: 'animation/valentinesKitsu.riv',
    autoplay: true,
    stateMachines: 'State Machine 1',
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
  });

  useEffect(() => {
    if (show) {
      const hideTimer = setTimeout(() => {
        setShow(false);
        setShowKitsu(false);
      }, 10000);

      return () => clearTimeout(hideTimer);
    }
  }, [show]);

  const handleExitComplete = () => {
    const showTimer = setTimeout(() => {
      setShow(true);
    }, 10000);

    return () => clearTimeout(showTimer);
  };

  // Framer Motion variants
  const questTextVariants = {
    hidden: { transform: 'translateY(-25%) translateX(-50%)', opacity: 0 },
    visible: { transform: 'translateY(-50%) translateX(-50%)', opacity: 1 },
    exit: { transform: 'translateY(-25%) translateX(-50%)', opacity: 0 },
  };

  const slideQuestVariants = {
    hidden: { width: '0', opacity: 0 },
    visible: {
      width: 'calc(100vw - 160px)',
      opacity: 1,
      maxWidth: 'calc(550px - 125px)',
    },
    exit: {
      width: '0',
      opacity: 0,
    },
  };

  return (
    <AnimatePresence onExitComplete={handleExitComplete}>
      {show && (
        <motion.div
          variants={slideQuestVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.3, delay: 0.5 }}
          onClick={() => {
            setIsValentinesDayOpenOffer(true);
          }}
          className="absolute h-[18px] top-[30%] translate-y-[-50%] left-20 z-[0]"
        >
          <img
            className="relative w-full h-full z-[1]"
            src={require('../../assets/images/offers/valentinesDay/valentines-day-notification.png')}
            alt=""
          />

          <motion.div
            variants={questTextVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onAnimationComplete={(definition) => {
              if (definition === 'visible') {
                setShowKitsu(true);
                console.log('Visible animation complete!');
              }
            }}
            transition={{ delay: 0.8 }}
            className="absolute top-[30%] translate-y-[-50%] left-[40%] translate-x-[-50%] w-full text-center text-nowrap z-[2]"
          >
            <h2 className="text-[#FF8098] text-2xl text-stroke-small">Valentine's day!</h2>
          </motion.div>

          <div className="absolute -top-[70px] -right-5 overflow-hidden w-[100px] h-[100px]">
            {showKitsu && (
              <motion.div
                initial={{ transform: 'translateY(25%)', opacity: 0 }}
                animate={{ transform: 'translateY(0)', opacity: 1 }}
                exit={{ transform: 'translateY(25%)', opacity: 0 }}
                transition={{ delay: 0.8 }}
                className="w-full h-full"
              >
                <RiveComponent />
              </motion.div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
