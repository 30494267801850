import { useTranslation } from 'react-i18next';
import { useUtils } from '../../utils/navigateTo';
import { getInfluencerRewards, getInfluencerStatus, getRewardsToClaim } from '../../endpoints/refEndpoints';
import { useTelegram } from '../../hooks/useTelegram';
import React from 'react';

export const FriendsButton = () => {
  const { t } = useTranslation();
  const { navigateTo } = useUtils();

  return (
    <div className="mr-2 select-none cursor-pointer w-14 h-10 pb-[2px] bg-[#452B31] border border-black rounded-lg shadow-lg  relative transform transition-transform duration-150 active:scale-95">
      <div
        className="w-full h-full rounded-lg flex justify-center items-center bg-gradient-to-b from-[#FEE7BA] to-[#B6765A] p-1 relative"
        onClick={() => navigateTo('/friends')}
      >
        <div className=" relative flex flex-col items-center w-7 h-7">
          <div className="relative w-full flex justify-center items-center overflow-visible">
            <img
              src={require('../../assets/images/friendsIcon.png?type=base64')}
              className="absolute -top-3 w-[48.57px] h-9 max-w-none"
            />
          </div>
          <div
            className="absolute -top-2.5 text-center text-white text-sm font-bold leading-3 mt-8"
            style={{
              filter:
                'drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)',
            }}
          >
            {t('friends.name')}
          </div>
        </div>
        <FriendsNotificationDot />
      </div>
    </div>
  );
};

const FriendsNotificationDot = () => {
  const { userId } = useTelegram();

  const [hasNotification, setHasNotification] = React.useState(false);

  const getReward = async () => {
    try {
      const data = await getRewardsToClaim({
        clientId: userId,
      });
      const userInfluencer = await getInfluencerStatus({
        clientId: userId,
      });
      let toClaimBalance;
      if (userInfluencer.isInfluencer) {
        const resultReward = await getInfluencerRewards({
          clientId: userId,
        });
        if (resultReward) {
          toClaimBalance = resultReward.toClaimBalance;
        }
      }

      const isReadyToClaim = data.premiumTasks.some((task: any) => task.isReadyToClaim && !task.isClaimed);

      if (
        isReadyToClaim ||
        data.regularReferralsAvailable ||
        data.revShare ||
        (userInfluencer.isInfluencer && toClaimBalance)
      ) {
        setHasNotification(true);
      } else {
        setHasNotification(false);
      }
    } catch (e) {}
  };

  React.useEffect(() => {
    getReward();
  }, [userId]);

  return hasNotification ? (
    <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full absolute -top-[6px] -right-[6px] z-50"></div>
  ) : null;
};
