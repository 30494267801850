import React, { useEffect, useState } from 'react';
import { useTelegram } from '../../../../hooks/useTelegram';
import { getMyTasks } from '../../../../endpoints/sbtEndpoints';

const SbtNotificationDot = () => {
  const { userId } = useTelegram();
  const [hasNotification, setHasNotification] = useState(false);

  const getReward = async () => {
    try {
      const data = await getMyTasks({ clientId: userId });

      const hasPendingTasks = data?.some((task: any) => {
        console.log('task.status', task.status);

        return task.status === 1;
      });

      // console.log('hasPendingTasks', hasPendingTasks);

      if (hasPendingTasks) {
        setHasNotification(true);
      } else {
        setHasNotification(false);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getReward();
  }, [userId]);

  return hasNotification ? (
    <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full absolute -top-[6px] -right-[6px] z-50"></div>
  ) : null;
};

export default SbtNotificationDot;
