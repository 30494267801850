import { useSelector } from 'react-redux';
import { RootState, store } from '../../../app/store';
import { RoomStatus } from '../../../enums/buildingStatus';
import { FooterSelectHero } from '../../../layout/components/FooterSelectHero';
import { RenderRoom } from './components/RenderRoom';
import { RoomProps } from './interface';
import { useRoom } from './useRoom';
import { memo, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useSessionStorage } from '@uidotdev/usehooks';
import { isStepLoaded } from '../../../app/features/loadingManagerSlice';
import { RoomTickAction } from './components/RoomTickAction';
import { RoomTimerAction } from './components/RoomTimerAction';
import { LevelUpBuilding } from '../../../components/LevelUpBuilding';
import { UpgradeDataRow } from '../../../components/PopupUpgradeTable';

export const Room = memo((props: RoomProps) => {
  const { scheme, room } = props;
  const {
    startSelectHero,
    onClick,
    building,
    isBattle,
    roomDisplay,
    triggerEndBuild,
    onClickStartDungeon,
    isDungeonStart,
  } = useRoom(props);

  const sessionKey = room?.buildingId ? `buildingLevelUp_${room?.buildingId}` : 'buildingLevelUp_default';
  const [triggerShowLevelUp, setTriggerShowLevelUp] = useSessionStorage(sessionKey, false);
  const [isValentineDayOfferBought, setIsValentineDayOfferBought] = useSessionStorage('isValentineDayOfferBought', false);


  console.log(`inside room ${room?.id}D`);

  const selectedRoom = useSelector((state: RootState) => state.selectedRoom);

  const variants = {
    hidden: { opacity: 0, y: 50 }, // Початковий стан: прозорість 0, зміщення вниз
    visible: { opacity: 1, y: 0 }, // Кінцевий стан: прозорість 1, без зміщення
    exit: { opacity: 0, y: 0 }, // Вихідний стан: прозорість 0, зміщення вниз
  };

  const [isVisible, setIsVisible] = useState(false);

  const [isBuilderBusy, setIsBuilderBusy] = useSessionStorage<any>('isBuilderBusy', false);

  useEffect(() => {
    if (isBattle() === RoomStatus.building) {
      setIsBuilderBusy(true);
    }
  }, [isBuilderBusy, isBattle() === RoomStatus.building]);

  const [currentBattleBuildingId, setCurrentBattleBuildingId] = useSessionStorage('currentBattleBuildingId', 0);
  useEffect(() => {
    if (room?.status === RoomStatus.battle) {
      setCurrentBattleBuildingId(room.buildingId!);
    }
  }, [currentBattleBuildingId, room?.status === RoomStatus.battle]);

  const backgroundLoaded = useSelector((state: RootState) => isStepLoaded(state, 'background'));

  const oldData = building?.lvlInfo.find((v) => v.lvl === room?.lvl! - 1);
  const upgradeData = building?.lvlInfo.find((v) => v.lvl === room?.lvl!);
  

  const combinedData: UpgradeDataRow[] =
    upgradeData?.upgradeData?.map((upgradeItem) => {
      const oldItem = oldData?.upgradeData?.find((item) => item.title === upgradeItem.title);

      return {
        title: upgradeItem.title,
        nowValue: oldItem ? oldItem.value : 0, // Якщо oldData немає або параметра немає, використовуємо 0
        newValue: upgradeItem.value, // Завжди беремо нове значення з upgradeData
      };
    }) || [];

  return (
    <>
      {backgroundLoaded && (
        <>
          {(room?.status === RoomStatus.farming || room?.status === RoomStatus.builded) && (
            <RoomTickAction roomDisplay={roomDisplay} room={room} scheme={scheme} building={building} />
          )}
          {(room?.status === RoomStatus.building || room?.status === RoomStatus.repairing) && (
            <RoomTimerAction roomDisplay={roomDisplay} room={room} scheme={scheme} building={building} />
          )}

          {triggerShowLevelUp && (
            <div className="fixed inset-0 z-[999]">
              <div className="w-full h-full absolute bg-gradient-to-b from-black via-black/70 to-black opacity-80"></div>

              <LevelUpBuilding
                roomLvl={room?.lvl}
                text={building?.title || ''}
                upgradeData={combinedData}
                buildingId={building?.id}
              />
            </div>
          )}
          <button
            key={isValentineDayOfferBought ? `room_${room?.id}_Valentine` : `room_${room?.id}`}
            className={`overflow-visible w-full h-full flex items-center justify-center relative`}
            style={{
              position: 'absolute',
              top: roomDisplay.y,
              left: roomDisplay.x,
              width: roomDisplay.width,
              maxHeight: roomDisplay.height,
              height: roomDisplay.height,
            }}
            onClick={onClick}
          >
            <RenderRoom status={isBattle()} building={building} triggerEndBuild={triggerEndBuild} />
          </button>

          <AnimatePresence>
            {isVisible && (
              <motion.div
                className="absolute -top-7 p-2 overflow-hidden flex-shrink-0 z-[999] bg-red-700"
                style={{
                  background: 'radial-gradient(circle, rgb(167 0 0 / 80%) 50%, rgba(250, 186, 60, 0) 100%)',
                }}
                variants={variants}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.2 }}
              >
                <div
                  className="absolute top-0 left-0 w-full h-[2px] pointer-events-none"
                  style={{
                    background: 'radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)',
                  }}
                ></div>

                <div
                  className="absolute bottom-0 left-0 w-full h-[2px] pointer-events-none"
                  style={{
                    background: 'radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)',
                  }}
                ></div>

                <div>
                  <p className="text-white">Builders are busy</p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
      {(startSelectHero || isDungeonStart) && selectedRoom.id === room?.id && (
        <FooterSelectHero id={'room_' + room?.id} onClickBattle={onClickStartDungeon} />
      )}
    </>
  );
});
