import React, { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

interface BattleIdleAnimationProps {
  draggableCard: boolean;
  isTutorStageBattle: boolean;
  hasManyCards: boolean;
  cardAvailable?: boolean;
}

export const BattleIdleAnimation = ({
  children,
  draggableCard,
  isTutorStageBattle,
  hasManyCards,
  cardAvailable,
}: PropsWithChildren<BattleIdleAnimationProps>) => {
  const [isTapping, setIsTapping] = React.useState(false);
  const [showAnimation, setShowAnimation] = React.useState(false);

  React.useEffect(() => {
    setShowAnimation(false);
    setIsTapping(false);
    const animationTimer = setTimeout(
      () => {
        if(cardAvailable){
          setShowAnimation(true);
        }
      },
      isTutorStageBattle ? 1500 : 7000
    );

    return () => clearTimeout(animationTimer);
  }, [draggableCard, isTutorStageBattle, cardAvailable]);

  const fingerIcon = require('../../../assets/images/tutorFinger.png?type=base64');
  const fingerIcon2 = require('../../../assets/images/tutorFinger2.png?type=base64');

  return (
    <motion.div
      className={`absolute !z-[9999] ${!draggableCard && showAnimation ? '' : 'hidden'}`}
      initial={{ top: 0, opacity: 1 }}
      animate={
        !showAnimation
          ? { top: 0, opacity: 1 } // anim refresh
          : { top: [0, 0, '-100%'], opacity: 1 } // main anim
      }
      exit={{ top: 0, opacity: 0 }}
      transition={{
        duration: 1.5,
        times: [0, 0.5, 1.5],
        ease: 'easeInOut',
        repeat: Infinity,
        repeatDelay: 1.5,
      }}
      onUpdate={(latest) => {
        if (latest.top === '-100%') {
          setIsTapping(false);
        }
      }}
    >
      <div className="relative">
        {/* Card */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={
            !showAnimation
              ? { opacity: 0 } // anim refresh
              : { opacity: [0, 0.8, 0, 0] } // main anim
          }
          transition={{
            duration: 2,
            times: [0, 1.3, 1.4, 1.5],
            repeat: Infinity,
            repeatDelay: 1,
          }}
        >
          {children}
        </motion.div>

        {/* Finger tapped*/}
        <motion.img
          initial={{ opacity: 0 }}
          animate={
            !showAnimation
              ? { opacity: 0 } // anim refresh
              : { opacity: [0, 0, 1, 1, 0, 0, 0] } // main anim
          }
          transition={{
            duration: 2,
            times: [0, 0.1, 0.2, 1.2, 1.3, 1.4, 1.5],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          onUpdate={(latest) => {
            if (latest.opacity === 1) {
              setIsTapping(true);
            }
          }}
          className={`absolute z-50 bottom-[15%] ${hasManyCards ? 'left-0' : '-right-[25%]'}`}
          src={fingerIcon2}
          alt="tapping finger"
        />
        {/* Finger not tapped*/}
        <motion.img
          initial={{ opacity: 0 }}
          animate={
            !showAnimation
              ? { opacity: 0 } // anim refresh
              : { opacity: [0, 1, 0, 0, 1, 1, 0] } // main anim
          }
          transition={{
            duration: 2,
            times: [0, 0.1, 0.2, 1.2, 1.3, 1.4, 1.5],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          onUpdate={(latest) => {
            if (latest.opacity === 1) {
              setIsTapping(true);
            }
          }}
          className={`absolute z-50 bottom-[15%] ${hasManyCards ? 'left-0' : '-right-[25%]'}`}
          src={fingerIcon}
          alt="tapping finger"
        />
      </div>
    </motion.div>
  );
};
