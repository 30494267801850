import { BrowserRouter } from 'react-router-dom';
import { openWebSocket } from '../../Websocket/websocketInit';
import React, { useEffect } from 'react';
import { AppDispatch, RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatedRoutes } from './components';
import { useSoundService } from '../../utils/soundService';
import { fetchAppConfig } from '../../app/features/appConfigSlice';
import { useTelegram } from '../../hooks/useTelegram';
import { TimerComponent } from '../../components';
import { useTranslation } from 'react-i18next';

export const Router = () => {
  const dispatch = useDispatch<AppDispatch>();
  const ws = useSelector((state: RootState) => state.webSocket?.webSocket);
  const webSocketStatus = useSelector((state: RootState) => state.webSocket.status);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { isActive } = useTelegram();
  const { t } = useTranslation();
  const maintenance = sessionStorage.getItem('maintenance') || 'false';

  useEffect(() => {
    if (!['connected', 'connecting'].includes(webSocketStatus)) {
      //dispatch(openWebSocket());
    }
  }, [dispatch, webSocketStatus]);

  useEffect(() => {}, [ws]);

  useEffect(() => {
    if (ws?.readyState === 1 && !Object.keys(appConfig).length) {
      dispatch(fetchAppConfig());
    }
  }, [dispatch, ws, appConfig]);

  useEffect(() => {
    const serverStatus = appConfig?.settings?.variables?.[0]?.serverStatus?.value;

    if (serverStatus !== undefined) {
      sessionStorage.setItem('maintenance', String(!serverStatus));
    }
  }, [appConfig]);

  if (maintenance === 'true') {
    return (
      <div className="absolute w-full h-full flex flex-col justify-center items-center text-white text-5xl bg-[#1f1c1a] z-[9999]">
        <img src={require("../../assets/images/maintenance.webp")} className="p-3" alt="" />
        <div className="flex items-center justify-center space-x-2 mt-5 mb-2">
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce"></div>
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce delay-200"></div>
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce delay-400"></div>
        </div>
        <div className="px-3">
          <div className="text-center text-[#ffefcb] text-2xl font-black leading-normal mb-2">
            {t('maintenance.title')}
          </div>
          <div className="text-center text-[#db9328] text-[16px] font-black leading-normal mt-5" dangerouslySetInnerHTML={{ __html: t("maintenance.description") }}></div>
        </div>
      </div>
    );
  }

  return <BrowserRouter>{ws?.readyState && Object.keys(appConfig).length && <AnimatedRoutes />}</BrowserRouter>;
};
