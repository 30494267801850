import React, { useEffect, useRef, useState } from 'react';
import { PopupWindow } from '../../../../../components/PopupWindow';
import { PopupButton } from '../../../../../components/PopupButton';
import { motion } from 'framer-motion';
import { RECIPIENT_WALLET, useJettonContract } from '../../../../../hooks/ton/useJettonContract';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { Resources } from '../../../../../enums/resources';
import { useTelegram } from '../../../../../hooks/useTelegram';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { resourcesMap } from '../../../../../utils/mapping';
import { ClaimCodePopup, UseCodeToPromotePopup } from '../PurchasePopup/components';
import { useSessionStorage } from '@uidotdev/usehooks';
import { HandleBackButton } from '../../../../../layout/components/HeaderCave/components';
import { claimCodesByClientId, getCodesByClientId, ReferralCodeType } from '../../../../../endpoints/codesEndpoints';
import { getBalance } from '../../../../../endpoints/farmMock';
import { useTranslation } from 'react-i18next';
import { TransactionStatus, useTonConnect } from '../../../../../hooks/ton/useTonConnect';
import { defineCountString } from '../../../../../utils/farmConfigParser';

export interface ClaimOptions {
  id: number;
  amount: number;
  label: string;
  type: ReferralCodeType;
}
export const DepositPopup = ({ onClick, address }: { onClick: () => void; address: string }) => {
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);
  window.location.hash = 'input';
  const [shouldHideElement, setShouldHideElement] = useSessionStorage<boolean>('shouldHideElementKey', false);
  const [isRefCodeAccess, setIsRefCodeAccess] = useSessionStorage('isRefCodeAccess', false);
  const depositPopupVariants = {
    hidden: { opacity: '0', transform: 'translateY(110%)' },
    visible: { opacity: '1', transform: 'translateY(-50%)' },
  };
  const [tonConnectUI] = useTonConnectUI();
  const { userId } = useTelegram();
  const [amount, setAmount] = useState<string>('50000');
  const [referralTooltip, setReferralTooltip] = useState<boolean>(false);
  const [codeTooltip, setCodeTooltip] = useState<number | null>(null);
  const { balance, transferJettons, transactionStatus } = useJettonContract();
  const resources = useSelector((state: RootState) => state.resources.resources);

  const claimOptions: ClaimOptions[] = [
    { id: 1, amount: 50000, label: '50k', type: ReferralCodeType.common },
    { id: 2, amount: 200000, label: '200k', type: ReferralCodeType.rare },
    { id: 3, amount: 800000, label: '800k', type: ReferralCodeType.epic },
  ];

  const [claim, setClaim] = useState(false);
  const [refBalance, setRefBalance] = useState(0);
  const [depositPendingModal, setDepositPendingModal] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const currentValue = resources.find((v) => v.resourceType === Resources.refPoints)?.value || 0;
    setRefBalance(currentValue);
  }, [resources]);
  const [userCodes, setUserCodes] = useSessionStorage('userCodes', []);

  const getUserCodes = async () => {
    const data = await getCodesByClientId({ clientId: userId });
    if (data) {
      setUserCodes(data);
    }
  };

  useEffect(() => {
    getUserCodes();
  }, []);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(',', '.');
    // Дозволяємо лише числа та десяткові крапки
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(String(isNaN(Math.min(parseFloat(value), 10000000)) ? 0 : Math.min(parseFloat(value), 10000000)));
    }
  };

  const [claimOffer, setClaimOffer] = useState<ClaimOptions | null>(null);

  const isNotEmpty = claimOffer && Object.keys(claimOffer).length > 0;

  const handleClaimCodes = async (type: ReferralCodeType, amount: number) => {
    const data = await claimCodesByClientId({
      clientId: userId,
      type,
      amount,
    });

    if (data) {
      await getBalance({ clientId: userId });
      getUserCodes();
      setClaimOffer(null);
    }
  };

  const handleDeposit = async () => {
    await transferJettons(amount, RECIPIENT_WALLET, userId);
  };

  useEffect(() => {
    if (transactionStatus === TransactionStatus.pending) {
      setDepositPendingModal(true);
    }
  }, [transactionStatus]);

  const handleCloseModal = () => {
    if (!isRefCodeAccess) {
      setShouldHideElement(false);
      setTimeout(() => {
        window.location.hash = '';
      }, 1000);
    }
    setOpenedDepositModal(false);
  };

  return (
    <>
      <motion.div
        variants={depositPopupVariants}
        initial="hidden"
        animate="visible"
        className="absolute w-[97%]
        min-h-[500px] top-[50%] translate-y-[-50%]  left-[1.5%] flex justify-center align-center"
      >
        <div className="absolute left-1">
          <HandleBackButton onClick={handleCloseModal} />
        </div>
        <div className="flex flex-col">
          <div className="text-center text-white text-[22px] font-black leading-[1.2] mb-2 px-10">
            {t('depositModal.depositKitsu')}
            <br /> {t('depositModal.activation')}{' '}
            <span
              className="text-[#6CB7F4] border-b-[2px] border-dashed border-[#6CB7F4]"
              onClick={() => setReferralTooltip(!referralTooltip)}
            >
              {t('depositModal.referral')}
            </span>{' '}
            {t('depositModal.code')}
            <div
              className="absolute w-[166px] text-center text-[10px] font-bold bg-white border border-[#45362e]
              rounded-[4px] leading-[1.2] p-2 mx-auto text-black left-1/2 -translate-x-1/2"
              style={{
                opacity: referralTooltip ? '1' : '0',
                display: referralTooltip ? 'block' : 'none',
              }}
              onClick={() => setReferralTooltip(false)}
            >
              <div
                className="absolute border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent
              border-r-transparent border-b-[#45362e] bottom-full right-[30%]"
              ></div>
              <div
                className="absolute border-l-[6px] border-r-[6px] border-b-[6px] border-l-transparent
              border-r-transparent border-b-white bottom-full right-[30%] -mb-[1px]"
              ></div>
              {t('codePurchase.tooltipReferral')}
            </div>
            <button className="absolute z-10 top-0 right-0 flex items-center justify-center" onClick={handleCloseModal}>
              <img src={require('../../../../../assets/images/shop-modal-close.png')} className="w-8 h-8" alt="" />
            </button>
          </div>
          <div className={`flex flex-col justify-center items-center`}>
            <div className="flex justify-between items-center gap-4 w-full px-3 py-2">
              <div className="text-center text-white text-[17px] font-bold leading-[17px]">
                {t('codePurchase.buyOn')}:
              </div>
              <div className="flex justify-center items-center gap-2">
                <div className="flex justify-center items-center gap-1">
                  <div className="w-9 h-9">
                    <img className="w-full h-full" src={require('../../../../../assets/images/deposit-stonefi.png')} />
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-center text-[#9adbff] text-sm font-black underline leading-[14px]"
                    href="https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&ft=TON&tt=EQAmLDfD9kc5nrqCfrCRQ1pK3KL6wB7Ya4Nud5HjNbwnbiWF"
                  >
                    Ston.fi
                  </a>
                </div>
                <div className="flex justify-center items-center gap-1">
                  <div className="w-9 h-9">
                    <img
                      className="w-full h-full"
                      alt=""
                      src={require('../../../../../assets/images/deposit-blume.png')}
                    />
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-center text-[#9adbff] text-sm font-black underline leading-[14px]"
                    href="https://t.me/blum/app?startapp=memepadjetton_KITSU_EoiWk-ref_tcJoW1eTcS"
                  >
                    Blum
                  </a>
                </div>
              </div>
            </div>

            <div className={`relative flex flex-col gap-1 w-[calc(100%-20px)]  mb-1`}>
              <div
                className={`flex flex-col items-end  bg-[#463d36] p-[14px] rounded-md 
                shadow-inner-sm-white border border-black`}
              >
                {address ? (
                  <div className="flex justify-between items-center gap-1 w-full">
                    <div className="flex justify-center items-center gap-1">
                      <p className="text-white text-[12px] text-left">
                        {t('codePurchase.walletBalance')}: {Math.floor(Number(balance))}
                      </p>
                      <div className="text-[#f6a000] text-[12px] text-left" onClick={() => tonConnectUI.disconnect()}>
                        {t('depositModal.disconnect')}
                      </div>
                    </div>

                    <div
                      className="text-[#f6a000] "
                      onClick={() => {
                        balance ? setAmount(balance) : setAmount('0');
                      }}
                    >
                      {t('depositModal.max')}
                    </div>
                  </div>
                ) : (
                  <p
                    className={`text-[#f5a000] text-stroke-small leading-none`}
                    onClick={() => tonConnectUI.openModal()}
                  >
                    {t('profile.connect')}
                  </p>
                )}
                <div className={`flex w-full justify-between gap-2`}>
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="w-[30px] h-[30px]"
                      alt="KITSU Icon"
                      src={require('../../../../../assets/images/kitsu-icon.webp')}
                    />
                    <span className="text-[24px] text-white text-stroke-small font-[700] ml-2">
                      KITSU <span className="text-[#b6ad8a]">›</span>
                    </span>
                  </div>
                  <div className="flex items-center flex-grow sm:flex-grow-0">
                    <input
                      type="text"
                      inputMode="decimal"
                      value={amount}
                      onChange={handleAmountChange}
                      placeholder={`${t('codePurchase.enterValue')}`}
                      className="w-full sm:w-40 max-w-xs pl-2 py-1 bg-[#463d36] text-white text-right text-[24px]
                      font-normal text-stroke-small rounded-md focus:outline-none focus:ring-2 focus:ring-[#f6a000]
                      placeholder:text-[#b6ad8a]"
                    />
                  </div>
                </div>
              </div>

              <div
                className="absolute top-[50%] translate-y-[-50%] w-[32px] h-[32px] rounded-full bg-[#a8704f]
                p-2 right-[24px]"
              >
                <img src={require('../../../../../assets/images/deposit-convert-arrow.png')} alt="" />
              </div>

              <div
                className="flex flex-col items-end  bg-[#463d36] p-[14px] rounded-md shadow-inner-sm-white
                border border-black"
              >
                <p className="text-white text-[12px] text-left w-full">
                  {t('codePurchase.gameBalance')}: {resources[Resources.kitsu]?.value}
                </p>
                <div className={`flex w-full justify-between `}>
                  <div className="flex flex-shrink-0 items-center ">
                    <img
                      className={`w-[30px] h-[30px] `}
                      src={require('../../../../../assets/images/kitsu-icon.webp')}
                      alt=""
                    />
                    <span className="text-[24px] text-white text-stroke-small font-[700] ml-2">
                      KITSU <span className="text-[#b6ad8a]">›</span>
                    </span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-white text-stroke-small text-[24px] font-[400]">{Math.floor(Number(amount))}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-white text-[12px] text-center mb-2">{t('depositModal.tip')}</div>

            <div className={`pb-5 ${amount && amount.length !== 0 ? '' : 'pointer-events-none'}`}>
              <PopupButton
                size="medium"
                type={amount && amount.length !== 0 && +amount > 0 ? 'blue' : 'gray'}
                onClick={address ? () => handleDeposit() : () => tonConnectUI.openModal()}
                disabled={+amount > 0 ? false : true}
              >
                <div
                  className={`absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[60%] -translate-y-[0%] `}
                >
                  <div
                    className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                  >
                    <img
                      src={require('../../../../../assets/images/shop/offer/notify.png')}
                      className={`w-[3em] aspect-square -translate-y-[5%] `}
                      alt=""
                    />
                    {Math.floor(Number(amount) / 5000)}
                  </div>
                </div>
                <div className="px-5">{t('codePurchase.deposit')}</div>
              </PopupButton>
            </div>
            <div className="relative z-[2] w-full">
              <div
                className="absolute w-[224px] text-center text-[10px] font-bold bg-white border left-1/2 mb-2
                -translate-x-1/2 border-[#45362e] rounded-[4px] leading-[1.2] p-2 bottom-full"
                style={{
                  opacity: codeTooltip ? '1' : '0',
                  display: codeTooltip ? 'block' : 'none',
                }}
                onClick={() => setCodeTooltip(null)}
              >
                <div
                  className={`absolute border-l-[6px] border-r-[6px] border-t-[6px] border-l-transparent
                  border-r-transparent border-t-[#45362e] top-full ${codeTooltip === 1 && 'left-[8%]'} 
                  ${codeTooltip === 2 && 'left-[50%]'} ${codeTooltip === 3 && 'left-[92%]'}`}
                ></div>
                <div
                  className={`absolute border-l-[6px] border-r-[6px] border-t-[6px] border-l-transparent
                  border-r-transparent border-t-white top-full -mt-[1px] ${codeTooltip === 1 && 'left-[8%]'} 
                  ${codeTooltip === 2 && 'left-[50%]'} ${codeTooltip === 3 && 'left-[92%]'}`}
                ></div>
                {t('codePurchase.tooltipCode', {
                  count: codeTooltip === 1 ? 10 : codeTooltip === 2 ? 50 : codeTooltip === 3 ? 200 : undefined,
                })}
              </div>
            </div>
            <div className="flex mb-2">
              {claimOptions
                .sort((a, b) => a.type - b.type)
                .map((claimOption, index) => {
                  const canClaim = refBalance >= claimOption.amount;

                  return (
                    <div key={claimOption.id} className="flex justify-center items-start px-[7px]">
                      <div className="flex flex-col gap-1">
                        <div
                          className="relative flex flex-col gap-1 bg-[#18191a]/50 rounded-[6px] border
                          border-[#847A70] backdrop-blur-[5px] p-2.5 mb-1"
                          onClick={() => {
                            setCodeTooltip(codeTooltip === index + 1 ? null : index + 1);
                          }}
                        >
                          <div
                            className={`relative w-[70px] h-[70px] min-w-[70px] flex flex-col gap-1 border 
                            border-white bg-gradient-to-b rounded-sm mx-auto ${
                              claimOption.type === 0
                                ? 'from-[#33a8cc] to-[#0d81b6]'
                                : claimOption.type === 1
                                  ? 'from-[#d95bd8] to-[#9245df]'
                                  : claimOption.type === 2
                                    ? 'from-[#fffe2e] to-[#fe8315]'
                                    : ''
                            }
                        `}
                          >
                            <div className="flex justify-center items-center w-full">
                              <div className="w-full">
                                <img
                                  className="w-full h-full"
                                  src={require('../../../../../assets/images/ticket-icon.png')}
                                  alt="Ticket Icon"
                                />
                              </div>
                            </div>

                            <div
                              className="absolute right-1 bottom-1 text-right text-white text-[17px] font-bold
                              leading-3 text-shadow-black-sm"
                            >
                              {claimOption.type === 0
                                ? '5'
                                : claimOption.type === 1
                                  ? '25'
                                  : claimOption.type === 2
                                    ? '100'
                                    : ''}
                            </div>
                          </div>

                          <div className="flex justify-center items-center gap-1 pt-1.5">
                            <div className="w-4 h-4">
                              <img src={resourcesMap[Resources.kitsu].image} alt="Resource" />
                            </div>

                            <div className="text-white text-[16px] font-black leading-none">{claimOption.label}</div>
                          </div>
                        </div>
                        {canClaim && (
                          <div className="flex justify-center items-center relative">
                            <PopupButton type="green" size="small" onClick={() => setClaimOffer(claimOption)}>
                              {t('rewards.claim')}
                              <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full absolute -top-1 -right-1"></div>
                            </PopupButton>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {isRefCodeAccess ? (
              <div className="flex justify-center">
                {/*<PopupButton type="gold" size="medium" className="mx-2" onClick={() => alert("coming soon")}>*/}
                {/*  <div className="px-3 text-[18px]">{t("codePurchase.activate")}</div>*/}
                {/*</PopupButton>*/}
                <PopupButton
                  type="blue"
                  size="medium"
                  className="mx-2"
                  onClick={() => {
                    setOpenedDepositModal(false);
                    setShouldHideElement(false);
                  }}
                >
                  <div className="px-3 text-[18px]">{t('codePurchase.myCodes')}</div>
                  <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full absolute top-0 right-0"></div>
                </PopupButton>
              </div>
            ) : null}
          </div>
        </div>
      </motion.div>
      {depositPendingModal ? (
        <div
          className="fixed inset-0 z-[100] w-full h-full flex justify-center items-center min-w-full max-w-full
          min-h-full max-h-full"
        >
          <div
            onClick={() => setDepositPendingModal(false)}
            className="absolute z-40 h-full w-full bg-[rgba(0,0,0,0.8)]"
          />
          <PopupWindow onClickClose={() => setDepositPendingModal(false)}>
            <div className="flex flex-col justify-center items-center p-4 gap-4">
              <div className="text-center py-10 px-2 text-[#FFEFCB] text-[22px] leading-[1.2]">
                {t('codePurchase.depositPending')}
              </div>
            </div>
          </PopupWindow>
        </div>
      ) : null}

      {isNotEmpty ? (
        <>
          <div onClick={() => {}} className="absolute z-[2] h-full w-full bg-[rgba(0,0,0,0.8)]" />
          <ClaimCodePopup
            handleClaimCodes={handleClaimCodes}
            claimOffer={claimOffer}
            onClose={() => {
              setClaimOffer(null);
            }}
          />
        </>
      ) : null}
    </>
  );
};
