import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Card } from '../../components/Card';
import { Artefacts, CardType, FullArtefacts, ICard } from '../../endpoints/mock';
import { CardProps } from '../../interfaces/card';
import * as Sentry from '@sentry/react';
import { ErrorType, RuntimeErrorData } from '../../interfaces/error';
import { ErrorReportingService } from '../../services/errorReportingService';
import { ArtefactCard, ErrorComponent } from '../../components';
import { useTelegram } from '../../hooks/useTelegram';
import { HandleBackButton } from '../../layout/components/HeaderCave/components/HandleBackButton';
import { mapToFullArtefacts } from '../../utils/artefactsParser';

const AllCardsPage: React.FC = () => {
  const cards = useSelector((state: RootState) => state.appConfig.cards);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const [loadedCards, setLoadedCards] = useState<ICard[]>([]);
  const [zoomedCard, setZoomedCard] = useState<CardProps | null | FullArtefacts>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const { userId } = useTelegram();

  const inputRef = useRef();
  const mappedArtefacts = useMemo(() => {
    if (!appConfig || !cards) return [];

    if (!appConfig.skills) return [];

    const configArtefacts = appConfig.skills.variables;

    return mapToFullArtefacts(cards as unknown as Artefacts[], configArtefacts);
  }, [cards, appConfig]);
  useEffect(() => {
    if (cards && cards.length > 0) {
      setLoadedCards(cards);
    }
  }, [cards]);

  const handleCardClick = (card: CardProps | FullArtefacts) => {
    setZoomedCard(card);
  };

  const closeZoomedCard = () => {
    setZoomedCard(null);
  };

  if (loadedCards.length === 0) {
    return <div className="p-6">Loading cards...</div>;
  }

  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
  };
  function isFullArtefact(card: FullArtefacts | CardProps | null): card is FullArtefacts {
    return !!card && 'stars' in card;
  }
  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: '' };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return <ErrorComponent jsError={{ error, resetError, componentStack, eventId }} />;
      }}
    >
      <div className="fixed bottom-7 left-2 w-3 z-10">
        <HandleBackButton />
      </div>
      <input
        type="text"
        placeholder="ID/NAME"
        className={`fixed bottom-7 text-white bg-black right-2 border border-[yellow] w-[20%] h-[2em] z-10`}
        onChange={handleInputChange}
      />

      <div className="overflow-y-auto h-screen p-6">
        {loadedCards
          .filter((c) =>
            inputValue
              ? c.id === Number(inputValue) || c.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
              : c
          )
          .filter((c) => {
            return c.cardType !== CardType.equip;
          })
          .map((card: ICard) => (
            <div key={card.id} className="grid grid-cols-3 gap-6">
              {[...Array(card.stars.length || 1)].map((_, level) => (
                <div
                  key={`${card.id}-${level}`}
                  className="relative"
                  onClick={() =>
                    handleCardClick({
                      id: card.id,
                      lvl: level + 1,
                      uid: `${card.id}-${level}`,
                    })
                  }
                >
                  <Card
                    id={card.id}
                    lvl={level + 1}
                    uid={`${card.id}-${level}`}
                    IsDraggable={false}
                    glow={false}
                    isBacklight={false}
                    card={{ id: card.id, lvl: level + 1 }}
                    showId={true}
                  />
                </div>
              ))}
            </div>
          ))}
        {mappedArtefacts.map((card: FullArtefacts) => (
          <div key={card.id} className="flex py-2">
            <div key={`${card.id}-${card.uid}`} className="relative" onClick={() => handleCardClick(card)}>
              <div className="absolute bg-[#ffecc1] right-full top-2 text-[12px] p-1 leading-[1] rounded-[3px] -mr-1">
                {card.id}
              </div>
              <div className="ml-3 max-w-[200px]">
                <ArtefactCard artefact={card} />
              </div>
            </div>
          </div>
        ))}
        {zoomedCard && (
          <div onClick={closeZoomedCard}>
            <div className="bg-black opacity-60 fixed top-0 left-0 right-0 bottom-0 w-full h-full z-10"></div>
            <div className="fixed z-20 top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center">
              <div className="relative w-[300px]">
                {isFullArtefact(zoomedCard) ? (
                  <ArtefactCard artefact={zoomedCard} selected={true} />
                ) : (
                  <Card
                    id={zoomedCard.id}
                    lvl={zoomedCard.lvl}
                    uid={zoomedCard.uid}
                    hidden={false}
                    glow={false}
                    showId={true}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default AllCardsPage;
