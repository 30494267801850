import { randomUUID } from 'node:crypto';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/Card';
import { PopupButton } from '../../../../components/PopupButton';

export const SelectSecretCards = ({
  count,
  cards,
  mainCard,
  setSelectedCardHandler,
  onClose,
  handleCardRequest,
  buttonId,
  actionType,
}: {
  count: number;
  cards: { id: number; lvl: number; uid: number }[];
  mainCard: { cardId: number; cardUid: string } | null;
  setSelectedCardHandler: (selectedCards: {
    cardId: number;
    cardUid: string;
    drawCards: { drawCardId: number; drawCardUid: number }[];
  }) => void;
  onClose: () => void;
  handleCardRequest: (optiodId: number) => void;
  buttonId: number;
  actionType: number[];
}) => {
  const { t } = useTranslation();

  const [selectedCard, setSelectedCard] = useState<{ id: number; uid: number; lvl: number }[]>([]);

  const handleCardClick = ({ id, uid, lvl }: { id: number; uid: number; lvl: number }) => {
    const isSelected = selectedCard.some((card) => card.uid === uid);

    if (isSelected) {
      setSelectedCard(selectedCard.filter((card) => card.uid !== uid));
    } else if (selectedCard.length < count) {
      setSelectedCard([...selectedCard, { id, uid, lvl }]);
    }
  };
  console.log('INSIDE SELECT');

  const handleConfirm = () => {
    console.log('Fired handle confirm');
    if (selectedCard !== null && mainCard) {
      console.log('Fired handle confirm in if', selectedCard);
      setSelectedCardHandler({
        cardId: mainCard.cardId,
        cardUid: mainCard.cardUid,
        drawCards: selectedCard.map((card) => ({
          drawCardId: card.id,
          drawCardUid: card.uid,
        })),
      });
      onClose();
    }
  };
  const modalType = actionType.includes(6) ? '2' : 1;

  return (
    <div className="absolute bg-[black]/[0.7] w-full h-full z-[100] left-0 top-0 flex items-center justify-center">
      <div className="relative">
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-gray-400/[0.2] to-traansparent mx-auto
          text-[#FFEFCB] text-center text-[14px] p-2"
        >
          {t(`roomsModal.${modalType}.title`, { count })}
        </div>
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto mb-[30px]"
        ></div>
        <div className="flex justify-center">
          {cards.length > 0 ? (
            cards.map((card, index) => (
              <div
                key={index}
                className={`relative ${
                  selectedCard.some((selected) => selected.uid === card.uid) ? 'orange-glow' : ''
                } w-[30%]`}
                onClick={() => handleCardClick(card)}
              >
                <Card id={card.id} lvl={card.lvl ?? 1} uid={card.uid!.toString()} />
              </div>
            ))
          ) : (
            <div className="text-[40px] text-white text-center">No cards available</div>
          )}
        </div>
        <div className="flex justify-center">
          <PopupButton
            type={selectedCard.length < count ? 'gray' : 'blue'}
            size="medium"
            className="mt-[30px] mx-auto"
            onClick={() => {
              handleConfirm();
            }}
            disabled={selectedCard.length < count}
          >
            <div className="px-6">{t('roomsModal.6.title')}</div>
          </PopupButton>
        </div>
      </div>
    </div>
  );
};
