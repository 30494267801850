import { useEffect, useRef, useState } from 'react';
import { CardProps } from '../../interfaces/card';
import { useScroll } from '../../utils/ScrollContext';
import { getChestRewards, takeChestReward } from '../../endpoints/dungeonEndpoints';
import { useTelegram } from '../../hooks/useTelegram';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useTranslation } from 'react-i18next';
import { PackCards, SilverPackAnimation } from './PackCards';
import { motion } from 'framer-motion';
import { RiveFile } from '@rive-app/react-canvas';

export const Chest = ({
  close,
  updateSomeStates,
  usingCard,
  stageId,
  clientId,
  dungeonId,
  packAnimation,
  setIsCardPicked,
}: {
  close: () => void;
  updateSomeStates: any;
  usingCard: any;
  dungeonId: number;
  clientId: string;
  stageId: number;
  packAnimation: RiveFile | null;
  setIsCardPicked: (isCardPicked: boolean) => void;
}) => {
  const { t } = useTranslation();
  const actualSaves = useSelector((state: RootState) => state.battleSave.battleSave.save);
  const [activeTab, setActiveTab] = useState('myCards');
  const { setScrollable } = useScroll();
  const scrollableElRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [notEnoughBalance, setNotEnoughBalance] = useState(false);
  const [cards, setCards] = useState<any[]>([
    // {
    //   id: 8,
    //   lvl: 0,
    //   uid: '1',
    //   glow: false,
    // },
    // {
    //   id: 9,
    //   lvl: 0,
    //   uid: '2',
    //   glow: false,
    // },
    // {
    //   id: 10,
    //   lvl: 0,
    //   uid: '3',
    //   glow: false,
    // }
  ] as CardProps[]);

  useEffect(() => {
    setScrollable(true, scrollableElRef);

    return () => {
      setScrollable(false);
    };
  }, [setScrollable]);

  const { userId } = useTelegram();

  //TODO запрос на получение карт с бека

  useEffect(() => {
    const getRewards = async () => {
      //@ts-ignore
      const getCards = await getChestRewards({ stageId: actualSaves?.currentStage, clientId: userId });
      if (getCards.length) {
        getCards.forEach((card, index) => {
          cards.push({
            id: card.cardId,
            lvl: card.stars,
            uid: `${index}`,
            glow: false,
          });
        });
        //setCards(getCards.cards);
        setIsLoading(true);
      }
    };
    getRewards();
    //setCards([])
  }, []);

  useEffect(() => {}, [cards.length, isLoading]);

  const [burningCardIndex, setBurningCardIndex] = useState<number | null>(null);

  const removeCardAndTake = ({ cardIndex, cardId }: { cardIndex: number; cardId: number }) => {
    const nonSelectedCardIndex = cards.findIndex((_, idx) => idx !== cardIndex);
    setBurningCardIndex(nonSelectedCardIndex);

    setTimeout(() => {
      try {
        close();
        setIsCardPicked(true);
        const timeout = setTimeout(async () => {
          const [, result] = await Promise.all([
            usingCard({ dungeonId, cardId: stageId, clientId }),
            takeChestReward({ stageId: actualSaves?.currentStage!, cardId, clientId: userId }),
          ]);
          if (result.currentHp) {
            updateSomeStates({
              currentMana: result.currentMana,

              currentHp: result.currentHp,
              cardsAtDeck: result.cardsAtDeck,
              gold: result.gold,
            });
            setIsCardPicked(false);
          }
        }, 1000);
        return () => clearTimeout(timeout);
      } catch (e) {
        console.error(e);
      }
    }, 1500);
  };

  return (
    <>
      {isLoading && (
        <div className="z-40 absolute top-1/2 left-1/2 ">
          <div className="w-full fixed top-0 left-0 bottom-0 right-0 z-5 flex items-center justify-center">
            <div className="w-full h-full absolute bg-black opacity-80"></div>
            <button
              className="absolute z-20 w-12 h-12 p-2 top-[20%] right-8 flex items-center justify-center"
              onClick={close}
            >
              <img src={require('../../assets/images/shop-modal-close.png')} className="w-8 h-8" alt="" />
            </button>
            <div className="absolute w-full flex items-center justify-center top-[25%] right-0 left-0">
              <Text />
            </div>
            <div className="absolute flex brightness-100 w-full h-full z-10 bottom-6">
              {packAnimation && <SilverPackAnimation riveFile={packAnimation} />}
            </div>
            <div className="relative w-[90%] p-[2px]">
              {cards.length ? (
                <div className="flex justify-around">
                  {cards.map((card, index) => (
                    <div className="w-1/3" key={index}>
                      <PackCards
                        burningCardIndex={burningCardIndex}
                        card={card}
                        cardsLength={cards.length}
                        index={index}
                        removeCardAndTake={removeCardAndTake}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-center h-full w-full text-2xl text-white pt-10 pb-12">
                  {t('tutorial.modals.empty')}
                </div>
              )}
            </div>
          </div>
          {notEnoughBalance && (
            <div
              className="absolute bottom-[100px] left-1/2 -translate-x-1/2 bg-[rgba(120,22,22,0.8)] text-white
                py-2.5 px-4 z-10 rounded flex items-center text-center leading-[1.2] whitespace-nowrap"
            >
              {t('notEnoughBalance')}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Text = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: (150 + 1640) / 1000,
        duration: 0.5,
        ease: 'easeOut',
      }}
    >
      <p className="text-3xl text-white">{t('dungeon.chest.chooseCard')}</p>
    </motion.div>
  );
};
