import { CardImage, TextColor } from '../components/Card/CardComponentBuilder';
import { Items } from '../mock/offers';
import { BattleEffectsData } from '../pages/Battle/EnemyBattleData';

export interface BattleInit {
  maxHp: number;
  currentHp: number;
  maxMana: number;
  defense: number;
  heroId: number;
  battleEffectsData: BattleEffectsData[][];
  cardsAtDeck: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  artefacts: Artefacts[];
  cardsAtHand: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  actionPoints: number;
  enemy: {
    id: number;
    lvl: number;
    currentHp: number;
    maxHp: number;
    maxMana: number;
    defense: number;
    battleEffectsData: BattleEffectsData[][];
    cardsAtHand: [
      {
        id: number;
        uid: string;
        lvl: number;
      }
    ];
  };
}

export enum ExecutionActionType {
  addEffect, // накалдываем бафф/дебафф
  executeEffect, // исполняется еффект бафф/дебаффа
  removeEffect, // удаляем бафф/дебафф
  executeSkill, // исполняем скилл
  changeParameters, // добавление/уменьшения хп/маны....
  updateCards, // обновляем карты в руке
  discardCards, // сбрасываем карты
  shuffleCards, // перемешиваем карты
  noEffect, // никакого эффекта для карты пранка
}

export enum Opponent {
  hero,
  enemy,
}

export enum Parameter {
  health,
  mana,
  actionPoints,
  defense,
}

export enum CardType {
  atk,
  action,
  equip,
  spell,
}

// export interface MakeAction {
//   type: ExecutionActionType;
//   data: {
//     effectUid?: string;
//     effectId?: number;
//     durationInRounds?: number;
//     skillId?: string;
//     target: Opponent;
//     parameters?: {
//       parameter: Parameter,
//       value: number;
//     }
//   }
// }

export interface HeroCards {
  cardId: number;
  stars: number;
}

export enum HeroType {
  warrior,
  wizard,
}

export const heroConfig: HeroConfig[] = [
  {
    id: 0,
    type: HeroType.warrior,
    hp: 100,
    mana: 100,
    cards: [],
    lvl: 1,
  },
];

export interface HeroConfig {
  id: number;
  type: HeroType;
  hp: number; //начальные х-ки
  mana: number; //начальные х-ки
  cards: HeroCards[];
  lvl: number;
}

export type Action =
  | {
      type: ExecutionActionType;
      effectUid: string;
      effectId: number;
      duration: number;
      target: Opponent;
    }
  | {
      type: ExecutionActionType;
      parameter: Parameter;
      value: number;
      target: Opponent;
    }
  | {
      type: ExecutionActionType;
      target: Opponent;
      skillId: string;
    }
  | {
      type: ExecutionActionType;
      target: Opponent;
      cardIds: number[];
    }
  | {
      type: ExecutionActionType;
      target: Opponent;
      value: number;
      cardIds: number[];
    }
  | {
      type: ExecutionActionType;
      target: Opponent;
      value: number;
    };

export type MakeAction = {
  actions: Action[];
  battleEffectsData: any;
  enemyBattleEffectsData: any;
};

export type Effect = {
  effectUid: string;
  effectId: number;
  duration: number;
  amount?: number;
  target: Opponent;
};

export interface EndTurn {
  steps: {
    cardInfo?: { cardId: number; lvl: number; uid: string };
    actions: Action[];
  }[];
  artefacts: Artefacts[];
  battleEffectsData: BattleEffectsData[][];
  cardsAtHand: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  cardsAtDeck: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  enemy: {
    battleEffectsData: BattleEffectsData[][];
    cardsAtHand: [
      {
        id: number;
        uid: string;
        lvl: number;
      }
    ];
  };
}

// enum CardType {
//   atk
// act
// equip
// spell
// }

export enum ConditionParameter {
  health,
  mana,
  actionPoints,
  defense,
}

export enum CalculationMethod {
  percent,
  count,
}

export enum ConditionSide {
  less,
  more,
}

export enum StageType {
  enemy,
  elixir,
  shop,
  forge,
  bonfire,
  abyss,
  boss,
  chest,
  random,
  endWay,
  secretroom,
}

export enum AwardType {
  gold,
  card,
  mana,
  health,
}

export interface DungeonStagesResponse {
  id: number;
  stageId: number;
  type: StageType;
  amount: number;
  currentAmount: number;
  randomEventId: number;
}

export type DungeonStage =
  | {
      id: number; //хилка
      type: StageType;
      amount: number;
      lvl?: number;
      title: string;
      description: string;
      currentAmount: number;
      percent?: number;
      pool?: StageType[];
      buildingId?: number;
    }
  | {
      //батл
      id: number;
      type: StageType;
      enemyId: number;
      lvl?: number;
      title: '';
      description?: string;
      buildingId?: number;
      pool?: StageType[];
    }
  | {
      id: number; // сундук
      type: StageType;
      awardType: AwardType;
      title: string;
      description?: string;
      buildingId?: number;
      lvl?: number;
      rewardAmount?: number;
      percent?: number;
      pool?: StageType[];
    }
  | {
      id: number; // магазин/кузница/таверна/сундук
      type: StageType;
      title: string;
      description?: string;
      buildingId?: number;
      lvl?: number;
      percent?: number;
      pool?: StageType[];
    }
  | {
      id: number; // random
      type: StageType;
      description?: string;
      lvl?: number;
      buildingId?: number;
      poolId?: StageType[];
    };

export interface DungeonType {
  dungeonId: number;
  description: string;
  stages: DungeonStage[];
}

interface DungeonStageConfig {
  id: { value: number };
  amount?: { value: number };
  currentAmount?: { value: number };
  enemyId?: { value: number };
  awardType?: { value: AwardType };
  rewardAmount?: { value: number };
  title?: { value: string };
  percent?: { value: number };
  pool?: { value: StageType[] };
  type: { value: StageType };
  description?: { value: string };
  lvl?: { value: number };
  buildingId?: { value: number };
  poolId?: { value: StageType[] };
}

interface DungeonUnlockConditions {
  buildingId: { value: number };
  level: { value: number };
}

export interface DungeonTypeConfig {
  description: { value: string };
  dungeonId: { value: number };
  rewards: { value: Items[] };
  stages: { value: DungeonStageConfig[] };
  conditions?: { value: DungeonUnlockConditions[] };
}

export interface BattleSaves {
  dungeonId: number;
  stages: DungeonStagesResponse[];
  enemyId?: number;
  currentHp: number;
  currentMana: number;
  maxHp?: number;
  gold: number;
  currentStage?: number;
  buildingId?: number;
  bossId?: number;
}

export enum EffectType {
  defense,
  triggeredByEnemiesAttackCard,
  doubleDamage,
  returnDamage,
  damage,
  repeatableDamage,
  getCard,
  shuffle,
  discard,
  gainMaxHp,
  lessCardPerRound,
  heal,
  piercingDamage,
  negateAttack,
  damageEqualPercentCurrentHealth,
  exiled,
  addActionPoint,
  copyAllFromHandByTypeAndShuffle,
  lessDamage,
  additionalDamage,
  additionalDefense,
  returnDamageByHandSize, // enemy  hand size
  damagePerLostHP, // - убрано
  damagePerEquiped, // 23 - убрано
  copyPreviousEffects, // 24 - убрано
  viewCardsandKeep, // view - value, keep - animationCount, card 37, 39, новые
  drawCardPerUseATK, // amount - value не используется
  transformLeftmostAttackToCopy, // card 40, новые
  shuffleTempCopiesOfAttack, // card 41, новые
  reduceCost, // - -1 cost, на зеленых
  priceManna, // todo old by dima
  getRandomCardFromDeck, //
  gainRandomTempAttack,
  getRandomConfigCard, // todo new for green 12
  damagePerArtefact, // five red
  viewCardConfig,
  viewCardDeck,
  // getRandomNotArtefactCard
  grimHarvest,
  recklessSlash,
  getBasicAttack,
  weightOfSteel,
  deathSentence,
  minusManaPerPower,
  addMana,
  arcaneEcho,
  mindBreak,
  spectralOnslaught,
  getCardNotArtefact,
  getCardDefence,
  equipCardArtefact,
  dealDamageByHandSize,
  restoreHealthByDamage,
  cloneBasicAttack,
  doubleEffects,
  noEffect,
  echoStrike,
  harlequinsTrick,
  exileForever,
  additionalMana, // works like additionalDamage|additionalDefence etc
  cloneLastBasicAttack,
  doubleDamageForTurns,
  removeRandomCard,
  clonePreviousCard,
  makeBasicDamage,
}

export type level = {
  value: number;
  target: 'enemy' | 'self';
  effectType: number;
  animationCount?: number;
};

export enum ArtefactActionType {
  startTurn,
  endTurn,
  trigger,
}

export enum TriggerType {
  none,
  cardType,
  effect,
}

export enum TriggerCondition {
  playAttackCard,
  gettingDamageOnAttackersTurn,
  playActionCard,
  enemyAttack,
  copyBasicAttackAfterUse,
  dealDamageAmount,
  playCard,
}

export interface ISkill {
  value: number;
  target: Opponent;
  duration: number;
  cost: number;
  effectType: EffectType;
  roundAmount?: number;
  animationCount: number;
  // condition?: {
  //   target: Opponent;
  //   parameter: ConditionParameter;
  //   value: number;
  //   calculationMethod: CalculationMethod;
  //   conditionSide: ConditionSide;
  // }
}
export interface SkillConfig {
  value: { value: number };
  target: { value: Opponent };
  duration: { value: number };
  cost: { value: number };
  effectType: { value: EffectType };
  roundAmount?: { value: number };
  animationCount: { value: number };
  // condition?: {
  //   target: Opponent;
  //   parameter: ConditionParameter;
  //   value: number;
  //   calculationMethod: CalculationMethod;
  //   conditionSide: ConditionSide;
  // }
}
export interface ArtefactOptions {
  artefactActionType: ArtefactActionType;
  triggerType?: TriggerType;
  triggerValue?: number;
  triggerCondition?: TriggerCondition;
  cardType?: CardType;
}
export interface ArtefactOptionsConfig {
  artefactActionType: { value: ArtefactActionType };
  triggerType?: { value: TriggerType };
  triggerValue?: { value: number };
  triggerCondition?: { value: TriggerCondition };
  cardType?: { value: CardType };
}

export interface ICard {
  id: number;
  energyType: number;
  cardType: number;
  image: CardImage;
  description: string;
  cost: number;
  upgrades: number;
  isExiled?: boolean;
  draggable?: boolean;
  stars: {
    skills: ISkill[];
    cost: number;
  }[];
  name: string;
  artefactOptions?: ArtefactOptions;
  probability: number;
  condition?: {
    target: Opponent;
    parameter: ConditionParameter;
    value: number;
    calculationMethod: CalculationMethod;
    conditionSide: ConditionSide;
  };
}

export interface Artefacts {
  id: number;
  uid: string;
  dungeonCost: number;
}

export interface ConfigArtefacts {
  id: { value: number };
  energyType: { value: number };
  cardType: { value: number };
  cost: { value: number };
  stars: {
    value: {
      skills: { value: SkillConfig[] };
      cost: { value: number };
    }[];
  };
  name: { value: string };
  artefactOptions?: { value: [ArtefactOptionsConfig] };
  rarity: { value: ArtefactRarity };
}
export enum ArtefactRarity {
  common,
  rare,
  legendary,
}
export interface FullArtefacts {
  rarity: ArtefactRarity;
  uid?: string;
  id: number;
  energyType: number;
  cardType: number;
  cost: number;
  stars: {
    skills: ISkill[];
    cost: number;
  };
  name: string;
  artefactOptions?: ArtefactOptions;
}
