import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import selectedIsland from '../../../../../app/features/selectedIsland';
import { AppDispatch, RootState } from '../../../../../app/store';
import TypingEffect from '../../../../../components/TypingEffect';
import { TutorialFarmSave, TutorialFarmStage } from '../../../../../interfaces/tutorial';
import { islandsConfigMock } from '../../../../../mock/buildings';
import { Room } from '../../../Room';
import { memo, useCallback, useEffect, useState } from 'react';
import { setDialogueInfo } from '../../../../../app/features/dialoguesSlice';
import { saveFarmTutorialProgress } from '../../../../../app/features/farmTutoralSlice';
import { useTranslation } from 'react-i18next';
import { useTelegram } from '../../../../../hooks/useTelegram';

interface Props {
}

export const IslandTutorial: React.FC<Props> = memo(() => {
  const [dialogStage, setDialogStage] = useState(0);
  const [speed, setSpeed] = useState(50);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [startText, setStartText] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const { t, i18n } = useTranslation();
  const { userId } = useTelegram();

  const isFarmTutorialCompleted = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave?.save?.completed
  );
  const updateSave = async (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const dialogueId: number = farmTutorialSave?.dialogueId ?? 1;

  const phrases = t(`farm.dialogues.dialogue${dialogueId}.phrases`, {
    returnObjects: true,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStartText(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const next = () => {

    
    if (!isTypingComplete) {
      setSpeed(10);
      return;
    }
    setSpeed(50);
    setIsTypingComplete(false);
    if (dialogStage < Object.keys(phrases).length - 1) {
      setDialogStage(dialogStage + 1);
    } else {
      switch (dialogueId) {
        case 1:
          updateSave({
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.start,
          });

          break;
        case 2:
          updateSave({
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.clickOnQuests,
          });
          break;
        case 3:
          updateSave({
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.battle,
          });

          break;
        case 4:
          updateSave({
            dialogueId: dialogueId + 1,
            stage: TutorialFarmStage.repair,
          });
          break;
        case 7:
          updateSave({
            dialogueId: dialogueId,
            completed: true,
            stage: TutorialFarmStage.finishFirstBuilding,
          });

          break;
        default:
          break;
      }

      setDialogStage(0);
      dispatch(setDialogueInfo({ id: dialogueId + 1 }));
    }
  };

  const handleTypingComplete = useCallback(() => {
    setIsTypingComplete(true);
  }, [setIsTypingComplete]);
  
  console.log('TUTORIAL SAVE ON ISLAND', farmTutorialSave);
  console.log('TUTORIAL SAVE ON ISLAND ne number', typeof farmTutorialSave?.stage !== 'number');
  
  return (
    <>
      {/* Dark bg for tutorial stages */}
      {farmTutorialSave?.stage === TutorialFarmStage.clickOnRewards ||
      farmTutorialSave?.stage === TutorialFarmStage.clickOnQuests ||
      farmTutorialSave?.stage === TutorialFarmStage.clickOnRewards2 ? (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-[56]"></div>
      ) : null}

      {!isFarmTutorialCompleted &&
      !farmTutorialSave ||
      !dialogueId ||
      (!farmTutorialSave?.stage ||
      farmTutorialSave?.stage! === TutorialFarmStage.start) &&
      dialogueId <= 2 &&
        // farmTutorialSave?.stage! < TutorialFarmStage.clickOnRewards &&
        // farmTutorialSave?.stage !== TutorialFarmStage.endbattle &&
        // farmTutorialSave?.stage !== TutorialFarmStage.finishQuestTutor &&
        // farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards2 &&
        // dialogueId !== 3 &&
        // dialogueId !== 5 &&
        // dialogueId !== 6 &&
        // dialogueId < 7 &&
         (
          <div
            className="absolute min-h-full max-h-full h-full flex min-w-full overflow-hidden z-[9999] "
            onClick={next}
          ></div>
        )}
      {(farmTutorialSave?.stage === TutorialFarmStage.start || !farmTutorialSave?.stage) && (
        <>
          <div
            className={`${
              !i18n.exists(`farm.dialogues.dialogue${dialogueId}.phrases.${dialogStage}.person`) ||
              (t(`farm.dialogues.dialogue${dialogueId}.phrases.${dialogStage}.person`) === '' && 'opacity-0')
            }`}
          >
            {startText && (
              <div
                className={`absolute z-[100] ${
                  'bottom-[25%]' // Стандартне положення
                } left-[25%]`}
              >
                <div className="relative px-5">
                  <img
                    src={require('../../../../../assets/images/dialog-cloud.png')}
                    className={`w-[200px] ml-auto mr-0`}
                    alt=""
                  />
                  {/* Текст поверх зображення */}
                  <div className="absolute z-10 right-4 top-1 w-[200px] h-[100px]">
                    <p className="w-full p-2 text-wrap leading-[1.2] text-sm">
                      <TypingEffect
                        speed={speed}
                        onComplete={handleTypingComplete}
                        text={t(`farm.dialogues.dialogue${dialogueId}.phrases.${dialogStage}.person`)}
                      />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          (
          <div
            className={`absolute bottom-0  transition-transform duration-1000 ease-in-out transform translate-x-[0vw] z-[53]`}
          >
            <img
              src={require('../../../../../assets/images/tutorialFarmFox.webp')}
              alt="Tutorial Farm Fox"
              className={`w-[215px] h-auto z-[55]`}
            />
          </div>
          )
        </>
      )}
      {!isFarmTutorialCompleted &&
        farmTutorialSave?.stage !== TutorialFarmStage.building &&
        farmTutorialSave?.stage &&
        farmTutorialSave?.stage !== TutorialFarmStage.endbattle &&
        farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards &&
        farmTutorialSave?.stage !== TutorialFarmStage.finishQuestTutor &&
        farmTutorialSave?.stage !== TutorialFarmStage.clickOnQuests &&
        farmTutorialSave?.stage < TutorialFarmStage.repair && (
          <div
            className={`absolute bottom-0 left-0 h-[175px] w-full max-w-full bg-gradient-to-b from-transparent to-black ${
              farmTutorialSave?.stage === TutorialFarmStage.finishRepair ? 'z-[101]' : 'z-[54]'
            }  flex justify-center items-end p-5`}
          >
            <div className="text-center text-white text-lg font-normal leading-[18px]">{t('farm.tapToSkip')}</div>
          </div>
        )}
    </>
  );
});
