// components/CardItem.tsx
import React from 'react';
import { BoostItem, HeroesItem, InventoryItem, LootBoxItem } from '../..';
import { HeroRarity } from '../../../../interfaces/hero';
import classNames from 'classnames';
import { ItemType, TimeBoosts, UniversalShard } from '../../../../interfaces/lootBotx';
import { timeBoostsMap } from '../../../../utils/mapping';

interface CardItemProps {
  item: InventoryItem;
  onSelect: (item: InventoryItem) => void;
  selected: boolean; // Новий пропс
}

const cardBackground: Record<number, string> = {
  [0]: 'linear-gradient(180deg, #5A60FF 3%, #3F44C2 100%)', // blue
  [1]: 'linear-gradient(to bottom, #D95BD8 0%, #9245DF 100%)', // purple
  [2]: 'linear-gradient(to bottom, #FFFE2E 0%, #FE8315 100%)', // yellow
  [3]: 'linear-gradient(to bottom, #CF0000 0%, #800B0B 100%)', // yellow
};

const getItemBackGroundColor = (item: InventoryItem): string => {
  if (item.type === ItemType.lootBox) {
    const lootBoxItem = item as LootBoxItem;
    return cardBackground[lootBoxItem.lootBoxRarity];
  } else if (item.type === ItemType.timeBoosts) {
    const boostItem = item as BoostItem;
    if (boostItem.duration / 3600000 >= 1) {
      return cardBackground[1];
    }
    return cardBackground[0];
  } else if (item.type === ItemType.universalShard) {
    const heroItem = item as HeroesItem;
    return cardBackground[heroItem.rarity];
  } else if (item.type === ItemType.heroShard) {
    const heroItem = item as HeroesItem;
    return cardBackground[heroItem.rarity];
  } else if (item.type === ItemType.keyShard) {
    return cardBackground[3];
  }
  return '#5BDFA4';
};

export const CardItem: React.FC<CardItemProps> = ({ item, onSelect, selected }) => {
  const handleClick = () => {
    onSelect(item);
  };

  const isBoostItem = (item: InventoryItem): item is BoostItem => {
    return item.type === ItemType.timeBoosts;
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(
        `relative flex flex-col items-center justify-center p-1 rounded-md shadow-lg aspect-square`
      )}
      style={{ background: getItemBackGroundColor(item) }}
    >
      {isBoostItem(item) && (
        <div className="absolute top-0 w-full flex justify-center items-center">
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="p-1 w-full z-10 text-center text-white text-base font-normal  leading-none">{item.name}</div>
        </div>
      )}
      <img src={item.icon} alt={`${item.type} icon`} className="w-[60px] h-[60px] object-contain" />

      <div className="absolute bottom-0.5 right-0.5 text-white px-1 text-base font-semibold text-stroke-regular">
        <p>{item.amount}</p>
      </div>

      {selected && (
        <div className="absolute inset-0 aspect-square rounded-md border-[4px] border-[#FFFE2E] shadow-[0_0_7.26px_0_#E98818]"></div>
      )}
    </div>
  );
};
