import { useState } from 'react';
import { SuccessOverlay } from '../../../../components/SuccessOverlay';
import { CheckInStatus, claimCheckInReward, verifyShareMessage } from '../../../../endpoints/calendarEnpoints';
import { Resources } from '../../../../enums/resources';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useTelegramShareMessage } from '../../../../hooks/useTelegramShareMessage';
import { resourcesMap } from '../../../../utils/mapping';
import { handleRewardResult } from '../../../../utils/rewardParse';
import { defineCountString } from '../../../../utils/farmConfigParser';
import { useSoundService } from '../../../../utils/soundService';
import { BigDailyRewardClaim, BigDailyRewardIdle, DailyRewardClaim, DailyRewardIdle } from '../../../../components';
import { ItemType } from '../../../../interfaces/lootBotx';
import { getBalance } from '../../../../endpoints/farmMock';
import { useTranslation } from 'react-i18next';

export const CheckInDay = ({
  id,
  onClaim,
  status,
  rewards,
  isLast,
  refetchDays,
}: {
  onClaim: () => void;
  id: number;
  status: CheckInStatus;
  rewards: { id: number; type: number; amount: number }[];
  isLast: boolean;
  refetchDays: () => void;
}) => {
  const mapRewards = rewards.map((reward) => {
    const result = handleRewardResult({ rewardId: reward.id, rewardType: reward.type });
    return { ...result, amount: reward.amount, rewardType: reward.type };
  });

  const { t } = useTranslation();
  const { userId } = useTelegram();

  const { handleShare } = useTelegramShareMessage(async () => {
    handleVerify();
  });

  const { playSound } = useSoundService();
  const handleVerify = async () => {
    const result = await verifyShareMessage({
      clientId: userId,
      checkinId: id,
    });
    if (result) {
      refetchDays();
    }
  };

  const handleClaim = async () => {
    playSound('questsReward');
    const result = await claimCheckInReward({
      clientId: userId,
      checkinId: id,
    });

    if (result) {
      await getBalance({ clientId: userId });
      refetchDays();
      onClaim();
    }
  };

  return (
    <div
      onClick={
        CheckInStatus.ToVerify === status ? handleShare : CheckInStatus.ToClaim === status ? handleClaim : () => {}
      }
      className={`
        ${
          // Якщо це останній день, ставимо градієнт
          // Інакше використовуємо кольори за статусом
          isLast
            ? 'bg-gradient-to-r from-[#eb8e47] to-[#fecb54] '
            : CheckInStatus.ToClaim === status
            ? 'bg-[#38a640]'
            : 'bg-[#c6774e]'
        }
        rounded-[10px]
        relative mb-2
      `}
    >
      {CheckInStatus.ToVerify === status && (
        <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full absolute -top-1 -right-1"></div>
      )}

      {CheckInStatus.ToVerify === status && rewards.length < 2 ? (
        <div className="absolute w-full h-full z-10">
          <DailyRewardIdle />
        </div>
      ) : (
        ''
      )}
      {CheckInStatus.ToVerify === status && rewards.length >= 2 ? (
        <div className="absolute w-full h-full z-20">
          <BigDailyRewardIdle />
        </div>
      ) : (
        ''
      )}
      {CheckInStatus.ToClaim === status && rewards.length < 2 ? (
        <div className="absolute w-full h-full">
          <DailyRewardClaim />
        </div>
      ) : (
        ''
      )}
      {CheckInStatus.ToClaim === status && rewards.length >= 2 ? (
        <div className="absolute w-full h-full">
          <BigDailyRewardClaim />
        </div>
      ) : (
        ''
      )}
      {CheckInStatus.ToClaim == status && (
        <div className="absolute right-[-4px] -top-1 w-[15px] h-[15px] ">
          <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full"></div>
        </div>
      )}
      {/* Накладений шар */}
      {CheckInStatus.Claimed == status && (
        <>
          <div className="absolute inset-0 bg-black opacity-60 rounded-[10px] z-10"></div>
          <div className="absolute flex justify-center items-center z-20 w-full h-full">
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_i_10431_57262)">
                <circle cx="21" cy="21" r="20" fill="#00973C" />
              </g>
              <circle cx="21" cy="21" r="20.5" stroke="#1CB358" />
              <path
                d="M20.8575 28.0211L20.8575 28.0211L33.1414 15.7363C33.4538 15.4239 33.4538 14.9174 33.1414 14.605L32.7879 14.9585L33.1414 14.605L31.395 12.8586C31.0826 12.5462 30.5761 12.5462 30.2637 12.8586L18.3333 24.7898L11.7363 18.1928C11.4239 17.8804 10.9174 17.8804 10.605 18.1928L8.85858 19.9392C8.54616 20.2516 8.54616 20.7581 8.85858 21.0706L15.8091 28.0211L17.7676 29.9796C18.08 30.292 18.5866 30.2921 18.899 29.9796L20.8575 28.0211Z"
                fill="white"
                stroke="#228A5D"
              />
              <defs>
                <filter
                  id="filter0_i_10431_57262"
                  x="0"
                  y="0"
                  width="42"
                  height="42"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1.5" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0" />
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_10431_57262" />
                </filter>
              </defs>
            </svg>
          </div>
        </>
      )}

      <div className="flex flex-col m-0.5 z-10">
        <div className="flex justify-center items-center rounded-t-lg pb-1 px-2 custom-shadow border-b border-b-zinc-800">
          <div
            className={`text-center ${
              CheckInStatus.ToClaim === status ? 'text-white' : 'text-[#ffefcb]'
            } text-base font-black leading-none pt-1 text-stroke-small`}
          >
            {t('checkIn.day')} {id}
          </div>
        </div>
        <div
          className={`relative flex justify-center items-center flex-col ${
            isLast ? 'bg-gradient-to-r from-[#ffe49f] to-[#ffca45] rounded-b-lg shadow-inner-sm-white' : 'bg-[#f6f6d5]'
          } pt-1 pb-2 px-3 ${CheckInStatus.ToClaim === status ? '' : 'rounded-b-lg'} `}
        >
          <div className={`flex justify-center items-center ${isLast && rewards.length > 2 ? 'gap-5' : 'gap-3'}`}>
            {mapRewards.map((mappedReward, index) => (
              <div key={index} className="relative mx-1">
                <div
                  className={`p-2 border border-black ${
                    mappedReward?.backgroundColor
                      ? mappedReward?.backgroundColor
                      : 'bg-gradient-to-b from-[#33a8cc] to-[#0d81b6]'
                  } rounded-md`}
                >
                  {mappedReward.rewardType === ItemType.timeBoosts ? (
                    <>
                      <div className="rounded-t-Fmd absolute top-0 left-0 w-full bg-black/40 flex justify-center items-center w-fullinline-block pb-1 pt-2 text-white   text-basefont-normal leading-none text-shadow-black-sm">
                        {mappedReward?.name}
                      </div>
                    </>
                  ) : null}
                  <div className="w-8 h-8">
                    <img className="w-full h-full" src={mappedReward.image} alt="Reward" />
                  </div>

                  <div className="absolute -bottom-2 left-1/2 -translate-x-1/2">
                    <div className="text-stroke-small text-white text-xl font-black uppercase leading-tight">
                      {defineCountString(mappedReward.amount)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {CheckInStatus.ToClaim === status && (
          <div
            className="flex justify-center items-center rounded-b-lg pb-1 px-2"
            style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3), 0 10px 20px rgba(0, 0, 0, 0.2)' }}
          >
            <div className="text-center text-[#e5ffe7] text-lg leading-[18px] text-stroke-small pt-1 px-[9px]">
              Collect
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
