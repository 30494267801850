import { HandleBackButton } from '../HeaderCave/components';
import { GoldBalance } from './components/GoldBalance';

export const HeaderSecretRoom: React.FC<{
  gold?: number;
  setIsSecretRoomOpen: (isOpen: boolean) => void;
  setIsScreenDarkened: (isOpen: boolean) => void;
}> = ({ gold = 0, setIsSecretRoomOpen, setIsScreenDarkened }) => {
  return (
    <header className="w-full h-14 border border-transparent bg-transparent z-[1] fixed">
      <div className={`h-full flex items-center justify-between px-4`}>
        <div className='invisible'>
          <HandleBackButton
            onClick={() => {
              setIsScreenDarkened(false);
              setIsSecretRoomOpen(false);
            }}
          />
        </div>
        <GoldBalance goldAmount={gold} />
      </div>
    </header>
  );
};
