import React from "react";
import { motion } from "framer-motion";
import { PopupButton } from "../../components/PopupButton";
import { useTranslation } from "react-i18next";

interface PurchaseModalProps {
  isVisible: boolean;
  isSuccess: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  selectedOffer?: any;
}

const modalVariants = {
  hidden: { y: 282, opacity: 0 },
  visible: { y: 0, opacity: 1 },
};

export const PurchaseModal: React.FC<PurchaseModalProps> = ({
  isVisible,
  isSuccess,
  onClose,
  onConfirm,
  selectedOffer,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Overlay */}
      <div
        className={`absolute z-10 bg-[#201f1e] w-full h-full duration-200 ${
          isVisible || isSuccess ? "visible opacity-85" : "invisible opacity-0"
        }`}
        onClick={onClose}
      ></div>

      {/* Success Modal */}
      {isSuccess && (
        <motion.div
          className="fixed z-20 left-0 right-0 bottom-0 p-5 h-[282px] 
            bg-no-repeat bg-[length:100%_100%] bg-[url('./assets/images/shop-buy-modal-background.png')]"
          variants={modalVariants}
          initial="hidden"
          animate={isSuccess ? "visible" : "hidden"}
          transition={{ type: "spring", stiffness: 50, duration: 0.5 }}
        >
          <button
            className="absolute z-10 w-5 h-5 top-3.5 right-3.5 flex items-center justify-center"
            onClick={onClose}
          >
            <img
              src={require("../../assets/images/shop-modal-close.png")}
              className="w-5 h-5"
              alt="Close Modal"
            />
          </button>
          <div className="text-[32px] mt-3 text-[#19191B] text-center">
            Purchase
          </div>
          <div className="text-stroke-regular text-[32px] text-white flex justify-center items-center mt-8">
            <img
              src={require("../../assets/images/kitsu-icon.webp")}
              className="w-[32px] h-[32px] mr-1"
              alt="Kitsu Icon"
            />
            <span className="font-[300]">Success!</span>
          </div>
          <div className="flex justify-center mt-6">
            <PopupButton type="green" width="180px" onClick={onClose}>
              <div className="flex items-center justify-center text-stroke-regular text-[20px]">
                Close
              </div>
            </PopupButton>
          </div>
        </motion.div>
      )}

      {/* Purchase Initiation Modal */}
      {!isSuccess && isVisible && (
        <motion.div
          className="fixed z-20 left-0 right-0 bottom-0 p-5 h-[282px] 
            bg-no-repeat bg-[length:100%_100%] bg-[url('./assets/images/shop-buy-modal-background.png')]"
          variants={modalVariants}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          transition={{ type: "spring", stiffness: 50, duration: 0.5 }}
        >
          <button
            className="absolute z-10 w-5 h-5 top-3.5 right-3.5 flex items-center justify-center"
            onClick={onClose}
          >
            <img
              src={require("../../assets/images/shop-modal-close.png")}
              className="w-5 h-5"
              alt="Close Modal"
            />
          </button>
          <div className="text-[32px] mt-3 text-[#19191B] text-center">
            Purchase
          </div>
          {selectedOffer &&
            selectedOffer.items &&
            selectedOffer.items.value[0]?.amount?.value &&
            selectedOffer.price?.value && (
              <div className="text-stroke-regular text-[32px] text-white flex justify-center items-center mt-8 font-[800]">
                {/* <img
                  src={require("../../assets/images/kitsu-icon.png")}
                  className="w-[32px] h-[32px] mr-1"
                  alt="Kitsu Icon"
                /> */}
                <span className="text-[#FFC923] pr-2">
                {t(`shop.offers.${selectedOffer.offerType.value}.title`)}
                </span>
                <span className="font-[600]">
                  = ${selectedOffer.price.value}
                </span>
              </div>
            )}
          <div className="flex justify-center mt-6">
            <PopupButton
              size="medium"
              type="green"
              width="200px"
              onClick={onConfirm}
              disabled={!onConfirm}
            >
              <div className="flex items-center justify-center">
                <img
                  src={require("../../assets/images/telegram-stars-icon.png")}
                  className="w-[20px] h-[20px] mr-1"
                  alt="Telegram Stars Icon"
                />
                Purchase
              </div>
            </PopupButton>
          </div>
        </motion.div>
      )}
    </>
  );
};
