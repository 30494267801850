import React, { useState } from 'react';
import { APP_ENV } from '../../../../../config';
import { PopupButton } from '../../../../../components/PopupButton';
import { useUtils } from '../../../../../utils/navigateTo';
import { TutorialFarmSave, TutorialFarmStage } from '../../../../../interfaces/tutorial';
//import { timeoutCollection } from 'time-events-manager';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../app/store';
import { useTelegram } from '../../../../../hooks/useTelegram';
import { saveFarmTutorialProgress } from '../../../../../app/features/farmTutoralSlice';

interface Props {
  className?: string;
}

export const DeveloperButtons: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { userId } = useTelegram();
  const { navigateTo } = useUtils();
  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  return (
    <>
      {APP_ENV !== 'production'
        ? farmTutorialSave?.stage !== TutorialFarmStage.endbattle &&
          farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards && (
            <div className="absolute top-[10%] left-[50%] translate-x-[-50%] z-[55] flex flex-col">
              {/* <PopupButton
                type="red"
                onClick={async () => {
                  await updateTutorialProgress({
                    clientId: userId,
                    save: JSON.stringify({
                      stage: 0,
                      dialogueId: 0,
                      cardIsDropped: false,
                      enemyId: 0,
                    }),
                  });
                  await updateFarmTutorialProgress({
                    clientId: userId,
                    save: '',
                  });
                  await resetProgress({ clientId: userId });

                  setTimeout(() => {
                    navigateTo('/battle');
                  }, 150);
                }}
              >
                Reset
              </PopupButton> */}
              <details className={`flex flex-col`}>
                <summary className={`bg-[#847860] text-white font-bold p-2  border-2 border-[brown] rounded-lg`}>
                  Debug buttons
                </summary>
                <div className="flex flex-col">
                  <PopupButton type="green" onClick={() => navigateTo('/allCards')}>
                    Card Glossary
                  </PopupButton>
                  <PopupButton
                    type="blue"
                    onClick={() => {
                      // console.log('TIMEOUT LIST', timeoutCollection.getScheduled());
                    }}
                  >
                    Get timeout list
                  </PopupButton>
                  <PopupButton
                    type="red"
                    onClick={() => {
                      console.log('render info', performance.getEntriesByType("paint"));
                    }}
                  >
                    Get render info
                  </PopupButton>
                  <PopupButton
                    type="red"
                    onClick={() => {
                      //@ts-ignore
                      if (performance.memory) {
                        //@ts-ignore
                        console.log("Memory info, used/limit:", performance.memory.usedJSHeapSize / 1024 / 1024, "MB", performance.memory.jsHeapSizeLimit / 1024 / 1024, "MB");                      }
                    }}
                  >
                    Get Memory info
                  </PopupButton>
                  <PopupButton
                    type="red"
                    onClick={() => {
                      if (typeof window !== 'undefined' && typeof window.gc === 'function') {
                        window.gc();
                      }
                    }}
                  >
                    Force Garbage Collection
                  </PopupButton>
                </div>
              </details>
            </div>
          )
        : null}
    </>
  );
};
