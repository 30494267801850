import { useLocation, useNavigate } from 'react-router-dom';
import { useSessionStorage } from '@uidotdev/usehooks';
import { useEffect, useRef } from 'react';

//const islandEntry = { current: false };

export function useUtils() {
  const [activeDiv, setActiveDiv] = useSessionStorage('page', '/');
  const navigate = useNavigate();
  const location = useLocation();
  const battleElement = document.getElementById('battle');
  const dungeonElement = document.getElementById('dungeon');
  const questsElement = document.getElementById('quests');
  const heroesElement = document.getElementById('heroes');
  const friendsElement = document.getElementById('friends');
  const bossDungeonElement = document.getElementById('boss-dungeon');
  const footerCaveElement = document.getElementById('footer-cave');
  const headerCaveElement = document.getElementById('header-cave');
  //const islandElement = document.getElementById('island');

  const navigateTo = (divLink: string) => {
    if (battleElement) {
      console.log('battleElement', battleElement);
      //@ts-ignore
      battleElement.innerHTML = null;
    }
    if (dungeonElement) {
      console.log('dungeonElement', dungeonElement);
      //@ts-ignore
      dungeonElement.innerHTML = null;
    }
    if (questsElement) {
      console.log('questsElement', questsElement);
      //@ts-ignore
      questsElement.innerHTML = null;
    }
    if (heroesElement) {
      console.log('heroesElement', heroesElement);
      //@ts-ignore
      heroesElement.innerHTML = null;
    }
    if (friendsElement) {
      console.log('friendsElement', friendsElement);
      //@ts-ignore
      friendsElement.innerHTML = null;
    }
    if (bossDungeonElement) {
      console.log('bossDungeonElement', bossDungeonElement);
      //@ts-ignore
      bossDungeonElement.innerHTML = null;
    }
    if (footerCaveElement) {
      console.log('footerCaveElement', footerCaveElement);
      //@ts-ignore
      footerCaveElement.innerHTML = null;
    }
    if (headerCaveElement) {
      console.log('headerCaveElement', headerCaveElement);
      //@ts-ignore
      headerCaveElement.innerHTML = null;
    }
    // if (islandElement) {
    //   if (!islandEntry.current) {
    //     console.log('islandElement entry', islandEntry.current);
    //     islandEntry.current = true;
    //   } else {
    //     console.log('islandElement', islandElement);
    //     //@ts-ignore
    //     islandElement.innerHTML = null;
    //   }
    // }
    setActiveDiv(divLink);
    navigate(divLink);
    if (typeof global !== 'undefined' && typeof global.gc === 'function') {
      console.log('gc');
      global.gc();
    }
  };

  useEffect(() => {
    setActiveDiv(location.pathname);
  }, [location.pathname]);

  const goBack = () => {
    const currentUrl = window.location.pathname;

    const lastSlashIndex = currentUrl.lastIndexOf('/');
    if (lastSlashIndex === -1 || lastSlashIndex === 0) {
      navigate('/island');
      return;
    }

    const newUrl = currentUrl.substring(0, lastSlashIndex);
    navigate(newUrl);
  };

  return {
    goBack,
    navigateTo,
    activeDiv,
  };
}
