import {DungeonType, StageType} from "./mock";


export const getTitleForStages = (stageType: StageType) => {
  switch (stageType) {
    case StageType.forge:
      return 'Forge';
    case StageType.shop:
      return 'Shop';
    case StageType.abyss:
      return 'Tavern';
    default:
      return 'Here should be a text';
  }
}


