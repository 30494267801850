import { Card } from '../../../components/Card';
import { PopupButton } from '../../../components/PopupButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectCards = ({
  count,
  cards,
  mainCard,
  setSelectedCardHandler,
  onClose,
}: {
  count: number;
  cards: { id: number; lvl: number; uid: number }[];
  mainCard: { cardId: number; cardUid: string } | null;
  setSelectedCardHandler: ({
    cardId,
    cardUid,
    drawCardId,
    drawCardLvl,
  }: {
    cardId: number;
    cardUid: string;
    drawCardId: number;
    drawCardLvl: number;
  }) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState<{ id: number; uid: number; lvl: number } | null>(null);
  const [removeCanvas, setRemoveCanvas] = useState(false);

  const handleRemoveCanvas = () => {
    console.log('trigger remove canvas');
    setRemoveCanvas(true); 
  }

  const handleClose = () => {
    handleRemoveCanvas(); 
    const timer = setTimeout(() => {
      onClose()
    }, 150)
    return () => clearTimeout(timer)
  }
  const handleCardClick = ({ id, uid, lvl }: { id: number; uid: number; lvl: number }) => {
    if (selectedCard && selectedCard.uid === uid) {
      setSelectedCard(null);
    } else {
      setSelectedCard({ id, uid, lvl });
    }
  };
  const handleConfirm = () => {
    if (selectedCard !== null && mainCard) {
      setSelectedCardHandler({
        cardId: mainCard.cardId,
        cardUid: mainCard.cardUid,
        drawCardId: selectedCard.id,
        drawCardLvl: selectedCard.lvl,
      });
    }
  };

  return (
    <div className="absolute bg-[black]/[0.7] w-full h-full z-[100] left-0 top-0 flex items-center justify-center">
      <div className="relative">
        <button
          className="absolute z-10 w-11 h-11 p-2 -top-2 right-1 flex items-center justify-center"
          onClick={handleClose}
        >
          <img src={require('../../../assets/images/shop-modal-close.png')} className="w-7 h-7" alt="" />
        </button>
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto"
        ></div>
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-gray-400/[0.2] to-traansparent mx-auto
          text-[#FFEFCB] text-center text-[14px] p-2"
        >
          {t('battleSelectCards', { count })}
        </div>
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto mb-[30px]"
        ></div>
        <div className="flex justify-center">
          {cards.length > 0 ? (
            cards.map((card, index) => (
              <div
                key={index}
                className={`relative ${selectedCard && selectedCard.uid === card.uid! ? 'orange-glow' : ''} w-[30%]`}
                onClick={() => handleCardClick(card)}
              >
                <Card id={card.id} lvl={card.lvl ?? 1} uid={card.uid!.toString()} triggerRemoveCanvas={removeCanvas} />
              </div>
            ))
          ) : (
            <div className="text-[40px] text-white text-center">No cards available</div>
          )}
        </div>
        <div className="flex justify-center">
          <PopupButton
            type={selectedCard !== null ? 'blue' : 'gray'}
            size="medium"
            className="mt-[30px] mx-auto"
            onClick={handleConfirm}
            disabled={selectedCard === null}
          >
            <div className="px-6">{t('confirm')}</div>
          </PopupButton>
        </div>
      </div>
    </div>
  );
};
