import { createSlice } from '@reduxjs/toolkit';

const globalTimerSlice = createSlice({
  name: 'globalTimer',
  initialState: {
    currentTime: Date.now(),
  },
  reducers: {
    timer: (state, action) => {
      state.currentTime = action.payload; 
    },
  },
});

export const { timer } = globalTimerSlice.actions;
export default globalTimerSlice.reducer;
