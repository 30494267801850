import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useSessionStorage } from '@uidotdev/usehooks';
import { handleRewardResult } from '../../../../utils/rewardParse';
import { AppDispatch, RootState } from '../../../../app/store';
import { useMemo, useState } from 'react';
import { PopupButton } from '../../../../components/PopupButton';
import { resourcesMap } from '../../../../utils/mapping';
import { Resources } from '../../../../enums/resources';
import { ResourcePurchase } from '../../../../components';
import { VipOffer } from './components';
import { getMyLootboxes } from '../../../../endpoints/lootBoxEndpoints';
import { useTelegram } from '../../../../hooks/useTelegram';
import { fetchActiveBoosts } from '../../../../app/features/inventorySlice';
import { useUtils } from '../../../../utils/navigateTo';

import { Swap } from '../../../../interfaces/swap';
import { swap } from '../../../../endpoints/farmMock';
import { SuccessOverlay } from '../../../../components/SuccessOverlay';
import { useTranslation } from 'react-i18next';
import { useSoundService } from '../../../../utils/soundService';
import { ItemType } from '../../../../interfaces/lootBotx';

export const VipShop = ({ buyMoreStars }: { buyMoreStars: () => void }) => {
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const resources = useSelector((state: RootState) => state.resources.resources);
  const { userId } = useTelegram();
  const [openSwap, setOpenSwap] = useState<Swap | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();
  const { playSound, pausedMusic } = useSoundService();
  const availableSwaps = useMemo(() => {
    if (!appConfig) return;

    const {
      swapRates: { variables: swapRates },
    } = appConfig;

    if (swapRates) {
      const sortedSwaps: Swap[] = [...swapRates].sort((a: Swap, b: Swap) => b.swapRate.value - a.swapRate.value);

      return sortedSwaps;
    }

    return null;
  }, [appConfig]);

  const currentKitsu = resources.find((v) => v.resourceType === Resources.kitsu)?.value || 0;

  const handleOnChainDeposit = () => {
    setOpenedDepositModal(true);
  };

  const handleSwap = async (amount: number, id: number) => {
    const result = await swap({
      clientId: userId,
      id: id,
      toAmount: amount,
    });

    if (result) {
      setOpenSwap(null);
      setIsSuccess(true);
      playSound('questsReward');

      setTimeout(() => {
        setIsSuccess(false);
      }, 2100);
    }
  };

  return (
    <div className="flex flex-col overflow-y-auto overflow-x-hidden max-h-full">
      {availableSwaps && (
        <motion.div
          className="flex flex-wrap gap-2 w-full overflow-y-auto max-h-[50vh]"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {availableSwaps.map((swap: Swap, i) => {
            const resultFirst = handleRewardResult({
              rewardId: swap.fromResourceId.value,
              rewardType: swap.fromResourceType.value,
            });
            const resultSecond = handleRewardResult({
              rewardId: swap.toResourceId.value,
              rewardType: swap.toResourceType.value,
            });

            return (
              resultFirst &&
              resultSecond && (
                <VipOffer
                  id={i}
                  firstImage={resultFirst.image}
                  secondName={
                    swap.toResourceType.value === ItemType.timeBoosts
                      ? `${resultSecond?.name} ${t('timeBoostsShop')}`
                      : resultSecond?.name
                  }
                  secondImage={resultSecond?.image}
                  backgroundColor={resultSecond.backgroundColor}
                  onClick={() => {
                    playSound('button');
                    setOpenSwap(swap);
                  }}
                  swapRate={swap.swapRate.value}
                  minBuy={swap.toMinAmount.value}
                />
              )
            );
          })}
        </motion.div>
      )}

      {openSwap ? (
        <motion.div
          className="absolute w-full left-0 top-[5%]"
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <ResourcePurchase
            swapId={openSwap.id.value}
            firstResourceType={openSwap.fromResourceType.value}
            firstResourceID={openSwap.fromResourceId.value}
            secondResourceType={openSwap.toResourceType.value}
            secondResourceID={openSwap.toResourceId.value}
            currentFirstResource={currentKitsu}
            currentSecondResource={0}
            swapRate={openSwap.swapRate.value}
            onSwap={handleSwap}
            onClose={() => setOpenSwap(null)}
            minBuy={openSwap.toMinAmount.value}
          />
        </motion.div>
      ) : null}

      {/* Блок із кнопками */}
      <motion.div
        className="pb-10 pt-5 flex flex-col justify-center items-center gap-1 w-full left-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="text-center text-[#ffefcb] text-xl font-black font-['Sofia Sans Semi Condensed'] leading-tight">
          {t('shop.vipStore.getMore')}
        </div>
        <div className="flex flex-col w-full p-2 gap-2">
          <div
            className="flex justify-between items-center bg-gradient-to-r from-[#eb8e47] to-[#fecb54] 
                     rounded shadow-[inset_0px_2px_2px_1px_rgba(255,255,255,0.50)]
                     border-2 border-[#fffe2e] py-4 px-2"
            onClick={buyMoreStars}
          >
            <div className="flex justify-center items-center gap-1">
              <div className="text-white text-base font-black text-shadow-black-sm leading-none">
                {t('shop.vipStore.buyForStars')}
              </div>
              <img
                className="w-[16px] h-[16px]"
                src={require('../../../../assets/images/shop/offer/offer-star.png')}
                alt=""
              />
            </div>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12L7 7L2 2" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M2 12L7 7L2 2" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>

          <div
            className="flex justify-between items-center bg-gradient-to-r from-[#eb8e47] to-[#fecb54] 
                     rounded shadow-[inset_0px_2px_2px_1px_rgba(255,255,255,0.50)]
                     border-2 border-[#fffe2e] py-4 px-2"
            onClick={handleOnChainDeposit}
          >
            <div className="flex justify-center items-center gap-1">
              <div className="text-white text-base font-black text-shadow-black-sm leading-none">
                {t('shop.vipStore.onChainDeposit')}
              </div>
            </div>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12L7 7L2 2" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M2 12L7 7L2 2" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
      </motion.div>

      {/* SuccessOverlay */}
      {isSuccess && (
        <div
          className="absolute left-0 w-full h-full"
          onClick={() => {
            setIsSuccess(false);
            setOpenSwap(null);
          }}
        >
          <SuccessOverlay
            isSuccess={isSuccess}
            onClose={() => {
              setIsSuccess(false);
              setOpenSwap(null);
            }}
          />
        </div>
      )}
    </div>
  );
};
