import { useRef, useEffect, useState } from "react";
import { getImageFromDB } from "../../utils/useIndexedDBStorage";
import { useUnmount } from "usehooks-ts";

const CardCanvas = ({ 
  cardId, 
  triggerRemoveCanvas,
}: { 
  cardId: number;
  triggerRemoveCanvas: boolean;
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  let imageUrl: string | null = null; // Храним текущий blob URL
  useEffect(() => {
    const loadImageToCanvas = async () => {
      try {
        // Получаем URL изображения из IndexedDB
        imageUrl = await getImageFromDB(`assets/images/cardsIcons/${cardId}.webp`);
        if (!imageUrl) {
          console.error(`Image not found for cardId: ${cardId}`);
          return;
        }

        const canvas = canvasRef.current;
        if (canvas) {
          const context = canvas.getContext("2d");
          if (context) {
            const image = new Image();
            image.src = imageUrl; // Используем blob URL

            image.onload = () => {
              context.clearRect(0, 0, canvas.width, canvas.height);
              context.drawImage(image, 0, 0, canvas.width, canvas.height);
            };

            image.onerror = (err) => {
              console.error("Error loading image:", err);
            };
          }
        }
      } catch (error) {
        console.error("Error loading image to canvas:", error);
      }
    };

    loadImageToCanvas();

    return () => {
      // Удаляем blob URL при размонтировании или обновлении
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
        imageUrl = null;
      }
    };
  }, [cardId]);

  useEffect(() => {
    const canvas = canvasRef.current;
    
    return () => {
      if (canvas) {
        // Get and cleanup WebGL context
        const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
        if (gl) {
          const extension = gl.getExtension('WEBGL_lose_context');
          if (extension) {
            extension.loseContext();
          }
          gl.finish();
          // Clear all WebGL state
          const numTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
          for (let unit = 0; unit < numTextureUnits; ++unit) {
            gl.activeTexture(gl.TEXTURE0 + unit);
            gl.bindTexture(gl.TEXTURE_2D, null);
            gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
          }
          gl.bindBuffer(gl.ARRAY_BUFFER, null);
          gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
          gl.bindRenderbuffer(gl.RENDERBUFFER, null);
          gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        }

        // Clear 2D context
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }

        // Clear dimensions and remove
        canvas.width = 0;
        canvas.height = 0;
        canvas.innerHTML='';
        
        // Clear the ref
        canvasRef.current = null;
      }

      // Cleanup image URL
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
        imageUrl = null;
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      width={500}
      height={700}
      className="w-full h-full inline"
    />
  );
};

export default CardCanvas;
