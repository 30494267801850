import { RequestWebsocket, ResponseWebsocket } from '../interfaces/wsInterfaces';
import { WebsocketState } from '../enums/wsEnums';
import { setWebSocket, setWebSocketError, setWebSocketStatus } from '../app/features/webSocketSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { store } from '../app/store';
import { API_URL } from '../config';
import * as Sentry from '@sentry/react';
import { setError } from '../app/features/errorSlice';
import { ErrorReportingService } from '../services/errorReportingService';
import { ErrorType } from '../interfaces/error';
import ReconnectingWebSocket from 'reconnecting-websocket';

let webSocketInstance: ReconnectingWebSocket | null = null;

const jsonStringifyOnServer = (object: any) => {
  return typeof window === 'undefined' ? JSON.stringify(object, null, 2) : object;
};

export const openWebSocket = createAsyncThunk<void, void, { rejectValue: string }>(
  'webSocket/openWebSocket',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      if (webSocketInstance) {
        return;
      }

      const options = {
        debug: false,
        minReconnectionDelay: 1000,
        maxReconnectionDelay: 60000,
        connectionTimeout: 5000,
        maxEnqueuedMessages: 100,
      };

      webSocketInstance = new ReconnectingWebSocket(`wss://${API_URL}`, [], options);

      const onOpen = () => {
        dispatch(setWebSocket(webSocketInstance as WebSocket));
        dispatch(setWebSocketStatus('connected'));
      };

      const onClose = () => {
        dispatch(setWebSocketStatus('failed'));
      };

      const onError = (error: any) => {
        console.error('WebSocket error:', error);
        dispatch(setWebSocketError('WebSocket encountered an error'));
        dispatch(setWebSocketStatus('failed'));
      };

      const onMessage = (msg: MessageEvent) => {
        dispatch(setWebSocketStatus('connected'));
        console.debug(
          'RECEIVE: [' + JSON.parse(msg.data)?.result?.request?.method + ']',
          jsonStringifyOnServer(JSON.parse(msg.data))
        );
      };

      webSocketInstance.addEventListener('open', onOpen);
      webSocketInstance.addEventListener('close', onClose);
      webSocketInstance.addEventListener('error', onError);
      webSocketInstance.addEventListener('message', onMessage);
    } catch (error) {
      console.error('Failed to open WebSocket connection:', error);
      Sentry.captureException(error);
      dispatch(setWebSocketStatus('failed'));
      rejectWithValue('Failed to open WebSocket connection.');
    }
  }
);

export const sendRequestAndGetResponse = async (request: RequestWebsocket) => {
  try {
    if (!webSocketInstance || webSocketInstance.readyState !== WebsocketState.open) {
      store.dispatch(setWebSocketStatus('failed'));
      store.dispatch(setWebSocketError('WebSocket is not open'));
      throw new Error('WebSocket is not open');
    }

    const TIMEOUT = 3000;

    const response = await new Promise<ResponseWebsocket>((resolve, reject) => {
      const timer = setTimeout(() => {
        webSocketInstance?.removeEventListener('message', messageHandler);
        store.dispatch(setWebSocketStatus('failed'));
        reject(new Error('Request timed out'));
      }, TIMEOUT);

      const messageHandler = (event: MessageEvent) => {
        try {
          const parsedMSG = JSON.parse(event.data) as ResponseWebsocket;
          if (request.id === parsedMSG.result.request.id) {
            clearTimeout(timer);
            webSocketInstance?.removeEventListener('message', messageHandler);
            if (parsedMSG.result.response.type === 'Left') {
              if (
                request.method !== 'notification_notifyErrorHappendOnApp' &&
                parsedMSG.result.response.value.type !== 'userAttemptsExhausted'
              ) {
                ErrorReportingService.reportError({
                  type: ErrorType.websocket,
                  errorData: {
                    agent: request.agent,
                    method: request.method,
                    requestId: request.id,
                    requestParams: request.params,
                    responseData: parsedMSG.result.response.value,
                  },
                  clientId: request.params.clientId ?? 'No ClientId',
                });
              }
              reject(new Error(parsedMSG.result.response.value));
            } else {
              resolve(parsedMSG);
            }
          }
        } catch (error) {
          clearTimeout(timer);
          store.dispatch(setWebSocketStatus('failed'));
          webSocketInstance?.removeEventListener('message', messageHandler);
          reject(error);
        }
      };

      if (webSocketInstance) {
        webSocketInstance.addEventListener('message', messageHandler);
      } else {
        store.dispatch(setWebSocketStatus('failed'));
        // console.error("WebSocket instance is not initialized.");
      }

      try {
        if (webSocketInstance) {
          console.debug('SEND: [' + request?.method + ']', jsonStringifyOnServer(request));
          webSocketInstance.send(JSON.stringify(request));
        } else {
          store.dispatch(setWebSocketStatus('failed'));
        }
      } catch (sendError) {
        clearTimeout(timer);
        webSocketInstance?.removeEventListener('message', messageHandler);
        reject(sendError);
      }
    });

    store.dispatch(setWebSocketStatus('connected'));

    return response.result.response;
  } catch (error) {
    console.error('ERROR at sendRequestAndGetResponse:', error);
    Sentry.captureException(error);
    store.dispatch(setError(error as Error));
    throw error;
  }
};
