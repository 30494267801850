import { motion } from 'framer-motion';
import { PopupWindow } from '../../../../../../../components/PopupWindow';
import { useSessionStorage } from '@uidotdev/usehooks';
import { useState } from 'react';
import { PopupButton } from '../../../../../../../components/PopupButton';
import { enterCode } from '../../../../../../../endpoints/codesEndpoints';
import { useTelegram } from '../../../../../../../hooks/useTelegram';
import { useTranslation } from 'react-i18next';

const depositPopupVariants = {
  hidden: { opacity: '0', transform: 'translateY(110%)' },
  visible: { opacity: '1', transform: 'translateY(-50%)' },
};

const EnterCodePopupHeader = ({}: {}) => {
  const { t } = useTranslation();
  window.location.hash = 'input';

  return (
    <>
      <div className="absolute top-[-15px] left-0 right-0 flex justify-self-center z-20">
        <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
          <div
            className="bg-[#351d1e] text-center text-white text-xl p-[7px] leading-none rounded-[1px] border
              border-[#18191a] shadow-inner-sm-black"
          >
            {t('codePurchase.enterCode')}
          </div>
        </div>
      </div>
    </>
  );
};

export const EnterCodePopup = () => {
  const [openEnterCodePopup, setOpenEnterCodePopup] = useSessionStorage('openEnterCodePopup', false);

  const [shouldHideElement, setShouldHideElement] = useSessionStorage<boolean>('shouldHideElementKey', false);
  const [isRefCodeAccess, setIsRefCodeAccess] = useSessionStorage('isRefCodeAccess', false);

  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const { userId } = useTelegram();
  const { t } = useTranslation();
  const [part1, setPart1] = useState('');
  const [part2, setPart2] = useState('');
  const [part3, setPart3] = useState('');

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');

    const segments = pastedData.split('-');

    setPart1(segments[0] || '');
    setPart2(segments[1] || '');
    setPart3(segments[2] || '');
  };

  const handlePasteButton = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const segments = text.split('-');

      setPart1(segments[0] || '');
      setPart2(segments[1] || '');
      setPart3(segments[2] || '');
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
      alert('Unable to read clipboard. Please ensure you have granted clipboard permissions.');
    }
  };

  const handleEnterCode = async () => {
    const data = await enterCode({
      clientId: userId,
      code: `${part1}-${part2}-${part3}`,
    });

    if (data) {
      setErrorMessage(false);
      setIsRefCodeAccess(true);
      // console.log('ENTERCODE');
      setShouldHideElement(false);
    } else {
      setErrorMessage(true);
    }
  };
  return (
    <motion.div
      variants={depositPopupVariants}
      initial="hidden"
      animate="visible"
      className={`absolute w-[97%] min-h-[300px] top-[50%] translate-y-[-50%]  left-[1.5%] flex justify-center align-center`}
    >
      <PopupWindow
        onClickClose={() => {
          setTimeout(() => {
            window.location.hash = '';
          }, 1000);
          setOpenEnterCodePopup(false);
        }}
        headerElement={<EnterCodePopupHeader />}
      >
        <div className="flex flex-col justify-center items-center p-4 gap-4">
          <div className="flex justify-center items-center gap-1 pt-16 pb-5">
            <div className="flex justify-center items-center gap-1">
              {/* Інпут 1 */}
              <input
                className="w-16 h-12 px-2 bg-[#1b1817] rounded border border-[#554837] text-center text-white text-lg font-black leading-tight"
                value={part1}
                onChange={(e) => setPart1(e.target.value)}
                onPaste={handlePaste}
                maxLength={4}
              />
              <span className="text-white text-lg">-</span>

              {/* Інпут 2 */}
              <input
                className="w-16 h-12 px-2 bg-[#1b1817] rounded border border-[#554837] text-center text-white text-lg font-black leading-tight"
                value={part2}
                onChange={(e) => setPart2(e.target.value)}
                onPaste={handlePaste}
                maxLength={4}
              />

              <span className="text-white text-lg">-</span>

              {/* Інпут 3 */}
              <input
                className="w-16 h-12 px-2 bg-[#1b1817] rounded border border-[#554837] text-center text-white text-lg font-black leading-tight"
                value={part3}
                onChange={(e) => setPart3(e.target.value)}
                onPaste={handlePaste}
                maxLength={4}
              />
            </div>

            {/* Paste Code Button */}
            <PopupButton type="blue" size="small" onClick={handlePasteButton}>
              <div className="flex flex-col justify-center items-center min-w-[26px]">
                <img
                  className="w-5 h-5"
                  src={require('../../../../../../../assets/images/paste.png')}
                  alt="Paste Icon"
                />
                <div className="text-center text-white text-[10px] font-bold leading-[10px] -mx-1">
                  {t('codePurchase.paste')}
                </div>
              </div>
            </PopupButton>
          </div>

          {errorMessage ? <div className="text-red-500">{t('codePurchase.invalidCode')}</div> : null}

          <PopupButton
            type={part1.length === 0 || part2.length === 0 || part3.length === 0 ? 'gray' : 'gold'}
            size="big"
            onClick={() => handleEnterCode()}
            className="mb-5"
            disabled={part1.length === 0 || part2.length === 0 || part3.length === 0}
          >
            <div className="text-center text-white text-lg font-black leading-[18px] px-4">
              {t('codePurchase.activate')}
            </div>
          </PopupButton>
        </div>
      </PopupWindow>
    </motion.div>
  );
};
