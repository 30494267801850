import { HeroCards } from "../endpoints/mock";

export interface BattleInit {
  maxHp: number;
  currentHp: number;
  maxMana: number;
  cardsAtDeck: number;
  cardIds: number[];
  enemy: {
    name: string;
    lvl: number;
    maxHp: number;
    maxMana: number;
  };
}

export enum EnemiesPower {
  atk,
}

export interface Enemies {
  id: number;
  lvl: number;
  isBoss: boolean;
  name: string;
  description: string;
  attributes: {
    health: number;
    mana: number;
    defence: number;
    maxHealth: number;
    maxMana: number;
  }[];
  cards: CardsAtDeck[];
  cardsAmountPerRound: number;
  power?: EnemiesPower; //для особых баффов или дебаффов
}

export interface EnemiesConfig {
  id: { value: number };
  lvl: { value: number };
  isBoss: { value: boolean };
  name: { value: string };
  description: { value: string };
  attributes: {
    value: {
      health: { value: number };
      mana: { value: number };
      defence: { value: number };
      maxHealth: { value: number };
      maxMana: { value: number };
    }[];
  };
  cards: { value: CardsAtDeckConfig[] };
  cardsAmountPerRound: { value: number };
  power?: EnemiesPower; //для особых баффов или дебаффов
}

export enum CardIds {
  stone,
  sixRed,
  threeGreen,
  eighteenBlue,
  nineRed,
  twentyBlue,
  threeRed,
  eighteenGreen,
  prank,
  oneRed,
  fifteenRed,
  elevenRed,
  eighteenRed,
  tenRed,
  sixteenRed,
  sevenRed,
  sixGreen,
  twoRed,
  fourRed,
  fiveRed,
  eightRed,
  fourteenRed,
  twelveRed,
  thirteenRed,
  nineteenRed,
  oneGreen,
  twoGreen,
  fourGreen,
  sevenGreen,
  nineGreen,
  elevenGreen,
  thirteenGreen,
  fifteenGreen,
  tenGreen,
  twelveGreen,
  nineBlue,
  seventeenRed,
  fiveGreen,
  eightGreen,
  fourteenGreen,
  seventeenGreen = 41,
  oneBlue,
  
}

export interface CardsAtDeckConfig {
  cardId: { value: number };
  stars: { value: number };
}
export interface CardsAtDeck {
  cardId: number;
  stars: number;
}

export function parseEnemiesConfig(config: EnemiesConfig[]): Enemies[] {

  return config ? config.map((enemyConfig: EnemiesConfig) => ({
    id: enemyConfig.id.value,
    lvl: enemyConfig.lvl.value,
    isBoss: enemyConfig.isBoss.value,
    name: enemyConfig.name.value,
    description: enemyConfig.description.value,
    attributes: enemyConfig.attributes.value.map((attributesConfig) => ({
      health: attributesConfig.health.value,
      mana: attributesConfig.mana.value,
      defence: attributesConfig.defence.value,
      maxHealth: attributesConfig.maxHealth.value,
      maxMana: attributesConfig.maxMana.value,
    })),
    cards: enemyConfig.cards.value.map((cardConfig) => ({
      cardId: cardConfig.cardId.value,
      stars: cardConfig.stars.value,
    })),
    cardsAmountPerRound: enemyConfig.cardsAmountPerRound.value,
    power: enemyConfig.power, // якщо потрібно, можна додати додаткову логіку для power
  })) : {} as Enemies[];
}

