import { useSelector } from 'react-redux';
import { PopupWindow } from '../../components/PopupWindow';
import { getCheckInStatusByClientId } from '../../endpoints/calendarEnpoints';
import { useTelegram } from '../../hooks/useTelegram';
import { CheckInDay } from './components';
import { useEffect, useState, useCallback } from 'react';
import { RootState } from '../../app/store';
import { SuccessOverlay } from '../../components/SuccessOverlay';
import { useTranslation } from 'react-i18next';
export const CheckIn = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();
  const { userId } = useTelegram();

  // Тягнемо конфіги з Redux (appConfig)
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  const [days, setDays] = useState([]);
  const [isSuccessClaim, setIsSuccessClaim] = useState(false);
  /**
   * Функція, яку можна викликати повторно,
   * щоб оновити (перезапитати) стани днів
   */
  const fetchDays = useCallback(async () => {
    if (!appConfig) return;

    const {
      calendar: { variables: calendarConfig },
    } = appConfig;

    // Отримуємо дані з бекенду
    const result = await getCheckInStatusByClientId({ clientId: userId });
    if (!result) {
      setDays([]);
      return;
    }

    // Мепимо результат у потрібний формат
    const mappedDays = calendarConfig.map((day: any) => {
      return {
        id: day.id.value,
        rewards: day.rewards.value.map((reward: any) => ({
          id: reward.rewardId.value,
          type: reward.rewardType.value,
          amount: reward.amount.value,
        })),
        status: result.find((d: any) => d.id === day.id.value)?.status,
      };
    });

    setDays(mappedDays);
  }, [appConfig, userId]);

  // Викликаємо fetchDays один раз при монтуванні (і коли зміняться залежності)
  useEffect(() => {
    fetchDays();
  }, [fetchDays]);

  return (
    <div className="fixed inset-0 z-[999] bg-black bg-opacity-50 flex justify-center items-center">
      <PopupWindow
        onClickClose={close}
        headerElement={
          <>
            <div className="absolute top-[-15px] left-0 right-0 flex justify-self-center z-20">
              <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
                <div className="bg-[#351d1e] text-center text-white text-xl p-[7px] leading-none rounded-[1px] border border-[#18191a] shadow-inner-sm-black">
                  {t('farm.icons.shareToEarn')}
                </div>
              </div>
            </div>
            <div className="pt-5 pb-2 w-full text-center text-[#c8bb93] text-xl font-black leading-tight">
              {t('checkIn.tapToCollect')}
            </div>
          </>
        }
      >
        <div className="max-h-[70vh] w-[80vw] md:w-auto overflow-y-auto">
          <div className="flex flex-wrap justify-center items-center gap-3 mb-5 text-white pt-3">
            {days.map((day: any, index) => {
              const isLast = index === days.length - 1;
              return (
                <CheckInDay
                  onClaim={() => setIsSuccessClaim(true)}
                  key={index}
                  status={day.status}
                  rewards={day.rewards}
                  id={day.id}
                  isLast={isLast}
                  refetchDays={fetchDays}
                />
              );
            })}
          </div>
        </div>
        <SuccessOverlay
          isSuccess={isSuccessClaim}
          onClose={() => {
            setIsSuccessClaim(false);
          }}
        />
      </PopupWindow>
    </div>
  );
};
