import { EBranch, TierLevel, Tree } from '../../../../../interfaces/hero';

// Визначте тип для skillsData
export interface SkillData {
  id: string;
  image: string;
  isClaimed: boolean;
  isAvailable: boolean;
  size: 'large' | 'small';
  position: { x: number; y: number };
  children: string[];
  levels: TierLevel[];
  transformTarget: { x: string; y: string };
  transformSource: { x: string; y: string };
}

// Мапінг між EBranch та координатами X

const branchXMap: Record<EBranch, number> = {
  [EBranch.left]: 200,
  [EBranch.central]: 250,
  [EBranch.right]: 300,
};

const largeBranchImageMap: Record<EBranch, string[]> = {
  [EBranch.left]: ['first-left-skill.png', 'second-left-skill.png', 'third-left-skill.png'],
  [EBranch.central]: ['first-skill.png', 'second-skill.png'],
  [EBranch.right]: ['first-right-skill.png', 'second-right-skill.png', 'third-right-skill.png'],
};

// Функція для перетворення Tree[] у skillsData[]
export const transformTreeToSkillsData = (trees: Tree[]): SkillData[] => {
  // Сортування дерев за ієрархією
  const sortedTrees = sortTrees(trees);

  const largeNodeCounters: Record<EBranch, number> = {
    [EBranch.left]: 0,
    [EBranch.central]: 0,
    [EBranch.right]: 0,
  };

  const skillsData: SkillData[] = sortedTrees.map((tree) => {
    const isLarge = tree.levels.length > 1;
    const size: 'large' | 'small' = isLarge ? 'large' : 'small';

    // Визначення зображення
    let image = 'defaultSkillIcon.png';

    if (isLarge) {
      largeNodeCounters[tree.type] += 1;
      const count = largeNodeCounters[tree.type];

      // Вибір зображення на основі лічильника та типу гілки
      const images = largeBranchImageMap[tree.type];
      image = images[count - 1] || 'defaultLargeSkill.png'; // Якщо більше зображень, ніж визначено, використовується дефолтне
    } else {
      if (tree.type === EBranch.left) image = 'default-skill-left.png';
      else if (tree.type === EBranch.central) image = 'default-skill-center.png';
      else if (tree.type === EBranch.right) image = 'default-skill-right.png';
    }

    // Визначення глибини вузла (level)
    const depth = getDepth(trees, tree.id);

    // Визначення координат
    const xBase = branchXMap[tree.type];
    const yBase = depth * 30; // Наприклад, кожен рівень на 100px нижче

    // Компенсація для маленьких вузлів
    const offset = isLarge ? 0 : 24; // Відступ для вирівнювання малих вузлів
    const offsetY = isLarge ? 14 : 24; // Відступ для вирівнювання малих вузлів

    const tilt: Record<number, Record<number, number>> = {
      0: {
        0: 0,
        1: 0,
        2: 0,
        3: -35,
        4: -65,
        5: -45,
        6: -25,
        7: -5,
      },
      1: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
      },
      2: {
        0: 0,
        1: 0,
        2: 0,
        3: 35,
        4: 65,
        5: 45,
        6: 25,
        7: 5,
      },
    };
    const tiltY: Record<number, Record<number, number>> = {
      0: {
        0: 0,
        1: 0,
        2: 25,
        3: 35,
        4: 35,
        5: 85,
        6: 105,
        7: 115,
      },
      1: {
        0: 0,
        1: -10,
        2: 45,
        3: 65,
        4: 79,
        5: 95,
        6: 100,
        7: 105,
      },
      2: {
        0: 0,
        1: 0,
        2: 25,
        3: 35,
        4: 35,
        5: 85,
        6: 105,
        7: 115,
      },
    };
    const tiltTranslateTarget: Record<number, Record<number, { x: string; y: string }>> = {
      0: {
        0: { x: 'none', y: 'none' },
        1: { x: 'none', y: 'none' },
        2: { x: '0px', y: '5px' },
        3: { x: '0px', y: '5px' },
        4: { x: '-10px', y: '0px' },
        5: { x: '0px', y: '0px' },
        6: { x: 'none', y: 'none' },
        7: { x: '5px', y: '0px' },
      },
      1: {
        0: { x: 'none', y: 'none' },
        1: { x: 'none', y: 'none' },
        2: { x: 'none', y: 'none' },
        3: { x: 'none', y: 'none' },
        4: { x: 'none', y: 'none' },
        5: { x: 'none', y: 'none' },
        6: { x: 'none', y: 'none' },
        7: { x: 'none', y: 'none' },
      },
      2: {
        0: { x: 'none', y: 'none' },
        1: { x: 'none', y: 'none' },
        2: { x: 'none', y: 'none' },
        3: { x: '0px', y: '5px' },
        4: { x: '10px', y: '0px' },
        5: { x: '2px', y: '0px' },
        6: { x: '3px', y: '0px' },
        7: { x: '-4px', y: '0px' },
      },
    };

    const tiltTranslateSource: Record<number, Record<number, { x: string; y: string }>> = {
      0: {
        0: { x: 'none', y: 'none' },
        1: { x: 'none', y: 'none' },
        2: { x: '10px', y: '-14px' },
        3: { x: 'none', y: 'none' },
        4: { x: '-10px', y: '1px' },
        5: { x: '2px', y: '-3px' },
        6: { x: '0px', y: '0px' },
        7: { x: 'none', y: 'none' },
      },
      1: {
        0: { x: 'none', y: 'none' },
        1: { x: 'none', y: 'none' },
        2: { x: 'none', y: 'none' },
        3: { x: 'none', y: 'none' },
        4: { x: 'none', y: 'none' },
        5: { x: 'none', y: 'none' },
        6: { x: 'none', y: 'none' },
        7: { x: 'none', y: 'none' },
      },
      2: {
        0: { x: 'none', y: 'none' },
        1: { x: 'none', y: 'none' },
        2: { x: '5px', y: '0px' },
        3: { x: 'none', y: 'none' },
        4: { x: '10px', y: '0px' },
        5: { x: '0px', y: '-5px' },
        6: { x: '0px', y: '0px' },
        7: { x: 'none', y: 'none' },
      },
    };

    const position = {
      x: xBase + offset + tilt[tree.type][depth],
      // y: tree.type === EBranch.central ? isLarge ? yBase - 48 : yBase : yBase + offsetY, // Додатковий відступ для вертикального вирівнювання
      y: yBase + tiltY[tree.type][depth], // Додатковий відступ для вертикального вирівнювання
    };

    // Визначення дітей
    const children = trees.filter((t) => t.prev === tree.id).map((child) => child.id.toString());

    return {
      id: tree.id.toString(),
      image,
      size,
      position,
      children,
      levels: tree.levels,
      isClaimed: tree.isClaimed,
      isAvailable: tree.isAvailable,
      transformTarget: tiltTranslateTarget[tree.type][depth],
      transformSource: tiltTranslateSource[tree.type][depth],
    };
  });
  return skillsData;
};

// Допоміжна функція для сортування дерев за глибиною
const sortTrees = (trees: Tree[]): Tree[] => {
  const sorted: Tree[] = [];
  const map = new Map<number, Tree>();
  trees.forEach((tree) => map.set(tree.id, tree));

  const traverse = (id: number) => {
    const node = map.get(id);

    // Якщо node не знайдено, припиняємо рекурсію
    if (!node) {
      return;
    }

    // Якщо попередній id не 0, продовжуємо рекурсію
    if (node.prev !== undefined && node.prev !== 0) {
      traverse(node.prev);
    }

    // Додаємо node до sorted лише якщо його ще немає
    if (!sorted.find((t) => t.id === id)) {
      sorted.push(node);
    }
  };

  trees.forEach((tree) => traverse(tree.id));

  return sorted;
};

// Допоміжна функція для визначення глибини вузла
const getDepth = (trees: Tree[], id: number): number => {
  let depth = 0;
  let current = trees.find((tree) => tree.id === id);
  while (current && current.prev !== undefined && current.prev !== null) {
    depth += 1;
    current = trees.find((tree) => tree.id === current!.prev);
  }
  return depth;
};
