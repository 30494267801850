import React, { useEffect, useState } from 'react';
import { BuildingPopup } from '../BuildingPopup';
import { SpeedUpPopUp } from '../SpeedUpPopUp';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { useSessionStorage } from '@uidotdev/usehooks';

export const BuildingModals = () => {
  const [currentRoomPopup, setCurrentRoomPopup] = useState<number>();
  const [openSpeed, setOpenSpeed] = useSessionStorage<any>('openSpeed', false);

  const selectedRoom = useSelector((state: RootState) => state.selectedRoom);

  useEffect(() => {
    if (selectedRoom.id !== -1 && selectedRoom.buildingId) {
      setCurrentRoomPopup(selectedRoom.id);
    } else {
      setCurrentRoomPopup(undefined);
      setOpenSpeed(false);
    }
  }, [selectedRoom]);
  return currentRoomPopup ? openSpeed ? <SpeedUpPopUp /> : <BuildingPopup /> : null;
};
