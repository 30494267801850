import React, { useEffect, useState } from 'react';
import { Resources } from '../../enums/resources';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { ReferralCodeType } from '../../endpoints/codesEndpoints';
import { ClaimOptions } from '../../containers/Farm/IslandWindow/components/DepositPopup';
import { useTranslation } from 'react-i18next';

export const DepositIcon = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const [refBalance, setRefBalance] = useState(0);
  const resources = useSelector((state: RootState) => state.resources.resources);
  const canClaim = refBalance >= 50000;
  useEffect(() => {
    const currentValue = resources.find((v) => v.resourceType === Resources.refPoints)?.value || 0;
    setRefBalance(currentValue);
    console.log('deposit useEffect');
  }, [resources]);

  return (
    <div onClick={onClick} className={`flex justify-center items-center relative w-[52px] h-[52px]`}>
      {canClaim && (
        <div className="absolute left-[-5px] top-[10px] w-[15px] h-[15px] z-30">
          <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full"></div>
        </div>
      )}
      <img
        className={`absolute w-[52px] left-[50%] translate-x-[-50%] bottom-0`}
        src={require('../../assets/images/get-code-icon.png?type=base64')}
        alt=""
      />
      <p
        className="absolute left-[50%] translate-x-[-50%] bottom-[-5px] text-[14px] text-white
        text-stroke-small  text-center leading-[12px] w-[54px] mb-[2px]"
      >
        {t('codePurchase.getCode')}
      </p>
    </div>
  );
};
