import React, { useState, useEffect, useRef, memo } from 'react';
import { BattleEffectsData, MappedEffect } from './EnemyBattleData';
import { useTranslation } from 'react-i18next';
import { BuffAnimation } from '../../components';
import { motion, Variants } from 'framer-motion';
import { EffectType } from '../../endpoints/mock';
import { useRiveFile } from '@rive-app/react-canvas';

// Варіанти анімації
const scaleAndRed: Variants = {
  visible: {
    scale: [1, 2, 1],
    transition: { duration: 0.5 },
  },
  initial: {
    scale: 1,
  },
};

const bounceAndFlash: Variants = {
  visible: {
    y: [0, -10, 0],
    opacity: [1, 0.8, 1],
    boxShadow: ['0px 0px 5px 2px #fff', '0px 0px 15px 5px #fff', '0px 0px 5px 2px #fff'],
  },
  initial: {
    y: 0,
    opacity: 1,
    boxShadow: '0px 0px 0px 0px #fff',
  },
};

// Енуми
export enum BattleEffectsLink {
  stun = '../../assets/images/effect-stun.png',
  dmg = '../../assets/images/effect-dmg.png',
}

export enum BattleEffects {
  defense,
  triggeredByEnemiesAttackCard,
  doubleDamage,
  returnDamage,
}

// Функція для отримання зображення ефекту
function getEffectImage(effect: EffectType): string {
  return require(`../../assets/images/effects/effect-${EffectType[effect]}.png`);
}

// Інтерфейс для displayAmount
interface DisplayAmount {
  [key: string]: number;
}

// Пропси компонента Effects
interface EffectsProps {
  effects: BattleEffectsData[];
  isReturnDamageAnimation: boolean;
  defence: number;
}

export const Effects: React.FC<EffectsProps> = memo(({ effects, isReturnDamageAnimation, defence }) => {
  const { t } = useTranslation();
  //console.log("EFFECTS", effects);
  // Стан для тултіпу
  const [effectTooltip, setEffectTooltip] = useState<{
    effectId: EffectType;
    amount: number;
  } | null>(null);
  const [effectList, setEffectList] = useState<MappedEffect[]>([]);

  // Масив з effectUid, які тригерять анімацію
  const [triggeredEffects, setTriggeredEffects] = useState<string[]>([]);

  // Тип анімації для кожного effectUid
  const [animationTypes, setAnimationTypes] = useState<{ [key: string]: 'bounce' | 'scale' }>({});

  // Відображувані значення amount
  const [displayAmount, setDisplayAmount] = useState<DisplayAmount>({});

  const [currentDefence, setCurrentDefence] = useState<BattleEffectsData>({
    effectId: 0,
    effectUid: 'someStringForDefene',
    amount: 0,
    duration: 0,
  });

  const { riveFile: BuffAnimationFile, status: BuffAnimationStatus } = useRiveFile({
    src: 'animation/statusvfx.riv',
  });

  // Попередні значення amount для кожного effectUid
  const prevAmounts = useRef<DisplayAmount>({});

  useEffect(() => {
    setCurrentDefence({
      effectId: 0,
      effectUid: 'someStringForDefene',
      amount: defence,
      duration: 0,
    });
  }, [defence]);

  // Обробники подій
  const handleMouseDown = (effect: BattleEffectsData) => {
    setEffectTooltip({ effectId: effect.effectId, amount: effect.amount ? effect.amount : 0 });
  };

  const handleMouseUp = () => {
    setEffectTooltip(null);
  };

  const handleMouseLeave = () => {
    setEffectTooltip(null);
  };

  const handleTouchStart = (effect: BattleEffectsData) => {
    setEffectTooltip({ effectId: effect.effectId, amount: effect.amount ? effect.amount : 0 });
  };

  const handleTouchEnd = () => {
    setEffectTooltip(null);
  };

  // useEffect для обробки змін у масиві effects
  useEffect(() => {
    //console.log("NEW EFFECTS IN EFFECTS USEEFFECT", effects);
    if (effectList.length > 0) {
      const updatedAnimationTypes: { [key: string]: 'bounce' | 'scale' } = {};
      const updatedTriggeredEffects: string[] = [];
      const timers: NodeJS.Timeout[] = []; // Для зберігання таймерів

      effectList
        .filter((e: MappedEffect) => e && e.data !== null)
        .forEach((effect: MappedEffect) => {
          //const { effectUid , effectId, amount, duration } = effect.data;
          const effectUid = effect.data?.effectUid!;
          const effectId = effect.data?.effectId!;
          const amount = effect.data?.amount!;
          const duration = effect.data?.duration!;
          // console.log("prevAmounts", effects);

          const prevAmount = prevAmounts.current[effectUid] || 0;

          const amountStrategy = {
            someStringForDefense: {},
          };

          if (amount && amount! > prevAmount) {
            // Amount increased
            updatedAnimationTypes[effectUid] = 'bounce';

            setDisplayAmount((prev) => ({
              ...prev,
              [effectUid]: amount,
            }));

            updatedTriggeredEffects.push(effectUid);
          } else if (amount && amount < prevAmount) {
            // Amount decreased
            updatedAnimationTypes[effectUid] = 'scale';
            setDisplayAmount((prev) => ({
              ...prev,
              [effectUid]: prevAmount, // Показуємо старе значення
            }));
            updatedTriggeredEffects.push(effectUid);

            // Встановлюємо таймер для оновлення до нового значення після анімації
            const timer = setTimeout(() => {
              setDisplayAmount((prev) => ({
                ...prev,
                [effectUid]: amount,
              }));
              // Видаляємо effectUid з triggeredEffects після анімації
              setTriggeredEffects((prev) => prev.filter((uid) => uid !== effectUid));
              // Видаляємо тип анімації
              setAnimationTypes((prev) => {
                const newTypes = { ...prev };
                delete newTypes[effectUid];
                return newTypes;
              });
            }, 500); // Тривалість анімації scaleAndRed

            timers.push(timer);
          } else {
            // Якщо amount не змінився, пропускаємо
            return;
          }

          // Оновлюємо попереднє значення
          prevAmounts.current[effectUid] = amount!;
        });

      // Оновлюємо animationTypes
      setAnimationTypes((prev) => ({
        ...prev,
        ...updatedAnimationTypes,
      }));

      // Встановлюємо triggeredEffects
      setTriggeredEffects((prev) => [...prev, ...updatedTriggeredEffects]);

      // Очищаємо таймери при відміні компонента або змінах
      return () => {
        timers.forEach((timer) => clearTimeout(timer));
      };
    }
    if (effectList.filter((e: MappedEffect) => e && e.data?.effectId === effectTooltip?.effectId).length < 1) {
      setEffectTooltip(null);
    }
  }, [effectList]);

  useEffect(() => {
    // console.log("displayAmount", displayAmount)
  }, [displayAmount]);

  const getEffectDetails = (effect: BattleEffectsData) => {
    let effectDetails: MappedEffect | null;
    switch (effect.effectId) {
      case EffectType.defense: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.doubleDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.lessCardPerRound: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.returnDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.negateAttack: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.lessDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.additionalDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.additionalDefense: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.arcaneEcho: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.mindBreak: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: -1,
            duration: effect.duration,
          },
        };
        break;
      }
      case EffectType.restoreHealthByDamage: {
        effectDetails = {
          data: {
            effectId: effect.effectId,
            effectUid: effect.effectUid,
            amount: effect.amount,
            duration: effect.duration,
          },
        };
        break;
      }
      default: {
        effectDetails = null;
      }
    }
    return effectDetails;
  };

  useEffect(() => {
    if (effects) {
      let newEffectList = effects.map((e) => getEffectDetails(e));
      if (currentDefence?.amount! > 0) {
        newEffectList.push(getEffectDetails(currentDefence));
      }
      //@ts-ignore
      setEffectList(newEffectList);
    }
  }, [effects, currentDefence]);

  useEffect(() => {
    // console.log("effectList", effectList);
  }, [effectList]);

  // useEffect для обробки isReturnDamageAnimation
  useEffect(() => {
    if (isReturnDamageAnimation) {
      const returnDamageEffects = effects.filter((effect) => effect.effectId === EffectType.returnDamage);

      if (returnDamageEffects.length > 0) {
        const returnDamageEffect = returnDamageEffects[0];
        const { effectUid, effectId, amount } = returnDamageEffect;

        // Порівнюємо з попереднім значенням
        const prevAmount = prevAmounts.current[effectUid] || 0;

        if (amount && amount > prevAmount) {
          setAnimationTypes((prev) => ({
            ...prev,
            [effectUid]: 'bounce',
          }));
        } else if (amount && amount < prevAmount) {
          setAnimationTypes((prev) => ({
            ...prev,
            [effectUid]: 'scale',
          }));
        }

        // Оновлюємо попереднє значення
        prevAmounts.current[effectUid] = amount ? amount : 0;

        if (amount && amount < prevAmount) {
          // Amount decreased
          setDisplayAmount((prev) => ({
            ...prev,
            [effectUid]: prevAmount, // Показуємо старе значення
          }));

          // Тригеримо анімацію
          setTriggeredEffects((prev) => [...prev, effectUid]);

          // Встановлюємо таймер для оновлення до нового значення після анімації
          const timer = setTimeout(() => {
            setDisplayAmount((prev) => ({
              ...prev,
              [effectUid]: amount,
            }));
            setTriggeredEffects((prev) => prev.filter((uid) => uid !== effectUid));
            setAnimationTypes((prev) => {
              const newTypes = { ...prev };
              delete newTypes[effectUid];
              return newTypes;
            });
          }, 500); // Тривалість анімації scaleAndRed

          return () => clearTimeout(timer);
        } else {
          // Amount increased
          setDisplayAmount((prev) => ({
            ...prev,
            [effectUid]: amount ? amount : 0,
          }));
          setTriggeredEffects((prev) => [...prev, effectUid]);

          const timer = setTimeout(() => {
            setTriggeredEffects((prev) => prev.filter((uid) => uid !== effectUid));
            setAnimationTypes((prev) => {
              const newTypes = { ...prev };
              delete newTypes[effectUid];
              return newTypes;
            });
          }, 1500); // Тривалість анімації bounceAndFlash

          return () => clearTimeout(timer);
        }
      }
    }
  }, [isReturnDamageAnimation, effects]);

  return (
    <>
      <div className="absolute flex flex-row bottom-[102px] z-0 gap-2">
        {effectList
          .filter((e: MappedEffect) => e && e.data !== null)
          .map((effectData: MappedEffect) => (
            <div key={effectData.data?.effectUid}>
              <motion.div
                className="flex w-[34px] h-[34px] ml-[4px] justify-center items-center text-center relative"
                onMouseDown={() => handleMouseDown(effectData.data!)}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onTouchStart={() => handleTouchStart(effectData.data!)}
                onTouchEnd={handleTouchEnd}
                variants={animationTypes[effectData.data?.effectUid!] === 'bounce' ? bounceAndFlash : scaleAndRed}
                initial="initial"
                animate={triggeredEffects.includes(effectData.data?.effectUid!) ? 'visible' : 'initial'}
                transition={{ duration: 0.5 }}
              >
                <div
                  className={`${
                    triggeredEffects.includes(effectData.data?.effectUid!) ? 'flex' : 'hidden'
                  } justify-center items-center absolute w-10 h-10 z-10`}
                >
                  {BuffAnimationFile && <BuffAnimation riveFile={BuffAnimationFile} />}
                </div>
                <img
                  src={getEffectImage(effectData.data?.effectId!)}
                  className="absolute w-[34px] h-[34px] rounded-[6px]"
                  alt=""
                />
                <div
                  className={`text-[18px] text-stroke-regular z-[1] ${
                    animationTypes[effectData.data?.effectUid!] === 'scale' ? 'text-red-500' : 'text-white'
                  }`}
                >
                  {/* {effectData.data?.amount} */}
                  {displayAmount[effectData.data?.effectUid!] > 0 ? displayAmount[effectData.data?.effectUid!] : ''}
                </div>
              </motion.div>
            </div>
          ))}
      </div>
      {effectTooltip && (
        <div
          className={`${
            effectTooltip ? 'flex' : 'hidden'
          } absolute bottom-[150px] left-[8px] right-[8px] bg-[rgba(25,25,27,0.9)] text-white py-2.5 px-4 z-10 rounded  items-center`}
        >
          <img src={getEffectImage(effectTooltip.effectId)} className="w-[28px] h-[28px] rounded-[6px] mr-2" alt="" />
          <div
            dangerouslySetInnerHTML={{
              __html: t('effects.descriptions.' + EffectType[effectTooltip.effectId], {
                amount: '<span class="text-[#ff3a3a]">' + effectTooltip.amount + '</span>',
              }),
            }}
          />
        </div>
      )}
    </>
  );
});
