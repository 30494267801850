import { useEffect, useMemo, useState } from 'react';
import { ArtefactCard } from '../../../../components';
import { Artefacts } from '../../../../endpoints/mock';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { mapToFullArtefacts } from '../../../../utils/artefactsParser';
import { useTranslation } from 'react-i18next';

export const ShrinesModal = ({ artefactsData, expanded }: { artefactsData: Artefacts[]; expanded: boolean }) => {
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { t } = useTranslation();
  const mappedArtefacts = useMemo(() => {
    if (!artefactsData || !appConfig) return [];

    if (!appConfig.skills) return [];

    const configArtefacts = appConfig.skills.variables;

    return mapToFullArtefacts(artefactsData, configArtefacts);
  }, [artefactsData, appConfig]);

  const [shrineExpand, setShrineExpand] = useState(false);

  useEffect(() => {
    setShrineExpand(false);
  }, [expanded]);

  return (
    <>
      <button
        onClick={() => {
          setShrineExpand(true);
        }}
        className={`absolute w-[50px] h-[50px] right-[9px] bottom-[101px] z-[1] `}
      >
        <img src={require('../../../../assets/images/artifact-icon-button.png')} alt="" />
      </button>
      <div
        onClick={() => {
          setShrineExpand(false);
        }}
        className={`absolute ${shrineExpand ? 'static' : 'hidden'}  w-full h-full z-[8] `}
      ></div>
      <div
        className={` absolute ${
          shrineExpand ? 'flex' : 'hidden'
        } flex-col items-center px-[16px] pb-[16px] right-2 bottom-[156px] max-h-[240px] w-[188px]  bg-[#19191b] z-[11] rounded-lg border border-[#534737] overflow-auto`}
      >
        <p
          className="text-white text-[12px] py-[7px]"
          style={{
            filter:
              'drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)',
          }}
        >
          {t('artefacts.name')}
        </p>
        <div className="flex flex-col gap-[5px]">
          {mappedArtefacts.length ? mappedArtefacts.map((artefact) => <ArtefactCard artefact={artefact} />) : null}
        </div>
      </div>
    </>
  );
};
