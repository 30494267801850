import { useEffect, useState } from 'react';
import { PopupButton } from '../../../../components/PopupButton';
import Timer from '../Timer';
import { motion } from 'framer-motion';
import { OfferProps } from '../Offer';
import { usePurchase } from '../../../../hooks/usePurchase';
import { claimFreebie, getDailyFreebieStatus } from '../../../../endpoints/getProfileInfo';
import { useTelegram } from '../../../../hooks/useTelegram';
import { SuccessOverlay } from '../../../../components/SuccessOverlay';
import { OpenLootBox } from '../../../../containers/Inventory/components';
import { handleRewardResult } from '../../../../utils/rewardParse';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { DisplayData, handleLootBoxResult } from '../../../../utils/lootBoxHandler';
import { getBalance } from '../../../../endpoints/farmMock';
import { useSessionStorage } from '@uidotdev/usehooks';
import { defineCountString } from '../../../../utils/farmConfigParser';
import { useSoundService } from '../../../../utils/soundService';
import { useTranslation } from 'react-i18next';
export interface DailySaleOfferProps {
  offerStatus?: boolean;
  offers: OfferProps[];
  heroId?: number;
  deadline?: string;
}

const freebieRewards = [
  {
    rewardType: 2,
    rewardId: 0,
    amount: 5,
  },
  {
    rewardType: 2,
    rewardId: 1,
    amount: 1500,
  },
];

export const DailySaleOffer = (props: DailySaleOfferProps) => {
  const { offerStatus = false, heroId = 0, deadline = 'December 31, 2025', offers } = props;

  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const { tg, userId } = useTelegram();
  const [dailyOffer, setDailyOffer] = useState<OfferProps>();
  const [getRewards, setGetRewards] = useState<boolean>(false);
  const [canClaimFreebie, setCanClaimFreebie] = useSessionStorage('canClaimFreebie', false);
  const { playSound } = useSoundService();
  const { t } = useTranslation();

  useEffect(() => {
    setDailyOffer(offers.filter((offer) => offer.id === 29)[0]);
  }, [offers]);

  const handleClaimFreebie = async () => {
    const chestInfo = await claimFreebie({ clientId: userId });
    if (chestInfo) {
      setGetRewards(true);
      playSound('questsReward');

      setTimeout(() => {
        setGetRewards(false);
      }, 5000);
      setCanClaimFreebie(false);
      const validateResources = await getBalance({ clientId: userId });
    }
    //onChestClaim()
    setTimeout(() => {
      const getChestInfo = async () => {
        const chestInfo = await getDailyFreebieStatus({ clientId: userId });
        if (chestInfo) {
          setCanClaimFreebie(chestInfo.isCanClaim);
        }
      };
      getChestInfo();
    }, 1000);
  };

  // const now = new Date();
  // const nextMidnight = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1)).toDateString();
  // const nextMidnight = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));

  const rewards = freebieRewards.map((reward) => handleLootBoxResult(reward));
  return (
    <motion.div
      initial={{ opacity: '0%' }}
      animate={{ opacity: '100%' }}
      transition={{ duration: '0.3' }}
      className={`relative w-full h-full `}
    >
      {getRewards && rewards !== null && (
        //@ts-ignore
        <OpenLootBox openBoxName="Rewards Claimed" onClose={() => setGetRewards(false)} rewards={rewards} />
      )}
      <div className={`w-full`}>
        <div className={`flex flex-col items-start w-fit ml-2 `}>
          <div className="">{t('shop.tabs.dailySale')}</div>
          <div className={`flex items-center gap-1`}>
            <img className="w-[16px] h-[16px]" src={require('../../../../assets/images/shop/offer/clock.png')} alt="" />
            <Timer />
          </div>
        </div>
        <div
          onClick={() => handleClaimFreebie()}
          className={`absolute w-[65px] h-[65px] left-[15px] bottom-[70px] ${canClaimFreebie ? '' : 'grayscale pointer-events-none'} `}
        >
          {canClaimFreebie && (
            <div className="absolute right-0 w-[12px] h-[12px] z-[10]">
              <div className="w-3 min-w-3 h-3 min-h-3 bg-[#ff3a3a] border border-[black] rounded-full absolute right-0 z-10"></div>
            </div>
          )}
          <div className={`w-[66px] h-[66px] ${canClaimFreebie ? 'animate-readyToClaim' : ''} `}>
            <img
              className={`absolute w-full h-full`}
              src={require('../../../../assets/images/shop/offer/daily-sale-freebie.png')}
              alt=""
            />
          </div>
          <p className="absolute bottom-[-5px] z-[5] w-[calc(100%+30px)] left-[-15px] text-center text-stroke-small text-[#FFF3DB] font-[800] leading-[normal]">
            {canClaimFreebie ? t('shop.dailySale.dailyFreebie') : ''}
          </p>
        </div>
        <div className={`absolute uppercase right-[10px] bottom-[60px] text-white text-stroke-small text-[28px]  z-10`}>
          {t('heroes.0')}
        </div>
        <div className={`w-[60%] absolute right-0 bottom-0`}>
          <img
            className={`relative w-full h-full z-[5]`}
            src={require(`../../../../assets/images/shop/offer/daily-sale-character${heroId}.png`)}
            alt=""
          />
          <img
            className={`absolute top-0 w-[calc(100%+20px)] h-full`}
            src={require('../../../../assets/images/shop/offer/daily-sale-character-highlights.png')}
            alt=""
          />
          <img
            className={`absolute top-0 w-[calc(100%+20px)] h-full`}
            src={require('../../../../assets/images/shop/offer/daily-sale-character-highlights1.png')}
            alt=""
          />
        </div>
      </div>

      {/* Offer's bottom panel */}
      <motion.div
        initial={{ transform: 'translateY(20%)', opacity: '0%' }}
        animate={{ transform: 'translateY(0%)', opacity: '100%' }}
        transition={{ delay: 0.3, duration: '0.3' }}
        className={`absolute flex justify-between items-center bottom-0 w-full py-1 bg-gradient-to-r from-[#e7e58e] to-[#fb8e4d] z-10 `}
      >
        <div className="flex items-center gap-1">
          <div className="relative">
            <img
              className={`w-[96px] h-[46px]`}
              src={require('../../../../assets/images/shop/offer/daily-sale-label.png')}
              alt=""
            />
            <p className={`absolute top-2 text-[20px] text-stroke-small ml-[10px]`}>3450%</p>
          </div>
          <p className="text-[#372C25] font-[900] text-[1rem] max-[367px]:text-[14px]">
            {t('shop.dailySale.description')} <br /> {t('shop.dailySale.description2')}
          </p>
        </div>
        {/* Some */}
        {offers.map((offer) => offer.id === 29).length > 0 && dailyOffer ? (
          <PopupButton
            type={offerStatus ? 'gray' : 'gold'}
            width="80px"
            height="20px"
            onClick={() => confirmPurchase({ offerId: dailyOffer!.id })}
            className={`mr-2 ${offerStatus ? 'pointer-events-none' : ''} `}
          >
            <div
              className={` absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[70%] translate-y-[10%] `}
            >
              <div
                className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
              >
                <img
                  src={require('../../../../assets/images/shop/offer/notify.png')}
                  className={`w-[4em] aspect-square -translate-y-[5%] `}
                  alt=""
                />
                {defineCountString(Math.floor(Number(dailyOffer!.price ? dailyOffer!.price : 0) / 10))}
              </div>
            </div>
            <div className={` flex flex-col `}>
              <div className="relative">
                <img
                  className={'absolute w-full h-full'}
                  src={require('../../../../assets/images/shop/offer/price-cross.png')}
                  alt=""
                />
                <p className={`text-[#806649] text-stroke-none text-[12px] font-[600]`}>468</p>
              </div>
              <div className={`flex justify-center w-full text-center`}>
                <img
                  className={`w-[13px] h-[13px]`}
                  src={require('../../../../assets/images/telegram-stars-icon.png')}
                  alt=""
                />
                {dailyOffer!.price}
              </div>
            </div>
          </PopupButton>
        ) : (
          <PopupButton type="gray" width="80px" height="20px" className={`mr-2 pointer-events-none `}>
            <div className={` flex flex-col `}>
              <div className="relative">
                <img
                  className={'absolute w-full h-full'}
                  src={require('../../../../assets/images/shop/offer/price-cross.png')}
                  alt=""
                />
                <p className={`text-[#54320E] text-stroke-none text-[12px] font-[600]`}>468</p>
              </div>
              <div className={`flex justify-center w-full text-center`}>
                <img
                  className={`w-[13px] h-[13px]`}
                  src={require('../../../../assets/images/telegram-stars-icon.png')}
                  alt=""
                />
                330
              </div>
            </div>
          </PopupButton>
        )}
      </motion.div>
      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </motion.div>
  );
};
