import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Claim, RoomProps } from './interface';
import { useDispatch, useSelector } from 'react-redux';
import { useUtils } from '../../../utils/navigateTo';
import { AppDispatch, RootState, store } from '../../../app/store';
import { RoomStatus } from '../../../enums/buildingStatus';
import { setSelectedRoom } from '../../../app/features/selectedRoom';
import { setSelectedIsland } from '../../../app/features/selectedIsland';
import { useTelegram } from '../../../hooks/useTelegram';
import { upgradeBuilding } from '../../../endpoints/farmMock';
import { updateBattleSave } from '../../../utils/updateBattleSave';
import { TutorialFarmStage } from '../../../interfaces/tutorial';
import { saveFarmTutorialProgress } from '../../../app/features/farmTutoralSlice';
import { getDungeonProgressByDungeonId } from '../../../endpoints/dungeonEndpoints';
import { useSessionStorage } from '@uidotdev/usehooks';
//import * as amplitude from "@amplitude/analytics-browser";
import { useFarm } from '../../../pages/Farm/useFarm';
import { useSoundService } from '../../../utils/soundService';

export interface RoomDisplay {
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
}

export function useRoom(props: RoomProps) {
  const { room, index, scheme } = props;
  const { playSound } = useSoundService();

  const [roomDisplay, setRoomDisplay] = useState<RoomDisplay>({
    x: scheme.left,
    y: scheme.top,
    width: scheme.width,
    height: scheme.height,
    zIndex: scheme.zIndex,
  });
  const { buildingConfigs } = useSelector((state: RootState) => state.config);
  const [startSelectHero, setStartSelectHero] = useSessionStorage('startSelectHero', false);
  const [isDungeonStart, setIsDungeonStart] = useSessionStorage('isDungeonStart', false);

  const [currentDungeonId, setCurrentDungeonId] = useSessionStorage('currentDungeonId', 0);
  const { islands } = useFarm();
  const dispatch = useDispatch<AppDispatch>();
  const { navigateTo } = useUtils();

  const { tg, userId } = useTelegram();
  const hasBuildingCompletedRef = useRef(false);

  const selectedHero = useSelector((state: RootState) => state.selectedHero);

  const heroId = Number(selectedHero.uid);
  const selectedIsland = useSelector((state: RootState) => state.selectedIsland.selectedIsland);

  const building = useMemo(() => {
    if (!buildingConfigs) return undefined; // Return undefined if buildingConfigs is null

    return scheme.buildingId
      ? buildingConfigs.find((v) => v.id === scheme.buildingId)
      : buildingConfigs.find((v) => v.id === room?.buildingId);
  }, [scheme.buildingId, room?.buildingId, buildingConfigs]);

  const windowDimensions = useMemo(
    () => ({
      width: document.querySelector('main')?.offsetWidth || document.documentElement.clientWidth,
      height: document.querySelector('main')?.offsetHeight || document.documentElement.clientHeight,
    }),
    [document.querySelector('main')?.offsetWidth, document.querySelector('main')?.offsetHeight]
  );

  const calculateRoomDisplay = useCallback(() => {
    const bgWidth = 1080;
    const bgHeight = 1850;

    const xk = windowDimensions.width / bgWidth;
    const yk = windowDimensions.height / bgHeight;

    return {
      x: scheme.left * xk,
      y: scheme.top * yk,
      width: scheme.width * xk,
      height: scheme.height * yk,
      zIndex: scheme.zIndex,
    };
  }, [scheme, windowDimensions]);

  useEffect(() => {
    setRoomDisplay(calculateRoomDisplay());
  }, [calculateRoomDisplay]);

  useEffect(() => {
    if (islands) {
      dispatch(
        setSelectedIsland({
          island: islands.find((v) => v.id === selectedIsland?.id)!,
        })
      );
    }
  }, [dispatch, islands, selectedIsland?.id]);

  const [triggerEndBuild, setTriggerEndBuild] = useState(false);

  useEffect(() => {
    if (!building || !room) return;

    // If the room status is not 'building' anymore, reset the ref
    if (room.status !== RoomStatus.building && hasBuildingCompletedRef.current) {
      hasBuildingCompletedRef.current = false;
    }
  }, [room?.status]);

  const [isConfirmationRequired, setIsConfirmationRequired] = useState(false);

  // Стан туторіалу: чи завершено
  const isFarmTutorialCompleted = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save?.completed
  );

  // Стадія туторіалу
  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const isBattle = useCallback(() => {
    const currentIsland = store.getState().selectedIsland.selectedIsland;
    if (!isFarmTutorialCompleted && scheme.id === 2) {
      
      if (room?.status === RoomStatus.repairing) {
        return room.status;
      } 
      if (room?.status === RoomStatus.builded) {
        return room.status;
      }

      if (farmTutorialSave?.stage === TutorialFarmStage.repair) {
        return RoomStatus.repair;
      }

      if (farmTutorialSave?.stage === TutorialFarmStage.startRepair) {
        return RoomStatus.building;
      }

      // if (!farmTutorialSave?.dialogueId && !farmTutorialSave?.stage) {
      //   return RoomStatus.inactive;
      // }

      // if (farmTutorialSave?.stage !== TutorialFarmStage.building && farmTutorialSave?.dialogueId! <= 2) {
      //   return RoomStatus.inactive;
      // }

      return room?.status ? room.status : RoomStatus.battle;
    }

    if (currentIsland?.buildings.length) {
      const lastRoomId = currentIsland?.buildings[currentIsland.buildings.length - 1].id;

      const lastRoomStatus = currentIsland?.buildings[currentIsland.buildings.length - 1].status;

      if (
        lastRoomId + 1 === scheme.id &&
        lastRoomStatus !== RoomStatus.battle &&
        lastRoomStatus !== RoomStatus.farming &&
        lastRoomStatus !== RoomStatus.builded
      ) {
        return RoomStatus.battle;
      } else {
        return room?.status ? room.status : RoomStatus.inactive;
      }
    } else {
      return index === 1 ? RoomStatus.battle : RoomStatus.inactive;
    }
  }, [index, room?.status, scheme.id, farmTutorialSave?.stage]);

  const getDungeonSaveResult = async () => {
    const result = await getDungeonSave();
    setIsDungeonStart(result);
    return result;
  };

  const getDungeonSave = async () => {
    try {
      //@ts-ignore
      const progress = await getDungeonProgressByDungeonId({
        clientId: userId,
        buildingId: building?.id,
        heroId: heroId,
      });

      if (progress) {
        setCurrentDungeonId(progress.currentDungeonId);
        // console.log("PROGRESS!!!!", progress);
        const maxStageId = Math.max(...progress.stages.map((stage) => stage.stageId));

        // Знаходимо об'єкт з цим stageId
        const maxStage = progress.stages.find((stage) => stage.stageId === maxStageId);
        // Перевіряємо, чи id цього об'єкта більше за 3
        return maxStage ? maxStage.stageId > 3 : false;
      }
      return false;
    } catch (error) {
      console.error('Error fetching dungeon progress:', error);
      return false;
    }
  };

  const onClickStartDungeon = useCallback(() => {
    const getDungeonSaveOrStartDungeon = async () => {
      //@ts-ignore
      const progress = await getDungeonProgressByDungeonId({
        clientId: userId,
        buildingId: building?.id,
        heroId: heroId,
      });
      console.log('STAGES', progress?.stages?.length);
      if (progress?.stages?.length) {
        if (progress.currentDungeonId && building?.id) {
          updateBattleSave({
            save: {
              gold: progress.gold,
              currentHp: progress.currentHp,
              currentMana: progress.currentMana,
              stages: progress.stages,
              dungeonId: progress.currentDungeonId,
              buildingId: building?.id,
              bossId: undefined,
            },
            clientId: userId,
          });
          navigateTo('/dungeon');
        }
      }
    };
    // @ts-ignore
    getDungeonSaveOrStartDungeon({
      clientId: userId,
      // @ts-ignore
      dungeonId: props?.dungeon?.dungeonId,
    });
    setTimeout(() => {
      setStartSelectHero(false);
      setIsDungeonStart(false);
    }, 500);
  }, [dispatch, index, scheme.buildingId, selectedIsland, building?.id]);

  const onClickBattle = useCallback(async () => {
    console.log('onClickBattle');

    dispatch(setSelectedRoom({ id: index }));
    const dungeonStarted = await getDungeonSaveResult();

    if (dungeonStarted) {
      return null;
    }

    setStartSelectHero(true);
  }, [dispatch, index, scheme.buildingId, selectedIsland, building?.id]);

  const dialogueId: number = farmTutorialSave?.dialogueId ?? 1;

  const onClickRepair = useCallback(async () => {
    if (building) {
      const data = await upgradeBuilding({
        islandId: selectedIsland?.id!,
        buildingId: index,
        clientId: userId,
        instabuild: false,
      });

      if (
        !isFarmTutorialCompleted &&
        farmTutorialSave?.stage &&
        farmTutorialSave?.stage < TutorialFarmStage.startRepair
      ) {
        dispatch(
          saveFarmTutorialProgress({
            clientId: userId,
            save: {
              dialogueId: dialogueId + 2,
              stage: TutorialFarmStage.startRepair,
            },
          })
        );
      }
    }
  }, [dispatch, index, building, selectedIsland?.id]);

  const onClick = useCallback(async () => {
    switch (isBattle()) {
      case RoomStatus.battle:
        return onClickBattle();
      case RoomStatus.inactive:
        return;
      case RoomStatus.repair:
        return onClickRepair();
      default:
        return;
    }
  }, [isBattle, onClickRepair]);

  const boosts = useSelector((state: RootState) => state.inventory.boosts);

  const heroesListRef = useRef<HTMLDivElement>(null);
  const [heroesListScroll, setHeroesListScroll] = useState(false);
  const [selectedHeroUid, setSelectedHeroUid] = useState<string | null>(null);

  useEffect(() => {
    const checkHorizontalScroll = () => {
      const container = heroesListRef.current;
      if (container) {
        const hasScroll = container.scrollWidth > container.clientWidth;
        setHeroesListScroll(hasScroll);
      }
    };

    checkHorizontalScroll();
    window.addEventListener('resize', checkHorizontalScroll);

    return () => {
      window.removeEventListener('resize', checkHorizontalScroll);
    };
  }, []);

  return {
    isDungeonStart,
    getDungeonSave,
    startSelectHero,
    onClickStartDungeon,
    setStartSelectHero,
    isConfirmationRequired,
    boosts,
    triggerEndBuild,
    onClick,
    building,
    isBattle,
    roomDisplay,
    heroesListRef,
    heroesListScroll,
    selectedHeroUid,
    setSelectedHeroUid,
  };
}
