import { memo, useEffect, useRef, useState } from 'react';
import { RoomStatus } from '../../../../../enums/buildingStatus';
import { BuildingConfig } from '../../../../../interfaces/farm';
import 'react-circular-progressbar/dist/styles.css';
import { SwordsAnimation } from '../../../../../components/animation/SwordsAnimation';
import { useDispatch } from 'react-redux';
import { markStepAsLoaded } from '../../../../../app/features/loadingManagerSlice';
import ReImg from '../../../../../components/ReloadableImage';
import { useSessionStorage } from '@uidotdev/usehooks';

export const RenderRoom = memo(
  (props: { triggerEndBuild: boolean; status?: RoomStatus; building?: BuildingConfig }) => {
    const { status = RoomStatus.inactive, building, triggerEndBuild } = props;
    console.log('inside renderroom');

    const [buildAnimation, setEndBuildAnimation] = useState(true);

    const [isValentineDayOfferBought, setIsValentineDayOfferBought] = useSessionStorage('isValentineDayOfferBought', false);
    
    const handleEndBuilding = () => {
      setTimeout(() => {
        setEndBuildAnimation(false);
        setTimeout(() => {
          setEndBuildAnimation(true);
        }, 5000);
      }, 1000);
    };

    useEffect(() => {
      if (triggerEndBuild) {
        handleEndBuilding();
      }
    }, [triggerEndBuild]);

    const dispatch = useDispatch();

    const handleBuildingsLoad = () => {
      dispatch(markStepAsLoaded('buildings'));
    };

    switch (status) {
      case RoomStatus.farming:
        return (
          <>
            {building && (
              <ReImg
                onLoad={handleBuildingsLoad}
                className="absolute z-[53] opacity-30 w-full h-full flex items-center justify-center object-contain"
                src={require(`../../../../../assets/images/buildings/build${building.id}${isValentineDayOfferBought && building.id === 1 ? 'Valentine' : ''}.png?type=base64`)}
              />
            )}
            {building && (
              <ReImg
                className="w-full h-full flex items-center justify-center object-contain"
                src={require(`../../../../../assets/images/buildings/build${building.id}${isValentineDayOfferBought && building.id === 1 ? 'Valentine' : ''}.png?type=base64`)}
              />
            )}
          </>
        );
      case RoomStatus.builded:
        return (
          <>
            {building && (
              <ReImg
                className="absolute z-[53] opacity-30 w-full h-full flex items-center justify-center object-contain"
                src={require(`../../../../../assets/images/buildings/build${building.id}${isValentineDayOfferBought && building.id === 1 ? 'Valentine' : ''}.png?type=base64`)}
              />
            )}
            {building && (
              <ReImg
                className="w-full h-full flex items-center justify-center object-contain"
                src={require(`../../../../../assets/images/buildings/build${building.id}${isValentineDayOfferBought && building.id === 1 ? 'Valentine' : ''}.png?type=base64`)}
              />
            )}
          </>
        );
      case RoomStatus.inactive:
        return (
          <div className={`w-full h-full flex items-center justify-center brightness-75`}>
            {building && (
              <ReImg
                className={`flex h-full w-full rounded-[15px] object-contain`}
                src={require(`../../../../../assets/images/buildingNeedRepaired/build${building.id}.webp?type=base64`)}
              />
            )}
          </div>
        );
      case RoomStatus.building:
        return (
          <button className="w-full h-full items-center flex justify-center flex-col p-1">
            {building && (
              <ReImg
                className="absolute z-[53] opacity-30 flex h-full w-full rounded-[15px] brightness-[30%] object-contain"
                src={require(`../../../../../assets/images/buildings/build${building.id}${isValentineDayOfferBought && building.id === 1 ? 'Valentine' : ''}.png?type=base64`)}
              />
            )}
            {building && (
              <ReImg
                className="flex h-full w-full rounded-[15px] brightness-[30%] object-contain"
                src={require(`../../../../../assets/images/buildings/build${building.id}${isValentineDayOfferBought && building.id === 1 ? 'Valentine' : ''}.png?type=base64`)}
              />
            )}
          </button>
        );
      case RoomStatus.battle:
        return (
          <>
            <div className="absolute flex brightness-100 z-[56] w-[75%] h-[75%]">
              <SwordsAnimation />
            </div>

            {building && (
              <ReImg
                className={`absolute flex h-full w-full rounded-[15px]  bg-opacity-50 opacity-30 z-[55] object-contain`}
                src={require(`../../../../../assets/images/buildingNeedRepaired/build${building.id}.webp?type=base64`)}
              />
            )}
            {building && (
              <ReImg
                className={`flex h-full w-full rounded-[15px]  bg-opacity-50 object-contain`}
                src={require(`../../../../../assets/images/buildingNeedRepaired/build${building.id}.webp?type=base64`)}
              />
            )}
          </>
        );
      case RoomStatus.empty:
        return (
          <div className="w-full h-full items-center flex justify-center flex-col p-1 ">
            <ReImg
              src={require('../../../../../assets/images/farmIcons/buildIco.png?type=base64')}
              className="absolute object-contain"
            />
            <div className="absolute z-30 self-end mr-[-20px]">
              <Arrow />
            </div>
            <div className="w-full h-full bg-slate-800 rounded-[12px]" />
          </div>
        );
      case RoomStatus.repair:
        return (
          <div className="w-full h-full flex items-center justify-center p-1 relative ">
            <div className="flex absolute top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[54] w-12 h-12 items-center justify-center">
              <div className="absolute z-[54] w-12 h-12">
                <svg
                  width="36"
                  height="40"
                  viewBox="0 0 36 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full h-full "
                >
                  <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_dii_2993_11069)">
                      <mask
                        id="path-1-outside-1_2993_11069"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="0"
                        width="34"
                        height="38"
                        fill="black"
                      >
                        <rect fill="white" x="1" width="34" height="38" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22.1132 32.4663C28.9566 30.6511 34 24.4145 34 17C34 8.16344 26.8366 1 18 1C9.16344 1 2 8.16344 2 17C2 24.4145 7.04339 30.6511 13.8868 32.4663L17.6297 36.5918C17.8281 36.8106 18.1719 36.8106 18.3703 36.5918L22.1132 32.4663Z"
                        />
                      </mask>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.1132 32.4663C28.9566 30.6511 34 24.4145 34 17C34 8.16344 26.8366 1 18 1C9.16344 1 2 8.16344 2 17C2 24.4145 7.04339 30.6511 13.8868 32.4663L17.6297 36.5918C17.8281 36.8106 18.1719 36.8106 18.3703 36.5918L22.1132 32.4663Z"
                        fill="url(#paint0_linear_2993_11069)"
                      />
                      <path
                        d="M22.1132 32.4663L21.8568 31.4998L21.5712 31.5755L21.3726 31.7944L22.1132 32.4663ZM13.8868 32.4663L14.6274 31.7944L14.4288 31.5755L14.1432 31.4998L13.8868 32.4663ZM17.6297 36.5918L18.3703 35.9199L17.6297 36.5918ZM18.3703 36.5918L17.6297 35.9199H17.6297L18.3703 36.5918ZM33 17C33 23.9497 28.2728 29.7979 21.8568 31.4998L22.3696 33.4329C29.6404 31.5043 35 24.8793 35 17H33ZM18 2C26.2843 2 33 8.71573 33 17H35C35 7.61116 27.3888 0 18 0V2ZM3 17C3 8.71573 9.71573 2 18 2V0C8.61116 0 1 7.61116 1 17H3ZM14.1432 31.4998C7.72718 29.7979 3 23.9497 3 17H1C1 24.8793 6.3596 31.5043 13.6304 33.4329L14.1432 31.4998ZM18.3703 35.9199L14.6274 31.7944L13.1462 33.1383L16.8891 37.2638L18.3703 35.9199ZM17.6297 35.9199C17.8281 35.7012 18.1719 35.7012 18.3703 35.9199L16.8891 37.2638C17.4844 37.9199 18.5156 37.9199 19.1109 37.2638L17.6297 35.9199ZM21.3726 31.7944L17.6297 35.9199L19.1109 37.2638L22.8538 33.1383L21.3726 31.7944Z"
                        fill="black"
                        mask="url(#path-1-outside-1_2993_11069)"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_dii_2993_11069"
                        x="0"
                        y="-2"
                        width="36"
                        height="41.7559"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2993_11069" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2993_11069" result="shape" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.36 0" />
                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2993_11069" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="-2" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                        <feBlend
                          mode="normal"
                          in2="effect2_innerShadow_2993_11069"
                          result="effect3_innerShadow_2993_11069"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_2993_11069"
                        x1="18"
                        y1="1"
                        x2="18"
                        y2="36.7559"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FFFE2E" />
                        <stop offset="1" stopColor="#FE8315" />
                      </linearGradient>
                    </defs>
                  </svg>
                </svg>
              </div>
              <div className="absolute z-[54] w-[30px] h-[30px] top-1 ">
                <ReImg
                  src={require('../../../../../assets/images/farmIcons/buildIco.png')}
                  className="absolute z-[54] w-full h-full object-contain"
                />
              </div>
            </div>
            {building && (
              <ReImg
                className={`absolute z-[53] opacity-30 flex h-full w-full rounded-[15px]  bg-opacity-50 object-contain`}
                src={require(`../../../../../assets/images/buildingNeedRepaired/build${building.id}.webp?type=base64`)}
              />
            )}
            {building && (
              <ReImg
                className={`flex h-full w-full rounded-[15px]  bg-opacity-50 object-contain`}
                src={require(`../../../../../assets/images/buildingNeedRepaired/build${building.id}.webp?type=base64`)}
              />
            )}
          </div>
        );

      default:
        return <div />;
    }
  }
);

const borders = {
  square: require('../../../../../assets/images/stone-frame-square.png?type=base64'),
  horizontal: require('../../../../../assets/images/stone-frame-horizontal.png?type=base64'),
  vertical: require('../../../../../assets/images/stone-frame-vertical.png?type=base64'),
};

const Arrow = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_932_9357)">
        <path d="M40.0003 18V30H24.0003V39.68L8.32031 24L24.0003 8.32001V18H40.0003Z" fill="#A2FFFD" />
        <path
          d="M24.0003 18.25H39.7503V29.75H24.0003H23.7503V30V39.0765L8.67387 24L23.7503 8.92356V18V18.25H24.0003Z"
          stroke="#19191B"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_932_9357"
          x="4.32031"
          y="4.32001"
          width="39.6797"
          height="39.36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_932_9357" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_932_9357" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
