import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { islandsConfigMock } from '../../../../../mock/buildings';
import { Room } from '../../../Room';
import { memo } from 'react';

export const IslandBuildings = memo(() => {
  let selectedIsland = useSelector((state: RootState) => state.selectedIsland.selectedIsland);

  return (
    <>
      {islandsConfigMock
        .filter((v) => v.id === selectedIsland?.id)[0]
        .scheme.map((scheme, i) => {
          return <Room key={scheme.id} scheme={scheme} index={i + 1} room={selectedIsland?.buildings[i]} />;
        })}
    </>
  );
});
