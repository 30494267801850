import {
  setIsVipTasksAllowed,
  setQuests,
  setSocialTask,
  setMissions,
  setDepositTask,
} from '../app/features/questsSlice';
import { store } from '../app/store';
import { RequestWebsocket } from '../interfaces/wsInterfaces';
import { generateRequestId } from '../utils/generateRequestId';
import { sendRequestAndGetResponse } from '../Websocket/websocketInit';

export enum SocialTaskCategory {
  none,
  Telegram,
  Twitter,
  TelegramPremium,
  Other,
}

export const getQuests = async (params: any): Promise<any[]> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'quests_getCurrentTasks',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.value) {
      // console.log(`SUCCESSFULLY getQuests for User ${params.clientId}`);

      // console.log("result?.value", result.value.quests);

      const sorted = result.value.quests?.sort((a: any, b: any) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });

      store.dispatch(setQuests(sorted));
      store.dispatch(setIsVipTasksAllowed(result.value.isVipTasksAllowed));

      const response = result.value.quests as any[];

      return response;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return [] as any[];
  }
};

export const claimQuestReward = async (params: any): Promise<boolean> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'quests_claimTaskReward',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
      questId: params.questId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.value) {
      // console.log(`SUCCESSFULLY getQuests for User ${params.clientId}`);

      // console.log("result?.value", result.value.quests);

      await getQuests({ clientId: params.clientId });

      return result?.value;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return true;
  }
};

export const getSocialTasks = async (params: any): Promise<any[] | false> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'socialTask_getMyTasks',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.value) {
      //console.log("result?.value", result?.value);

      const sorted = result.value?.sort((a: any, b: any) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });

      store.dispatch(setSocialTask(sorted));

      return sorted;
    }

    return false;
  } catch (e) {
    console.error((e as Error).message);
    return [] as any[];
  }
};

export const claimTaskReward = async (params: any): Promise<boolean> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'socialTask_claimTaskReward',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
      taskId: params.taskId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.value) {
      // console.log(`SUCCESSFULLY getQuests for User ${params.clientId}`);

      // console.log("result?.value", result.value.quests);

      await getSocialTasks({ clientId: params.clientId });

      return result?.value;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return true;
  }
};

export const claimDepositTaskReward = async (params: any): Promise<boolean> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'depositQuest_claimCurrentQuest',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.value) {
      // console.log(`SUCCESSFULLY getQuests for User ${params.clientId}`);

      // console.log("result?.value", result.value.quests);

      await getDepositTask({ clientId: params.clientId });

      return result?.value;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return true;
  }
};

export const setTaskCompleted = async (params: any): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'socialTask_setCompleted',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
      taskId: params.taskId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.value) {
      // console.log(`SUCCESSFULLY getQuests for User ${params.clientId}`);

      // console.log("result?.value", result.value.quests);

      const data = await getSocialTasks({ clientId: params.clientId });
      return data;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return true;
  }
};

export const getDepositTask = async (params: any): Promise<any | false> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'depositQuest_getQuest',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value || false;
  } catch (e) {
    console.error((e as Error).message);
    return [] as any[];
  }
};

export const getMissions = async (params: any): Promise<any | false> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'achievements_getAchievements',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    // console.log('RESULT OF MOISSION RERQU', result)
    store.dispatch(setMissions(result?.value));
    return result?.value || false;
  } catch (e) {
    console.error((e as Error).message);
    return [] as any[];
  }
};

export const claimMission = async (params: any): Promise<any | false> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'achievements_claimAchievement',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
      id: params.id,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value || false;
  } catch (e) {
    console.error((e as Error).message);
    return [] as any[];
  }
};

export const isCanClaimDailyChest = async (
  params: any
): Promise<{ isCanClaim: boolean; isClaimed: boolean; completed: number }> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'dailyQuestChest_isCanClaim',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return { isCanClaim: false, isClaimed: false, completed: 0 };
  }
};

export const claimDailyChest = async (params: any): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'dailyQuestChest_claim',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      // deviceId: clientId // Розкоментуйте, якщо потрібно
    },
    params: {
      clientId: params.clientId,
    },
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return true;
  }
};
