import { Buildings } from '../enums/buildingStatus';
import { RoomTypes } from '../enums/roomType';
import i18next from '../i18n/config';
import { BuildingConfig, Island } from '../interfaces/farm';

// Assuming these enums are defined somewhere in your code
enum Resources {
  Gold = 0,
  Wood = 1,
  Stone = 2,
  Iron = 3,
}

interface LevelInfo {
  lvl: number;
  createCost: number;
  buildingTimeMs: number;
  resourceAmount?: number;
  gatherDelayMs?: number;
  condition?: Condition[];
  upgradeData?: UpgradeData[] | null;
  minTimeMs: number; // Додаємо
  maxTimeMs: number; // Додаємо
  kitsune: number;
}

interface Condition {
  buildingId: number;
  neededLvl: number;
}

export enum AdditionalConditionType {
  Friends,
  DonateStars,
  PremiumFriends,
  RankLvl,
}

export interface AdditionalCondition {
  conditionType: AdditionalConditionType;
  value: number;
}

interface UpgradeData {
  title: string;
  value: number;
}

function mapConditions(conditions: any[]): Condition[] {
  return conditions.map((cond) => ({
    buildingId: cond.buildingId,
    neededLvl: cond.level,
  }));
}

function mapAdditionalConditions(additionalConditions: any[]): AdditionalCondition[] {
  return additionalConditions.map((cond) => ({
    conditionType: cond.conditionType,
    value: cond.value,
  }));
}

function mapLevelInfo(levels: any[], buildingId: number): LevelInfo[] {
  return levels.map((level, index) => {
    // Отримуємо наступний рівень
    const nextLevel = levels[index + 1];
    let title = '';
    let titleTime = i18next.t('buildings.reload.maxProductionTime');
    switch (buildingId) {
      case 1:
        titleTime = i18next.t('buildings.reload.maxLevelBuildings');
        break;
      case 2:
        title = i18next.t('buildings.reload.stoneOutput');
        break;
      case 3:
        title = i18next.t('buildings.reload.kitsuFarm');
        break;
      case 4:
        title = i18next.t('buildings.reload.xpOutput');
        break;
      case 5:
        title = '';
        titleTime = i18next.t('buildings.reload.ticketCooldown');
        break;
      case 6:
        title = i18next.t('buildings.reload.xpOutput');
        break;
      case 7:
        title = i18next.t('buildings.reload.kitsuFarm');
        break;
    }
    if (title) {
      title += `/${i18next.t('timer.hour')}`;
    }
    return {
      lvl: level.id,
      createCost: level.cost,
      kitsune: level.kitsune,
      buildingTimeMs: level.buildingDuration,
      resourceAmount: level.farming?.amountPerTick || 0,
      gatherDelayMs: level.farming?.tickDurationMs || 0,
      minTimeMs: level.farming?.minTimeMs || 0,
      maxTimeMs: level.farming?.maxTimeMs || 0,
      condition: level.conditions ? mapConditions(level.conditions) : undefined,
      additionalCondition: level.additionalConditions ? mapAdditionalConditions(level.additionalConditions) : undefined,
      upgradeData: [
        {
          title: title,
          value: nextLevel?.farming?.amountPerTick,
        },
        {
          title: titleTime,
          value: nextLevel?.farming?.maxTimeMs,
        },
      ], // Встановлюємо значення наступного рівня або null
    };
  });
}
function mapBuildings(buildings: any[]): BuildingConfig[] {
  return buildings.map((building) => {
    return {
      id: building.id,
      dungeonIds: building.dungeonIds,
      title: i18next.t(`buildings.titles.${building.id}`),
      description: i18next.t(`buildings.descriptions.${building.id}`),
      maxCount: 1,
      possibleSpots: [RoomTypes.square], // Map if necessary
      resourceType: building.resourceType,
      lvlInfo: mapLevelInfo(building.levels, building.id),
    };
  });
}
function mapIslands(data: any[]): Island[] {
  return data.map((island) => ({
    id: island.id,
    buildings: island.buildings.map((building: { id: any; buildingId: any; level: any; instabuild: any }) => ({
      id: building.id,
      status: 'Available', // Set appropriate status
      statusUpdateDate: new Date(), // Set appropriate date
      buildingId: building.buildingId,
      lvl: building.level,
      instabuild: building.instabuild,
    })),
  }));
}

export function parseConfig(jsonString: string): {
  islands: Island[];
  buildingConfigs: BuildingConfig[];
} {
  const data = JSON.parse(jsonString);

  // Map building configurations
  const buildingConfigs = mapBuildings(data[0].buildings);

  // Map islands
  const islands = mapIslands(data);

  return { islands, buildingConfigs };
}

export function defineCountString(count: number, forceTwoDecimals: boolean = false): string {
  const isNegative = count < 0;
  count = Math.abs(count);

  if (count === 0) {
    return '0';
  }

  const suffixes = [
    { value: 1_000_000_000_000, symbol: 'T' },
    { value: 1_000_000_000, symbol: 'B' },
    { value: 1_000_000, symbol: 'M' },
    { value: 1_000, symbol: 'K' },
  ];

  for (const suffix of suffixes) {
    if (count >= suffix.value) {
      // Спочатку отримуємо число зі «зсуненим» суфіксом
      const floatVal = Math.round(count / (suffix.value / 10)) / 10;

      // Перевіряємо, чи це ціле число
      const strVal = Number.isInteger(floatVal)
        ? floatVal.toFixed(forceTwoDecimals ? 2 : 0) // якщо без дробової частини
        : floatVal.toFixed(1);

      return `${isNegative ? '-' : ''}${strVal}${suffix.symbol}`;
    }
  }

  // Якщо жоден суфікс не підійшов, повертаємо ціле (або від’ємне) число
  return `${isNegative ? '-' : ''}${count.toFixed(forceTwoDecimals ? 2 : 0)}`;
}
