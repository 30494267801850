// components/ActiveCards.tsx
import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { Card } from '../../components/Card';
import { CardProps } from '../../interfaces/card';
import { motion, AnimatePresence } from 'framer-motion';
import { CardType } from '../../endpoints/mock';
import { useTelegram } from '../../hooks/useTelegram';
import { preloadImages } from '../../utils/preloadImages'; // Імпортуємо утиліту
import { useSoundService } from '../../utils/soundService';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { TutorialSave, TutorialStage } from '../../interfaces/tutorial';
import { setSave } from '../../app/features/tutorialSaveSlice';
import { updateTutorialProgress } from '../../endpoints/tutorialProgress';
import { useSessionStorage } from '@uidotdev/usehooks';
import { DraggableCardItem } from './DraggableCardItem';
import BattleCardTooltip from '../../components/BattleCardTooltip';

export const ActiveCards = memo(({
  cards,
  makeCardAction,
  usedCards,
  setUsedCards,
  cardStrategy,
  removeCard,
  currentMana,
  isDraggingCards,
  className,
  cardsEffectsTooltip,
}: {
  cards: any[];
  makeCardAction: any;
  usedCards: CardProps[];
  setUsedCards: any;
  cardStrategy: any;
  removeCard: any;
  expandTiers: any;
  currentMana: number;
  isDraggingCards: boolean;
  className: string;
  cardsEffectsTooltip?: any[];
}) => {
   console.log('CARDS AT CATIVECARDS', cards);
  const activeCardsRef = useRef<HTMLDivElement>(null);
  const [activeCardsHeight, setActiveCardsHeight] = useState<number>(0);
  const allowedDropAreaRef = useRef<HTMLDivElement>(null);
  const [cardsAtHand, setCardsAtHand] = useState<CardProps[]>([]);
  const [cardTypes, setCardTypes] = useState<{ [uid: string]: CardType }>({});
  const [cardUsedTypes, setUsedCardTypes] = useState<{
    [uid: string]: CardType;
  }>({});
  const [usedCardsPosition, setUsedCardsPosition] = useState<{ x: number; y: number }[]>([]);
  const { userId } = useTelegram();

  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false); // Стан для відстеження завантаження зображень
  const [cardIsDragged, setCardIsDragged] = useState(false);

  const [showAnimation, setShowAnimation] = useState<boolean>(false);

  const configCards = useSelector((state: RootState) => state.appConfig.cards);

  // console.log('isDraggingCards', isDraggingCards)

  const tutorialSave = useSelector((state: RootState) => state.tutorialSave.tutorialSave.save);

  const dispatch = useDispatch<AppDispatch>();

  const updateSave = ({ save }: { save: TutorialSave }) => {
    // const save = { stage: TutorialStage.stone }
    dispatch(setSave(save));
    const updatingSave = async () => {
      await updateTutorialProgress({
        clientId: userId,
        save: JSON.stringify(save),
      });
    };
    updatingSave();
  };

  // console.log('CARDS AT HAND in ACTIVE CARDS', cardsAtHand);

  useEffect(() => {
    if (activeCardsRef.current) {
      const currentHeight = activeCardsRef.current.offsetHeight;
      if (currentHeight > activeCardsHeight) {
        setActiveCardsHeight(currentHeight);
      }
    }
  }, [cardsAtHand]);

  useEffect(() => {
    //console.log('USE EFFECT CARDS AT HAND', cardsAtHand);
    playSound('cardPlay');
  }, [cardsAtHand]);
  // console.log('Acttive cards init');
  // Синхронізуємо positions та dragOffsets з cardsAtHand

  useEffect(() => {
    const sortedCards = [...cards].sort((a, b) => a?.id - b?.id);
    const initializedCards = sortedCards.map((card) => ({
      ...card,
      draggable: true,
      isBackLight: card?.isBackLight,
    }));

    // Підготовка шляхів
    const imagePaths = initializedCards.flatMap((card) => {
      const foundCardData = configCards.find((v) => v.id === card.id);
      if (!foundCardData) return [];
      // 1) Основне зображення
      let mainSrc: string[] = [];
      try {
        mainSrc = [require(`../../assets/images/cards/${card.id}.png`)];
      } catch (error) {
        console.error(`Image for card ID ${card.id} not found.`);
      }
      // 2) Іконка (lights)
      // Якщо потрібно, теж додавайте в масив
      let iconSrc: string[] = [];
      try {
        iconSrc = [require(`../../assets/images/cardsIcons/${card.id}.webp`)];
      } catch (error) {
        // Можливо, не всі карти мають .webp
      }

      return [...mainSrc, ...iconSrc];
    });

    // Викликаємо preload ОДИН раз
    preloadImages(imagePaths)
      .then(() => {
        setCardsAtHand(initializedCards);
        // setPositions(initializedCards.map(() => ({ x: 0, y: 0 })));
        // setDragOffsets(initializedCards.map(() => ({ x: 0, y: 0 })));
        setImagesLoaded(true);
      })
      .catch((error) => {
        console.error('Error preloading images:', error);
      });
  }, [cards, configCards]);

  useEffect(() => {
    const updateCardTypes = () => {
      const newCardTypes = {} as any;

      cards.forEach((card) => {
        const cardData = configCards.find((v) => v.id === card.id);

        if (cardData) {
          newCardTypes[card.uid] = cardData.cardType;

          cardStrategy[cardData.cardType]({ card });
        }
      });

      setCardTypes(newCardTypes);
    };

    updateCardTypes();
  }, [cards]);

  const updateCardTypesUsed = (newArray: CardProps[]) => {
    const newCardTypes: { [uid: string]: CardType } = {};

    newArray.forEach((card: CardProps) => {
      const cardData = configCards.find((v: any) => v.id === card.id);
      if (cardData) {
        newCardTypes[card.uid] = cardData.cardType;
      }
    });

    setUsedCardTypes(newCardTypes);
  };

  const scale: number = 1.3;

  const getRotationAngle = (index: number, totalCards: number) => {
    const maxAngle = 8;
    const minAngle = -8;

    if (totalCards <= 2) return 0;

    const angleStep = (maxAngle - minAngle) / (totalCards - 1);

    return (minAngle + index * angleStep).toFixed(2);
  };

  const getVerticalOffset = (index: number, totalCards: number) => {
    const b = totalCards < 6 ? -15 : -25;

    if (totalCards === 1) return 0;

    const angleStep = Math.PI / (totalCards - 1);
    const angle = angleStep * index;

    return (b * Math.sin(angle)).toFixed(2);
  };

  const selectedTransformStyle = {
    transform: `rotate(0deg) translateY(0)`,
  };

  // const debounce = (func: Function, delay: number) => {
  //   let timeoutId: NodeJS.Timeout;
  //   return (...args: any[]) => {
  //     if (timeoutId) clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       func(...args);
  //     }, delay);
  //   };
  // };

  const [disappearingCard, setDisappearingCard] = useState('0');
  const { playSound } = useSoundService();

  useEffect(() => {
    if (tutorialSave?.stage === TutorialStage.battle) {
      setShowAnimation(true);
    }
  }, [tutorialSave?.stage]);

  // Визначаємо початкову позицію для появи карт
  const initialCardPosition = { x: 70, y: 170 };

  const configCardCostById = useCallback((id: number, lvl: number) => {
    return configCards.filter((card: any) => card.id === id)[0].stars[lvl - 1]?.cost;
  }, []);

  if (!imagesLoaded) {
    // Покажіть глобальний завантажувач або нічого не рендеріть
    return null; // Або <div>Завантаження...</div>
  }

  return (
    <div className={className}>
      <div ref={allowedDropAreaRef} className="absolute left-0 top-0 right-0 bottom-[330px]"></div>
      {/* {usedCards.map((card, index) => (
        <div
          className={`absolute max-w-[30%]`}
          key={index}
          style={{
            width: `${100 / usedCards.length + 75}%`,
            top: usedCardsPosition[index]?.y,
            left: usedCardsPosition[index]?.x,
          }}
        >
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              style={{ position: 'relative' }}
              className={`${!card.hidden ? '' : 'hidden'}`}
            >
              <Card
                key={'card'}
                id={card.id}
                lvl={card.lvl}
                uid={card.uid}
                glow={false}
                IsDraggable={isDraggingCards}
                triggerRemoveCanvas={false}
              />
            </motion.div>
          </AnimatePresence>

          <AnimatePresence>
            {disappearingCard === card.uid && (
              <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1.5 }}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: '100%', // Збільшена ширина
                  height: '100%', // Збільшена висота
                  transform: 'translate(-50%, -50%)', // Центрування
                  zIndex: 100,
                  transition: 'width 0.3s ease, height 0.3s ease',
                }}
              >
                <img
                  className="w-full h-full"
                  src={require(`../../assets/images/cardsIcons/${cardUsedTypes[card.uid]}.png`)}
                  alt=""
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))} */}

      <div
        ref={activeCardsRef}
        className={`absolute w-full px-3 pb-[154px] flex items-end h-full ${
          tutorialSave?.stage === TutorialStage.battle &&
          tutorialSave.enemyId === 0 &&
          !tutorialSave?.cardIsDropped &&
          cardsAtHand?.length
            ? 'z-[10]'
            : 'z-[0]'
        }`}
      >
        <div className="w-full flex justify-center items-end ">
          {cardsAtHand.map((card, index) => {
            return (
              <DraggableCardItem
                key={card.uid}
                card={card}
                index={index}
                isDraggingCards={isDraggingCards}
                allowedDropAreaRef={allowedDropAreaRef.current}
                usedCards={usedCards}
                setUsedCards={setUsedCards}
                usedCardsPosition={usedCardsPosition}
                setUsedCardsPosition={setUsedCardsPosition}
                removeCard={removeCard}
                cardStrategy={cardStrategy}
                tutorialSave={tutorialSave}
                currentMana={currentMana}
                configCardCostById={configCardCostById}
                cardsAtHand={cardsAtHand}
                setCardsAtHand={setCardsAtHand}
                makeCardAction={makeCardAction}
                setDisappearingCard={setDisappearingCard}
                updateCardTypesUsed={updateCardTypesUsed}
                cardIsDragged={cardIsDragged}
                setCardIsDragged={(val: boolean) => setCardIsDragged(val)}
                updateSave={updateSave}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
});
