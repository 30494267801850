import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState, store } from '../../../app/store';
import { Room } from '../Room';
import { islandsConfigMock } from '../../../mock/buildings';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BuildingPopup } from './components/BuildingPopup';
import { setSelectedIsland } from '../../../app/features/selectedIsland';
import { ErrorComponent } from '../../../components';
import { useSessionStorage } from '@uidotdev/usehooks';
import { getBalance } from '../../../endpoints/farmMock';
import { useTelegram } from '../../../hooks/useTelegram';
//import * as amplitude from "@amplitude/analytics-browser";
import { TutorialFarmSave, TutorialFarmStage, TutorialSave } from '../../../interfaces/tutorial';
import { fetchFarmTutorialProgress, saveFarmTutorialProgress } from '../../../app/features/farmTutoralSlice';
import { updateTutorialProgress } from '../../../endpoints/tutorialProgress';
import { fetchConfig } from '../../../app/features/configSlice';
import { getDungeonProgressSafe } from '../../../endpoints/dungeonEndpoints';
import { BuildingMask, CloudAnimation } from '../../../components/animation/CloudAnimation';
import { resetDungeon } from '../../../app/features/dungeonCompleted';
import PageTransition from '../../Router/components/PageTransition';
import { useFarm } from '../../../pages/Farm/useFarm';
import { SpeedUpPopUp } from './components/SpeedUpPopUp';
import { RoomStatus } from '../../../enums/buildingStatus';
import { fetchIslands } from '../../../app/features/islandsSlice';
import { OpenLootBox } from '../../Inventory/components';
import { useTonAddress } from '@tonconnect/ui-react';
import {
  getProfileInfo,
  getUserDeposites,
  getUserOffers,
  updateProfileWallet,
} from '../../../endpoints/getProfileInfo';
import * as Sentry from '@sentry/react';
import { ErrorReportingService } from '../../../services/errorReportingService';
import { ErrorType, RuntimeErrorData } from '../../../interfaces/error';
import { useSoundService } from '../../../utils/soundService';
import { setSave } from '../../../app/features/tutorialSaveSlice';
import { preloadImages } from '../../../utils/preloadImages';
import { markStepAsLoaded } from '../../../app/features/loadingManagerSlice';
import TavernOffer from '../../../components/TavernOffer';
import { ConfigOffer, OfferType } from '../../../mock/offers';
import { OfferProps } from '../../../pages/Shop/components/Offer';
import { getIsSelected } from '../../../pages/Shop';
import { OfferResourceProps } from '../../../pages/Shop/components/OfferResources';
import { defineCountString } from '../../../utils/farmConfigParser';
import { DeveloperButtons } from './components/DeveloperButtons';
import { IslandTutorial } from './components/IslandTutorial';
import { IconsAndModals } from './components/IconsAndModals';
import { IslandBuildings } from './components/IslandBuildings';
import { BuildingModals } from './components/BuildingModals';
import ReImg from '../../../components/ReloadableImage';
import { ValentinesDayOffer } from '../../../components/ValentinesDayOffer';

export const IslandWindow = () => {
  const { playSound } = useSoundService();
  const { userId } = useTelegram();
  const [userDepositedAmount, setUserDepositedAmount] = useState(null);
  const [currentDungeonId, setCurrentDungeonId] = useSessionStorage('currentDungeonId', 0);
  const [isShopOpen, setShopOpen] = useSessionStorage('isShopOpen', false);
  let selectedIsland = useSelector((state: RootState) => state.selectedIsland.selectedIsland);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const [isOpenOffer, setOpenOffer] = useState(true);
  const [isResourcesShown, setIsResourcesShown] = useState(false);
  const [firstConnectWallet, setFirstConnectWallet] = useState(false);
  const [isRefCodeAccess, setIsRefCodeAccess] = useSessionStorage('isRefCodeAccess', true);
  const [isResoursesShown, setResoursesShown] = useState(false);
  const [purchasedOfferIds, setPurchasedOfferIds] = useState<Set<number> | null>(null);
  const [buildingMasks, setBuildingMasks] = useState<BuildingMask[]>([
    { height: 187, width: 163, x: 136, y: 413 },
    { height: 200, width: 200, x: 230, y: 470 },
    { height: 195, width: 146, x: 0, y: 452 },
  ]);
  const [shouldHideElement, setShouldHideElement] = useSessionStorage<boolean>('shouldHideElementKey', true);
  const [isValentinesDayOpenOffer, setIsValentinesDayOpenOffer] = useSessionStorage<boolean | null>('showValentinesDayOffer', null);
  const [isValentineDayOfferBought, setIsValentineDayOfferBought] = useSessionStorage('isValentineDayOfferBought', false);


  const dispatch = useDispatch<AppDispatch>();

  const isFarmTutorialCompleted = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave?.save?.completed
  );
  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);
  const dungeonIsCompleted = useSelector((state: RootState) => state.dungeonCompleted.dungeonCompleted);
  const dungeonResources = useSelector((state: RootState) => state.dungeonCompleted.resources);
  const { buildingConfigs } = useSelector((state: RootState) => state.config);

  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);

  const fetchBalance = async () => {
    if (userId) {
      await getBalance({ clientId: `${userId}` });
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  useEffect(() => {
    if (dungeonIsCompleted) {
      const timeout = setTimeout(() => {
        setResoursesShown(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [dungeonIsCompleted]);

  useEffect(() => {
    if (dungeonIsCompleted && isResoursesShown) {
      playSound('dungeonReward');
    }
  }, [dungeonIsCompleted, isResoursesShown]);

  useEffect(() => {
    if (!farmTutorialSave?.stage) {
      if (userId) {
        dispatch(fetchFarmTutorialProgress(userId));
      }
    }
  }, [userId, dispatch]);

  const updateBattleTutorialSave = ({ save }: { save: TutorialSave }) => {
    dispatch(setSave(save));
    const updatingSave = async () => {
      await updateTutorialProgress({
        clientId: userId,
        save: JSON.stringify(save),
      });
    };
    updatingSave();
  };

  useEffect(() => {
    updateBattleTutorialSave({ save: { completed: true } });
  }, []);

  const updateSave = async (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  useEffect(() => {
    dispatch(fetchIslands(`${userId}`));
  }, []);

  const { islands } = useFarm();

  if (!selectedIsland && islands.length > 0) {
    store.dispatch(setSelectedIsland({ island: islands[0] }));
    selectedIsland = islands[0];
  }

  useEffect(() => {
    if (islands.length && buildingConfigs) {
      const building = islands[0].buildings.filter((building) => building.status === RoomStatus.battle)[0];
      const dungeonStages = buildingConfigs.find((v) => v.id === building?.buildingId)?.dungeonIds;

      const imageSources = dungeonStages?.map(({ dungeonId }) => {
        return dungeonId > 0 ? require(`../../../assets/images/dungeonStages/dungeon-stage-${dungeonId}.jpg`) : '';
      });

      if (imageSources) {
        preloadImages(imageSources).catch((err) => {
          console.warn('Failed to preload dungeon stages', err);
        });
      }
    }
  }, [islands.length, buildingConfigs]);

  useEffect(() => {
    if (islands.length) {
      const count = islands[0].buildings.filter(
        (building) => building.status === RoomStatus.farming || building.status === RoomStatus.builded
      ).length;

      const firstResult = count >= 2;

      const secondResult = count >= 3;

      if (firstResult && !secondResult) {
        updateSave({
          dialogueId: 999,
          stage: TutorialFarmStage.finishFirstBuilding,
        });
      }

      if (firstResult && secondResult) {
        updateSave({
          dialogueId: 999,
          completed: true,
          stage: TutorialFarmStage.finishSecondBuilding,
        });
      }
    }
  }, [islands.length]);

  useEffect(() => {
    const fetchDungeonProgress = async () => {
      if (islands.length) {
        for (const building of islands[0].buildings) {
          if (building.status === RoomStatus.battle) {
            try {
              const progress = await getDungeonProgressSafe({
                clientId: userId,
              });
              setCurrentDungeonId(progress.currentDungeonId);
            } catch (error) {
              console.warn('Error fetching dungeon progress:', error);
            }
          }
        }
      }
    };

    fetchDungeonProgress();
  }, [islands.length]);

  const handleClaimResourcesForCompletedDungeon = useCallback(() => {
    dispatch(resetDungeon());
  }, [dispatch]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isResourcesShown) {
      timeout = setTimeout(() => {
        dispatch(resetDungeon());
      }, 2500);
    }
    return () => clearTimeout(timeout);
  }, [isResourcesShown]);

  const address = useTonAddress();

  const hasLoadedImages = sessionStorage.getItem('imagesLoaded');

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!hasLoadedImages) {
      // card details
      let imagePaths: string[] = [
        require('../../../assets/images/cardsIcons/spell_frame.webp'),
        require('../../../assets/images/cardsIcons/artifact_frame.webp'),
        require('../../../assets/images/cardsIcons/atk_frame.webp'),
        require('../../../assets/images/cardsIcons/mana.webp'),
        require('../../../assets/images/stars/full-star.png'),
        require('../../../assets/images/stars/empty-start.png')
      ];

      // card images
      [
        0, 9, 17, 6, 18, 19, 1, 15, 20, 4, 13, 11, 22, 23, 21, 10, 14, 36, 12, 24, 25, 26, 2, 27, 37, 16, 28, 38, 29,
        33, 30, 34, 31, 39, 32, 40, 41, 7, 8, 3, 5, 35,
      ].forEach((number) => {
        imagePaths.push(require(`../../../assets/images/cards/${number}.png`));
      });

      sessionStorage.setItem('imagesLoaded', 'true');

      timeout = setTimeout(() => {
        preloadImages(imagePaths);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [hasLoadedImages]);

  const handleBackgroundLoad = useCallback(() => {
    dispatch(markStepAsLoaded('background'));
  }, [dispatch]);

  const fetchProfileInfo = async () => {
    const data = await getProfileInfo({
      clientId: userId,
    });

    if (data) {
      setIsRefCodeAccess(data.isRefCodeAccess);
    }

    if (!data.wallet) {
      setFirstConnectWallet(true);
    }

    const userDeposites = await getUserDeposites({
      clientId: userId,
    });

    if (userDeposites) {
      setUserDepositedAmount(userDeposites.deposited);
    }

    const userOffersData = await getUserOffers({
      clientId: userId,
    });

    if (Array.isArray(userOffersData)) {
      setPurchasedOfferIds(
        new Set(userOffersData.map((o: { offerId: number; offerType: number; quantity: number }) => o.offerId))
      );
    }
  };

  useEffect(() => {
    if (typeof userDepositedAmount !== 'number' || !purchasedOfferIds || typeof isRefCodeAccess === 'undefined') return;
    const isNotPurchased = !purchasedOfferIds || purchasedOfferIds.size === 0;
    const isLowDeposit = userDepositedAmount < 50000;

    if (!isNotPurchased || !isLowDeposit) {
      setShouldHideElement(true);
    } else {
      if (!isShopOpen) setShouldHideElement(isRefCodeAccess);
    }
  }, [purchasedOfferIds, userDepositedAmount, isRefCodeAccess]);

  const handleUpdateProfileWallet = useCallback(
    async (address: string) => {
      await updateProfileWallet({
        clientId: userId,
        wallet: address,
      });
      setFirstConnectWallet(false);
    },
    [setFirstConnectWallet]
  );

  useEffect(() => {
    if (address) {
      handleUpdateProfileWallet(address);
    }

    fetchProfileInfo();
  }, [address]);

  const [error, setError] = useState(true);

  const isFarmTutorialStatus = useSelector((state: RootState) => state.farmTutorial.status);

  useEffect(() => {
    if (isFarmTutorialStatus === 'failed' || farmTutorialSave?.stage === undefined) {
      setError(true);
    } else {
      setError(false);
    }
  }, [isFarmTutorialStatus, farmTutorialSave?.stage]);


  // const [isPurchaseClosed, setIsPurchaseClosed] = useSessionStorage('isPurchaseClosed', false);
  const [isTavernshownOnly, setIsTavernshownOnly] = useSessionStorage('isTavernshownOnly', false);

  const stoneOffer = useMemo(() => {
    if (
      !appConfig ||
      !purchasedOfferIds ||
      !(selectedIsland?.buildings[4]?.status === RoomStatus.farming) ||
      isTavernshownOnly
    )
      return undefined;
    const {
      offers: { variables: allOffers },
    } = appConfig;

    const stoneOffer = allOffers.filter(
      (offer: ConfigOffer) => offer.offerType.value === OfferType.stoneOffer && offer.offerId.value === 9
    );
    const notPurchasedOffers = stoneOffer.filter((offer: ConfigOffer) => !purchasedOfferIds.has(offer.offerId.value));

    const chosenOffer = notPurchasedOffers.find(
      (offer: ConfigOffer) => stoneOffer[0].offerId.value === offer.offerId.value
    );

    if (!chosenOffer) return undefined;

    const mapOffer = (offer: ConfigOffer): OfferProps => {
      const sortedItems = [...offer.items.value].sort((a, b) => {
        const aSelected = getIsSelected(a);
        const bSelected = getIsSelected(b);

        if (aSelected === bSelected) return 0;
        return aSelected ? -1 : 1;
      });
      let kitsu: number = 0; // або let kitsu: number = 0;

      const resourceList: OfferResourceProps[] = sortedItems
        .filter((item) => {
          if (item.rewardType.value === 2 && item.rewardId.value === 0) {
            kitsu = item.amount.value;
            return false;
          }
          return true;
        })
        .map((item, index: number): OfferResourceProps => {
          const isEnabled = getIsSelected(item);

          return {
            resourceType: item.rewardType.value,
            resourceId: item.rewardId.value,
            isEnabled: isEnabled,
            layout: 'vertical',
            amount: defineCountString(item.amount.value),
          };
        });

      return {
        id: offer.offerId.value,
        resourceList: resourceList,
        offerType: offer.offerType.value,
        type: 'gold',
        label: 'corner',
        layout: 'wide',
        kitsu: kitsu ? defineCountString(kitsu) : '0',
        price: offer.stars.value.toString(),
        deadline: 'January 31, 2025',
      };
    };

    return mapOffer(chosenOffer);
  }, [appConfig, purchasedOfferIds]);

  const valentinesDayOffer = useMemo(() => {
    if (!appConfig) return undefined;
    const {
      offers: { variables: allOffers },
    } = appConfig;

    const valentinesDayOfferFiltered = allOffers.filter(
      (offer: ConfigOffer) => offer.offerType.value === OfferType.valentinesDayOffer
    )[0];
    const haveValentinesDayOffer = purchasedOfferIds?.has(valentinesDayOfferFiltered.offerId.value);

    if (haveValentinesDayOffer) {
      setIsValentineDayOfferBought(true);
    } 

    if (!valentinesDayOfferFiltered || haveValentinesDayOffer) return undefined;

    if (isValentinesDayOpenOffer === null && haveValentinesDayOffer !== undefined) {
      setIsValentinesDayOpenOffer(true);
    }

    const mapOffer = (offer: ConfigOffer): OfferProps => {
      const sortedItems = [...offer.items.value].sort((a, b) => {
        const aSelected = getIsSelected(a);
        const bSelected = getIsSelected(b);

        if (aSelected === bSelected) return 0;
        return aSelected ? -1 : 1;
      });
      let kitsu: number = 0; // або let kitsu: number = 0;

      const resourceList: OfferResourceProps[] = sortedItems.map((item, index: number): OfferResourceProps => {
        const isEnabled = getIsSelected(item);

        return {
          resourceType: item.rewardType.value,
          resourceId: item.rewardId.value,
          isEnabled: isEnabled,
          layout: 'vertical',
          amount: defineCountString(item.amount.value),
        };
      });

      return {
        id: offer.offerId.value,
        resourceList: resourceList,
        offerType: offer.offerType.value,
        type: 'gold',
        label: 'corner',
        layout: 'wide',
        kitsu: kitsu ? defineCountString(kitsu) : '0',
        price: offer.stars.value.toString(),
        deadline: 'January 31, 2025',
      };
    };

    return mapOffer(valentinesDayOfferFiltered);
  }, [appConfig, purchasedOfferIds]);

  const valentinesDayDate = new Date('2025-02-14');
  const currentDate = new Date();
  const isValentinesDay = currentDate >= valentinesDayDate;

  return (
    <Sentry.ErrorBoundary
      onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: '' };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) {
          errorData.stack = componentStack;
        }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return <ErrorComponent jsError={{ error, resetError, componentStack, eventId }} />;
      }}
    >
      <PageTransition>
        <div id={'island'}>
          <div className={`absolute z-55 w-full h-full`}>
            <IconsAndModals firstConnectWallet={firstConnectWallet} shouldHideElement={shouldHideElement} />
          </div>

          {/* {stoneOffer ? <TavernOffer offer={stoneOffer} /> : null} */}
          {shouldHideElement && valentinesDayOffer && isValentinesDayOpenOffer && isValentinesDay ? (
            <ValentinesDayOffer offer={valentinesDayOffer} />
          ) : null}
          {shouldHideElement && isOpenOffer && stoneOffer && !isValentinesDayOpenOffer ? (
            <TavernOffer offer={stoneOffer} setOpenOffer={setOpenOffer} />
          ) : null}

          <BuildingModals />

          <DeveloperButtons />
          <main
            className={`absolute min-h-full max-h-full h-full flex min-w-full max-w-[550px] overflow-hidden  ${
              !isFarmTutorialCompleted && farmTutorialSave?.stage === TutorialFarmStage.clickOnQuests ? 'z-[53]' : ''
            }`}
          >
            <div className="w-full h-full overlay-element">
              <CloudAnimation buildingMasks={buildingMasks} />
            </div>
            {dungeonIsCompleted && (
              <>
                {isResoursesShown && (
                  <div className="z-[9999]">
                    <OpenLootBox
                      rewards={dungeonResources}
                      openBoxName={'Dungeon Completed'}
                      onClose={handleClaimResourcesForCompletedDungeon}
                    />
                  </div>
                )}
              </>
            )}

            {/* BACKGROUND */}
            <div className={`absolute w-full max-w-full min-w-full h-full max-h-full min-h-full`}>
              <img className="w-full h-full bg-slate-900" onLoad={handleBackgroundLoad} src={require('../../../assets/images/islandBgMain.webp?type=base64' )} alt="" />
            </div>
            {/* {<BuilderOffer />} */}

            {selectedIsland && <IslandBuildings />}
            <IslandTutorial />
          </main>
        </div>
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
