import { Tooltip } from 'react-tooltip';
import { resoursesOfDailyQuests, stageOfDailyQuests } from '../../mock/other';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTelegram } from '../../hooks/useTelegram';
import { claimDailyChest, isCanClaimDailyChest } from '../../endpoints/userQuests';
import Timer from '../../pages/Shop/components/Timer';
import { useSessionStorage } from '@uidotdev/usehooks';
import { AnimatedResource } from '../../containers/Farm/Room/components/RenderRoom/components';
import { OpenLootBox } from '../../containers/Inventory/components';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { DisplayData, handleLootBoxResult } from '../../utils/lootBoxHandler';
import { useSoundService } from '../../utils/soundService';
import { useTranslation } from 'react-i18next';

const dailyQuestsRewards = [
  {
    rewardType: 2,
    rewardId: 4,
    amount: 1,
  },
  {
    rewardType: 2,
    rewardId: 1,
    amount: 30000,
  },
  {
    rewardType: 2,
    rewardId: 2,
    amount: 500,
  },
];

const QuestsProgressBar = () => {
  const { userId } = useTelegram();
  const [isToolTipOpen, setTooltipOpen] = useState(false);
  const questRewardTooltipRef = useRef<HTMLDivElement>(null);
  const [newAmountOfCompletedTasks, setNewAmountOfCompletedTasks] = useSessionStorage('amountOfCompletedTasks', 0);
  const [claimData, setClaimData] = useState({
    isCanClaim: false,
    isClaimed: false,
  });
  const { playSound } = useSoundService();
  const { t } = useTranslation();
  const isChestOpen = newAmountOfCompletedTasks >= 8 || claimData.isClaimed || claimData.isCanClaim;
  const [isShowReward, setIsShowReward] = useState(false);
  const rewards = dailyQuestsRewards
    .map((reward) => handleLootBoxResult(reward))
    .filter((reward): reward is DisplayData => reward !== null);

  useEffect(() => {
    const handleCloseTooltip = (e: Event) => {
      if (!questRewardTooltipRef.current || !questRewardTooltipRef.current.contains(e.target as Node)) {
        setTooltipOpen(false);
      }
    };
    document.addEventListener('mousedown', handleCloseTooltip);
    document.addEventListener('wheel', handleCloseTooltip);
    document.addEventListener('touchmove', handleCloseTooltip);
    return () => {
      document.removeEventListener('mousedown', handleCloseTooltip);
      document.removeEventListener('wheel', handleCloseTooltip);
      document.removeEventListener('touchmove', handleCloseTooltip);
    };
  }, []);

  useEffect(() => {
    const getIsCanClaim = async () => {
      const response = await isCanClaimDailyChest({ clientId: userId });
      setClaimData({
        isCanClaim: response.isCanClaim,
        isClaimed: response.isClaimed,
      });
      setNewAmountOfCompletedTasks((prevState) => Math.max(prevState, response.completed));
    };
    getIsCanClaim();
  }, [newAmountOfCompletedTasks]);

  const returnPersentsOfLine = () => {
    switch (newAmountOfCompletedTasks) {
      case 0:
        return '0%';
      case 1:
        return '22%';
      case 2:
        return '29%';
      case 3:
        return '35%';
      case 4:
        return '45%';
      case 5:
        return '54%';
      case 6:
        return '65%';
      case 7:
        return '73%';
      case 8:
        return '100%';
      default:
        return newAmountOfCompletedTasks >= 9 ? '100%' : '0%';
    }
  };

  const claimReward = async () => {
    const response = await claimDailyChest({ clientId: userId });
  };

  const handleClaimReward = () => {
    if (claimData.isClaimed) return;

    if (!claimData.isCanClaim || newAmountOfCompletedTasks < 8 || claimData.isClaimed) {
      setTooltipOpen((prev) => !prev);
    } else {
      claimReward();
      setClaimData({
        isCanClaim: false,
        isClaimed: true,
      });
      setIsShowReward(true);
      playSound('questsReward');
    }
  };

  return (
    <div className="fixed flex flex-col w-full h-full z-[16] max-h-[126px] max-w-[550px]">
      <div className=" shadow-inner-sm-black text-white  h-full flex items-center pl-[30px] mr-[24px] bg-gradient-radial-brown">
        {isShowReward ? (
          <div className="z-[9999]">
            <OpenLootBox rewards={rewards} openBoxName={'Daily Chest Reward'} onClose={() => setIsShowReward(false)} />
          </div>
        ) : null}

        <div className="flex items-center absolute w-[140px] h-[20px] top-3 left-0 bg-[#332b26] rounded-tl-none rounded-br-[2px] rounded-tr-[2px] rounded-bl-none border border-l-[black] border-r-[black] border-b-[black] border-t-[black] border-l-[transparent] shadow-inner-sm-white">
          <img src={require('../../assets/images/shop/offer/clock.png')} className="w-6 h-6 mx-1" alt="" />
          <div className="flex items-center text-[12px] text-stroke-small">
            <p className="text-[#A49A7C] mr-[2px]">{t('updateVia')}</p>
            <Timer />
          </div>
        </div>
        <div className="relative h-[16px] bg-gray-400 w-full flex flex-col bottom-[-20px] mr-[40px]">
          <ul className="flex absolute w-full ">
            {stageOfDailyQuests.map((stage) => (
              <li
                className={`flex flex-col items-center absolute  max-w-[30px]`}
                style={{ left: stage.marginLeft, gap: stage.gap, bottom: stage.marginBottom }}
                key={stage.id}
              >
                {/* <span
                className={` font-bold bg-[linear-gradient(180deg,_#F6DF77_0%,_#F0A833_100%)] bg-clip-text text-transparent [text-stroke:2px_black]`}
                style={{ fontSize: stage.fontSize }}
              >
                {stage.count}
              </span> */}
                <img
                  src={require('../../assets/images/quests/daily-quest-star.webp')}
                  className={` w-[${stage.imageSize}] h-[${stage.imageSize}]  bottom-0`}
                  alt=""
                />
              </li>
            ))}
          </ul>
          <div className="w-full absolute h-[16px]">
            <img
              src={
                newAmountOfCompletedTasks < 8
                  ? require('../../assets/images/quests/daily-quests-not-ready.webp')
                  : require('../../assets/images/quests/daily-quests-ready.webp')
              }
              className="absolute w-[48px] h-[60px] -left-[12px] -bottom-[15px]"
              alt=""
            />
            <div
              className="absolute -right-[5%] -bottom-3 flex flex-col gap-2 items-center"
              onClick={handleClaimReward}
              ref={questRewardTooltipRef}
              data-tooltip-id="DailyQuestReward"
            >
              {!claimData.isClaimed ? (
                <div className="bg-white w-8 h-8 rounded-lg p-1">
                  <div
                    className="w-full h-full rounded border border-black p-[1px]"
                    style={{ background: 'linear-gradient(196deg, #CF0000 -5.43%, #800B0B 146.19%)' }}
                  >
                    <img src={require('../../assets/images/keys.png')} alt="" />
                  </div>
                </div>
              ) : null}
              <img
                src={
                  isChestOpen
                    ? require('../../assets/images/quests/daily-quests-chest-open.webp')
                    : require('../../assets/images/quests/daily-quests-chest.webp')
                }
                className={`w-[44px] h-[44px] ${claimData.isClaimed ? 'grayscale' : ''} ${claimData.isCanClaim ? 'animate-readyToClaim' : ''}`}
                alt=""
              />
              <Tooltip
                isOpen={isToolTipOpen}
                id="DailyQuestReward"
                place="bottom-end"
                // className="!opacity-100 "
                style={{
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  border: '1px solid #000000',
                  borderRadius: '8px',
                  padding: '7px',
                  textAlign: 'center',
                  maxWidth: '152px',
                  wordWrap: 'break-word',
                  left: -105,
                  top: 95,
                  zIndex: 100,
                  pointerEvents: 'auto',
                }}
                classNameArrow="top-[-3px] right-[10px]"
                clickable={true}
                opacity={1}
              >
                <ul className="flex flex-col gap-1">
                  {resoursesOfDailyQuests.map((res) => (
                    <li
                      className="w-full flex items-center gap-[6px] rounded-[7px] bg-[#00000033] p-[3px] pr-[8px]"
                      key={res.id}
                    >
                      <div
                        className="min-w-8 min-h-8 rounded-[3px] border border-black p-[1px] flex items-center justify-center"
                        style={{ background: res.bgGradient }}
                      >
                        <img src={res.image} alt="" className="w-6 h-6" />
                      </div>
                      <div className="w-full flex justify-between gap-5 text-white text-[12px] text-stroke-small font-bold">
                        <p>{res.resourse}</p>
                        <span>{res.count}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Tooltip>
            </div>
          </div>
          <span
            className=" w-full h-[2px]"
            style={{
              background: 'linear-gradient(127deg, #352A21 0%, rgba(164,115,75,1) 50%, #352A21 100%)',
            }}
          ></span>
          <div
            className={`h-full transition-all duration-300 bg-cover bg-no-repeat max-h-[1rem] `}
            style={{
              width: returnPersentsOfLine(),
              backgroundImage: `url(${require('../../assets/images/quests/line-full.png')})`,
            }}
          ></div>
          <span
            className=" w-full h-[2px]"
            style={{
              background: 'linear-gradient(127deg, #352A21 0%, rgba(164,115,75,1) 50%, #352A21 100%)',
            }}
          ></span>
        </div>
      </div>
      <div className="w-[97.8%] h-4 bg-[#242520] border-t-[#19191B] border-b-[#19191B]"></div>
    </div>
  );
};

export default QuestsProgressBar;
