import { useTranslation } from 'react-i18next';
import { PopupButton } from '../../../components/PopupButton';
import { heroesMap } from '../../../utils/mapping';
import { UpgradeCard } from './components';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { BattleSaves } from '../../../endpoints/mock';
import { useSessionStorage } from '@uidotdev/usehooks';
import { useUtils } from '../../../utils/navigateTo';
import { TutorialFarmStage } from '../../../interfaces/tutorial';

export const LoosePage = ({
  boughtLives,
  isDead,
  currentHp,
  handleResetDungeon,
  enemyId,
  offerCost,
  handleShare,
  handleBuy,
}: {
  isDead?: boolean;
  boughtLives: number;
  currentHp: number;
  handleResetDungeon: () => Promise<void>;
  enemyId?: number;
  offerCost: number;
  handleShare: () => void;
  handleBuy: () => void;
}) => {
  const { t } = useTranslation();
  const [startSelectHero, setStartSelectHero] = useSessionStorage('startSelectHero', false);
  const { navigateTo } = useUtils();

  const actualSaves = useSelector((state: RootState) => state.battleSave.battleSave.save) as BattleSaves | null;
  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const tutorialUpgradeActive = !farmTutorialSave?.stage || farmTutorialSave?.stage < TutorialFarmStage.upgradeHero;

  const upgradeCards = [
    {
      image: require('../../../assets/images/farmIcons/XP.webp'),
      title: t('upgradeYourHeroLvl'),
      buttonText: t('btnStatus.go'),
      link: '/heroes',
    },
    {
      image: require('../../../assets/images/heroes/heroShards/0.png'),
      title: t('Upgrade hero Stars'),
      buttonText: t('btnStatus.go'),
      link: '/heroes',
    },
    // {
    //   image: require("../../../assets/images/buildings/build1.png"),
    //   title: "Upgrade Shrine",
    //   buttonText: "Upgrade",
    //   link: "/island",
    // },
    // Add more items as needed
  ];

  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="fixed inset-0 z-10 flex flex-col justify-center items-center"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="relative flex justify-center items-center pb-4 px-2 max-w-[420px] mx-4 mt-[30px]"
        variants={itemVariants}
      >
        <div className="absolute bottom-full flex justify-center w-60 h-32 -mb-[50px]">
          <img className="z-[2]" src={require('../../../assets/images/skullLose.png')} alt="" />
        </div>

        <img src={require('../../../assets/images/afterBattleBar.png')} alt="" />

        <div className="absolute bottom-11 top-6 left-8 right-8 flex flex-col">
          <div className="relative w-full rounded-[3px] border border-[#18191a] bg-[#554837] p-[2px] mb-[5px] flex-1 h-0">
            <div className="rounded-[2px] border border-[#18191a] h-full overflow-hidden">
              <img
                src={require(
                  `../../../assets/images/looseBackground${enemyId && enemyId > 2 ? '_herz.webp' : '.webp'}`
                )}
                className={`w-full h-full object-cover ${enemyId && enemyId > 2 ? '' : 'scale-125'}`}
                alt=""
              />
            </div>
          </div>

          {!(enemyId && enemyId > 2) && (
            <div
              className="relative px-2 py-1.5 bg-[rgba(18,20,14,0.5)] rounded-[3px] border border-[#18191a]
              flex justify-start items-center gap-1 flex-wrap max-w-full"
            >
              <div className="text-white text-[12px] break-words leading-[1.2] text-stroke-small">
                {t('battleLooseDescription')}
              </div>
            </div>
          )}
        </div>
      </motion.div>

      {(currentHp <= 0 || isDead) && boughtLives < 3 && (
        <motion.div className="w-full pb-3 flex items-center justify-center" variants={itemVariants}>
          <div className="text-white">
            {t('buyMore')} <img className="inline w-4 h-4" src={require('../../../assets/images/Herz.png')} />{' '}
            {t('heartsToContinue')}
          </div>
        </motion.div>
      )}

      <div className="w-full flex justify-center items-center mb-10 gap-4">
        {enemyId && enemyId > 2 ? (
          <motion.div variants={itemVariants}>
            <PopupButton type="green" size="medium">
              <div
                onClick={async () => {
                  await handleResetDungeon();
                  if (actualSaves?.bossId) {
                    navigateTo('/boss');
                    // navigateTo('/island');
                  } else {
                    navigateTo('/island');
                    setStartSelectHero(true);
                  }
                }}
                className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]"
              >
                {t('btnStatus.leave')}
              </div>
            </PopupButton>
          </motion.div>
        ) : null}
        {(currentHp <= 0 || isDead) && boughtLives < 3 && (
          <motion.div variants={itemVariants} className="relative flex justify-center">
            {boughtLives === 0 ? (
              <PopupButton size="medium" type="gold" onClick={handleShare}>
                <div className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]">
                  <div className="flex justify-center items-center gap-[2px]">
                    <div className="flex justify-center items-center gap-[3px]">
                      {t('btnStatus.share')} +{' '}
                      <img className="inline w-4 h-4" src={require('../../../assets/images/Herz.png')} />
                    </div>
                  </div>
                </div>
              </PopupButton>
            ) : (
              <PopupButton size="medium" type="gold" onClick={handleBuy}>
                <div
                  className={` absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[80%] -translate-y-[20%] `}
                >
                  <div
                    className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                  >
                    <img
                      src={require('../../../assets/images/shop/offer/notify.png')}
                      className={`w-[3em] aspect-square -translate-y-[5%] `}
                      alt=""
                    />
                    {(offerCost / 10).toFixed(0)}
                  </div>
                </div>
                <div className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]">
                  <div className="flex justify-center items-center gap-[2px]">
                    <div className="flex justify-center items-center gap-[3px]">
                      {t('buyFor')}
                      <img className="inline w-4 h-4" src={require('../../../assets/images/telegram-stars-icon.png')} />
                      {offerCost}
                    </div>
                  </div>
                </div>
              </PopupButton>
            )}
            <div className="absolute -bottom-7 font-bold text-[#949494]">
              <span className="text-[18px] text-white">{3 - boughtLives}</span>
              /3
            </div>
          </motion.div>
        )}
      </div>

      {enemyId && enemyId > 2 && !tutorialUpgradeActive ? (
        <>
          <motion.div
            className="text-center text-white text-[20px] text-stroke-small font-[500] leading-tight pb-2"
            variants={itemVariants}
            transition={{
              delay: 1,
            }}
          >
            {t('looseALot')}
          </motion.div>

          <div className="flex flex-col gap-2">
            {upgradeCards.map((card, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                transition={{
                  delay: 1,
                }}
              >
                <UpgradeCard
                  image={card.image}
                  title={card.title}
                  buttonText={card.buttonText}
                  link={card.link}
                  handleDefeat={handleResetDungeon}
                />
              </motion.div>
            ))}
          </div>
        </>
      ) : null}
    </motion.div>
  );
};
