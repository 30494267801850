// QuestBlock.jsx
import React from 'react';
import { PopupButton } from '../../../../components/PopupButton';
import { Resources } from '../../../../enums/resources';
import { KitsuIco, StoneIco, XPIco } from '../../../../layout/components/HeaderFarm/components';
import { useTranslation } from 'react-i18next';
import { TaskStatus } from '../../../../app/features/questsSlice';
import { handleRewardResult } from './rewardParse';
import { QuestType } from '../..';
import { SocialTaskCategory } from '../../../../endpoints/userQuests';
import { useDispatch, useSelector } from 'react-redux';
import { saveFarmTutorialProgress } from '../../../../app/features/farmTutoralSlice';
import { AppDispatch, RootState } from '../../../../app/store';
import { useTelegram } from '../../../../hooks/useTelegram';
import { TutorialFarmSave, TutorialFarmStage } from '../../../../interfaces/tutorial';
import { DepositPopup } from '../../../Farm/IslandWindow/components/DepositPopup';
import { useTonAddress } from '@tonconnect/ui-react';
import { useSessionStorage } from '@uidotdev/usehooks';

export const getImageSrc = (category: SocialTaskCategory) => {
  switch (category) {
    case SocialTaskCategory.Telegram:
      return require('../../../../assets/images/quests/tg.png');
    case SocialTaskCategory.Twitter:
      return require('../../../../assets/images/quests/x.png');
    case SocialTaskCategory.TelegramPremium:
      return require('../../../../assets/images/quests/tg.png');
    default:
      return require('../../../../assets/images/quests/tg.png'); // Значення за замовчуванням
  }
};

interface QuestBlockProps {
  quest: any;
}

export const DepositBlock: React.FC<QuestBlockProps> = ({ quest }) => {
  const { t } = useTranslation();
  const address = useTonAddress();

  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);

  const handleOpenDepositModal = () => {
    setOpenedDepositModal(!openedDepositModal);
  };

  function decodeHTMLEntities(text: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = text;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  function stripHTMLTags(str: string): string {
    // Видаляємо всі HTML-теги з рядка
    const withoutTags = str.replace(/<\/?[^>]+(>|$)/g, '');

    // Декодуємо HTML-сутності (наприклад, &nbsp;)
    const decoded = decodeHTMLEntities(withoutTags);

    // Обрізаємо пробіли з обох кінців рядка
    const trimmed = decoded.trim();

    // Якщо після всіх операцій рядок порожній, повертаємо порожній рядок
    return trimmed === '' ? '' : trimmed;
  }

  const { condition, rewards, canClaim, currentProgress, onClick } = quest;

  const Reward = () => {
    const rewardParts = rewards.map((reward: any, index: number) => {
      const { amount, rewardId, rewardType } = reward;

      const resource = handleRewardResult({
        rewardId: rewardId,
        rewardType: rewardType,
      });

      // Отримуємо назву ресурсу

      // Отримуємо іконку ресурсу
      const icon = resource?.image;

      // Формуємо частину повідомлення з кількістю і ресурсом

      // Повертаємо JSX для кожного елемента з текстом і іконкою
      const resourceName = resource?.name as keyof typeof Resources;
      return (
        <div
          key={index}
          className={`flex items-center justify-center flex-col min-w-[40px] max-w-[40px] h-[40px] p-1 rounded-md border border-black bg-gradient-to-b 
            ${rewardId === Resources.kitsu ? 'from-[#D158D8] to-[#9444DD]' : 'from-[#484CAA] to-[#4247C9]'} pb-[1px]`}
        >
          {Resources[resourceName] === Resources['5m'] && (
            <div className="w-[38px] h-[10px] mt-[2px] flex items-center justify-center border-b border-black bg-[#333693] rounded-t-md">
              <span className="w-full text-[8px] text-white text-center">5m</span>
            </div>
          )}
          <div
            className={`relative w-full h-[30px] flex items-center justify-center ${
              Resources[resourceName] === Resources['5m'] && 'mb-[5px]'
            }`}
          >
            <img src={icon} alt="Icon" className="w-[28px] h-[28px] object-cover rounded-lg" />
            <span
              className={`absolute text-[8px] text-white right-0 ${
                Resources[resourceName] === Resources['5m'] ? 'mt-[20px]' : 'mt-[25px]'
              }`}
              style={
                Resources[resourceName] !== Resources['5m']
                  ? {
                      filter:
                        'drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)',
                    }
                  : {}
              }
            >
              {Resources[resourceName] === Resources['5m'] ? 1 : amount}
            </span>
          </div>
        </div>
      );
    });

    return <div className="flex  gap-1 w-full min-w-[40%] overflow-scroll">{rewardParts}</div>;
  };

  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const formatNumber = (num: number): string => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2).replace(/\.?0+$/, '') + 'М';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(2).replace(/\.?0+$/, '') + 'К';
    } else {
      return Math.floor(num).toString();
    }
  };

  return (
    <>
      <div
        className={`relative flex flex-col gap-2 rounded-md overflow-visible w-full 
          ${canClaim ? 'bg-[#584d3c] order-first' : ''}`}
      >
        {canClaim ? (
          <div className="w-3 min-w-3 h-3 min-h-3 bg-[#ff3a3a] border border-[black] rounded-full absolute -top-[1px] -right-[1px] z-15 pointer-events-none"></div>
        ) : null}

        <div className="flex items-center justify-center flex-grow w-full bg-[url('./assets/images/deposit-task-background.jpg')] bg-cover rounded-md shadow-md border-2 border-[#574E48]">
          {/* Зображення */}
          <div className="w-[68px] h-[68px] p-1 flex-shrink-0 ml-2 mr-[2px] border border-black rounded-md bg-[#796b4c] shadow-inner-sm-black">
            <img
              src={require('../../../../assets/images/deposit-task-icon.png')}
              alt=""
              className="w-full h-full object-contain"
            />
          </div>

          {/* Текст та іконка */}
          <div className="flex flex-col items-center flex-grow p-[7px] gap-[3px]">
            <div
              className="text-white text-base font-bold leading-tight w-full break-words"
              style={{
                filter:
                  'drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)',
              }}
            >
              {`${t('deposit')}/${t('buy')} $KITSU ${formatNumber(Math.floor(currentProgress))}/${formatNumber(condition)}`}
            </div>
            {/* {stripHTMLTags(description).length ? (
              <div
                className="text-[#f6a000] text-[11px] font-bold w-full leading-[10px] break-words mb-[5px] text-shadow-black"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            ) : null} */}

            <div className="w-full flex items-center justify-between">
              <div className="self-start bg-[#00000033] p-[3px] rounded-md">
                <Reward />
              </div>
              {/* Кнопка */}
              {!canClaim ? (
                <div className="ml-auto min-w-[70px] flex flex-shrink-0 self-end">
                  <PopupButton type="blue" onClick={handleOpenDepositModal} className="mr-2">
                    {t('deposit')}
                  </PopupButton>
                  <PopupButton type="blue">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&ft=TON&tt=EQAmLDfD9kc5nrqCfrCRQ1pK3KL6wB7Ya4Nud5HjNbwnbiWF"
                    >
                      {t('buy')}
                    </a>
                  </PopupButton>
                </div>
              ) : (
                <PopupButton type="green" onClick={onClick} width="60px">
                  {t('btnStatus.claim')}
                </PopupButton>
              )}
            </div>
          </div>
        </div>
      </div>
      {openedDepositModal && (
        <div
          className={`fixed z-[56] left-1/2 -translate-x-1/2 top-0 max-w-[550px] mx-auto w-full h-full bg-[rgba(0,0,0,0.5)]`}
        >
          <DepositPopup onClick={handleOpenDepositModal} address={address} />
        </div>
      )}
    </>
  );
};
