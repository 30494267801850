import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardsModal } from '../../../pages/Battle/CardsModal';
import { CardProps } from '../../../interfaces/card';
import { HpBarAnimation } from '../../../components';
import { useUtils } from '../../../utils/navigateTo';
import { ManaBarAnimation } from '../../../components/animation/ManaBarAnimation';
import { useTranslation } from 'react-i18next';
import { useSoundService } from '../../../utils/soundService';
import { PopupButton } from '../../../components/PopupButton';
import TypingFinger from '../../../components/animation/TypingFinger';
import { RootState } from '../../../app/store';
import { TutorialStage } from '../../../interfaces/tutorial';
import { ItemCard } from '../FooterCave/components';

interface ProfileBattleDataProps {
  currentHp: number;
  maxHp: number;
  currentMana: number;
  maxMana: number;
  heroName: string;
  isDeckOpen: boolean;
  openDeck: () => void;
  closeDeck: () => void;
  cardsAtDeck: CardProps[];
  cardsAtHand: CardProps[];
  endTurnEndpoint: any;
  currentDefense: number;
  isDraggingCards: boolean;
  isFirstCardPlayed: boolean;
  startTurn: boolean;
  usedLives: number;
  heroLvl: number;
}
export const ProfileBattleData = (props: ProfileBattleDataProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { playSound } = useSoundService();
  const { activeDiv } = useUtils();
  const [isClick, setIsClick] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isPlayerTurn, setIsPlayerTurn] = useState<boolean>(true);
  const tutorialSave = useSelector((state: RootState) => state.tutorialSave.tutorialSave.save);

  // const [currentHp, setCurrentHp] = useState(15);
  // const [currentMana, setCurrentMana] = useState(2);
  // const [maxHp, setMaxHp] = useState(20);
  // const [maxMana, setMaxMana] = useState(8);
  // const [isDeckOpen, setIsDeckOpen] = useState(false);
  const {
    isDeckOpen,
    maxHp,
    maxMana,
    currentMana,
    currentHp,
    heroName,
    cardsAtDeck,
    cardsAtHand,
    endTurnEndpoint,
    currentDefense,
    isDraggingCards,
    isFirstCardPlayed,
    startTurn,
    usedLives,
    heroLvl,
  } = props;

  useEffect(() => {
    if (startTurn) {
      setIsPlayerTurn(true);
    }
  }, [startTurn]);

  // const openDeck = () => {
  //   setIsDeckOpen(true);
  // };
  //
  // const closeDeck = () => {
  //   setIsDeckOpen(false);
  // };

  useEffect(() => {
    if (cardsAtHand.length === 0 && !isClick && tutorialSave?.enemyId === 0) {
      setIsShow(true);
    }
    if (isClick) {
      setIsShow(false);
    }
  }, [cardsAtHand.length, isClick]);
  return (
    <div
      className={`bottom-0 fixed max-w-[550px] px-2 pt-[10px] pb-8 w-full bg-[#1b1b1d] flex flex-nowrap items-center ${isDeckOpen ? 'z-[11]' : 'z-[1]'}`}
    >
      {isDeckOpen && <CardsModal cardsAtDeck={cardsAtDeck} closeDeck={props.closeDeck} />}
      <div className="absolute top-0 left-0 w-full h-[6px] bg-gradient-to-b from-[#4f3e35] to-[#302620] border-y border-black shadow-[0_0.4px_0_1px_#131314]"></div>
      <div className="mr-2">
        <ItemCard
              key={'card'}
          img={undefined}
          name={t('deck')}
          onClick={() => {
            if (isPlayerTurn) {
              props.openDeck();
              playSound('button');
            }
          }}
          cardsAmount={cardsAtDeck.length}
        />
      </div>

      <div className="flex-1 mr-5">
        <div className="flex justify-between">
          <div className="flex items-center gap-2 mb-1">
            {heroName ? <div className="text-white font-[900] text-[16px] leading-[1]">{heroName}</div> : null}
            <div className="text-amber-500 text-[16px] text-nowrap leading-[1] font-[600]">
              {t('level', { lvl: heroLvl })}
            </div>
          </div>
          <div className="flex gap-1 mb-1">
            <div className="w-[25px] h-[25px] flex items-center justify-center">
              <img className="w-full h-full object-cover" src={require('../../../assets/images/Herz.png')} alt="Herz" />
              <p className="absolute text-[14px] text-white text-stroke-regular leading-none">{2 - usedLives}</p>
            </div>
            <div className="w-[25px] h-[25px] flex items-center justify-center">
              <img
                className="w-full h-full object-cover"
                src={require('../../../assets/images/mana-icon.png')}
                alt="Mana"
              />
              <p className="absolute text-[14px] text-white text-stroke-regular leading-none">{currentMana}</p>
            </div>
          </div>
        </div>
        <div className="relative text-white h-4 text-center text-xl leading-3 w-full">
          {activeDiv === '/battle' && <HpBarAnimation key={'hpBarAnimation'} currentHp={currentHp} maxHp={maxHp} />}
        </div>
      </div>

      <PopupButton
        type="green"
        className="flex-shrink-0"
        onClick={() => {
          if (!isDraggingCards) return;
          endTurnEndpoint();
          setIsClick(true);
          playSound('button');
          setIsPlayerTurn(false);
          if (cardsAtHand.length > 0) {
            playSound('discard');
          }
        }}
      >
        {isShow && <TypingFinger />}
        {isFirstCardPlayed && isDraggingCards && (
          <div
            className="btn-pulse transition animate-opacityTransition
          absolute z-[-1] w-full h-full left-0 top-0"
          ></div>
        )}
        {t('tutorial.endTurn')}
      </PopupButton>
    </div>
  );
};
