import { CardImage } from '../components/Card/CardComponentBuilder';
import {
  ArtefactActionType,
  ArtefactOptions,
  CalculationMethod,
  CardType,
  ConditionParameter,
  ConditionSide,
  EffectType,
  ICard,
  ISkill,
  Opponent,
  TriggerCondition,
  TriggerType,
} from '../endpoints/mock';

// interface ConfigCard {
//   id: { value: number };
//   cardType: { value: number };
//   cost: { value: number };
//   stars: {
//     value: Array<{
//       skills: {
//         value: Array<{
//           effectType: { value: number };
//           value: { value: number };
//           target: { value: string };
//           animationCount: { value: number };
//         }>;
//       };
//     }>;
//   };
// }
interface CardSkill {
  value: { value: number };
  target: { value: Opponent };
  duration: { value: number };
  cost: { value: number };
  effectType: { value: EffectType };
  roundAmount?: { value: number };
  animationCount: { value: number };
}

interface ConfigArtefactOptions {
  artefactActionType: { value: ArtefactActionType };
  triggerType?: { value: TriggerType };
  triggerValue: { value: number };
  triggerCondition?: { value: TriggerCondition };
  cardType?: { value: CardType };
}

interface CardsConfig {
  id: { value: number };
  energyType: { value: number };
  cardType: { value: number };
  image: { value: CardImage };
  description: { value: string };
  cost: { value: number };
  upgrades: { value: number };
  isExiled?: { value: boolean };
  stars: {
    value: {
      skills: { value: CardSkill[] };
      cost: { value: number };
    }[];
  };
  name: { value: string };
  artefactOptions?: { value: ConfigArtefactOptions };
  probability: { value: number };
  condition?: {
    value: {
      target: { value: Opponent };
      parameter: { value: ConditionParameter };
      value: { value: number };
      calculationMethod: { value: CalculationMethod };
      conditionSide: { value: ConditionSide };
    };
  };
}

export const mixCards = (configCards: CardsConfig[]): ICard[] => {
  return configCards.map((configCard) => {
    if (!configCard) {
      // console.warn(`No config found for local card ID ${localCard.id}`);
      return {} as ICard;
    }
    const cardType = configCard.cardType.value;

    return {
      id: configCard.id.value,
      energyType: configCard.energyType.value,
      cardType: configCard.cardType.value,
      cost: configCard.cost.value,
      upgrades: configCard.upgrades.value,
      isExiled: configCard.isExiled?.value,
      // priceManna: configCard.cost.value,
      stars: configCard.stars.value.map((star) => {
        return {
          skills: star.skills.value.map((effect) => ({
            value: effect.value.value,
            target: effect.target.value,
            effectType: effect.effectType.value,
            animationCount: effect.animationCount.value,
            duration: effect.duration.value,
          })),
          cost: star.cost.value,
        };
      }),
      name: configCard.name.value,
    } as unknown as ICard;
  });
};
