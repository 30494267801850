import { SkillNode } from '../SkillNode';
import { SkillData } from '../transformTreeToSkillsData';

export const GenerateNodesAndEdges = (skills: SkillData[], selectedNodeId: string | null) => {
  const nodes = skills.map((skill, index) => {
    return {
      id: skill.id,
      type: 'customSkillNode',
      data: {
        image: skill.image,
        size: skill.size,
        alt: `Skill ${skill.id}`,
        selected: selectedNodeId ? skill.id === selectedNodeId : false,
        levels: skill.levels,
        claimed: skill.isClaimed,
        available: skill.isAvailable,
        transformTarget: skill.transformTarget,
        transformSource: skill.transformSource,
      },
      position: skill.position,
    };
  });

  const edges: any[] = [];
  skills.forEach((skill) => {
    if (skill.children) {
      skill.children.forEach((childId: any) => {
        const childSkill = skills.find((s) => s.id === childId);

        const edgeColor =
          !childSkill?.isClaimed && !childSkill?.isAvailable
            ? // ? "rgba(0,0,0,0)"
              'rgba(0,0,0,0)'
            : '#dc8603';

        edges.push({
          id: `e${skill.id}-${childId}`,
          source: skill.id,
          target: childId,
          type: 'custom',
          animated: !childSkill?.isClaimed && childSkill?.isAvailable ? true : false,
          style: { stroke: edgeColor },
        });
      });
    }
  });

  return { nodes, edges };
};
