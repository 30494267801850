import { useEffect, useState, memo } from 'react';
import { SkillData } from '../../../containers/Heroes/components/HeroCard/components/transformTreeToSkillsData';
import { useTranslation } from 'react-i18next';
import { BonusType } from '../../../interfaces/hero';

interface TiersModalProps {
  skillData: SkillData[];
  expanded: boolean;
}

interface GroupedReward {
  bonusType: BonusType;
  value: number;
  image: string;
}

export const TiersModal: React.FC<TiersModalProps> = memo(({ skillData, expanded }) => {
  const [tiersExpand, setTiersExpand] = useState(false);
  useEffect(() => {
    setTiersExpand(false);
  }, [expanded]);
  const { t } = useTranslation();

  const singleLevelRewards = skillData
    .filter((skill) => skill.isClaimed && skill.isAvailable)
    .filter((skill) => {
      const claimedLevels = skill.levels.filter((level) => level.isClaimed);
      return claimedLevels.length === 1;
    })
    .flatMap((skill) => {
      const claimedLevels = skill.levels.filter((level) => level.isClaimed);
      const level = claimedLevels[0];
      return level.rewards.map((reward) => ({
        ...reward,
        image: skill.image,
      }));
    });

  // Групуємо бонуси із скілів з одним рівнем за bonusType із сумуванням значень.
  const groupedSingleRewards = singleLevelRewards.reduce((acc, reward) => {
    if (acc[reward.bonusType]) {
      acc[reward.bonusType]!.value += reward.value;
    } else {
      acc[reward.bonusType] = {
        bonusType: reward.bonusType,
        value: reward.value,
        image: reward.image, // зображення скіла береться з першої появи бонусу
      };
    }
    return acc;
  }, {} as Partial<Record<BonusType, GroupedReward>>);

  const groupedSingleRewardsArr = Object.values(groupedSingleRewards) as GroupedReward[];

  const multiLevelRewards = skillData
    .filter((skill) => skill.isClaimed && skill.isAvailable)
    .filter((skill) => {
      const claimedLevels = skill.levels.filter((level) => level.isClaimed);
      return claimedLevels.length > 1;
    })
    .flatMap((skill) => {
      const claimedLevels = skill.levels.filter((level) => level.isClaimed);
      const lastLevel = claimedLevels[claimedLevels.length - 1]; // останній доступний рівень
      return lastLevel.rewards.map((reward) => ({
        ...reward,
        image: skill.image,
      }));
    });

  // Об'єднуємо обидва набори бонусів для відображення:
  const rewardsToRender = [...groupedSingleRewardsArr, ...multiLevelRewards];

  return (
    <>
      <button
        onClick={() => setTiersExpand(true)}
        className="absolute w-[50px] h-[50px] left-[9px] bottom-[101px] z-[1]"
      >
        <img src={require('../../../assets/images/battle-tiers.png')} alt="Battle Tiers" />
      </button>

      {/* Overlay */}
      {tiersExpand && <div onClick={() => setTiersExpand(false)} className="absolute w-full h-full z-[8] "></div>}

      {/* Modal Content */}
      {tiersExpand && (
        <div className="absolute flex flex-col items-center left-[10px] bottom-[156px] max-h-[240px] w-[225px] bg-[#19191b] z-[11] rounded-[7px] border border-[#534737] overflow-auto">
          <div className="w-full">
            {rewardsToRender.map((reward, index) => {
              return (
                <div
                  key={index}
                  className={`flex justify-start items-center ${
                    index % 2 === 0 ? 'bg-[#342a21] rounded' : 'bg-[#18191a]'
                  } py-1 px-2`}
                >
                  <div className="flex items-center">
                    <img
                      src={require(`../../../assets/images/heroes/upgradeIcons/${reward.image}`)}
                      className="w-[34px] min-w-[34px] h-[34px] mr-2"
                      alt="Skill Icon"
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex gap-2 justify-center items-center">
                      <div className="text-white text-sm font-light leading-[14px]">
                        {t(`bonusTypes.${reward.bonusType}`) || reward.bonusType}:
                      </div>
                      <span className="text-[#ff3a3a] text-sm font-light leading-[14px]">{reward.value}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
});
