import { useDispatch, useSelector } from 'react-redux';
import { HeaderIco } from './components/HeaderIco';
import { ResourceCard } from './components/ResourceCard';
import { AppDispatch, RootState } from '../../../app/store';
import React, { useEffect, useState } from 'react';
import { useUtils } from '../../../utils/navigateTo';
import { Shop } from '../../../pages/Shop';
import { useTelegram } from '../../../hooks/useTelegram';
import { getBalance } from '../../../endpoints/farmMock';
import { Resources } from '../../../enums/resources';
import { useSoundService } from '../../../utils/soundService';
import { motion } from 'framer-motion'; // Імпорт motion
import { TutorialFarmStage } from '../../../interfaces/tutorial';
import { getUserRank } from '../../../endpoints/rankEndpoints';
import { clearUserRank, fetchUserRank } from '../../../app/features/userRankSlice';
import { useSessionStorage } from '@uidotdev/usehooks';
import { getDailyFreebieStatus } from '../../../endpoints/getProfileInfo';
import { useTranslation } from 'react-i18next';

export const HeaderFarm = () => {
  const { navigateTo } = useUtils();
  const dispatch = useDispatch<AppDispatch>();
  const [isShopOpen, setShopOpen] = useSessionStorage('isShopOpen', false);
  const [isLoading, setIsLoading] = useState(true);
  const [canClaimFreebie, setCanClaimFreebie] = useSessionStorage('canClaimFreebie', false);
  const [chestIsClaimed, setChestIsClaimed] = useState<boolean>(false);
  const { playSound } = useSoundService();
  const { userRank, loading, error } = useSelector((state: RootState) => state.userRank);
  const webSocketStatus = useSelector((state: RootState) => state.webSocket.status);
  const { t } = useTranslation();

  const { tg, userId } = useTelegram();

  const resources = useSelector((state: RootState) => state.resources.resources);

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  useEffect(() => {
    const getChestInfo = async () => {
      const chestInfo = await getDailyFreebieStatus({ clientId: userId });
      if (chestInfo) {
        setCanClaimFreebie(chestInfo.isCanClaim);
      }
    };

    getChestInfo();
  }, []);

  const fetchBalance = async () => {
    if (userId) {
      setIsLoading(true);
      try {
        return await getBalance({ clientId: `${userId}` });
      } catch (error) {
        console.error('Failed to fetch balance:', error);
        // Можливо, встановити стан помилки та відобразити повідомлення користувачу
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchUserRank({ clientId: userId }));

    return () => {
      dispatch(clearUserRank());
    };
  }, [dispatch]);

  useEffect(() => {
    fetchBalance();
  }, []);

  const closeShop = () => {
    setShopOpen(false);
    playSound('button');
  };

  const handleChestClaim = () => {
    if (chestIsClaimed) {
      setChestIsClaimed(true);
      setTimeout(() => {
        setChestIsClaimed(false);
      }, 1000);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Або ваш компонент завантаження
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return resources &&
    resources.length &&
    farmTutorialSave?.stage !== TutorialFarmStage.endbattle &&
    farmTutorialSave?.stage !== TutorialFarmStage.clickOnQuests &&
    farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards ? (
    <motion.div
      initial={{ opacity: 0, y: -50 }} // Початковий стан: прозорість 0 і зміщення вгору на 50px
      animate={{ opacity: 1, y: 0 }} // Анімація до: прозорість 1 і початкове положення
      transition={{ duration: 0.5, ease: 'easeOut' }} // Тривалість анімації 0.5 секунди
      className="fixed pt-2 flex flex-row px-4 pr-0 z-[57] justify-center items-start w-full gap-[12px]
        bg-gradient-to-b from-[#000000cd] from-start to-[#0d0d0d00] to-end max-w-[550px]"
    >
      <div className="flex flex-col items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards) {
              navigateTo('/profile');
              playSound('menuOpen');
            }
          }}
        >
          <HeaderIco lvl={0} />
        </div>
        {userRank ? (
          <div
            className="flex justify-center items-center absolute top-[85px]"
            onClick={() => {
              navigateTo('/rank');
              playSound('menuOpen');
            }}
          >
            <div className="relative w-[42px] h-[42px]">
              {userRank?.myRankLvl ? (
                <img
                  className="w-full h-full"
                  src={require(`../../../assets/images/ranks/${userRank.myRankLvl}.png?type=base64`)}
                />
              ) : null}
              <div className="flex justify-center items-center absolute w-full bottom-0">
                <div className="w-9 h-3">
                  <img className="w-full h-full" src={require('../../../assets/images/ranks/titlePlace.png?type=base64')} />
                </div>
                <div className="absolute text-center text-white text-[8.57px] font-black  leading-[8.57px] text-shadow-black-sm">
                  {userRank?.myRankLvl} Rank
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className={`flex justify-center items-center absolute top-[141px]`}>
          <div
            onClick={() => {
              navigateTo('/leaderboard');
            }}
            className={`flex justify-center items-center relative w-[50px] h-[50px]`}
          >
            <img
              className={`absolute object-cover`}
              src={require('../../../assets/images/leaderboard-icon.png?type=base64')}
              alt="leaderboard"
            />
            <p
              className={`text-center text-sm absolute left-[50%] translate-x-[-50%] bottom-[-5px] text-white text-stroke-small leading-[0.9]`}
            >
              {t('farm.icons.leaderboard')}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-1 ml-2 flex-row mb-[14px] gap-4 pt-1">
        <ResourceCard count={resources[Resources.stone]?.value} type={resources[Resources.stone].resourceType} />
        <ResourceCard
          count={resources[Resources.experience]?.value}
          type={resources[Resources.experience].resourceType}
        />
      </div>
      <button
        className="relative w-[102px]"
        onClick={() => {
          if (farmTutorialSave?.stage !== TutorialFarmStage.clickOnRewards) {
            setShopOpen(true);
            playSound('itemUseShop');
          }
        }}
      >
        {canClaimFreebie && (
          <div className="absolute left-[-5px] top-[10px] w-[15px] h-[15px]">
            <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full"></div>
          </div>
        )}
        <img src={require('../../../assets/images/header-shop-button.png?type=base64')} alt="" />
        <div className="absolute bottom-1 w-full text-center text-white text-stroke-regular flex justify-center">
          {/* <div className="flex justify-center items-center text-center mr-[2px]">
            <img
              src={require("../../../assets/images/kitsu-icon.png")}
              className="w-[18px] h-[18px]"
              alt=""
            />
          </div> */}
          <ResourceCard count={resources[Resources.kitsu]?.value} type={resources[Resources.kitsu]?.resourceType} />
        </div>
      </button>
      {isShopOpen && (
        <>
          <Shop gold={resources[Resources.kitsu]?.value} close={() => closeShop()} />
        </>
      )}
    </motion.div>
  ) : null;
};
