// import React from 'react';
// import { useState, useEffect } from 'react';

// const Timer = (props: { deadline: string }) => {
//   const [days, setDays] = useState(0);
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);

//   const deadline = new Date(props?.deadline);

//   const getTime = (deadline: any) => {
//     const time = Date.parse(deadline.toString()) - Date.now();
//     setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
//     setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
//     setMinutes(Math.floor((time / 1000 / 60) % 60));
//     setSeconds(Math.floor((time / 1000) % 60));
//   };

//   useEffect(() => {
//     getTime(deadline);
//     const interval = setInterval(() => getTime(deadline), 1000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="flex items-center text-white text-stroke-small">
//       {days > 1 ? (
//         <div>{days} days</div>
//       ) : (
//         <div className="flex">
//           <p>{hours < 10 ? '0' + hours : hours}:</p>
//           <p>{minutes < 10 ? '0' + minutes : minutes}:</p>
//           <p>{seconds < 10 ? '0' + seconds : seconds}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Timer;
import React, { useState, useEffect, useMemo } from 'react';

const Timer = ({ deadline }: { deadline?: string }) => {
  // Вычисляем время до следующего полночи по UTC
  const getNextUTCMidnight = () => {
    const now = new Date();
    const nextUTCMidnight = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate() + 1, // Следующий день
        0,
        0,
        0,
        0 // 00:00:00 по UTC
      )
    );
    return nextUTCMidnight.getTime() - now.getTime(); // Разница в миллисекундах
  };

  const [timeLeft, setTimeLeft] = useState<number>(getNextUTCMidnight());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getNextUTCMidnight()); // Обновляем время каждую секунду
    }, 1000);

    return () => clearInterval(interval); // Очистка интервала
  }, []);

  // Форматирование времени
  const { hours, minutes, seconds } = useMemo(() => {
    const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
    const seconds = Math.floor((timeLeft / 1000) % 60);

    return { hours, minutes, seconds };
  }, [timeLeft]);

  // Функция для форматирования чисел (добавление ведущего нуля)
  const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

  return (
    <div className="flex items-center text-white text-stroke-small">
      <div className="flex">
        <p>{formatTime(hours)}:</p>
        <p>{formatTime(minutes)}:</p>
        <p>{formatTime(seconds)}</p>
      </div>
    </div>
  );
};

export default Timer;
