import { useState } from "react";
import { ArtefactCard } from "../../../components";


// interface Artifact {
//   id: number;
//   name: string;
//   description: string;
//   rarity: string;
//   image: string;
// }

// interface ArtifactsModalProps {
//   artifacts: Artifact[];
// }

export const ArtefactsModal: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsExpanded(true)}
        className="absolute w-[50px] h-[50px] right-[9px] bottom-[101px] z-[1]"
      >
        <img
          src={require("../../../assets/images/artifact-icon.png")}
          alt="Artifacts"
        />
      </button>

      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className="absolute w-full h-full z-[8]"
        ></div>
      )}

      {isExpanded && (
        <div className="absolute flex flex-col items-center px-[16px] pb-[16px] right-2 bottom-[156px] max-h-[240px] w-[188px] max-w-[240px] bg-[#19191b] z-[11] rounded-lg border border-[#534737] overflow-auto">
          <p
            className="text-white text-[12px] py-[7px]"
            style={{
              filter:
                "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
            }}
          >
            Artifacts
          </p>

          <div className="flex flex-col gap-[5px]">
            {/* <ArtefactCard />
            <ArtefactCard />
            <ArtefactCard /> */}
          </div>
        </div>
      )}
    </>
  );
};
