import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

interface ReloadableImageProps {
  src: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
}

const ReImg = ({src, alt = '', className, style, onLoad }: ReloadableImageProps) => {
  const wsStatus = useSelector((state: RootState) => state.webSocket.status)
  const [attempt, setAttempt] = useState(0);
  const [imgSrc, setImgSrc] = useState(src);
  const maxRetries: number = 3

  useEffect(() => {
    if (wsStatus === 'connected') {
      setTimeout(() => {
        if (!src.startsWith('data:image')) { // base64 images
          setImgSrc(src);
        }
      }, 1000);
    }
  }, [wsStatus]);

  const handleError = () => {
    if (attempt < maxRetries) {
      setAttempt(attempt + 1);
    }
  };

  return (
    <img
      src={imgSrc}
      className={className}
      style={style}
      alt={alt}
      onError={handleError}
      onLoad={onLoad} />
  );
};

export default ReImg;
