import {
  Rive,
  Layout,
  Fit,
  Alignment,
  useRive,
  useStateMachineInput,
  FileAsset,
  decodeImage,
  ImageAsset,
  RiveFile,
} from "@rive-app/react-canvas";
import { useEffect, memo } from "react";

export const HitAnimation = memo(({
  triggerHit,
  hitType,
  hitAmount,
  className,
  riveFile,
}: {
  triggerHit?: boolean;
  hitType: number;
  hitAmount: number;
  className?: string;
  riveFile: RiveFile;
}) => {
  const { rive, RiveComponent } = useRive({
    src: "animation/Hit.riv",
    stateMachines: "State Machine 1",
    autoplay: false,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
    riveFile: riveFile,
  });


  const hitInput = useStateMachineInput(
    rive,
    "State Machine 1",
    "Number 1",
    hitType
  );

  useEffect(() => {
    if (rive) {

      rive.setTextRunValue("HitNum", `-${hitAmount}`);
    }
  }, [rive]);
  console.log('insidehit', triggerHit);
  
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (rive && triggerHit) {
      console.log('hit');
      rive.play();
      timeout = setTimeout(() => {
        rive.cleanup()
      }, 500)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [rive, triggerHit]);

  

  return <RiveComponent className={className} />;
});
