import { Layout, Fit, Alignment, useRive } from "@rive-app/react-canvas";

export const ClickAnimation = ({ className }: { className?: string }) => {
  const { rive, RiveComponent } = useRive({
    src: "animation/click.riv",
    stateMachines: "State Machine 1",
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
  });

  return <RiveComponent className={className} />;
};
