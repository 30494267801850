interface ImageEntry {
  id?: number;
  name: string;
  data: Blob;
}

const openDatabase = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('preloaded-images', 3);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;

      if (!db.objectStoreNames.contains('images')) {
        const objectStore = db.createObjectStore('images', { keyPath: 'id', autoIncrement: true });
        objectStore.createIndex('name', 'name', { unique: true });
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const saveImageToDB = async (imageData: Blob, name: string): Promise<void> => {
  const db = await openDatabase();
  const transaction = db.transaction('images', 'readwrite');
  const store = transaction.objectStore('images');

  const entry: ImageEntry = { name, data: imageData };

  return new Promise((resolve, reject) => {
    const request = store.add(entry);

    request.onsuccess = () => {
      console.log(`Image "${name}" saved to IndexedDB.`);
      resolve();
    };

    request.onerror = () => {
      console.error(`Error saving image "${name}" to IndexedDB:`, request.error);
      reject(request.error);
    };
  });
};

export const getImageFromDB = async (name: string): Promise<string | null> => {
    const db = await openDatabase();
    const transaction = db.transaction('images', 'readonly');
    const store = transaction.objectStore('images');
    const index = store.index('name');
  
    return new Promise((resolve, reject) => {
      const request = index.get(name);
  
      request.onsuccess = () => {
        const result = request.result as ImageEntry | undefined;
        if (result) {
          const url = URL.createObjectURL(result.data); // Создаем временный URL из Blob
          resolve(url);
        } else {
          resolve(null); // Если изображение не найдено
        }
      };
  
      request.onerror = () => {
        console.error(`Error retrieving image "${name}" from IndexedDB:`, request.error);
        reject(request.error);
      };
    });
  };
  

// export const preloadAndSaveImage = async (src: string, name: string): Promise<void> => {
//   try {
//     const response = await fetch(src);
//     const blob = await response.blob();
//     await saveImageToDB(blob, name);
//   } catch (error) {
//     console.error(`Error preloading image "${name}" from "${src}":`, error);
//   }
// };

export const preloadImageWithRequire = async (path: string): Promise<void> => {
    try {
      const imagePath = require(`../${path}`);
      const response = await fetch(imagePath); 
      const blob = await response.blob();
      const imageName = path || "unknown"; 
      await saveImageToDB(blob, imageName);
      console.log(`Image "${imageName}" saved to IndexedDB.`);
    } catch (error) {
      console.error(`Error preloading image from "${path}":`, error);
    }
  };


//   useEffect(() => {
//     const fetchImage = async () => {
//       const imageName = path.split("/").pop() || ""; // Извлекаем имя файла
//       const url = await getImageFromDB(imageName);

//       if (url) {
//         setImageUrl(url);
//       } else {
//         console.error(`Image "${imageName}" not found in IndexedDB.`);
//       }
//     };

//     fetchImage();
//   }, [path]);

export const preloadAndSaveImageIfNotExists = async (src: string) => {
  try {
    // 1. Check if it's already in the DB
    const existingUrl = await getImageFromDB(src);
    console.log('existingUrl', existingUrl);
    
    if (existingUrl) {
      console.log(`Image "${src}" is already in IndexedDB, skipping fetch...`);
      return;
    }

    // 2. If not in DB, fetch from server
    const imagePath = require(`../${src}`);
    const response = await fetch(imagePath); 

    if (!response.ok) throw new Error(`Fetch failed for ${src}`);

    // 3. Save to DB
    const blob = await response.blob();
    await saveImageToDB(blob, src);
    console.log(`Image "${src}" saved to IndexedDB.`);
  } catch (error) {
    console.error(`Error preloading image "${src}" from "${src}":`, error);
  }
};