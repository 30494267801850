import { motion } from 'framer-motion';
import { PopupWindow } from '../../../../../../../components/PopupWindow';
import { useSessionStorage } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { PopupButton } from '../../../../../../../components/PopupButton';
import {
  getCodesByClientId,
  getPromotion,
  getPromotions,
  promoteCodesByClientId,
  updatePromotionByCode,
} from '../../../../../../../endpoints/codesEndpoints';
import { useTelegram } from '../../../../../../../hooks/useTelegram';
import { getBalance } from '../../../../../../../endpoints/farmMock';
import { Code } from '../..';
import { useTranslation } from 'react-i18next';
import { resourcesMap } from '../../../../../../../utils/mapping';
import { Resources } from '../../../../../../../enums/resources';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../app/store';

const depositPopupVariants = {
  hidden: { opacity: '0', transform: 'translateY(110%)' },
  visible: { opacity: '1', transform: 'translateY(-50%)' },
};

const EnterCodePopupHeader = ({}: {}) => {
  window.location.hash = 'input';
  const { t } = useTranslation();

  return (
    <>
      <div className="absolute top-[-15px] left-0 right-0 flex justify-self-center z-20">
        <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
          <div
            className="bg-[#351d1e] text-center text-white text-xl p-[7px] leading-none rounded-[1px] border
            border-[#18191a] shadow-inner-sm-black"
          >
            {t('codePurchase.kitsuCode')}
          </div>
        </div>
      </div>
    </>
  );
};
const NoPromotionsPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <motion.div
      variants={depositPopupVariants}
      initial="hidden"
      animate="visible"
      className="absolute w-full max-w-[400px] min-h-[200px] top-[50%] left-[5%] transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center"
    >
      <PopupWindow onClickClose={onClose} headerElement={<EnterCodePopupHeader />}>
        <div className="flex flex-col justify-center items-center p-4 gap-4">
          <div className="text-white text-[18px] font-bold text-center">{t('noPromotions.title')}</div>
          <div className="text-white text-[14px] text-center">{t('noPromotions.message')}</div>
          <PopupButton type="blue" size="small" onClick={onClose}>
            <div className="text-center text-white text-lg font-black">OK</div>
          </PopupButton>
        </div>
      </PopupWindow>
    </motion.div>
  );
};
export const UseCodeToPromotePopup: React.FC<any> = ({ getAllPromotions }) => {
  const [openUseCodeToPromotePopup, setOpenUseCodeToPromotePopup] = useSessionStorage<Code | null>(
    'openUseCodeToPromotePopup',
    null
  );
  const [openNoPromotionsPopup, setOpenNoPromotionsPopup] = useState(false);

  const [part1, setPart1] = useState('');
  const [part2, setPart2] = useState('');
  const [part3, setPart3] = useState('');

  const { userId } = useTelegram();
  const { t } = useTranslation();

  const [link, setLink] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [image, setImage] = useState(require('../../../../../../../assets/images/social/link.png'));

  const [userPromotions, setUserPromotions] = useState(0);
  const [userCodes, setUserCodes] = useSessionStorage('userCodes', []);
  const resources = useSelector((state: RootState) => state.resources.resources);

  useEffect(() => {
    const currentValue = resources.find((v) => v.resourceType === Resources.promotions)?.value || 0;
    setUserPromotions(currentValue);
  }, [resources]);

  useEffect(() => {
    if (openUseCodeToPromotePopup?.code) {
      // console.log('KOD', openUseCodeToPromotePopup);
      const segments = openUseCodeToPromotePopup.code.split('-');
      setPart1(segments[0] || '');
      setPart2(segments[1] || '');
      setPart3(segments[2] || '');
    }
    if (openUseCodeToPromotePopup?.isPromoting) {
      getPromotionInfo(openUseCodeToPromotePopup.code);
    }
  }, [openUseCodeToPromotePopup?.code]);

  const getPromotionInfo = async (code: string) => {
    const promotionInfo = await getPromotion({ code });

    if (promotionInfo) {
      setTitle(promotionInfo.title);
      setLink(promotionInfo.link);
      setImage(require('../../../../../../../assets/images/social/' + getLinkIcon(promotionInfo.link)));
    }
  };

  const handleSetLink = (value: string) => {
    setLink(value);
    setImage(require('../../../../../../../assets/images/social/' + getLinkIcon(value)));
  };

  const getLinkIcon = (url: string) => {
    if (url[0] === '@') return 'telegram.png';

    const socialMediaIcons = {
      'x.com': 'x.png',
      'twitter.com': 'twitter.png',
      't.me': 'telegram.png',
      'youtube.com': 'youtube.png',
      'facebook.com': 'facebook.png',
      'instagram.com': 'instagram.png',
      'tiktok.com': 'tiktok.png',
      'vk.com': 'vk.png',
      'twitch.tv': 'twitch.png',
    };

    for (const [domain, icon] of Object.entries(socialMediaIcons)) {
      if (url.includes(domain)) return icon;
    }

    return 'link.png';
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');

    const segments = pastedData?.split('-');

    setPart1(segments[0] || '');
    setPart2(segments[1] || '');
    setPart3(segments[2] || '');
  };

  const handleUpdatePromote = async () => {
    const data = await updatePromotionByCode({
      link,
      title,
      code: openUseCodeToPromotePopup?.code!,
    });

    if (data) {
      setOpenUseCodeToPromotePopup(null);
      getAllPromotions();
    }
  };
  const handlePromoteCode = async () => {
    const data = await promoteCodesByClientId({
      clientId: userId,
      code: `${part1}-${part2}-${part3}`,
      link: link[0] === '@' ? `https://t.me/${link.slice(1)}` : link,
      title: title.trim(),
    });

    if (data) {
      setOpenUseCodeToPromotePopup(null);
      getAllPromotions();
      const userCodes = await getCodesByClientId({ clientId: `${userId}` });
      if (userCodes) setUserCodes(userCodes);
      await getBalance({ clientId: `${userId}` });
    }
  };

  const [copied, setCopied] = useState(false);

  const handleCopyClick = async () => {
    if (!userId) {
      return;
    }

    const copyCode = `${part1}-${part2}-${part3}`;
    try {
      await navigator.clipboard.writeText(copyCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const validUrlOrChannel =
    '[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()!@:%_\\+.~#?&\\/\\/=]*)|^\\@[-a-zA-Z0-9._]{1,256}';
  const validUrlOrChannelRegex = new RegExp(validUrlOrChannel);

  const formatValue = (value: any, type: string) => {
    switch (type) {
      case 'url':
        return value?.replace(/[^\w\s\d@:\/\.\-]/g, '').trim();
      case 'title':
        return value?.replace(/[^\w\s\d]/g, '').slice(0, 20);
    }
  };

  const handleButtonClick = () => {
    if (userPromotions >= 0) {
      if (openUseCodeToPromotePopup?.isPromoting) {
        handleUpdatePromote();
      } else {
        handlePromoteCode();
      }
    } else {
      setOpenNoPromotionsPopup(true);
    }
  };

  return (
    <motion.div
      variants={depositPopupVariants}
      initial="hidden"
      animate="visible"
      className={`absolute w-[97%] min-h-full top-[50%] translate-y-[-50%] left-[1.5%] flex justify-center items-center`}
    >
      <PopupWindow
        onClickClose={() => {
          window.location.hash = '';
          setOpenUseCodeToPromotePopup(null);
        }}
        headerElement={<EnterCodePopupHeader />}
      >
        <div className="flex justify-center items-center flex-col p-2 gap-4 w-full max-w-[292px]">
          <div className="text-white text-[16px] text-center leading-[1.2] mt-3.5 -mb-2">
            {t('codePurchase.promotionModalReferral')}
          </div>
          <div className="flex justify-center items-center gap-1">
            <div className="flex justify-center items-center p-2 gap-2">
              {/* Інпут 1 */}
              <input
                className="w-16 h-8 px-1 py-1.5 bg-[#1b1817] rounded border border-[#554837] justify-center items-center gap-2.5 inline-flex text-center text-white text-xl font-black leading-tight pointer-events-none uppercase"
                value={part1}
                onChange={(e) => setPart1(e.target.value)}
                onPaste={handlePaste}
                readOnly
              />
              <span className="text-white">-</span>

              {/* Інпут 2 */}
              <input
                className="w-16 h-8 px-1 py-1.5 bg-[#1b1817] rounded border border-[#554837] justify-center items-center gap-2.5 inline-flex text-center text-white text-xl font-black leading-tight pointer-events-none uppercase"
                value={part2}
                onChange={(e) => setPart2(e.target.value)}
                onPaste={handlePaste}
                readOnly
              />

              <span className="text-white">-</span>

              {/* Інпут 3 */}
              <input
                className="w-16 h-8 px-1 py-1.5 bg-[#1b1817] rounded border border-[#554837] justify-center items-center gap-2.5 inline-flex text-center text-white text-xl font-black leading-tight pointer-events-none uppercase"
                value={part3}
                onChange={(e) => setPart3(e.target.value)}
                onPaste={handlePaste}
                readOnly
              />
            </div>

            <PopupButton type="blue" size={'small'} onClick={handleCopyClick} className="w-[34px] h-[34px]">
              <div className="-m-3 flex justify-center items-center h-[36px] w-[32px] p-1">
                {copied ? (
                  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm5.707 7.293l-6 6a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L9 11.586l5.293-5.293a1 1 0 111.414 1.414z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_5771_25439)">
                      <path
                        d="M24 9V27C24 27.2652 23.8946 27.5196 23.7071 27.7071C23.5196 27.8946 23.2652 28 23 28H5C4.73478 28 4.48043 27.8946 4.29289 27.7071C4.10536 27.5196 4 27.2652 4 27V9C4 8.73478 4.10536 8.48043 4.29289 8.29289C4.48043 8.10536 4.73478 8 5 8H23C23.2652 8 23.5196 8.10536 23.7071 8.29289C23.8946 8.48043 24 8.73478 24 9ZM27 4H9C8.73478 4 8.48043 4.10536 8.29289 4.29289C8.10536 4.48043 8 4.73478 8 5C8 5.26522 8.10536 5.51957 8.29289 5.70711C8.48043 5.89464 8.73478 6 9 6H26V23C26 23.2652 26.1054 23.5196 26.2929 23.7071C26.4804 23.8946 26.7348 24 27 24C27.2652 24 27.5196 23.8946 27.7071 23.7071C27.8946 23.5196 28 23.2652 28 23V5C28 4.73478 27.8946 4.48043 27.7071 4.29289C27.5196 4.10536 27.2652 4 27 4Z"
                        fill="#FFEFCB"
                      />
                      <path
                        d="M24.25 27V9C24.25 8.66848 24.1183 8.35054 23.8839 8.11612C23.6495 7.8817 23.3315 7.75 23 7.75H5C4.66848 7.75 4.35054 7.8817 4.11612 8.11612C3.8817 8.35054 3.75 8.66848 3.75 9V27C3.75 27.3315 3.8817 27.6495 4.11612 27.8839C4.35054 28.1183 4.66848 28.25 5 28.25H23C23.3315 28.25 23.6495 28.1183 23.8839 27.8839C24.1183 27.6495 24.25 27.3315 24.25 27ZM27 3.75H9C8.66848 3.75 8.35054 3.8817 8.11612 4.11612C7.8817 4.35054 7.75 4.66848 7.75 5C7.75 5.33152 7.8817 5.64946 8.11612 5.88388C8.35054 6.1183 8.66848 6.25 9 6.25H25.75V23C25.75 23.3315 25.8817 23.6495 26.1161 23.8839C26.3505 24.1183 26.6685 24.25 27 24.25C27.3315 24.25 27.6495 24.1183 27.8839 23.8839C28.1183 23.6495 28.25 23.3315 28.25 23V5C28.25 4.66848 28.1183 4.35054 27.8839 4.11612C27.6495 3.8817 27.3315 3.75 27 3.75Z"
                        stroke="#1D3874"
                        strokeWidth="0.5"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_5771_25439"
                        x="3"
                        y="2.8"
                        width="25.5"
                        height="25.7"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="-0.5" dy="-0.7" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.113725 0 0 0 0 0.219608 0 0 0 0 0.454902 0 0 0 1 0"
                        />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5771_25439" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5771_25439" result="shape" />
                      </filter>
                    </defs>
                  </svg>
                )}
              </div>
            </PopupButton>
          </div>

          <div className="flex flex-col gap-2 w-full">
            <div className="w-full">
              <div className="flex justify-between">
                <div className="text-white text-[14px] leading-none mb-2">{t('codePurchase.channelTitle')}</div>
                <div
                  className="text-[14px] leading-none mb-2"
                  style={{
                    color: title.length > 20 ? '#e44b39' : '#6F675C',
                  }}
                >
                  {title.length}/20
                </div>
              </div>
              <input
                type="text"
                placeholder={t('codePurchase.enterChannelTitle')}
                value={title}
                className="w-full px-4 py-1 bg-[#1b1817] rounded border border-[#554837] text-white text-[16px]
                  font-medium focus:outline-none focus:border-blue-500"
                onChange={(e) => setTitle(formatValue(e.target.value, 'title'))}
              />
            </div>
            <div className="w-full">
              <div className="text-white text-[14px] leading-none mb-2 mt-3">{t('codePurchase.channelLink')}</div>
              <input
                type="text"
                placeholder={t('codePurchase.enterChannelLink')}
                value={link}
                className="w-full px-4 py-1 bg-[#1b1817] rounded border border-[#554837] text-white text-[16px]
                  font-medium focus:outline-none focus:border-blue-500"
                onChange={(e) => handleSetLink(formatValue(e.target.value, 'url'))}
              />
            </div>
            {openUseCodeToPromotePopup ? (
              <>
                <div className="text-white text-[14px] leading-none mb-2 mt-3">{t('codePurchase.preview')}</div>
                <div className="w-full">
                  <div
                    className="w-full flex items-center bg-[#473D37] p-2 rounded-[2px] shadow-inner-sm-white
                    relative mb-1"
                  >
                    <div
                      className="flex justify-center items-center w-12 h-12 min-w-12 bg-[#242520] rounded-[3px]
                      border border-[#19191B] p-[2px] mr-2"
                    >
                      <img className="w-full h-full object-contain block rounded-sm" src={image} alt="kitsu" />
                    </div>
                    <div className="flex flex-col items-start gap-2" style={{ width: 'calc(100% - 116px)' }}>
                      <div
                        className="text-white text-[14px] font-black leading-normal text-stroke-small
                        overflow-hidden text-ellipsis w-full whitespace-nowrap"
                        style={{ opacity: title.length > 0 ? 1 : 0.5 }}
                      >
                        {title.length > 0 ? title : 'Example name'}
                      </div>
                      <div
                        className="text-[#4096ff] text-[14px] font-black leading-normal overflow-hidden
                        text-ellipsis w-full -mt-2 whitespace-nowrap"
                        style={{ opacity: link.length > 0 ? 1 : 0.5 }}
                      >
                        {link.length > 0 ? link : 'https://example.com'}
                      </div>
                    </div>
                    <div
                      className="absolute right-2 w-12 h-12 min-w-12 flex justify-center items-center border
                      border-white rounded-[3px] bg-gradient-to-bl from-[#33A8CC] to-[#0D81B6]"
                    >
                      <img src={require('../../../../../../../assets/images/ticket-icon.png')} className="w-[48px]" />
                      <div className="absolute text-stroke-small bottom-0.5 right-1 text-[12px] text-white leading-none">
                        {openUseCodeToPromotePopup.amount ? openUseCodeToPromotePopup.amount : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {!openUseCodeToPromotePopup?.isPromoting ? (
              <div className="text-center text-white flex justify-center text-[18px] font-black">
                <div className="w-6 h-6 mr-1">
                  <img className="w-full h-full" src={resourcesMap[Resources.promotions].image} alt="" />
                </div>
                <span className={userPromotions === 0 ? 'text-red-500' : 'text-[#3ADC96]'}>{userPromotions}</span>/
                <span>1</span>
              </div>
            ) : null}
            <PopupButton
              type={title.length === 0 || link.length === 0 || !validUrlOrChannelRegex.test(link) ? 'gray' : 'gold'}
              size="big"
              onClick={() => (openUseCodeToPromotePopup?.isPromoting ? handleUpdatePromote() : handlePromoteCode())}
              disabled={title.length === 0 || link.length === 0 || !validUrlOrChannelRegex.test(link)}
            >
              <div className="text-center text-white text-lg font-black leading-[18px]">
                {openUseCodeToPromotePopup?.isPromoting ? t('codePurchase.update') : t('codePurchase.startPromotion')}
              </div>
            </PopupButton>
            <div className="text-center text-white text-xs font-extrabold leading-3">
              {t('codePurchase.promotionInfo')}
            </div>
            <div className="text-center text-red-600 text-sm font-extrabold leading-3">
              {t('codePurchase.promotingOtherProject')}
            </div>
          </div>
        </div>
      </PopupWindow>
    </motion.div>
  );
};
