import { useEffect, useState } from 'react';
import { useTelegram } from '../../hooks/useTelegram';
import { getMessageToShare } from '../../endpoints/botEndpoints';

interface ShareMessageProps {
  onSucces: () => void; // Викликається у разі успішного поширення повідомлення
}

export const ShareMessage: React.FC<ShareMessageProps> = ({ onSucces }) => {
  const { tg, userId } = useTelegram();

  const handleShare = async () => {
    try {
      // 1. Отримуємо ID повідомлення (та іншу потрібну інфу)
      const result = await getMessageToShare({
        clientId: userId,
        options: { allowGroupChats: true },
      });

      if (!result?.id) {
        // Якщо бекенд не повернув коректний ID, виходимо
        return;
      }

      if (!tg) {
        return;
      }

      // 2. «Промісифікуємо» виклик shareMessage:
      const shareResult: boolean = await new Promise((resolve, reject) => {
        tg.shareMessage(result.id, (res: boolean) => {
          // res true - користувач погодився, false - скасовано
          if (res) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      });

      if (shareResult) {
        onSucces();
      }
    } catch (error) {
      console.warn('Message sharing canceled or failed', error);
    }
  };

  return (
    <div>
      <button onClick={handleShare} className={`px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600`}>
        Share Message
      </button>
    </div>
  );
};
