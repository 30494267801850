import { motion } from 'framer-motion';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { PopupButton } from '../../../../components/PopupButton';
import {
  EffectAction,
  GenericEffect,
  getRandomEvent,
  buyLifeWithShare,
  resetCurrentDungeon,
  getExecuteEvent,
} from '../../../../endpoints/dungeonEndpoints';
import { usePurchase } from '../../../../hooks/usePurchase';
import { useTelegramShareMessage } from '../../../../hooks/useTelegramShareMessage';
import { FooterSecretRoom } from '../../../../layout/components/FooterSecretRoom';
import { HeaderSecretRoom } from '../../../../layout/components/HeaderSecretRoom';
import { ConfigOffer, OfferType } from '../../../../mock/offers';
import { HeartStop } from '../../../Battle/HeartStop';
import { LoosePage } from '../../../Battle/LoosePage';
import { DeleteSecretCards } from '../DeleteSecretCards';
import { GetSecretCards } from '../GetSecretCards';
import { SecretPopUp } from '../SecretPopUp';
import { SelectSecretCards } from '../SelectSecretCard';
import { useSoundService } from '../../../../utils/soundService';
import { mapToFullArtefacts } from '../../../../utils/artefactsParser';
import { Artefacts } from '../../../../endpoints/mock';

export const SecretRoom: React.FC<{
  hpAmount: number;
  currentHpAmount: number;
  manaAmount: number;
  currentManaAmount: number;
  heroName?: string;
  heroLevel?: number | undefined;
  cardsAtDeck: any[];
  openDeck?: () => void;
  closeDeck: () => void;
  setIsSecretRoomOpen: (isOpen: boolean) => void;
  gold?: number;
  isDeckOpen: boolean;
  usedLives: number;
  randomEventId: number;
  userId: any;
  stageId: number;
  cancelCard: (stageid: number, currentIndex: number) => void;
  updateSaves: () => void;
  currentIndex: number;
  setIsScreenDarkened: (isOpen: boolean) => void;
  dungeonId: number;
  visibleItem: number;
  //@ts-ignore
  usingCard: ({ dungeonId, clientId, cardId, index }) => void;
  boughtLives: number;
}> = ({
  cancelCard,
  setIsSecretRoomOpen,
  gold,
  currentManaAmount,
  hpAmount,
  currentHpAmount,
  heroName,
  heroLevel,
  cardsAtDeck,
  openDeck,
  closeDeck,
  isDeckOpen,
  usedLives,
  randomEventId,
  userId,
  stageId,
  updateSaves,
  currentIndex,
  setIsScreenDarkened,
  usingCard,
  dungeonId,
  boughtLives,
}) => {
  const [stageIdForCards, setStageIdForCards] = useState<number | null>(null);
  const [selectedButtonId, setSelectedButtonId] = useState<number | null>(null);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const [cards, setCards] = useState<any[]>([]);
  const [actionType, setActionType] = useState<number[]>([]);
  const [actionValue, setActionValue] = useState<number[]>([]);
  const [buttonId, setButtonId] = useState<number>();
  const [optionIdModal, setOptionIdModal] = useState<number>();
  const [optionID, setOptionId] = useState();
  const [selectedCards, setSelectedCards] = useState<{ cardId: number; cardUid: number; cardLvl: number }[]>([]);
  const [rewards, setRewards] = useState<{ cardId: number; cardLvl: number; uid: string }[]>([]);
  const [cardToDelete, setCardToDelete] = useState<{ cardId: number; cardLvl: number; uid: string }[]>([]);
  const [getCardAction, setGetCardAction] = useState();
  const [execute, setExecute] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [rewardModal, setRewardModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectToDelete, setSelectToDelete] = useState(true);
  const [selectToUpgrade, setSelectToUpgrade] = useState(false);
  const [actionTypeIndex, setActionTypeIndex] = useState(0);
  const [getOneCard, setGetOneCard] = useState(false);
  const { playSound } = useSoundService();

  const { t } = useTranslation();

  const selectedRandomEvents = useMemo(() => {
    if (!appConfig || !appConfig.randomEvents) return [];
    const {
      randomEvents: { variables: randomEvents },
    } = appConfig;
    return randomEvents.find((event: any) => event.id.value === randomEventId);
  }, [appConfig]);

  const mappedArtefacts = useMemo(() => {
    if (!cardsAtDeck || !appConfig) return [];

    if (!appConfig.skills) return [];

    const configArtefacts = appConfig.skills.variables;

    return mapToFullArtefacts(cardsAtDeck as unknown as Artefacts[], configArtefacts);
  }, [cardsAtDeck, appConfig]);

  const fiteredCardsAtDeck = useMemo(() => {
    if (!mappedArtefacts) return cardsAtDeck;

    const filtered = cardsAtDeck.filter((card) => !mappedArtefacts.some((artefact) => artefact.id === card.id));
    return filtered;
  }, [mappedArtefacts]);

  useEffect(() => {
    if (selectedCards.length && buttonId) {
      handleCardRequest(buttonId);
      // updateSaves();
    }
  }, [selectedCards, buttonId]);

  useEffect(() => {
    if (execute && optionID) {
      handleButtonClick(optionID);
      // updateSaves();
    }
  }, [execute, rewards]);

  const getSelectedCard = ({
    cardId,
    cardUid,
    drawCards,
  }: {
    cardId: number;
    cardUid: number;
    drawCards: {
      drawCardId: number;
      drawCardUid: number;
      drawCardLvl: number;
    }[];
  }) => {
    setSelectedCards(
      drawCards.map((card) => ({
        cardId,
        cardUid: card.drawCardUid,
        cardLvl: card.drawCardLvl,
      }))
    );
  };

  const handleCloseRoom = () => {


    if (
      actionType.some((type) =>
        [EffectAction.deleteCards, EffectAction.upgradeCards, EffectAction.getCard].includes(type)
      )
    ) {


      setIsSecretRoomOpen(true);
    } else {

      setIsScreenDarkened(false);
      // usingCard({
      //   dungeonId,
      //   cardId: stageId,
      //   clientId: userId,
      //   index: currentIndex,
      // });
      setIsSecretRoomOpen(false);
    }
  };

  const [avalaible, setAvalaible] = useState(false);
  useEffect(() => {

  }, [avalaible]);

  const [price, setPrice] = useState([]);

  const handleButtonClick = async (optionId: number) => {

    const selectedOption = selectedRandomEvents.options.value.find((option: any) => option.id.value === optionId);


    if (selectedOption) {
      const result = await randomEvent(optionId);
      if (!result) {
        return;
      }

      const groupId = result.orGroupId;

      setOptionIdModal(groupId);

      const selectedOptionGroupId = selectedOption.orEffects.value.find((group: any) => group.groupId.value === groupId)
        .effects.value;

      // const priceInHandle = selectedOptionGroupId
      //   .filter((item: any) => item.action.value === 0 && item.value.value < 0)
      //   .map((item: any) => item.value.value);

      // console.log(priceInHandle, 'PRICE IN HANDLE');

      // if (priceInHandle.length === 0 || gold + priceInHandle[0] >= 0) {
      //   setAvalaible(true);
      //   console.log(avalaible, 'AVALAIBLE');
      // }

      //для 4 румы
      // const newActionType = selectedOptionGroupId[0].action.value;
      // setActionType(newActionType);
      // console.log(newActionType, "IM NEW ACTION TYPE");

      const newActionType = selectedOptionGroupId.map((option: any) => option.action.value);
      const newActionValue = selectedOptionGroupId
        .map((option: any) => {
          if (option.value && typeof option.value.value === 'number') {
            return option.value.value;
          }

          return undefined;
        })
        .filter((value: any) => value !== undefined);


      if (newActionValue.length > 0) {
        setActionValue(newActionValue);
      }

      // if (currentHpAmount + newActionValue[0] <= currentHpAmount) {
      //   setIsDead(true);
      // }

      const dangerousActions = [EffectAction.modifyHpInRange, EffectAction.modifyHp, EffectAction.modifyMaxHp];

      if (newActionValue.length > 0) {
        const isDead = selectedOptionGroupId.some((item: any) => {
          return (
            item.action?.value !== undefined &&
            dangerousActions.includes(item.action.value) &&
            item.value?.value !== undefined &&
            currentHpAmount + item.value.value <= 0
          );
        });

        setIsDead(isDead);
      } else {
        setIsDead(false);
      }

      if (usedLives < 1) {
        setIsAnimationLooseStart(true);

      } else {
        setShowDefeatScreen(true);
      }


      setActionType(newActionType);
      const hasInvalidAction = newActionType.some(
        (actionType: any) => actionType !== EffectAction.upgradeCards && actionType !== EffectAction.deleteCards
      );

      const actionGetCard = selectedOptionGroupId.find((item: any) => item.action.value === 4);
      if (actionGetCard) {


        setGetCardAction(actionGetCard.action.value);
      }
      setOptionId(selectedOptionGroupId);

      if (hasInvalidAction) {
        const response = await executeEvent(selectedOptionGroupId);

        if (response && Array.isArray(response)) {
          const findObj = response.find((item: any) => item.cardId);

          const cardToGet = response.find((item) => item.action === 4);


          const amountToRecieve = response.find((item: any) => item.action === 4)?.amount;

          const cardsToRemove = response.filter((item: any) => item.action === 8);


          if (cardsToRemove.length) {
            const newCards = cardsToRemove.map((card: any) => ({
              cardId: Number(card.cardId),
              cardLvl: Number(card.cardStars),
              uid: String(card.cardId),
            }));

            setCardToDelete((prevCards) => [...prevCards, ...newCards]);
          }

          if (findObj && amountToRecieve) {
            const newRewards = new Array(amountToRecieve).fill(null).map(() => ({
              cardId: Number(cardToGet.cardId),
              cardLvl: Number(cardToGet.cardStars),
              uid: String(cardToGet.cardId),
            }));

            setRewards((prevCards) => [...prevCards, ...newRewards]);
          }
        }
      }

      let types = [];

      for (const ef of selectedOptionGroupId) {
        const type = ef.action.value;
        //@ts-ignore
        if (stategyEffect[type]) {
          await new Promise<void>((resolve) => {
            //@ts-ignore
            stategyEffect[type]({ ef });
            resolve();
          });

          types.push(type);
        }
      }


      if (types.includes(EffectAction.deleteCards) || types.includes(EffectAction.upgradeCards)) {
        setExecute(false);
      } else {
        setExecute(true);
      }
      setStageIdForCards(stageId);
      cancelCard(stageId, currentIndex);
    }
  };

  const handleCardRequest = async (optionId: number) => {
    const selectedOption = selectedRandomEvents.options.value.find((option: any) => option.id.value === optionId);


    //Для апгрейда + удаления
    let selectedActionType = actionType[actionTypeIndex];
    if (optionIdModal === 3 && actionType.length === 2) {
      setActionTypeIndex((prevIndex) => (prevIndex + 1) % actionType.length);
    }

    if (selectedCards.length) {
      const formattedEffects = selectedCards.map((uuid) => ({
        value: Number(uuid.cardUid),
        action: selectedActionType,
      }));


      //@ts-ignore
      const result = await executeEventForCards(formattedEffects);

      if (result) {
        // cancelCard(stageId, currentIndex);
      }
      // updateSaves();
      // usingCard({
      //   dungeonId,
      //   cardId: stageId,
      //   clientId: userId,
      //   index: currentIndex,
      // });
    }
  };

  const getShuffledCards = () => {
    const allClientCards = fiteredCardsAtDeck;
    const shuffleArray = (array: any) => array.sort(() => Math.random() - 0.5);
    const shuffledCards = shuffleArray(allClientCards);
    return shuffledCards.slice(0, 3);
  };

  const shufledCardsForChoise = getShuffledCards();

  const getShuffledCardsForUpgrade = () => {
    const allClientCards = fiteredCardsAtDeck;
    // const shuffleArray = (array: any) => array.sort(() => Math.random() - 0.5);
    // const shuffledCards = shuffleArray(allClientCards);
    const filteredCards = allClientCards.filter((card: any) => card.lvl < 3);
    return filteredCards.slice(0, 3);
  };

  const shufledCardsForUpgrade = getShuffledCardsForUpgrade();

  // для 4 румы
  const stategyEffect: {
    [k in EffectAction]?: ({ effects }: { effects: any }) => void;
  } = {
    [EffectAction.upgradeCards]: ({ effects }) => {
      const allClientCards = fiteredCardsAtDeck;
      const shuffleArray = (array: any[]) => array.sort(() => Math.random() - 0.5);
      const shuffledCards = shuffleArray(allClientCards);
      const filteredCards = shuffledCards.filter((card) => card.lvl < 3);
      const shufledCardsForChoise = filteredCards.slice(0, 3);

      setCards(shufledCardsForChoise);

    },

    [EffectAction.deleteCards]: ({ effects }) => {
      const allClientCards = fiteredCardsAtDeck;
      const shuffleArray = (array: any[]) => array.sort(() => Math.random() - 0.5);
      const shuffledCards = shuffleArray(allClientCards);
      const filteredCards = shuffledCards.filter((card) => card.lvl <= 3);
      const shufledCardsForChoise = filteredCards.slice(0, 3);

      setCards(shufledCardsForChoise);

    },
  };

  const executeEventForCards = async (effect: GenericEffect[]) => {
    // const parsed = effect.map((item) => ({
    //   action: item.action.value,
    //   value: item.value.value,
    // }));

    const params = {
      clientId: userId,
      stageId: stageIdForCards,
      chooses: effect,
    };
    try {
      //@ts-ignore
      const result = await getExecuteEvent(params);

      setStageIdForCards(null);
      return result;
    } catch (err) {
      console.log(err);
    }
  };

  const executeEvent = async (effect: GenericEffect[]) => {
    const params = {
      clientId: userId,
      stageId: stageId,
      chooses: [],
    };

    try {
      const result = await getExecuteEvent(params);
      return result;
    } catch (err) {
      console.log(err);
    }
  };

  const randomEvent = async (optionId: number) => {
    const params = {
      clientId: userId,
      stageId: stageId,
      optionId: optionId, // айди кнопки которую нажмут
    };

    try {
      const result = await getRandomEvent(params);
      return result;
    } catch (err) {
      console.log(err);
    }
  };
  const maincard = { cardId: 1, cardUid: '1' };

  const [isDead, setIsDead] = useState(false);

  const [isAnimationLooseStart, setIsAnimationLooseStart] = useState(false);
  const [showDefeatScreen, setShowDefeatScreen] = useState(false);
  const { isSuccessPurchase, confirmPurchase } = usePurchase();
  const tutorialSave = useSelector((state: RootState) => state.tutorialSave.tutorialSave.save);
  const {
    offers: { variables: allOffers },
  } = appConfig;
  const heartOffers: ConfigOffer = allOffers.find(
    (offer: ConfigOffer) => offer.offerType.value === OfferType.buyHeartByStarsOffer
  );
  const { handleShare } = useTelegramShareMessage(async () => {
    hadnleBuyLifeWithShare();
  });

  const hadnleBuyLifeWithShare = async () => {
    const result = await buyLifeWithShare({ clientId: userId });
    if (result) {
      setShowDefeatScreen(false);
    }
  };

  const resetDungeon = async () => {
    await resetCurrentDungeon({ clientId: userId, heroId: 1 });
  };


  useEffect(() => {
    // Перевіряємо, чи всі умови виконані
    if (isAnimationLooseStart && isDead && !selectedButtonId) {
      playSound('battleEnd');
    }
  }, [isAnimationLooseStart, isDead, selectedButtonId, playSound]);
  
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
        ease: 'easeOut',
      }}
      className=" w-full h-full top-0 left-0 fixed z-[99] bg-cover bg-no-repeat "
      style={{
        backgroundImage: `url(${require(`../../../../assets/images/secretRoom/secretroom-${randomEventId}.png`)})`,
      }}
    >
      <div className="absolute top-0 left-0 w-full h-[35%] bg-gradient-to-b from-black/90 to-transparent"></div>
      <div className="absolute bottom-[60px] left-0 w-full h-[45%] bg-gradient-to-t from-black/90 to-transparent"></div>

      <HeaderSecretRoom
        setIsSecretRoomOpen={setIsSecretRoomOpen}
        gold={gold}
        setIsScreenDarkened={setIsScreenDarkened}
      />

      <div className="flex w-[270px] absolute top-[100px] left-1/2 transform -translate-x-1/2 z-[auto]">
        <p
          className="w-full text-regular text-white break-words text-center text-lg"
          style={{
            lineHeight: '1.2',
            filter:
              'drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)',
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(`rooms.${randomEventId}.description`),
            }}
          />
        </p>
      </div>

      <div className="flex flex-col gap-[15px] absolute bottom-[125px] left-1/2 transform -translate-x-1/2">
        {selectedRandomEvents.options.value.map((o: any) => {
          // Знаходимо групу ефектів, яка відповідає потрібному groupId
          const data = o.orEffects.value;

          const isAvailable = data.every((dataEffect: any) => {
            return dataEffect.effects.value.every((effectData: any) => {
              if (effectData.action.value === EffectAction.modifyGold && effectData.value.value < 0) {
                return gold + effectData.value.value >= 0;
              }
              return true;
            });
          });

          return (
            <button
              key={o.id.value}
              className={`w-[213px] h-[34px] z-10 ${!isAvailable ? 'text-[#00000077] opacity-70 cursor-not-allowed' : ''} ${
                selectedButtonId === o.id.value ? '[filter:drop-shadow(0px_0px_10px_#C6D9FF)]' : ''
              }`}
              style={{
                backgroundImage: `url(${require('../../../../assets/images/secretRoom/secret-button-choise.png')})`,
              }}
              onClick={() => {
                setSelectedButtonId(o.id.value);
                setButtonId(o.id.value); // якщо щось не працює – дивись сюди
                handleButtonClick(o.id.value);
                setIsOpenModal(true); // і сюди теж
              }}
              disabled={!isAvailable} // якщо хоча б один ефект не проходить перевірку – кнопка недоступна
            >
              <p className="text-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(`rooms.${randomEventId}.options.buttons.${o.id.value}.title.0`),
                  }}
                />
              </p>
            </button>
          );
        })}
      </div>

      {/* СДЕЛАЛ ФИКС ЛОГИКИ ЗДЕСЬ, ЕСЛИ СЛОМАЕТЬСЯ ОТКАТИТЬ ТУТ  */}
      {selectedButtonId &&
        !isDead &&
        (optionIdModal === 3 ||
          !actionType.some((type) => [EffectAction.deleteCards, EffectAction.upgradeCards].includes(type))) && (
          <motion.div className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center">
            <motion.div
              className="flex justify-center items-center fixed inset-0"
              initial={{
                opacity: 0,
                transform: 'translateY(100%)',
              }}
              animate={{
                opacity: 1,
                transform: 'translateY(0%)',
              }}
              transition={{
                duration: 0.6,
              }}
            >
              <SecretPopUp onClickClose={() => {}}>
                <div className="flex flex-col items-center pt-[32px] pb-[16px] px-[32px] gap-[16px] z-[200] relative">
                  <p className="text-white text-base font-light text-center" style={{ lineHeight: '1.2' }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(
                          `rooms.${randomEventId}.options.buttons.${selectedButtonId}.optionId.${optionIdModal}.modal`
                        ),
                      }}
                    />
                  </p>
                  <PopupButton
                    className="w-[150px]"
                    type="green"
                    onClick={() => {
                      setButtonId(selectedButtonId);
                      setSelectedButtonId(null);
                      setDeleteModal(true);
                      // setRewardModal(true);
                      setIsOpenModal(true);
                      setGetOneCard(true);
                      setTimeout(() => {
                        handleCloseRoom();
                        // updateSaves();
                      }, 100);
                    }}
                  >
                    Confirm
                  </PopupButton>
                </div>
              </SecretPopUp>
            </motion.div>
          </motion.div>
        )}

      {/* MODAL WHEN DEAD */}
      {selectedButtonId && isDead && !showDefeatScreen && (
        <motion.div className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center">
          <motion.div
            className="flex justify-center items-center fixed inset-0"
            initial={{
              opacity: 0,
              transform: 'translateY(100%)',
            }}
            animate={{
              opacity: 1,
              transform: 'translateY(0%)',
            }}
            transition={{
              duration: 0.6,
            }}
          >
            <SecretPopUp onClickClose={() => {}}>
              <div className="flex flex-col items-center pt-[32px] pb-[16px] px-[32px] gap-[16px] z-[200] relative">
                <p className="text-white text-base font-light text-center" style={{ lineHeight: '1.2' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(
                        `rooms.${randomEventId}.options.buttons.${selectedButtonId}.optionId.${optionIdModal}.modal`
                      ),
                    }}
                  />
                </p>
                <PopupButton
                  className="w-[150px]"
                  type="green"
                  onClick={() => {
                    setSelectedButtonId(null);
                    setTimeout(() => {
                      handleCloseRoom();
                      // updateSaves();
                    }, 2000);
                  }}
                >
                  Confirm
                </PopupButton>
              </div>
            </SecretPopUp>
          </motion.div>
        </motion.div>
      )}

      {showDefeatScreen && isDead && (
        <>
          <div className="absolute w-full h-full bg-black opacity-80 z-10"></div>
          <div className="relative z-[99]">
            <LoosePage 
              key={'loosePage'}
              boughtLives={boughtLives}
              currentHp={currentHpAmount}
              isDead={isDead}
              handleResetDungeon={resetDungeon}
              enemyId={3}
              offerCost={heartOffers.stars.value}
              handleShare={handleShare}
              handleBuy={() => confirmPurchase({ offerId: heartOffers.offerId.value })}
            />
          </div>
        </>
      )}

      {/* FOR LOSE HEART */}
      {isAnimationLooseStart && isDead && !selectedButtonId && (
        <HeartStop key={'heartStop'} usedLives={usedLives} handleLoose={() => {}} />
      )}

      {/* FOR 3 ROOM */}
      {getOneCard && !selectedButtonId && getCardAction === EffectAction.getCard && randomEventId === 3 && (
        <motion.div className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center">
          <motion.div
            className="flex justify-center items-center fixed inset-0"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.6,
            }}
          >
            <GetSecretCards
              buttonId={buttonId!}
              optionIdModal={optionIdModal!}
              randomEventId={randomEventId}
              cards={rewards}
              onClose={() => {
                setTimeout(() => {

                  setIsScreenDarkened(false);
                  setIsSecretRoomOpen(false);
                }, 100);
              }}
            />
          </motion.div>
        </motion.div>
      )}

      {/* FOR 6 ROOM */}
      {!selectedButtonId && getCardAction === EffectAction.getCard && rewardModal && (
        <motion.div className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center">
          <motion.div
            className="flex justify-center items-center fixed inset-0"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.6,
            }}
          >
            <GetSecretCards
              buttonId={buttonId!}
              optionIdModal={optionIdModal!}
              randomEventId={randomEventId}
              cards={rewards}
              onClose={() => {
                setTimeout(() => {

                  setIsScreenDarkened(false);
                  setIsSecretRoomOpen(false);
                }, 100);
              }}
            />
          </motion.div>
        </motion.div>
      )}

      {/* FOR 6 ROOM */}
      {!selectedButtonId &&
        deleteModal &&
        optionIdModal !== 3 &&
        actionType.some((type) => [EffectAction.deleteCards, EffectAction.deleteRandomAtkCards].includes(type)) && (
          <motion.div className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center">
            <motion.div
              className="flex justify-center items-center fixed inset-0"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.6,
              }}
            >
              <DeleteSecretCards
                cards={cardToDelete}
                onClose={() => {
                  setRewardModal(true);
                  setDeleteModal(false);
                }}
              />
            </motion.div>
          </motion.div>
        )}

      {/* FOR 4 ROOM */}
      {isOpenModal &&
        actionType.some((type) => [EffectAction.deleteCards, EffectAction.upgradeCards].includes(type)) &&
        optionIdModal !== 3 && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center ">
            <motion.div
              className="fixed inset-0 flex justify-center items-center"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.6,
              }}
            >
              <SelectSecretCards
                actionType={actionType}
                count={2}
                cards={cards}
                mainCard={maincard}
                //@ts-ignore
                setSelectedCardHandler={getSelectedCard}
                //@ts-ignore
                buttonId={buttonId}
                handleCardRequest={handleCardRequest}
                onClose={() => {
                  setTimeout(() => {

                    setIsScreenDarkened(false);
                    setIsSecretRoomOpen(false);
                  }, 100);
                }}
              />
            </motion.div>
          </div>
        )}

      {/* FOR 4 ROOM - 3 BUTTON */}
      {isOpenModal &&
        !selectedButtonId &&
        actionType.includes(EffectAction.deleteCards) &&
        optionIdModal === 3 &&
        selectToDelete && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center ">
            <motion.div
              className="fixed inset-0 flex justify-center items-center"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.6,
              }}
            >
              <SelectSecretCards
                actionType={[EffectAction.deleteCards]}
                count={1}
                cards={shufledCardsForChoise}
                mainCard={maincard}
                //@ts-ignore
                setSelectedCardHandler={getSelectedCard}
                onClose={() => {
                  setSelectToDelete(false);
                  setSelectToUpgrade(true);
                }}
              />
            </motion.div>
          </div>
        )}

      {selectToUpgrade && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.6,
          }}
          className="fixed inset-0 bg-black bg-opacity-50 z-[2] flex justify-center items-center "
        >
          <SelectSecretCards
            actionType={[EffectAction.upgradeCards]}
            count={1}
            cards={shufledCardsForUpgrade}
            mainCard={maincard}
            //@ts-ignore
            setSelectedCardHandler={getSelectedCard}
            onClose={() => {
              // setTimeout(() => {

              // }, 100);

              setIsScreenDarkened(false);
              setIsSecretRoomOpen(false);
            }}
          />
        </motion.div>
      )}

      <FooterSecretRoom
        heroName={heroName || 'Hero'}
        heroLvl={heroLevel ?? 1}
        currentManaAmount={currentManaAmount}
        currentHpAmount={currentHpAmount}
        hpAmount={hpAmount}
        openDeck={openDeck}
        closeDeck={closeDeck}
        cardsAtDeck={fiteredCardsAtDeck}
        isDeckOpen={isDeckOpen}
        usedLives={usedLives}
      />
    </motion.div>
  );
};
