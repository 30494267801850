import { Card } from '../../../../components/Card';
import { PopupButton } from '../../../../components/PopupButton';
import { useTranslation } from 'react-i18next';

export const GetSecretCards = ({
  cards,
  onClose,
  randomEventId,
  buttonId,
  optionIdModal,
}: {
  cards: { cardId: number; cardLvl: number; uid: string }[];
  onClose: () => void;
  randomEventId?: number;
  buttonId?: number;
  optionIdModal?: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className="absolute bg-[black]/[0.7] w-full h-full z-[100] left-0 top-0 flex items-center justify-center">
      <div className="relative">
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto"
        ></div>
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-gray-400/[0.2] to-traansparent mx-auto
          text-[#FFEFCB] text-center text-[14px] p-2"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(`rooms.${randomEventId}.options.buttons.${buttonId}.optionId.${optionIdModal}.nextModal`),
            }}
          />
        </div>
        <div
          className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto mb-[30px]"
        ></div>
        <div className="flex justify-center overflow-x-auto py-4">
          <div className={`flex space-x-4 ${cards.length <= 3 ? 'justify-center' : 'justify-start'}`}>
            {cards.length > 0 &&
              cards.map((card, index) => (
                <div
                  key={index}
                  className={`relative flex-shrink-0 ${cards.length > 3 ? 'w-[calc(32%-1rem)]' : 'w-[calc(33%-1rem)]'}`}
                >
                  <Card id={card.cardId} lvl={card.cardLvl ?? 1} uid={card.uid} />
                </div>
              ))}
          </div>
        </div>
        <div className="flex justify-center">
          <PopupButton type={'blue'} size="medium" className="mt-[30px] mx-auto" disabled={false} onClick={onClose}>
            <div className="px-6">{t('roomsModal.6.title')}</div>
          </PopupButton>
        </div>
      </div>
    </div>
  );
};
