import { useTranslation } from 'react-i18next';

const BattleCardTooltip = ({ cardId, type }: { cardId: number; type: 'battle' | 'bag' | 'shop' }) => {
  const { t } = useTranslation();
  const tooltipContent = t(`cardsEffectsTooltip.${cardId}`, { returnObjects: true });
  const fontSize = type === 'battle' ? '10px' : type === 'shop' ? '12px' : '14px';

  return (
    <div className="absolute bottom-full  flex flex-col gap-1 z-10">
      {Array.isArray(tooltipContent)
        ? tooltipContent.map((text, index) => (
            <div className="relative h-full w-full" key={index}>
              <div className="top-0 absolute bg-black opacity-80 rounded-[8px] w-full h-full p-2"></div>
              <div
                className={`relative z-2 border-[0.5px] border-white rounded-[6px] text-[${fontSize}] leading-[${fontSize}] text-white m-[2px] px-2 py-1 text-center font-light`}
                key={index}
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            </div>
          ))
        : null}
    </div>
  );
};

export default BattleCardTooltip;
