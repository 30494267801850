import { ItemType } from '../interfaces/lootBotx';
import { RequestWebsocket } from '../interfaces/wsInterfaces';
import { generateRequestId } from '../utils/generateRequestId';
import { sendRequestAndGetResponse } from '../Websocket/websocketInit';

export interface ITasks {
  id: number;
  rewards: ITaskRewards[];
  status: number;
}
export interface ITaskRewards {
  rewardId: number;
  rewardType: ItemType;
  amount: number;
}

export const getMyTasks = async (params: { clientId: string }): Promise<ITasks[]> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'sbt_getMyTasks',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('getMyTasks ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as ITasks[];
  }
};

export const claimTaskReward = async (params: { clientId: string; taskId: number }): Promise<boolean> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests', //TODO: отримати значення від бекенду
    lang: 'en',
    method: 'sbt_claimTaskReward',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };

  try {
    const result = await sendRequestAndGetResponse(request);
    if (!result?.value) {
      throw new Error('claimTaskReward ERROR');
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as boolean;
  }
};
