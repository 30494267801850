import { useSelector, useDispatch } from 'react-redux';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useEffect } from 'react';
import { RootState } from '../../../app/store';
import { clearRipple } from '../../../app/features/rippleSlice';
// import { useLocation } from 'react-router-dom';

const AnimationClick = () => {
  const ripple = useSelector((state: RootState) => state.ripple);
  const dispatch = useDispatch();
  // const location = useLocation();
  useEffect(() => {
    if (ripple.x !== null && ripple.y !== null) {
      const timeout = setTimeout(() => {
        dispatch(clearRipple());
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [ripple, dispatch]);

  if (ripple.x === null || ripple.y === null) return null;

  // Определяем, какую анимацию показывать
  // const animationSrc = location.pathname.includes('/battle')
  //   ? 'https://lottie.host/3154d5a1-b31b-41c3-9669-35dd4259d53e/QQfJcTvWoM.lottie'
  //   : 'https://lottie.host/90601b83-f462-44d2-b878-f85c031cd3e2/RvgoozIaof.lottie';

  return (
    <div className="fixed top-0 left-0 w-full h-full pointer-events-none z-[999999]">
      <div
        key={ripple.key}
        className="absolute"
        style={{
          left: ripple.x - 46,
          top: ripple.y - 46,
          width: 90,
          height: 90,
        }}
        color="#ffd332"
      >
        <DotLottieReact data={require('../../../assets/animations/AnimClick.json')} loop={false} autoplay speed={3} />
      </div>
    </div>
  );
};

export default AnimationClick;
