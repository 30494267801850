import { useCallback, useEffect, useMemo, useState } from 'react';
import { Claim } from './interface';
import { RoomStatus } from '../../../enums/buildingStatus';
import { claimResourse } from '../../../endpoints/farmMock';
import { Resources } from '../../../enums/resources';
import { setSelectedRoom } from '../../../app/features/selectedRoom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { FarmRoom, IslandSchemeI } from '../../../interfaces/farm';
import { useSoundService } from '../../../utils/soundService';
import { useTelegram } from '../../../hooks/useTelegram';
import { useSessionStorage } from '@uidotdev/usehooks';

interface useTimerActionProps {
  room: FarmRoom | undefined;
  scheme: IslandSchemeI;
}

export const useRoomTick = (props: useTimerActionProps) => {
  const { scheme, room } = props;
  const { buildingConfigs } = useSelector((state: RootState) => state.config);
  const resources = useSelector((state: RootState) => state.resources.resources);
  const selectedIsland = useSelector((state: RootState) => state.selectedIsland.selectedIsland);
  const selectedRoom = useSelector((state: RootState) => state.selectedRoom);
  const gTick = useSelector((state: RootState) => state.globalTick);

  const [Claim, setClaim] = useState<Claim>({
    isClaim: false,
    isLimit: false,
    value: 0,
    percentage: 0,
    perTick: 0,
  });
  const [claimTooQuick, setClaimTooQuick] = useState(false);

  const [isRefCodeAccess, setIsRefCodeAccess] = useSessionStorage('isRefCodeAccess', true);
  const [shouldHideElement, setShouldHideElement] = useSessionStorage<boolean>('shouldHideElementKey', true);

  const { playSound } = useSoundService();
  const { userId } = useTelegram();
  const dispatch = useDispatch<AppDispatch>();

  const building = useMemo(() => {
    if (!buildingConfigs) return undefined; // Return undefined if buildingConfigs is null

    return scheme.buildingId
      ? buildingConfigs.find((v) => v.id === scheme.buildingId)
      : buildingConfigs.find((v) => v.id === room?.buildingId);
  }, [scheme.buildingId, room?.buildingId, buildingConfigs]);

  const buildingLvlConfig = useMemo(
    () => building?.lvlInfo.find((lvl) => lvl.lvl === room?.lvl),
    [building, room?.lvl]
  );

  const isClaimAvailable = useCallback(() => {
    if (!buildingLvlConfig || !room) return;

    if (room.status === RoomStatus.building) {
      setClaim({
        isClaim: false,
        isLimit: false,
        value: 0,
        percentage: 0,
        perTick: 0,
      });
      return;
    }

    console.log('timer action before if', room, buildingLvlConfig);

    if (
      room &&
      buildingLvlConfig &&
      buildingLvlConfig.gatherDelayMs &&
      buildingLvlConfig.resourceAmount &&
      buildingLvlConfig.minTimeMs !== undefined &&
      buildingLvlConfig.maxTimeMs !== undefined
    ) {
      const now = Date.now();
      const statusUpdateTime = new Date(room.statusUpdateDate).getTime();
      const timeDifference = now - statusUpdateTime;

      // Перевірка мінімального часу для клейму
      if (timeDifference < buildingLvlConfig.minTimeMs) {
        setClaim({
          isClaim: false,
          isLimit: false,
          value: 0,
          percentage: 0,
          perTick: 0,
        });
        return;
      }

      // Перевірка умови "kлейму занадто швидкий"
      if (claimTooQuick) {
        setClaim({
          isClaim: false,
          isLimit: false,
          value: 0,
          percentage: 0,
          perTick: 0,
        });
        return;
      }

      const gatherDelayMs = buildingLvlConfig.gatherDelayMs;
      const resourceAmount = buildingLvlConfig.resourceAmount;
      const maxTimeMs = buildingLvlConfig.maxTimeMs;
      const maxGatheredValue = Math.floor(maxTimeMs / gatherDelayMs) * resourceAmount;

      const ticks = Math.floor(timeDifference / gatherDelayMs);
      const gatheredValue = Math.min(ticks * resourceAmount, maxGatheredValue);

      const percentage = Math.min(((timeDifference % gatherDelayMs) / gatherDelayMs) * 100, 100);

      setClaim((prevClaim) => {
        // Оновлюємо тільки якщо значення змінилося
        if (gatheredValue !== prevClaim.value || percentage !== prevClaim.percentage) {
          return {
            isClaim: gatheredValue > 0,
            isLimit: gatheredValue >= maxGatheredValue,
            value: gatheredValue,
            percentage,
            perTick: gatherDelayMs,
          };
        }
        return prevClaim;
      });
    } else {
      setClaim({
        isClaim: false,
        isLimit: false,
        value: 0,
        percentage: 0,
        perTick: 0,
      });
    }
  }, [room, buildingLvlConfig]);

  const onClickClaim = useCallback(async () => {
    if (room?.status === RoomStatus.building) {
      return;
    }

    if (building && building.resourceType != null) {
      const resource = resources.find((v) => v.resourceType === building.resourceType);

      if (resource) {
        if (resource.resourceType === Resources.experience) {
          playSound('takeExperience');
        }

        if (resource.resourceType === Resources.stone) {
          playSound('takeStone');
        }

        if (resource.resourceType === Resources.kitsu) {
          if (!isRefCodeAccess) {
            playSound('click');
            setShouldHideElement(false);
            return false;
          } else {
            playSound('takeCoin');
          }
        }

        const data = await claimResourse({
          clientId: userId,
          buildingId: room!.id,
          islandId: selectedIsland!.id,
          resource: building.resourceType,
        });

        if (!data) {
          setClaimTooQuick(true);
          setClaim({
            isClaim: false,
            isLimit: false,
            value: 0,
            percentage: 0,
            perTick: 0,
          });
          setTimeout(() => {
            setClaimTooQuick(false);
          }, 25 * 1000);
        }

        setClaim({
          isClaim: false,
          isLimit: false,
          value: 0,
          percentage: 0,
          perTick: 0,
        });
      }
    }
  }, [dispatch, building, resources, Claim.value, selectedIsland, room]);

  const onClickZoom = useCallback(() => {
    console.log('calim 2', scheme);

    dispatch(
      setSelectedRoom({
        id: scheme.id,
        buildingId: room?.buildingId,
        lvl: room?.lvl,
        type: 1,
      })
    );
  }, [dispatch, scheme.id, room?.buildingId, room?.lvl]);



  const onClickBuild = useCallback(() => {
    console.log('calim 1', room?.status);

    switch (room?.status) {
      case RoomStatus.farming: {
        return Claim.isClaim ? onClickClaim() : onClickZoom();
      }
      case RoomStatus.builded:
        return onClickZoom();
    }
  }, [onClickClaim, onClickZoom, Claim.isClaim]);

  useEffect(() => {
    if (room && room.status === RoomStatus.farming) {
      isClaimAvailable();
    }
  }, [room, gTick]);

  return { isClaimAvailable, Claim, onClickBuild };
};
