import { Artefacts, CardType, ConfigArtefacts, FullArtefacts, ISkill } from '../endpoints/mock';

export function mapToFullArtefacts(backendArtefacts: Artefacts[], configArtefacts: ConfigArtefacts[]): FullArtefacts[] {
  try {
    // console.log('configArtefacts', configArtefacts)
    return backendArtefacts
      .map((artFromBackend) => {
        // Знаходимо в конфігурації той, який має такий же ID
        const config = configArtefacts.find(
          (c) => c.id.value === artFromBackend.id && c.cardType.value === CardType.equip
        );

        if (!config) {
          console.log(`No artefact id = ${artFromBackend.id}`);
          return undefined as unknown as FullArtefacts;
        }

        // Припустимо, що нам потрібна лише перша "зірка" (stars[0]).
        // Якщо вам треба інша логіка – змініть нижче під свої потреби.
        const firstStar = config.stars.value[0];
        if (!firstStar) {
          throw new Error(`id = ${artFromBackend.id} no stars or empty`);
        }

        // Перетворюємо artefactOptions, якщо він є
        let artefactOptions;
        if (config.artefactOptions) {
          const opt = config.artefactOptions.value[0];

          artefactOptions = {
            artefactActionType: opt.artefactActionType.value,
            triggerType: opt.triggerType ? opt.triggerType.value : undefined,
            triggerValue: opt.triggerValue ? opt.triggerValue.value : undefined,
            triggerCondition: opt.triggerCondition?.value,
            cardType: opt.cardType ? opt.cardType.value : undefined,
          };
        }

        const stars = config.stars.value.map((star) => {
          return {
            skills: star.skills.value.map(
              (effect): ISkill => ({
                value: effect.value.value,
                target: effect.target.value,
                effectType: effect.effectType.value,
                animationCount: effect.animationCount.value,
                duration: effect.duration.value,
                cost: 0,
              })
            ),
            cost: star.cost.value,
          };
        });

        // Формуємо об'єкт типу FullArtefacts
        const fullArtefact: FullArtefacts = {
          rarity: config.rarity.value,
          uid: artFromBackend.uid,
          id: config.id.value,
          energyType: config.energyType.value,
          cardType: config.cardType.value,
          cost: artFromBackend.dungeonCost ? artFromBackend.dungeonCost : config.cost.value,
          stars: stars[0],
          name: config.name.value,
          artefactOptions,
        };

        return fullArtefact;
      })
      .filter(Boolean);
  } catch (error) {
    console.log('Error', error);
    return [];
  }
}
