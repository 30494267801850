import { createSlice } from '@reduxjs/toolkit';

const globalTickSlice = createSlice({
  name: 'globalTick',
  initialState: {
    currentTime: Date.now(),
  },
  reducers: {
    tick: (state, action) => {
      state.currentTime = action.payload; 
    },
  },
});

export const { tick } = globalTickSlice.actions;
export default globalTickSlice.reducer;
