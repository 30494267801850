import React, { useEffect, useState } from 'react';
import { useRoomTimer } from '../../useRoomTimer';
import { BuildingConfig, FarmRoom, IslandSchemeI } from '../../../../../interfaces/farm';
import { RoomDisplay } from '../../useRoom';
import { BuildAnimation } from '../../../../../components';
import { UpgradeDataRow } from '../../../../../components/PopupUpgradeTable';

interface RoomTimerActionProps {
  room: FarmRoom | undefined;
  roomDisplay: RoomDisplay;
  scheme: IslandSchemeI;
  building?: BuildingConfig;
}

export const RoomTimerAction = (props: RoomTimerActionProps) => {
    const { room, scheme, roomDisplay, building } = props;

  const { timer, isConfirmationRequired, confirmBuildingCompletion, triggerEndBuild, onClickBuild } = useRoomTimer({ room, scheme });

  const [buildAnimation, setEndBuildAnimation] = useState(true);

  const oldData = building?.lvlInfo.find((v) => v.lvl === room?.lvl! - 1);
  const upgradeData = building?.lvlInfo.find((v) => v.lvl === room?.lvl!);

  const combinedData: UpgradeDataRow[] =
    upgradeData?.upgradeData?.map((upgradeItem) => {
      const oldItem = oldData?.upgradeData?.find((item) => item.title === upgradeItem.title);

      return {
        title: upgradeItem.title,
        nowValue: oldItem ? oldItem.value : 0, // Якщо oldData немає або параметра немає, використовуємо 0
        newValue: upgradeItem.value, // Завжди беремо нове значення з upgradeData
      };
    }) || [];

  const handleEndBuilding = () => {
    setTimeout(() => {
      setEndBuildAnimation(false);
      setTimeout(() => {
        setEndBuildAnimation(true);
      }, 5000);
    }, 1000);
  };

  useEffect(() => {
    if (triggerEndBuild) {
      handleEndBuilding();
    }
  }, [triggerEndBuild]);

  return (
    <button
      className={`overflow-visible w-full h-full flex items-center justify-center relative z-[56]`}
      style={{
        position: 'absolute',
        top: roomDisplay.y,
        left: roomDisplay.x,
        width: roomDisplay.width,
        maxHeight: roomDisplay.height,
        height: roomDisplay.height,
      }}

      onClick={() => {
        if (!timer.length) {
          confirmBuildingCompletion();
        }
        else{
            onClickBuild();
        }
      }}
    >
      <div
        className="w-full h-full flex items-center justify-center"
       
      >
        {isConfirmationRequired && !timer.length && (
          <div className="flex absolute top-10   z-[54] w-14 h-14 items-center justify-center pulse-animation ">
            <div className="absolute z-[54] w-14 h-14 ">
              <svg
                width="40"
                height="48"
                viewBox="0 0 40 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full"
              >
                <g filter="url(#filter0_d_3189_36290)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.985 39.0891C34.107 36.5452 40 28.9608 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 28.9608 5.89303 36.5452 14.015 39.0891L19.622 45.5636C19.8214 45.7938 20.1786 45.7938 20.378 45.5636L25.985 39.0891Z"
                    fill="#E9FFDC"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_3189_36290"
                    x="0"
                    y="0"
                    width="40"
                    height="47.7362"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.119963 0 0 0 0 0.270455 0 0 0 0 0.187204 0 0 0 1 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3189_36290" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3189_36290" result="shape" />
                  </filter>
                </defs>
              </svg>
            </div>
            <div className="absolute z-[54] w-10 h-10 top-1 rounded-full bg-[#4BC560] shine-animation">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-7 h-7 flex items-center justify-center">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22.8193 14.1458C22.682 14.5185 22.3386 14.9235 21.803 14.9235H17.8475V24.1584C17.8475 24.8713 17.3531 25.4546 16.7488 25.4546H11.255C10.6507 25.4546 10.1563 24.8713 10.1563 24.1584V14.9235H6.18708C5.65144 14.9235 5.32181 14.5185 5.18447 14.1458C5.04713 13.7732 5.01966 13.2061 5.39048 12.7363L13.1916 2.95051C13.4113 2.69129 13.686 2.54547 14.0019 2.54547C14.3178 2.54547 14.5925 2.69129 14.7985 2.95051L22.6133 12.7363C22.9704 13.2061 22.9567 13.7732 22.8193 14.1458Z"
                      fill="white"
                      stroke="#3F6E54"
                      strokeWidth="0.7"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}

        {buildAnimation && !isConfirmationRequired && (
          <div className="absolute w-full h-full flex items-center justify-center z-[54]">
            <BuildAnimation triggerHit={triggerEndBuild} />
          </div>
        )}

        <div className="absolute flex flex-col items-center justify-center">
          <p className="text-white text-[16px] text-stroke-small z-[53]">{timer}</p>
        </div>
      </div>

      
    </button>
  );
};
