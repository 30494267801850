import {
  Layout,
  Fit,
  Alignment,
  useRive,
  RiveFile,
} from "@rive-app/react-canvas";

export const Startbattle = ({
  className,
  riveFile,
}: {
  className?: string;
  riveFile: RiveFile;
}) => {
  // Завантаження файлу Rive
  const { rive, RiveComponent } = useRive({

    riveFile: riveFile,
    stateMachines: "State Machine 1",
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
  });



  return <RiveComponent className={className} />;
};
