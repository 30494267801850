import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';
import { motion, AnimatePresence } from 'framer-motion';
import { Card } from '../../components/Card';
import CardCanvas from './CardCanvas';
import { CardProps } from '../../interfaces/card';
import { TutorialSave } from '../../interfaces/tutorial';
import { TutorialStage } from '../../interfaces/tutorial';
import { CardType } from '../../endpoints/mock';
import { cards } from '../Dungeon/mock';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useSoundService } from '../../utils/soundService';
import { useTelegram } from '../../hooks/useTelegram';
import { useDebounce, useSessionStorage } from '@uidotdev/usehooks';
import { BattleIdleAnimation } from '../../components/animation/BattleIdleAnimation';
import BattleCardTooltip from '../../components/BattleCardTooltip';

interface DraggableCardItemProps {
  card: CardProps;
  index: number;
  isDraggingCards: boolean;
  allowedDropAreaRef: any;
  usedCards: CardProps[];
  setUsedCards: any;
  cardStrategy: any;
  cardsAtHand: CardProps[];
  usedCardsPosition: { x: number; y: number }[];
  setUsedCardsPosition: React.Dispatch<React.SetStateAction<{ x: number; y: number }[]>>;
  setCardsAtHand: React.Dispatch<React.SetStateAction<CardProps[]>>;
  removeCard: any;
  makeCardAction: any;
  setDisappearingCard: React.Dispatch<React.SetStateAction<string>>;
  updateCardTypesUsed: (newArray: CardProps[]) => void;
  tutorialSave: TutorialSave | null;
  currentMana: number;
  configCardCostById: (id: number, lvl: number) => number;
  cardIsDragged: boolean;
  updateSave: ({ save }: { save: TutorialSave }) => void;
  setCardIsDragged: (val: boolean) => void;
}

/**
 * Окремий компонент, який рендерить 1 карту, обгорнуту в DraggableCore.
 * Завдяки React.memo воно не буде даремно оновлюватись,
 * якщо пропси (card, index, positions[index], і т.д.) не змінюються.
 */
export const DraggableCardItem = memo(function DraggableCardItem(props: DraggableCardItemProps) {
  const {
    card,
    index,
    isDraggingCards,
    allowedDropAreaRef,
    usedCards,
    setUsedCards,
    removeCard,
    tutorialSave,
    currentMana,
    configCardCostById,
    cardStrategy,
    cardsAtHand,
    usedCardsPosition,
    setUsedCardsPosition,
    setCardsAtHand,
    makeCardAction,
    setDisappearingCard,
    updateCardTypesUsed,
    updateSave,
    cardIsDragged,
    setCardIsDragged,
  } = props;

  const [cardTypes, setCardTypes] = useState<{ [uid: string]: CardType }>({});

  const [draggableCard, setDraggableCard] = useState<string>('');
  const [dragOffsets, setDragOffsets] = useState<{ x: number; y: number } | null>({ x: 0, y: 0 });
  const [positions, setPositions] = useState<{ x: number; y: number } | null>({ x: 0, y: 0 });
  const [currentBattleBuildingId, setCurrentBattleBuildingId] = useSessionStorage('currentBattleBuildingId', 0);
  //const [triggerRemoveCanvas, setTriggerRemoveCanvas] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const configCards = useSelector((state: RootState) => state.appConfig.cards);

  let triggerRemoveCanvas = false;

  const { userId } = useTelegram();
  const { playSound } = useSoundService();
  const isCurrentlyDragged = draggableCard === card.uid;
  const canDrag = isDraggingCards && card.draggable !== false;
  const isFocused = positions?.x === 0 && positions?.y === 0;

  const resetTooltipTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isFocused && isCurrentlyDragged) {
      timer = setTimeout(() => {
        setShowTooltip(true);
      }, 1000);
    } else {
      setShowTooltip(false);
    }

    return () => clearTimeout(timer);
  }, [isFocused, isCurrentlyDragged]);

  const handleDragStart = useCallback(
    (e: DraggableEvent, data: DraggableData, index: number) => {
      if (!isDraggingCards) return false;
      setCardIsDragged(true);
      setDraggableCard(cardsAtHand[index].uid);
      setDragOffsets({ x: data.x, y: data.y });
    },
    [cardsAtHand, isDraggingCards]
  );

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  //const debounce = useRef<boolean>(true)
  const handleDrag = useCallback(
    (e: DraggableEvent, data: DraggableData, index: number) => {
      const newPos = { x: data.x - dragOffsets!.x, y: data.y - dragOffsets!.y };
      //Изменение координат и ререндер карты каждые 10мс
      setPositions(newPos);
      setShowTooltip(false); // Скрываем tooltip при движении

      resetTooltipTimer();

      timerRef.current = setTimeout(() => {
        setShowTooltip(true);
      }, 2000);
    },
    [dragOffsets]
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleDragStop = useCallback(
    (e: any, data: DraggableData, index: number) => {
      setDraggableCard('');
      if (!cardsAtHand[index].draggable) return false;
      setDraggableCard('');
      if (tutorialSave?.enemyId === 0) {
        updateSave({ save: { ...tutorialSave, cardIsDropped: true } });
      }
      const allowedArea = allowedDropAreaRef;
      let timeout1: NodeJS.Timeout;
      if (allowedArea && (positions?.x !== 0 || positions?.y !== 0)) {
        const rect = allowedArea.getBoundingClientRect();
        const element = e.target.getBoundingClientRect();
        if (element.top + element.height / 2 < rect.height) {
          cardsAtHand[index].draggable = false;

          setPositions({
            x: data.x - dragOffsets!.x,
            y: data.y - dragOffsets!.y,
          });

          const usedCard = cardsAtHand.filter((card, i) => i === index)[0];

          const newArrayCards = [...usedCards, usedCard];

          setUsedCards(newArrayCards);

          updateCardTypesUsed(newArrayCards);
          const cardData = configCards.find((v: any) => v.id === usedCard.id);
          if (cardData && cardData?.cardType === CardType.spell) {
            playSound('attackMagic');
          }
          if (cardData && cardData?.cardType === CardType.atk) {
            playSound('attackBow');
          }
          if (cardData && cardData?.cardType === CardType.equip) {
            playSound('defend');
          }
          if (cardData && cardData?.name === '16Red') {
            playSound('healing');
          }

          usedCardsPosition.push({ x: element.left, y: element.top });
          setUsedCardsPosition(usedCardsPosition);

          // Set triggerRemoveCanvas before removing card
          triggerRemoveCanvas = true;

          // Force immediate cleanup of canvas elements
          const canvasElements = e.target.getElementsByTagName('canvas');
          Array.from(canvasElements).forEach((canvas) => {
            //@ts-ignore
            const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
            if (gl) {
              gl.getExtension('WEBGL_lose_context')?.loseContext();
              gl.finish();
            }
            //@ts-ignore
            canvas.width = 0;
            //@ts-ignore
            canvas.height = 0;
            //@ts-ignore
            canvas.innerHtml = '';
          });

          // Continue with card removal
          setCardsAtHand(cardsAtHand.filter((card, ind) => card.uid !== usedCard.uid));
          removeCard(usedCard.uid);
          //setTutorialCardIsDropped(true);

          // setDragOffsets(null);

          makeCardAction({
            clientId: userId,
            cardUid: usedCard.uid,
            cardId: usedCard.id,
          });
          setDisappearingCard(cardsAtHand[index].uid);
          setCardIsDragged(false);
          console.log('before setTriggerRemoveCanvas');
          //setTriggerRemoveCanvas(true)
          if (cardRef.current) {
            console.log('NUKED THAT SHIT');
            //@ts-ignore
            cardRef.current.innerHTML = null;
          }
          timeout1 = setTimeout(function () {
            const newUsedCards = newArrayCards;

            setUsedCards(newUsedCards);

            const timeout2 = setTimeout(() => {
              newUsedCards.filter((card) => card.uid === usedCard.uid)[0].hidden = true;
              setUsedCards(newUsedCards);
            }, 1000);
            return () => clearTimeout(timeout2);
          }, 150);
        } else {
          setPositions({ x: 0, y: 0 });
          //setTutorialCardDroppedNotToTheEnd(true);
        }
      }
      resetTooltipTimer();
      setShowTooltip(false);
      setCardIsDragged(false);
      setPositions({ x: 0, y: 0 });
      return () => {
        clearTimeout(timeout1);
      };
    },
    [positions, cardsAtHand]
  );

  useEffect(() => {
    const updateCardTypes = () => {
      const newCardTypes = {} as any;

      const cardData = configCards.find((v) => v.id === card.id);

      if (cardData) {
        newCardTypes[card.uid] = cardData.cardType;

        cardStrategy[cardData.cardType]({ card });
      }
      setCardTypes(newCardTypes);
    };

    updateCardTypes();
  }, [cards]);

  const getRotationAngle = (index: number, totalCards: number) => {
    const maxAngle = 8;
    const minAngle = -8;
    if (totalCards <= 2) return 0;
    const angleStep = (maxAngle - minAngle) / (totalCards - 1);
    return (minAngle + index * angleStep).toFixed(2);
  };

  const getVerticalOffset = (index: number, totalCards: number) => {
    const b = totalCards < 6 ? -15 : -25;
    if (totalCards === 1) return 0;
    const angleStep = Math.PI / (totalCards - 1);
    const angle = angleStep * index;
    return (b * Math.sin(angle)).toFixed(2);
  };

  const selectedTransformStyle = {
    transform: `rotate(0deg) translateY(0)`,
  };

  useEffect(() => {
    return resetTooltipTimer;
  }, []);

  useEffect(() => {
    console.log('triggerRemoveCanvas in drag', triggerRemoveCanvas);
  }, [triggerRemoveCanvas]);

  return (
    <DraggableCore
      onStart={(e, data) => handleDragStart(e, data, index)}
      onDrag={(e, data) => handleDrag(e, data, index)}
      //@ts-ignore
      onStop={(e, data) => handleDragStop(e, data, index)}
      disabled={!canDrag}
    >
      <AnimatePresence>
        <motion.div
          ref={cardRef}
          key={card.uid}
          className={`
            max-w-[30%] aspect-[0.67]
            ${currentMana < configCardCostById(card.id, card.lvl) ? 'pointer-events-none grayscale' : ''}
          `}
          style={{
            width: `calc(100% / ${cardsAtHand.length} + 75px)`,
            marginLeft:
              index !== 0
                ? cardsAtHand.length < 4
                  ? '6px' // карток менше ніж 4
                  : cardsAtHand.length < 6
                  ? '-10%' // карток менше ніж 6
                  : '-20%' // карток більше ніж 6
                : '0',
            zIndex: isCurrentlyDragged ? 10 : 9,
          }}
          // Приклад анімації входу
          initial={{ opacity: 0, x: 70, y: 170, scale: 0.1, rotate: 0 }}
          animate={{
            opacity: 1,
            x: 0,
            y: 0,
            transform: isCurrentlyDragged
              ? `${selectedTransformStyle.transform} ${
                  isFocused
                    ? `scale(1.3) 
                  ${
                    tutorialSave?.enemyId === 0 && index === 1 && tutorialSave?.stage === TutorialStage.battle
                      ? 'translateY(0px)'
                      : 'translateY(-130px)'
                  }  `
                    : ' scale(1) translateY(0)'
                }
                `
              : `rotate(${getRotationAngle(index, cardsAtHand.length)}deg) translateY(${getVerticalOffset(
                  index,
                  cardsAtHand.length
                )}px) scale(1)`,
            // Анімація marginLeft до потрібного значення
            marginLeft: index !== 0 ? (cardsAtHand.length < 4 ? '6px' : cardsAtHand.length < 6 ? '-10%' : '-20%') : '0',
            willChange: 'transform',
            position: 'relative',
          }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {/* 
            Тут можна додати логіку "кут нахилу" та "вертикального зсуву", якщо карта не обрана:
            Якщо карта зараз "тягнеться" (draggableCard === card.uid) - одна трансформація,
            якщо ні — інша (обчислена).
          */}
          <div
            style={{
              transform: `translate(${positions!.x}px, ${positions!.y}px)`,
              position: 'relative',
              willChange: 'transform',
            }}
          >
            {
              <div
                style={{
                  opacity: isCurrentlyDragged ? 1 : 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%', // Збільшена ширина
                  height: '95.5%', // Збільшена висота
                  zIndex: 100,
                  transition: 'width 0.3s ease, height 0.3s ease',
                }}
              >
                {/* Якщо треба Canvas чи "підсвітка" */}
                <CardCanvas
                  cardId={cardTypes[card.uid]}
                  triggerRemoveCanvas={triggerRemoveCanvas}
                />
              </div>
            }
            {index === Math.floor(cardsAtHand.length / 2) &&
              isDraggingCards &&
              ((tutorialSave?.stage === TutorialStage.battle && !tutorialSave?.completed) ||
                currentBattleBuildingId === 2) && (
                <BattleIdleAnimation
                  cardAvailable={currentMana >= configCardCostById(card.id, card.lvl)}
                  draggableCard={cardIsDragged}
                  isTutorStageBattle={tutorialSave?.stage === TutorialStage.battle && !tutorialSave?.completed}
                  hasManyCards={cardsAtHand.length > 3}
                >
                  <Card
                    id={card.id}
                    lvl={card.lvl}
                    uid={card.uid}
                    glow={false}
                    IsDraggable={isDraggingCards}
                    triggerRemoveCanvas={triggerRemoveCanvas}
                  />
                </BattleIdleAnimation>
              )}

            {/* Звичайний рендер самої картки: */}
            <Card
              key={card.uid}
              id={card.id}
              lvl={card.lvl}
              uid={card.uid}
              glow={true}
              IsDraggable={isDraggingCards}
              // isCurrentlyDragged={isCurrentlyDragged}
              // isFocused={isFocused}
              showTooltip={showTooltip}
              card={card}
              triggerRemoveCanvas={triggerRemoveCanvas}
            />
          </div>
        </motion.div>
      </AnimatePresence>
    </DraggableCore>
  );
});
