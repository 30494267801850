import { generateRequestId } from '../utils/generateRequestId';
import { sendRequestAndGetResponse } from '../Websocket/websocketInit';
import { RequestWebsocket } from '../interfaces/wsInterfaces';
import { useDispatch } from 'react-redux';
import { Profile } from '../interfaces/profile';
import { User } from '../interfaces/user';

export const getProfile = async (): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'user',
    lang: 'en',
    method: 'profile_getProfileInfo',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      //deviceId: clientId
    },
    params: {
      userData: {
        id: '12345678',
        username: 'user12345',
        first_name: 'Name',
        last_name: 'Lastname',
        language_code: 'uk',
        is_premium: true,
        allows_write_to_pm: true,
      },
      walletAddress: 'xo332o432454345gdfgsdfg',
    },
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (result?.value) {
      return result.value.profile;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message;
  }
};

export const getProfileInfo = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-user',
    lang: 'en',
    method: 'user_getUser',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      //deviceId: clientId
    },
    params: params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);

    if (result?.value) {
      return result.value as Profile;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message;
  }
};

export const createProfile = async (params: User): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-user',
    lang: 'en',
    method: 'user_createUser',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const updateProfileWallet = async (params: { clientId: string; wallet: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-user',
    lang: 'en',
    method: 'user_updateWallet',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const deleteProfile = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-lobby',
    lang: 'en',
    method: 'lobbyEndpoints_removeAllByClientId',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const getAvailableOffers = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'tg-battler-bot',
    lang: 'en',
    method: 'userPayments_getAvailableOffers',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);

    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const getUserOffers = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'tg-battler-bot',
    lang: 'en',
    method: 'userPayments_getUserOffers',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    if (result.type === 'Left') {
      return false;
    }
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const getDailyFreebieStatus = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'dailyChest_isCanClaim',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const claimFreebie = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-quests',
    lang: 'en',
    method: 'dailyChest_claim',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const getUserDeposites = async (params: { clientId: string }): Promise<any> => {
  const request: RequestWebsocket = {
    agent: 'battler-tg-ton-wrapper',
    lang: 'en',
    method: 'admin_getDepositedByClientId',
    id: generateRequestId(),
    auth: {
      type: 'mobileApp',
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};
