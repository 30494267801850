import { CSSProperties, useEffect, useState } from 'react';
import { BossDungeonBtnAnimation } from '../../../../components/animation/BossDungeonBtnAnimation';
import { BossStatus } from '../..';

const Star = () => {
  return (
    <div className={` w-[1em] h-[1em]`}>
      <img src={require('../../../../assets/images/stars/full-star.png')} className="w-full h-full" alt="" />
    </div>
  );
};

export const BossEnemyBtn = ({
  enemy,
  onClick,
  activeEnemy,
}: {
  enemy: {
    enemyId: number;
    dungeonId: number;
    status: number;
    title: string;
    stars: string;
  };
  onClick: (enemyId: number) => void;
  activeEnemy: number;
}) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [animate, setAnimate] = useState<string>('');
  const [popupExpandOffset, setPopupexpandOffset] = useState<number>(0);
  const [triggerAnimation, setTriggerAnimation] = useState<boolean>(false);
  const [currentEnemyId, setCurrentEnemyId] = useState<number | undefined>();

  useEffect(() => {
    setCurrentEnemyId(enemy?.enemyId);
  }, []);

  const stars: Record<number, Record<number, string>> = {
    0: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: '',
    },
    1: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: '',
    },
    2: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: '',
    },
    3: {
      0: '',
      1: 'translate-y-[10px]',
      2: '',
      3: '',
      4: '',
    },
    4: {
      0: 'translate-x-[5px]',
      1: 'translate-y-[10px]',
      2: 'translate-y-[10px]',
      3: 'translate-x-[-5px]',
      4: '',
    },
    5: {
      0: 'translate-y-[-10px] translate-x-[10px]',
      1: 'translate-x-[5px] ',
      2: 'translate-y-[10px]',
      3: 'translate-x-[-5px]',
      4: 'translate-y-[-10px] translate-x-[-10px]',
    },
  };

  return (
    <div
      className={` w-[92px] h-[140px] ${enemy.dungeonId === activeEnemy ? 'scale-125' : ''} ${enemy.status === BossStatus.locked ? 'pointer-events-none grayscale' : enemy.status === BossStatus.completed ? 'pointer-events-none' : ''} transition-transform `}
      onClick={() => {
        onClick(enemy.dungeonId);
        setTriggerAnimation(true);
        setTimeout(() => {
          setTriggerAnimation(false);
        }, 1000);
      }}
    >
      {/* BUTTON BACKGROUND DEPENDING ON STATUS */}
      {enemy.dungeonId === activeEnemy && (
        <div
          className={`absolute w-[650px] h-[650px] top-[50%] left-[50%] translate-x-[-49.6%] translate-y-[-50%] pointer-events-none `}
        >
          <BossDungeonBtnAnimation triggerHit={triggerAnimation} />
          {/* <img src={preloadedBossBg} alt="" /> */}
        </div>
      )}

      <div className={`w-full h-full `}>
        {/* Nickname block with borders */}
        <div className={`relative w-full`}>
          <div
            className={`absolute w-full top-[-1px] h-[1px] bg-gradient-to-r from-transparent via-white to-transparent`}
          ></div>
          <div
            className={`absolute w-full bottom-[-1px] h-[1px] bg-gradient-to-r from-transparent via-white to-transparent`}
          ></div>
          <div
            className={`w-full text-center text-[13px]`}
            style={{
              background:
                'linear-gradient(to right, transparent, rgba(0,0,0,0.4), rgba(0,0,0,0.5), rgba(0,0,0,0.4), transparent )',
            }}
          >
            {enemy.title}
          </div>
        </div>
        <div className={`relative h-full`}>
          {/* BLURED BG FOR COMPLETED BOSS */}
          {enemy.status === BossStatus.completed && (
            <div
              className={`absolute top-2 w-full flex justify-center items-center aspect-square rounded-full scale-[0.7] bg-[rgba(0,0,0,0.5)] z-[10]`}
              style={{ clipPath: 'circle(50.0% at 50% 50%)' }}
            >
              <img
                className={`w-[60px] h-[45px]`}
                src={require('../../../../assets/images/bossDungeon/boss-enemy-completed.png')}
                alt=""
              />
            </div>
          )}

          {/* BORDER FOR ENEMY */}
          <img
            className={`absolute z-[11] top-3`}
            src={require('../../../../assets/images/bossDungeon/boss-dungeon-border-1.webp')}
            alt=""
          />

          {/* ENEMY AND BG */}
          <div
            className={`absolute w-full aspect-square top-2 bg-[#FACB3C] scale-[0.7] z-[9] `}
            style={{ clipPath: 'circle(50.0% at 50% 50%)' }}
          >
            <img
              className={`w-full h-full`}
              src={require(`../../../../assets/images/bossDungeon/boss-dungeon-enemy${enemy.dungeonId}.webp`)}
              alt=""
            />
          </div>

          {/* LABEL FOR THE BUTTON */}
          <div
            className={`absolute w-full h-full top-[16px] bg-cover bg-no-repeat bg-center scale-[0.7]
                before:content-[""] before:absolute before:bg-cover before:bg-no-repeat before:scale-[0.85] before:bg-center before:w-full before:h-full before:top-0 before:bg-[url('./assets/images/bossDungeon/boss-dungeon-label.webp')]
                 `}
          ></div>
          <span
            className={`absolute flex justify-center bottom-[30px] z-[15] w-full text-center text-[20px] text-[#33EA85] text-stroke-small `}
          >
            {Array(Number(enemy.stars))
              .fill(<Star />)
              .map((S, index) => (
                <div className={`relative ${stars[Number(enemy.stars)][index]}`}>{S}</div>
              ))}
          </span>
        </div>
      </div>
    </div>
  );
};
