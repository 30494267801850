import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TutorialFarmSave, TutorialFarmStage } from "../../interfaces/tutorial";
import { getFarmTutorialProgress, updateFarmTutorialProgress } from "../../endpoints/tutorialProgress";
import { RootState } from "../store";

interface TutorialFarmSaveState {
  tutorialFarmSave: {
    save: TutorialFarmSave | null;
  };
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: TutorialFarmSaveState = {
  tutorialFarmSave: { save: null },
  status: "idle",
  error: null,
};

export const fetchFarmTutorialProgress = createAsyncThunk(
  "farmTutorial/fetchProgress",
  async (clientId: string) => {
    const response = await getFarmTutorialProgress({ clientId });

    if (!response) {
      throw Error();
    }
    return response;
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const { farmTutorial } = state;
      // Логіка перевірки:
      // якщо islands завантажені (і ще актуальні),
      // можемо сказати що немає потреби знову робити запит
      if (farmTutorial.tutorialFarmSave.save?.completed) {
        // Повертаємо false, щоб "скасувати" запит
        return false;
      }
    },
  }
);

export const saveFarmTutorialProgress = createAsyncThunk(
  "farmTutorial/saveProgress",
  async ({ clientId, save }: { clientId: string; save: TutorialFarmSave }) => {
    await updateFarmTutorialProgress({
      clientId,
      save: JSON.stringify(save),
    });
    return save;
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const { farmTutorial } = state;
      // Логіка перевірки:
      // якщо islands завантажені (і ще актуальні),
      // можемо сказати що немає потреби знову робити запит
      if (farmTutorial.tutorialFarmSave.save && farmTutorial.tutorialFarmSave.save?.completed && farmTutorial.tutorialFarmSave.save?.stage! >= TutorialFarmStage.finishSecondBuilding) { 
        // Повертаємо false, щоб "скасувати" запит
        return false;
      }
    },
  }
);

const farmTutorialSlice = createSlice({
  name: "farmTutorial",
  initialState,
  reducers: {
    setFarmSave(state, action) {
      state.tutorialFarmSave.save = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFarmTutorialProgress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFarmTutorialProgress.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.tutorialFarmSave.save = action.payload;
      })
      .addCase(fetchFarmTutorialProgress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch progress";
      })
      .addCase(saveFarmTutorialProgress.fulfilled, (state, action) => {
        state.tutorialFarmSave.save = action.payload;
      });
  },
});

export const { setFarmSave } = farmTutorialSlice.actions;
export default farmTutorialSlice.reducer;
