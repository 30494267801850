import { useTranslation } from 'react-i18next';
import { useUtils } from '../../utils/navigateTo';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { TutorialFarmSave, TutorialFarmStage } from '../../interfaces/tutorial';
import { saveFarmTutorialProgress } from '../../app/features/farmTutoralSlice';
import { useTelegram } from '../../hooks/useTelegram';
import React, { useEffect, useState } from 'react';
import { getMissions, getQuests, getSocialTasks } from '../../endpoints/userQuests';
import { MissionCondition, MissionTask, TaskStatus } from '../../app/features/questsSlice';
import { AnimatePresence, motion } from 'framer-motion';
import { QuestsEvents } from '../../enums/questsEvents';
import { eventsMap } from '../../utils/mapping';
import { fetchUserRank, clearUserRank } from '../../app/features/userRankSlice';
import { useSessionStorage } from '@uidotdev/usehooks';
import ReImg from '../ReloadableImage';

interface ConfigQuest {
  questId: {
    value: number;
    description: string;
  };
  rewards: {
    value: {
      rewardId: number;
      amount: number;
      rewardType: number;
      multiplyByShirine: boolean;
    };
    description: string;
  };
  conditions: {
    value: {
      event: string;
      amount: number;
    };
  };
  isVIP?: {
    value: boolean;
    description: string;
  };
}

export const QuestsButton = () => {
  const { t } = useTranslation();
  const { navigateTo } = useUtils();
  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);
  const [exit, setExit] = useState<boolean>(false);
  const [currentQuestDescription, setCurrentQuestDescription] = useState<any>('');
  const [userIsVip, setUserIsVip] = useState<boolean>(false);
  //const [completedQuests, setCompletedQuests] = useState<any[]>([]);
  const [combinedQuests, setCombinedQuests] = useState<any[]>([]);
  const [missionTask, setMissionTask] = useState<any>();
  const [displayedTask, setDisplayedTask] = useState<MissionTask | undefined>();
  const [openMissionsTab, setOpenMissionsTab] = useSessionStorage('openMissionsTab', false);

  const { userId } = useTelegram();

  const dispatch = useDispatch<AppDispatch>();
  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };
  const rank = useSelector((state: RootState) => state.userRank);

  useEffect(() => {
    if (rank.userRank?.myRankLvl! >= 5) {
      setUserIsVip(true);
    } else setUserIsVip(false);
  }, [rank]);

  useEffect(() => {
    dispatch(fetchUserRank({ clientId: userId }));

    return () => {
      dispatch(clearUserRank());
    };
  }, [dispatch]);

  const { userRank, loading, error } = useSelector((state: RootState) => state.userRank);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const dailyQuests = useSelector((state: RootState) => state.quests.quests);
  const {
    quests: { variables: allQuests },
  } = appConfig;

  useEffect(() => {
    const getMissionsData = async () => {
      const result = await getMissions({ clientId: userId });
      setMissionTask(result);
      return result;
    };
    getMissionsData();
  }, []);

  useEffect(() => {
    if (missionTask) {
      if (missionTask?.sideAchievements?.length > 0) {
        setDisplayedTask(missionTask.sideAchievements[0]);
      } else if (missionTask?.currentMainAchievement) {
        setDisplayedTask(missionTask.currentMainAchievement);
      } else {
        setDisplayedTask(undefined);
      }
    }
  }, [missionTask]);

  useEffect(() => {
    const message = displayedTask?.config?.conditions.map((condition: MissionCondition) => {
      const { value, type } = condition;
      const action = t(`missions.condition.actionType.${type.toString()}`);
      const fullMessage = `${action}: ${value}`;
      return fullMessage;
    });

    setCurrentQuestDescription(message);
  }, [displayedTask]);

  //Passing quests through config
  const allQuestsById =
    Array.isArray(allQuests) && allQuests.length > 0
      ? allQuests.reduce((acc: any, quest: any) => {
          acc[quest.questId.value] = quest;
          return acc;
        }, {})
      : {};

  //Returning full version of quest
  useEffect(() => {
    const combinedQuestsList = (dailyQuests ?? [])
      .map((dq) => {
        const correspondingQuest = allQuestsById[dq.taskId];
        if (correspondingQuest) {
          return {
            ...correspondingQuest,
            status: dq.status,
            currentProgress: dq.currentProgress,
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);

    setCombinedQuests(combinedQuestsList);
  }, [dailyQuests]);

  //Getting quests with completed status
  // useEffect(() => {
  //   const completedQuestsList = userIsVip
  //     ? combinedQuests.filter((dq) => {
  //         return dq?.status === 1;
  //       })
  //     : combinedQuests.filter((dq) => {
  //         return dq?.status === 1 && dq?.isVIP?.value === false;
  //       });
  //   // console.log('completed quests list', completedQuestsList)
  //   setCompletedQuests(completedQuestsList);
  // }, [combinedQuests]);

  useEffect(() => {
    const fetchQuests = async () => {
      try {
        const fetchedQuests = await getQuests({
          clientId: userId,
        });
      } catch (err) {
        console.log('Failed to load quests.');
      }
    };
    const fetchSocialTasks = async () => {
      try {
        const fetchedSocialTasks = await getSocialTasks({
          clientId: userId,
        });
      } catch (err) {
        console.log('Failed to load social tasks.');
      }
    };

    fetchSocialTasks();
    fetchQuests();
  }, [userId]);

  const slideQuestVariants = {
    hidden: { width: '0', opacity: '0' },
    visible: { width: 'calc(100vw - 125px)', opacity: '1', maxWidth: 'calc(550px - 125px)' },
  };

  const questTextVariants = {
    hidden: { transform: 'translateY(-100%) translateX(-50%)', opacity: '0' },
    visible: { transform: 'translateY(-50%) translateX(-50%)', opacity: '1' },
  };

  //Timeout for animation
  useEffect(() => {
    if (exit) {
      const timer = setTimeout(() => {
        setExit(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [exit]);

  return (
    <div className="select-none cursor-pointer w-14 h-10 pb-[2px] bg-[#452B31] border border-black rounded-lg shadow-lg  relative transform transition-transform duration-150 active:scale-95">
      <div
        className="w-full h-full rounded-lg flex justify-center items-center bg-gradient-to-b from-[#FEE7BA] to-[#B6765A] p-1 relative z-[2]"
        onClick={() => {
          if (farmTutorialSave?.stage === TutorialFarmStage.clickOnQuests) {
            updateSave({
              stage: TutorialFarmStage.clickOnQuestList,
            });
          }
          navigateTo('/quests');
        }}
      >
        <div className=" relative flex flex-col items-center w-7 h-7">
          <div className="relative w-full flex justify-center items-center overflow-visible">
            <ReImg
              src={require('../../assets/images/farmIcons/quests.png?type=base64')}
              className="absolute -top-3 w-[34px] h-[34px] max-w-none"
            />
          </div>
          <div
            className="absolute -top-2.5 text-center text-white text-sm font-bold leading-3 mt-8"
            style={{
              filter:
                'drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)',
            }}
          >
            {t('quests.name')}
          </div>
        </div>
        <QuestsNotificationDot />
      </div>
      <AnimatePresence>
        {!exit &&
        displayedTask &&
        farmTutorialSave?.stage !== TutorialFarmStage.clickOnQuests &&
        farmTutorialSave?.stage !== TutorialFarmStage.clickOnQuestList &&
        farmTutorialSave?.stage !== TutorialFarmStage.clickOnCompletedQuest &&
        farmTutorialSave?.stage !== TutorialFarmStage.claimQuest &&
        (
          <motion.div
            variants={slideQuestVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ duration: 0.3, delay: 0.5 }}
            onClick={() => {
              setExit(true);
              navigateTo('/quests');
              setOpenMissionsTab(true);
            }}
            key={displayedTask?.id}
            className={`absolute h-[29px] top-[50%] translate-y-[-50%] left-12 `}
          >
            {displayedTask?.config.baseQuestId ? (
              <img
                className={`w-full h-full`}
                src={require('../../assets/images/quests/quest-tile-uncompleted.png')}
                alt=""
              />
            ) : (
              <img
                className={`w-full h-full`}
                src={require('../../assets/images/quests/quest-tile-completed.png?type=base64')}
                alt=""
              />
            )}
            <motion.div
              variants={questTextVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: 0.8 }}
              className={`absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] w-[90%] text-center text-nowrap overflow-scroll text-white text-stroke-small`}
            >
              {currentQuestDescription}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const QuestsNotificationDot = () => {
  const [completedQuestsAndTasks, setCompletedQuestsAndTasks] = useState<number>(0);

  const dailyQuests = useSelector((state: RootState) => state.quests.quests);
  const socialTask = useSelector((state: RootState) => state.quests.socialTask);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const isVipTasksAllowed = useSelector((state: RootState) => state.quests.isVipTasksAllowed);

  const {
    quests: { variables: allQuests },
  } = appConfig;

  const checkComplition = () => {
    let counter = 0;
    dailyQuests.map((quest) => {
      if (
        (quest.status === TaskStatus.Done &&
          !allQuests.filter((q: ConfigQuest) => q.questId.value === quest.taskId)[0]?.isVIP?.value) ||
        (quest.status === TaskStatus.Done &&
          allQuests.filter((q: ConfigQuest) => q.questId.value === quest.taskId)[0]?.isVIP?.value &&
          isVipTasksAllowed)
      ) {
        counter++;
      }
    });
    socialTask.map((task) => {
      if (task.status === TaskStatus.Done) {
        counter++;
      }
    });
    setCompletedQuestsAndTasks(counter);
  };

  useEffect(() => {
    checkComplition();
  }, [dailyQuests, socialTask]);

  return completedQuestsAndTasks ? (
    <div className="absolute flex items-center justify-center -top-[6px] -right-[6px] z-50 w-4 h-4">
      {completedQuestsAndTasks > 1 && (
        <span className="text-center text-white font-bold text-[10px] z-10">{completedQuestsAndTasks}</span>
      )}
      <div className="w-4 min-w-4 h-4 min-h-4 bg-[#ff3a3a] border border-[black] rounded-full absolute top-0 right-0"></div>
    </div>
  ) : null;
};
