import { motion } from "framer-motion";
import React from "react";

interface HeartStopProps {
  usedLives: number;
  handleLoose: () => void;
}

export const HeartStop = ({
  usedLives,
  handleLoose,
}: HeartStopProps) => {
  const [triggerEchoAnim, setTriggerEchoAnim] = React.useState(false);
  const [triggerGrayAnim, setTriggerGrayAnim] = React.useState(false);

  const heartVariants = {
    hidden: { x: "-100vw", scale: 0, opacity: 0 },
    visible: {
      x: 0,
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const beatVariants = {
    hidden: { scale: 0, opacity: 0.5 },
    visible: {
      scale: 10,
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: "easeOut",
      },
    },
  };

  const pulseAnimation = {
    scale: [1, 1.2, 1],
    transition: {
      duration: 0.2,
      ease: "easeInOut",
      times: [0, 0.5, 1],
    },
  };

  const grayScaleAnimation = {
    filter: ["grayscale(0%)", "grayscale(100%)"],
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  };

  React.useEffect(() => {
    if (triggerGrayAnim) {
      const timeout = setTimeout(() => {
        if (usedLives < 1) {
          handleLoose();
        } else {
          handleLoose();
        }
      }, 500);
      return () => clearTimeout(timeout)
    }
  }, [triggerGrayAnim]);

  return (
    <>
      {/* Основное сердце */}
      <motion.div
        className="fixed inset-0 z-20 flex justify-center items-center"
        initial="hidden"
        animate="visible"
        variants={heartVariants}
        onAnimationComplete={() => {
          setTriggerEchoAnim(true);
        }}
      >
        <motion.img
          src={require("../../../assets/images/Herz_Big.png")}
          alt="Heart"
          animate={
            triggerGrayAnim
              ? grayScaleAnimation
              : triggerEchoAnim
              ? pulseAnimation
              : {}
          }
          onAnimationComplete={() => {
            if (triggerEchoAnim) setTriggerGrayAnim(true);
          }}
        />
      </motion.div>

      {/* Эффект "эха" */}
      {triggerEchoAnim && (
        <motion.div
          className="fixed inset-0 z-30 flex justify-center items-center"
          initial="hidden"
          animate="visible"
          variants={beatVariants}
          onAnimationComplete={() => {
            setTriggerGrayAnim(true);
          }}
        >
          <img
            src={require("../../../assets/images/Herz_Big.png")}
            alt="Beat"
            className="w-40 h-40 opacity-50"
          />
        </motion.div>
      )}

      {/* Фон */}
      <motion.div
        className="h-full w-full absolute bg-black z-10"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 0.8,
            transition: {
              duration: 0.5,
              ease: "easeOut",
            },
          },
        }}
      ></motion.div>
    </>
  );
};
